import { Main, Page } from "../components/Elements/Elements";
import Sidebar from "../components/Navigation/Sidebar";
import { Test as TestComponent } from "../components/Test/Test";

const Test = () => {
  return (
    <Page name="career">
      <Sidebar />
      <Main>
        <TestComponent />
      </Main>
    </Page>
  );
};

export default Test;
