import segmentPlugin from "@analytics/segment";
import { Analytics, AnalyticsInstance } from "analytics";
import { Markup } from "interweave";
import { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import shaka from "shaka-player/dist/shaka-player.compiled";
import Context from "../../context/Context";
import historyNavigate from "../../hooks/useHistoryNavigate";
import useRequest from "../../hooks/useRequest";
import { useUser } from "../../hooks/useUser";
import ArrowSVG from "../../Icons/ArrowSVG";
import BubbleFilledSVG from "../../Icons/BubbleFilledSVG";
import BubbleOutlineSVG from "../../Icons/BubbleOutlineSVG";
import ListSVG from "../../Icons/ListSVG";
import PlaySVG from "../../Icons/PlaySVG";
import { AcademyResponseDto, Paginated } from "../../requests/interfaces";
import { CountdownTimer, MasterclassEarlyBirdPopup } from "../Elements/Components";
import {
  AllAcademiesCTAPopup,
  Button,
  Component,
  ComponentCard,
  Icon,
  Image,
  LevelBar,
  MasterclassBuyButton,
  Paragraph,
  ProfileBubble,
  Section,
  SectionElement,
  TagsFiltered,
  Title,
  Wrapper,
} from "../Elements/Elements";
import { SkillsFilter } from "../Elements/FormElements";
import { Loading } from "../Loading/Loading";

const AllMasterclasses = () => {
  const { apiRequest } = useRequest();

  const [academies, setAcademies] = useState<AcademyResponseDto[]>([]);
  const [featuredAcademies, setFeaturedAcademies] = useState<AcademyResponseDto[]>();
  const [filters, setFilters] = useState<string[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);

  const analytics =
    process.env.REACT_APP_NODE_ENV === "production"
      ? Analytics({
          app: "sdrs-of-germany",
          plugins: [
            segmentPlugin({
              writeKey: `C3tmMQhdfyXjKbiNy1j39r1z0dso8kXd`,
            }),
          ],
        })
      : undefined;

  useEffect(() => {
    const loadFeaturedAcademies = async () => {
      const { data } = await apiRequest<Paginated<AcademyResponseDto>>(
        `academy?featuredFilter=featured`,
        "GET",
      );
      setFeaturedAcademies(data.docs);
    };
    loadFeaturedAcademies();
  }, []);

  const getAcademies = async (resetPage?: boolean) => {
    const filterString = filters?.join("&");
    const url = `academy?page=${resetPage ? 0 : page}${
      filterString ? `&${filterString}` : ""
    }&limit=8`;

    const { data } = await apiRequest<Paginated<AcademyResponseDto>>(url, "GET");

    setAcademies((oldValues) => {
      const combined = [...(oldValues ?? []), ...data.docs];
      const final: AcademyResponseDto[] = [];
      if (filterString !== "" || resetPage) {
        return data.docs;
      }
      combined.map((value) => {
        const index = final.findIndex((item) => item._id === value._id);
        if (index === -1) {
          final.push(value);
        }
      });
      return final;
    });

    setHasNextPage(data.hasNextPage);
    if (data.hasNextPage) {
      setPage(data.page + 1);
    }
  };

  useEffect(() => {
    getAcademies(true);
  }, [filters]);

  const [popupIsOpen, setPopupIsOpen] = useState<boolean>();
  const { setPopupContent, isMobile } = useContext(Context);

  useEffect(() => {
    if (setPopupContent) {
      if (popupIsOpen) {
        setPopupContent(<AllAcademiesCTAPopup setPopupOpen={setPopupIsOpen} />);
      } else {
        setPopupContent(false);
      }
    }
  }, [popupIsOpen]);

  const onBuyButtonClick = () => {
    setPopupIsOpen(true);
  };

  return (
    <Section name="academy">
      <SectionElement type="header" name="academy">
        <AcademyHero academies={featuredAcademies ?? []} onBuyButtonClick={onBuyButtonClick} />
      </SectionElement>
      <SectionElement type="body" name="academy">
        <Component name="academy-masterclass-filters">
          <SkillsFilter
            onFilterChange={setFilters}
            showLabel={isMobile}
            type={isMobile ? "large" : "small"}
          />
        </Component>
        <InfiniteScroll
          dataLength={academies.length}
          next={getAcademies}
          hasMore={hasNextPage}
          loader={<h4>Loading...</h4>}
          scrollableTarget="parent-component">
          <Component name="academy-masterclasses">
            {academies?.map((academy, i) => {
              return (
                <AcademyItem
                  key={i}
                  academy={academy}
                  filters={filters}
                  toDelete={i}
                  analytics={analytics}
                />
              );
            })}
          </Component>
        </InfiniteScroll>
        {academies === undefined && <Loading />}
      </SectionElement>
      <SectionElement type="footer" name="academy"></SectionElement>
    </Section>
  );
};

export const AcademyItem = (props: {
  academy: AcademyResponseDto;
  filters: string[];
  toDelete: number;
  analytics?: AnalyticsInstance;
  noPopup?: boolean;
  onClick?: (acadmey: AcademyResponseDto) => void;
}) => {
  const academy = props.academy;
  const { useHistoryNavigate } = historyNavigate();
  const [hovering, setHovering] = useState<boolean>();
  const [isOpen, setOpen] = useState<boolean>();
  const { setPopupContent } = useContext(Context);
  const analytics = props.analytics;
  const { user } = useUser();
  const analyticsUser = {
    id: user?._id,
    name: `${user?.firstName} ${user?.lastName}`,
    email: user?.email,
  };

  useEffect(() => {
    if (setPopupContent && !props.noPopup) {
      if (isOpen) {
        if (analytics) {
          analytics.track("clicked-on-soon-available-academy", {
            title: props.academy.title,
            user: analyticsUser,
          });
        }
        setPopupContent(<MasterclassEarlyBirdPopup setOpen={setOpen} />);
      } else {
        setPopupContent(undefined);
      }
    }
  }, [isOpen]);

  return (
    <ComponentCard
      name="academy-masterclass"
      className={!props.academy.isBought || props.academy.academyProgress == 0 ? "has-flag" : ""}
      onClick={() => {
        if (analytics) {
          analytics.track(`clicked-academy-${props.academy.isBought ? "bought" : "not-bought"}`, {
            title: props.academy.title,
            user: analyticsUser,
          });
        }
        if (new Date(academy.releaseDate).getTime() > new Date().getTime()) {
          setOpen(true);
        } else if (props.onClick) {
          return props.onClick(academy);
        } else {
          useHistoryNavigate(`?academyId=${academy._id}`);
        }
      }}
      onMouseEnter={() => !hovering && setHovering(true)}
      onMouseLeave={() => hovering && setHovering(false)}>
      <Component name="test">
        <Image img={academy.thumbnailUrl} name="academy-masterclass-thumbnail" />
        <CountdownTimer
          display={new Date(academy.releaseDate).getTime() > new Date().getTime()}
          targetDate={new Date(academy.releaseDate)}
        />
      </Component>
      <Component name="academy-masterclass-info">
        <TagsFiltered hashtags={academy.skills} filters={props.filters} />
        <Component name="academy-masterclass-meta">
          <Title type="h2" size="xs" name="academy-masterclass-title" className="txt--2lines">
            {academy.title}
          </Title>
          <Wrapper
            wrType="paragraph"
            name="academy-masterclass-description"
            className="txt--1line p--m">
            <Markup content={academy.description} />
          </Wrapper>
        </Component>
        <Component name="academy-masterclass-info-footer">
          <ProfileBubble user={academy.instructors[0]} />
          {academy.isBought && academy.academyProgress > 0 && (
            <Component name="academy-progress">
              <Paragraph name="media-library-overview-course-certificates" size="m">
                {`${academy.academyProgress}/${academy.tracksCount} Lektionen abgeschlossen`}
              </Paragraph>
              <LevelBar
                points={academy.academyProgress}
                pointsToNextLevel={academy.tracksCount}
                withHover={false}
                size="m"
              />
            </Component>
          )}
        </Component>
      </Component>
      <Component name="flag" style={{ position: "absolute" }}>
        {!academy.isBought && (
          <Paragraph name="flag" size="m" className="txt--c-on-pri">
            Jetzt Freischalten
          </Paragraph>
        )}
        {academy.academyProgress < 1 && academy.isBought && !props.noPopup && (
          <Paragraph name="flag" size="m" className="txt--c-on-pri">
            Jetzt starten
          </Paragraph>
        )}
        {props.noPopup && (
          <Paragraph name="flag" size="m" className="txt--c-on-pri">
            noch nicht gestartet
          </Paragraph>
        )}
      </Component>
      {/*<Component name="academy-course-infos">
        <Title name="academy-course-infos" size="xxs" type="h4" className="txt--c-pri">
          Über diese Masterclass
        </Title>
        <Markup content={academy.description} />
      </Component>*/}
    </ComponentCard>
  );
};

export const AcademyHero = (props: {
  academies: AcademyResponseDto[];
  onBuyButtonClick?: () => void;
  type?: "mediaLibrary";
}) => {
  const [index, setIndex] = useState<number>(props.academies.length !== 1 ? -1 : 0);

  const { useHistoryNavigate } = historyNavigate();
  const { isMobile } = useContext(Context);

  const controllerRef = useRef<any>(null);
  const playerRef = useRef<any>(null);

  useEffect(() => {
    if (props.academies.length !== 0) {
      shaka.polyfill.installAll();

      const player = new shaka.Player(controllerRef?.current);
      playerRef.current = player;
      const initPlayer = async () => {
        player.addEventListener("error", (e: any) => {
          console.log(e);
        });
        player.configure({
          abr: {
            enabled: false,
          },
        });

        try {
          await player.load(props.academies[index].marketingVideoUrl);
          player.play();
        } catch (e) {
          // console.log(e);
          // onError is executed if the asynchronous load fails.
        }
      };
      if (shaka.Player.isBrowserSupported()) {
        initPlayer();
      }
      return () => {
        player.destroy();
      };
    }
  }, [props.academies, index]);

  useEffect(() => {
    setIndex(props.academies.length !== 1 ? -1 : 0);
  }, [props.academies]);

  return (
    <Component name="academy-header">
      <>
        <Component name="academy-header-information">
          {index !== -1 && (
            <ComponentCard name="academy-header-information-card">
              <Title name="academy-header-title" className="txt--2lines" size="m" type="h1">
                {props.academies[index].title}
              </Title>
              <Wrapper wrType="paragraph" className={`${isMobile ? "" : "txt--4lines"} p--m`}>
                <Markup content={props.academies[index].description} />
              </Wrapper>
              <Component name="academy-header-track-cta">
                <Component name="academy-header-track-number">
                  <Icon name="academy-header-track-number" svg={<ListSVG />} />
                  <Paragraph name="academy-header-track-number" size="m">
                    {`${props.academies[index].tracksCount} Lektionen`}
                  </Paragraph>
                </Component>
                {props.academies.length > 1 && (
                  <Button
                    type="primary"
                    className="txt--c-pri"
                    svg={<PlaySVG />}
                    onClick={() => {
                      useHistoryNavigate(`?academyId=${props.academies[index]._id}`);
                    }}
                    iconBefore={true}>
                    Jetzt starten
                  </Button>
                )}
                {props.academies.length === 1 && props.academies[index].isBought === false && (
                  // props.academies[index].videoProcessingStatus !== "PROCESSING" &&
                  <MasterclassBuyButton
                    type="track"
                    productLink={props.academies[index].paymentUrl} // academy Link
                  />
                )}
              </Component>
            </ComponentCard>
          )}
          {index === -1 && (
            <ComponentCard name="academy-header-information-card">
              <Component>
                <Title name="academy-header-title" size="m" type="h1">
                  {props.type === "mediaLibrary" &&
                    `Hol dir jetzt die Academy und bekomm Zugriff auf alle Masterclasses!`}
                  {props.type !== "mediaLibrary" && `Die SDRs of Germany Masterclass Academy`}
                </Title>
                {props.type !== "mediaLibrary" && (
                  <Paragraph name="academy-header-subtitle" size="l">
                    Sales Education – neu gedacht.
                  </Paragraph>
                )}
              </Component>
              {props.type !== "mediaLibrary" && (
                <Paragraph name="academy-header-paragraph" size="m">
                  Hier in der SDRs of Germany Masterclass Academy werden Erfolge gemeinsam gefeiert.
                  Lerne von den renommiertesten Sales Experten, wie moderner Vertrieb geht. Und zwar
                  ohne deiner Zielgruppe auf den Senkel zu gehen.
                </Paragraph>
              )}
              <Component name="academy-header-track-cta">
                <MasterclassBuyButton
                  type="hero"
                  productLink={`https://payments.sdrsofgermany.com/b/14kaFf0BAaD1h2wdRo`}
                  // all academies link
                />
              </Component>
            </ComponentCard>
          )}
          {props.academies.length > 1 && (
            <Component name="academy-hero-slide-controller">
              <Icon
                svg={<ArrowSVG />}
                name="academy-hero-slide-left-arrow"
                className={`${index === -1 ? "disabled" : ""}`}
                onClick={() => {
                  if (index !== -1) {
                    setIndex((value) => (value -= 1));
                  }
                }}
              />
              <Component name="academy-hero-slide-bubbles">
                <Icon
                  key={-1}
                  svg={-1 === index ? <BubbleFilledSVG /> : <BubbleOutlineSVG />}
                  onClick={() => setIndex(-1)}
                  name="academy-hero-slide-bubble"
                />
                {props.academies.map((value, i) => {
                  return (
                    <Icon
                      key={i}
                      svg={i === index ? <BubbleFilledSVG /> : <BubbleOutlineSVG />}
                      onClick={() => setIndex(i)}
                      name="academy-hero-slide-bubble"
                    />
                  );
                })}
              </Component>
              <Icon
                svg={<ArrowSVG />}
                name="academy-hero-slide-right-arrow"
                className={`${index === props.academies.length - 1 ? "disabled" : ""}`}
                onClick={() => {
                  if (index !== props.academies.length - 1) {
                    setIndex((value) => (value += 1));
                  }
                }}
              />
            </Component>
          )}
        </Component>
        <div className="el--academy-header-background el" />
        <Component name="academy-header-preview-video">
          {/* <ShakaPlayer controls={false} ref={controllerRef} /> */}
          <Image
            img={
              index === -1
                ? "https://storage.googleapis.com/pictures-sdrs-production/academy-buy-image.png"
                : props.academies[index].instructors[0]?.picture
            }
          />
        </Component>
      </>
    </Component>
  );
};

export default AllMasterclasses;
