const InstructorsSVG: React.FC<React.HTMLProps<HTMLOrSVGElement>> = () => {
  return (
    <svg viewBox="0 0 17 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 3C10 2.44772 10.4477 2 11 2H15.9979C16.7871 2 17.2652 2.87134 16.8414 3.53706L14.3419 7.46294C14.1333 7.7906 14.1333 8.2094 14.3419 8.53706L16.8414 12.4629C17.2652 13.1287 16.787 14 15.9979 14H12C10.8954 14 10 13.1046 10 12V3Z" />
      <path d="M4 1C4 0.447715 4.44772 0 5 0H6C7.10457 0 8 0.895431 8 2V11C8 11.5523 7.55228 12 7 12H5C4.44772 12 4 11.5523 4 11V1Z" />
      <path d="M0 1C0 0.447716 0.447715 0 1 0C1.55228 0 2 0.447715 2 1V19C2 19.5523 1.55228 20 1 20C0.447715 20 0 19.5523 0 19V1Z" />
    </svg>
  );
};

export default InstructorsSVG;
