import {
  Page,
  Main,
  ComingSoon,
  AppView,
  Section,
  SectionElement,
} from "../components/Elements/Elements";
import Sidebar from "../components/Navigation/Sidebar";
import { Messages as MessagesComponent } from "../components/Messages/Messages";
import Navigation from "../components/Navigation/Navigation";

const Messages = () => {
  return (
    <Page name="messages">
      <Sidebar />
      <Main>
        {true && <MessagesComponent />}
        {false && (
          <AppView name="messages">
            <Navigation />
            <Section name="messages">
              <SectionElement type="body" name="messages">
                <ComingSoon />
              </SectionElement>
            </Section>
          </AppView>
        )}
      </Main>
    </Page>
  );
};

export default Messages;
