import React, { useContext, useEffect, useState } from "react";
import LogoSVG from "../../Icons/LogoSVG";
import Context from "../../context/Context";
import historyNavigate from "../../hooks/useHistoryNavigate";
import useRequest from "../../hooks/useRequest";
import { PersonalTemplate, User } from "../../requests/interfaces";
import {
  Component,
  Icon,
  Image,
  Paragraph,
  Section,
  SectionElement,
  Seperator,
  Title,
} from "../Elements/Elements";
import CareerStep from "./RegisterSteps/CareerStep";
import PersonalStep from "./RegisterSteps/PersonalStep";
import VerifyStep from "./RegisterSteps/VerifyStep";
import "./authentication.scss";

interface Props {
  currentStep: number;
}

export interface StepProps {
  action: (e: any, operator?: number, request?: any) => void;
  id?: number;
}

interface StepItem {
  id: number;
  label: string;
  item: any;
}

const Register = (props: Props) => {
  const { useHistoryNavigate } = historyNavigate();
  const { setUser } = useContext(Context);
  const { apiRequest } = useRequest();

  const personalAction = (e: any, operator?: number, request?: any) => {
    setCurrentStepId((value) => value + (operator ?? 0));
    const cpyReq = { ...requestData };
    cpyReq.personal = request as PersonalTemplate;
    setRequestData(cpyReq);
  };

  const careerAction = async (e: any, operator?: number, request?: any) => {
    const cpyReq = { ...requestData };
    const finalRequest = {
      ...cpyReq.personal,
    };
    if (Object.keys(request.career).length !== 0) {
      cpyReq.career = request.career;
      finalRequest.career = cpyReq.career;
    }
    if (request.hyre) {
      cpyReq.hyre = request.hyre;
      finalRequest.hyre = cpyReq.hyre;
    }
    setRequestData(finalRequest);
    const { data, status } = await apiRequest<User>("user", "POST", {
      body: finalRequest,
    });
    if (status > 200 && status < 299) {
      useHistoryNavigate("/");
      if (setUser) setUser(data);
    } else {
      console.log("hello");
    }
  };

  const steps: StepItem[] = [
    {
      id: 0,
      label: "Verifizierung",
      item: <VerifyStep key="verify" />,
    },
    {
      id: 1,
      label: "Persönlich",
      item: <PersonalStep key="setup" action={personalAction} />,
    },
    {
      id: 2,
      label: "Karriere",
      item: <CareerStep key="career" action={careerAction} />,
    },
  ];

  const [currentStepId, setCurrentStepId] = useState(0);
  const [currentStep, setCurrentStep] = useState(steps[currentStepId]);
  const [requestData, setRequestData] = useState<any>({});

  useEffect(() => {
    setCurrentStep(steps[currentStepId]);
  }, [currentStepId]);

  useEffect(() => {
    setCurrentStepId(props.currentStep);
  }, [props.currentStep]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (Object.keys(requestData).length !== 0 && setUser) {
          console.log(requestData);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [requestData]);

  return (
    <>
      <Section name="register">
        <SectionElement name="register" type="body">
          <Component name="form-steps">
            {steps.map((step) => {
              return (
                <React.Fragment key={step.label}>
                  <Component name="form-step">
                    <Component
                      name="form-step-number"
                      className={step.id === currentStepId ? "active" : ""}>
                      <Paragraph
                        name="form-step-number"
                        className={step.id === currentStepId ? "p--l" : "p--m"}>
                        {step.id + 1}
                      </Paragraph>
                    </Component>
                    <Paragraph name="form-step">{step.label}</Paragraph>
                  </Component>
                  {step.id !== steps.length - 1 && <Seperator />}
                </React.Fragment>
              );
            })}
          </Component>
          <Component name="form">{currentStep.item}</Component>
        </SectionElement>
      </Section>
      <Component name="register">
        <Image
          name="register"
          img={"https://storage.googleapis.com/pictures-sdrs-production/academy-buy-image.png"}
        />
        <Component name="register-visual" className="txt--c-on-pri txt--a-c">
          <Icon name="register-visual" svg={<LogoSVG />}></Icon>
          <Title name="register-visual" type="h3" size="m">
            Die größte deutschsprachige
          </Title>
          <Title name="register-visual" type="h3" size="xxl">
            Sales Community
          </Title>
          <Paragraph name="register-visual" size="m">
            Hier werden Erfolge gemeinsam gefeiert.
          </Paragraph>
        </Component>
      </Component>
      {/* <Icon name="logo-design" svg={<TestSVG />} /> */}
      {/* <Icon name="logo-design" svg={<LogoSVG />} /> */}
    </>
  );
};

export { Register };
