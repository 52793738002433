const DoubleCheckSVG: React.FC<React.HTMLProps<HTMLOrSVGElement>> = () => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 12L7 15L13 9" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12 13.5L13.5 15L19.5 9"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DoubleCheckSVG;
