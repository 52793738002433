import { useState, useEffect } from "react";
import DiscussionSVG from "../../Icons/DiscussionSVG";
import ListSVG from "../../Icons/ListSVG";
import VideoSVG from "../../Icons/VideoSVG";
import historyNavigate from "../../hooks/useHistoryNavigate";
import useRequest from "../../hooks/useRequest";
import { useUser } from "../../hooks/useUser";
import { AcademyResponseDto, CredlyBadgeStatus } from "../../requests/interfaces";
import {
  BackButton,
  Button,
  Component,
  ComponentCard,
  Icon,
  MasterclassBuyButton,
  Paragraph,
  ProfileBubble,
  Section,
  SectionElement,
  Title,
  Image,
} from "../Elements/Elements";
import { Loading } from "../Loading/Loading";
import { AcademyHero } from "./AllMasterclasses";

const AcademyDetails = (props: { academyId: string }) => {
  const [academy, setAcademy] = useState<AcademyResponseDto>();
  const { useHistoryNavigate } = historyNavigate();
  const { apiRequest } = useRequest();
  const { user } = useUser();

  const loadAcademy = async () => {
    const { data } = await apiRequest<AcademyResponseDto>(`academy/${props.academyId}`, "GET");
    setAcademy(() => {
      data.tracks.sort((a, b) => a.index - b.index);
      return data;
    });
  };

  useEffect(() => {
    loadAcademy();
  }, []);

  const toggleBought = async () => {
    if (academy) {
      await apiRequest<AcademyResponseDto>(
        `academy/admin/${props.academyId}/${academy.isBought ? "revokeAccess" : "grantAccess"}/${
          user?._id
        }`,
        "POST",
        { showToast: true },
      );
      await apiRequest(`academy/progress/${props.academyId}/expectation`, "POST", {
        body: {
          expectation: "asdfsadf",
        },
      });
      loadAcademy();
    }
  };

  return (
    <Section name="masterclass">
      {academy && (
        <>
          {user?.role === "ADMIN" && process.env.REACT_APP_NODE_ENV === "development" && (
            <Button type="primary" onClick={toggleBought}>
              TOGGLE BOUGHT
            </Button>
          )}
          <SectionElement type="header" name="masterclass">
            <AcademyHero academies={[academy]} />
          </SectionElement>
          <SectionElement type="body" name="masterclass">
            <Component name="masterclass-header">
              <BackButton fixedLocation="/academy?tab=0" />
              <OrderCredlyBadgeButton id={academy._id} status={academy.credlyBadgeStatus} />
            </Component>
            {academy.tracks.map((track, i) => {
              return (
                <ComponentCard
                  onClick={() =>
                    useHistoryNavigate(`/academy/${props.academyId}/${track._id}?tab=2`)
                  }
                  name="masterclass-track"
                  key={i}>
                  <Image img={track.thumbnailUrl} name="masterclass-track-thumbnail" />
                  <Component name="masterclass-track-info">
                    <Paragraph name="masterclass-track-lesson" size="l">{`${
                      track.index === 1 || track.index === academy.tracks.length
                        ? ""
                        : `Lektion ${track.index - 1}:`
                    }`}</Paragraph>
                    <Component name="masterclass-track-meta">
                      <Title
                        name="masterclass-track-title"
                        className="txt--1line"
                        size="xs"
                        type="h3">{`${track.title}`}</Title>

                      <Paragraph
                        name="masterclass-track-description"
                        size="m"
                        className="txt--2lines">{`${track.learningObjective}`}</Paragraph>
                    </Component>
                    <ProfileBubble user={academy.instructors[0]}></ProfileBubble>
                  </Component>
                  <Component name="masterclass-track-steps">
                    {track.contentTrack && (
                      <Component name="masterclass-track-step">
                        <Icon name="masterclass-track-step" svg={<VideoSVG />} />
                        <Paragraph name="masterclass-track-step" size="m">
                          {track.contentTrack.title}
                        </Paragraph>
                      </Component>
                    )}
                    {track.exerciseTrack && (
                      <Component name="masterclass-track-step">
                        <Icon name="masterclass-track-step" svg={<ListSVG />} />
                        <Paragraph name="masterclass-track-step" size="m">
                          {track.exerciseTrack.title}
                        </Paragraph>
                      </Component>
                    )}
                    {track.discussionTrack && (
                      <Component name="masterclass-track-step">
                        <Icon name="masterclass-track-step" svg={<DiscussionSVG />} />
                        <Paragraph name="masterclass-track-step" size="m">
                          {track.discussionTrack.title}
                        </Paragraph>
                      </Component>
                    )}
                    {!track.discussionTrack && !track.exerciseTrack && (
                      <Component name="masterclass-track-step">
                        <Icon name="masterclass-track-step" svg={<DiscussionSVG />} />
                        <Paragraph name="masterclass-track-step" size="m">
                          {track.index === 1 ? "Deine Ziele" : "Feedback"}
                        </Paragraph>
                      </Component>
                    )}
                  </Component>
                  {track.trackStatus === "FINISHED" && (
                    <Component
                      name="flag"
                      className={track.trackStatus === "FINISHED" ? "is-available" : ""}>
                      <Paragraph name="flag" size="m" className="txt--c-on-pri">
                        Abgeschlossen
                      </Paragraph>
                    </Component>
                  )}
                </ComponentCard>
              );
            })}
            {!academy.isBought && (
              <Component name="masterclass-upsell">
                <MasterclassBuyButton
                  type="lock"
                  productLink={academy.paymentUrl} // academy Link
                />
              </Component>
            )}
          </SectionElement>
          <SectionElement type="footer" name="masterclass">
            <Component name="masterclass-footer">
              <OrderCredlyBadgeButton id={academy._id} status={academy.credlyBadgeStatus} />
            </Component>
          </SectionElement>
        </>
      )}
      {academy === undefined && <Loading />}
    </Section>
  );
};

const OrderCredlyBadgeButton = (props: { id: string; status: CredlyBadgeStatus }) => {
  const { apiRequest } = useRequest();

  const onBadgeOrdered = async () => {
    apiRequest(`academy/progress/orderBadge/${props.id}`, "POST", {
      showToast: true,
      toastText: "Successfully ordered credly badge",
    });
  };

  return (
    <>
      <Component name="order-credly-badge">
        {props.status === CredlyBadgeStatus.DONE ? (
          <></>
        ) : (
          <div className="tooltip">
            <Button
              type="primary"
              name="order-credly-badge-button"
              onClick={onBadgeOrdered}
              disabled={props.status !== CredlyBadgeStatus.OPEN}>
              Jetzt Masterclass Zertifikat beantragen
            </Button>
            {props.status !== CredlyBadgeStatus.OPEN && (
              <span className="tooltiptext">Beende zuerst alle Aufgaben</span>
            )}
          </div>
        )}
      </Component>
    </>
  );
};

export default AcademyDetails;
