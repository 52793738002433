import { ResetPassword as ResetPasswordComponent } from "../components/Authentication/ResetPassword";
import { Main, Page } from "../components/Elements/Elements";

const ResetPassword = () => {
  return (
    <>
      <Page name="reset-password">
        <Main>
          <ResetPasswordComponent />
        </Main>
      </Page>
    </>
  );
};

export default ResetPassword;
