import { useEffect, useState } from "react";
import { useProfile } from "../../hooks/useProfile";
import { UsersWithRoleResponseDto } from "../../requests/interfaces";
import { Component, ComponentCard, Icon, Paragraph, ProfilePicture } from "../Elements/Elements";
import { ProfileName } from "../util/Components";
import useFormOptions from "../../hooks/useFormOptions";
import AmabassadorsSVG from "../../Icons/AmabassadorsSVG";
import InstructorsSVG from "../../Icons/InstructorsSVG";
import AcademySVG from "../../Icons/AcademySVG";

interface UserCardProps {
  user: UsersWithRoleResponseDto;
  filters: string[];
  displaySkills: boolean;
  isInAcademy: boolean;
}

const UserCard = (props: UserCardProps) => {
  const { onProfileClick } = useProfile();
  const [getLabels, setGetLabels] = useState<(id: string) => any>(() => (id: string) => {
    return [id];
  });
  const { getFormOptions } = useFormOptions();

  const setLabels = async () => {
    const labelId = (await getFormOptions()).getLabelById;
    setGetLabels(() => labelId);
  };

  useEffect(() => {
    setLabels();
  }, []);

  const career = props.user.career;

  return (
    <ComponentCard name="user" type="shadow" onClick={() => onProfileClick(props.user._id)}>
      <Component name="user-snippet">
        <ProfilePicture size="l" img={props.user.picture} />
        <Component name="user-intro">
          <Component name="user-header">
            <ProfileName
              firstName={props.user.firstName}
              lastName={props.user.lastName}
              userId={props.user._id}
              size="xs"
            />
            {!props.isInAcademy && props.user.role === "AMBASSADOR" && (
              <Icon svg={<AmabassadorsSVG />} name="ambassadors-badge" />
            )}
            {props.isInAcademy && <Icon svg={<AcademySVG />} name="academy-badge" />}
            {!props.isInAcademy && props.user.role === "INSTRUCTOR" && (
              <Icon svg={<InstructorsSVG />} name="instructors-badge" />
            )}
          </Component>
          {career && (
            <Component name="user-profile-information">
              <Paragraph name="user-profile-information">
                {`${getLabels(career.position).name} @ ${career.company} | ${
                  career.location?.name
                }`}
              </Paragraph>
            </Component>
          )}
        </Component>
      </Component>
    </ComponentCard>
  );
};

export { UserCard };
