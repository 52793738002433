import { TabProps } from "../Elements/Components";
import { AppView } from "../Elements/Elements";
import Navigation from "../Navigation/Navigation";
import ProfileInformation from "./ProfileInformation";
import Settings from "./Settings";
// import Settings from "./Settings";

const Profile = () => {
  const tabs: TabProps[] = [
    {
      children: <ProfileInformation />,
      id: 0,
      isActive: true,
      label: "Mein Profil",
    },
    {
      children: <Settings />,
      id: 1,
      isActive: false,
      label: "Settings",
    },
    // {
    //   children: <ComingSoon />,
    //   id: 2,
    //   isActive: false,
    //   label: "Einstellungen",
    // },
  ];

  return (
    <>
      <AppView name="profile">
        <Navigation tabProps={tabs} />
      </AppView>
    </>
  );
};

export { Profile };
