import { Main, Page } from "../components/Elements/Elements";
import Sidebar from "../components/Navigation/Sidebar";
import { UserProfile as UserProfileComponent } from "../components/User/UserProfile";

const UserProfile = () => {
  return (
    <Page name="career">
      <Sidebar />
      <Main>
        <UserProfileComponent />
      </Main>
    </Page>
  );
};

export default UserProfile;
