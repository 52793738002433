const AmabassadorsSVG: React.FC<React.HTMLProps<HTMLOrSVGElement>> = () => {
  return (
    <svg viewBox="0 0 19 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.99976 14.3153V12.6667C5.99976 12.2985 5.70128 12 5.33309 12H3.0183C2.50093 12 2.12131 12.4862 2.24679 12.9881L2.68074 14.7239C2.86823 15.4739 3.54207 16 4.3151 16C5.24551 16 5.99976 15.2458 5.99976 14.3153Z" />
      <path d="M15 6.77778C15 5.79594 15.7959 5 16.7778 5H17C18.1046 5 19 5.89543 19 7C19 8.10457 18.1046 9 17 9H16.7778C15.7959 9 15 8.20406 15 7.22222V6.77778Z" />
      <path d="M3 4.00002C1.34315 4.00002 0 5.34316 0 7.00002C0 8.65687 1.34315 10 3 10H5.5C5.77614 10 6 9.77616 6 9.50002V4.50002C6 4.22388 5.77614 4.00002 5.5 4.00002H3Z" />
      <path d="M8.24751 3.8556C8.09421 3.94529 8 4.10956 8 4.28717V9.7135C8 9.89098 8.09408 10.0552 8.24719 10.1449L14.236 13.6545C15.9026 14.6312 18 13.4294 18 11.4976V2.5089C18 0.577996 15.9042 -0.623997 14.2375 0.351077L8.24751 3.8556Z" />
    </svg>
  );
};

export default AmabassadorsSVG;
