import { Main, Page } from "../components/Elements/Elements";
import Sidebar from "../components/Navigation/Sidebar";
import { TeamDashboard as TeamDashboardComponent } from "../components/TeamDashboard/TeamDashboard";

const TeamDashboard = () => {
  return (
    <Page name="team-dashboard">
      <Sidebar />
      <Main>
        <TeamDashboardComponent />
      </Main>
    </Page>
  );
};

export default TeamDashboard;
