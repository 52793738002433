import { Main, Page } from "../components/Elements/Elements";
import Sidebar from "../components/Navigation/Sidebar";
import { Journal as JournalComponent } from "../components/Journal/Journal";

const Journal = () => {
  return (
    <Page name="journal">
      <Sidebar />
      <Main>
        <JournalComponent />
      </Main>
    </Page>
  );
};

export default Journal;
