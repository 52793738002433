import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Paginated, UsersWithRoleResponseDto } from "../../requests/interfaces";
import {
  Section,
  SectionElement,
  Title,
  Table,
  Paragraph,
  TableColumn,
  TablePagination,
  Component,
  ProfileBubble,
  Button,
  TableSearch,
} from "../Elements/Elements";
import { UserRolesSelect } from "../Elements/FormElements";
import useRequest from "../../hooks/useRequest";

const AdminUsers = () => {
  const [users, setUsers] = useState<UsersWithRoleResponseDto[]>([]);
  const [hasNext, setHasNext] = useState<boolean>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { apiRequest } = useRequest();
  const [usersToChange, setUsersToChange] = useState<{ id: string; role: string }[]>([]);

  const getUsers = async () => {
    const { data } = await apiRequest<Paginated<UsersWithRoleResponseDto>>(
      `users/role?limit=10&page=${currentPage}`,
      "GET",
    );
    setUsers(data.docs);
    setHasNext(data.hasNextPage);
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    getUsers();
  }, [currentPage]);

  const changeRole = async () => {
    usersToChange.map(async (value) => {
      await apiRequest(`users/role/${value.id}`, "PATCH", {
        body: { role: value.role },
        showToast: true,
      });
    });
    setUsersToChange([]);
  };

  return (
    <>
      <Section name="admin-users">
        <SectionElement type="header" name="admin-users">
          <Title name="admin-users-title" type="h1" size="m">
            Benutzer
          </Title>
          <Button
            type="primary"
            name="change-user-role"
            onClick={changeRole}
            disabled={usersToChange.length === 0}>
            Speichern
          </Button>
        </SectionElement>
        <SectionElement type="body" name="admin-users">
          <Component name="table-background">
            <TableSearch
              route="users"
              setItem={setUsers}
              resetSearched={() => {
                if (currentPage !== 0) {
                  setCurrentPage(0);
                } else {
                  getUsers();
                }
              }}
            />
            <Table name="admin-users">
              <thead>
                <tr className="h--xxs">
                  <th>
                    <Paragraph name="admin-users-user" type="paragraph">
                      Benutzer
                    </Paragraph>
                  </th>
                  <th>
                    <Paragraph name="admin-users-login-state" type="paragraph">
                      Login Status
                    </Paragraph>
                  </th>
                  <th>
                    <Paragraph name="admin-users-profile-role" type="paragraph">
                      Rolle
                    </Paragraph>
                  </th>
                  <th>
                    <Paragraph name="admin-users-profile-state" type="paragraph">
                      Profil Status
                    </Paragraph>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users?.map((user, i) => {
                  return <UserListItem user={user} setUsersToChange={setUsersToChange} key={i} />;
                })}
              </tbody>
            </Table>
            {hasNext !== undefined && (
              <TablePagination
                currentPage={currentPage}
                hasNext={hasNext}
                setCurrentPage={setCurrentPage}
              />
            )}
          </Component>
        </SectionElement>
      </Section>
    </>
  );
};

const getLoginState = (user: UsersWithRoleResponseDto): { label: string; class: string } => {
  if (user.hyre) {
    return {
      label: "Hyre",
      class: "info",
    };
  }
  if (user.career) {
    return {
      label: "Karriere",
      class: "success",
    };
  }
  if (user.firstName) {
    return {
      label: "Persönliches",
      class: "warning",
    };
  }
  return {
    label: "Setup",
    class: "danger",
  };
};

const getProfileState = (user: UsersWithRoleResponseDto): { label: string; class: string } => {
  const isReported = user.isReported;
  return {
    label: isReported ? "Reported" : "Aktiv",
    class: isReported ? "danger" : "success",
  };
};

const UserListItem = (props: {
  user: UsersWithRoleResponseDto;
  setUsersToChange: Dispatch<
    SetStateAction<
      {
        id: string;
        role: string;
      }[]
    >
  >;
}) => {
  const user = props.user;
  const loginState = getLoginState(user);
  const profileState = getProfileState(user);
  const [role, setRole] = useState<string>(user.role);

  return (
    <tr>
      <TableColumn name="admin-col">
        <ProfileBubble
          user={{
            _id: user._id,
            firstName: user.firstName,
            lastName: user.lastName,
            role: user.role,
            createdAt: new Date(),
            picture: user.picture,
            skills: user.skills,
          }}
        />
      </TableColumn>
      <TableColumn name="admin-col">
        <Component name="admin-users-login-state" className={loginState.class}>
          <Paragraph type="paragraph" name="admin-users-login-state">
            {loginState.label}
          </Paragraph>
        </Component>
      </TableColumn>
      <TableColumn>
        <UserRolesSelect
          onInput={(newValue) => {
            setRole(newValue?.label ?? "");
            props.setUsersToChange((value) => {
              return [...value, { id: user._id, role: newValue?.label ?? "" }];
            });
          }}
          defaultValue={role}
          showLabel={false}
        />
      </TableColumn>
      <TableColumn>
        <Component name="admin-users-profile-state" className={profileState.class}>
          <Paragraph type="paragraph" name="admin-users-profile-state">
            {profileState.label}
          </Paragraph>
        </Component>
      </TableColumn>
    </tr>
  );
};

export default AdminUsers;
