import { AppView } from "../Elements/Elements";
import Navigation from "../Navigation/Navigation";
import { TabProps } from "../Elements/Components";
import WelcomeTab from "./WelcomeTab";
import "./home.scss";
import { Loading } from "../Loading/Loading";

const Home = () => {
  const tabs: TabProps[] = [
    {
      children: <WelcomeTab />,
      id: 0,
      isActive: true,
      label: "Willkommens Tab",
    },
    // {
    //   children: <QuestionsAndAnswersTab />,
    //   id: 1,
    //   isActive: false,
    //   label: "Fragen und Antworten",
    // },
  ];

  return (
    <>
      <AppView name="home">
        <Navigation />
        <WelcomeTab />
      </AppView>
    </>
  );
};

export { Home };
