import { format } from "date-fns";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { useLocation } from "react-router-dom";
import AnswerSVG from "../../Icons/AnswerSVG";
import ArrowSVG from "../../Icons/ArrowSVG";
import CloseSVG from "../../Icons/CloseSVG";
import DiscussionSVG from "../../Icons/DiscussionSVG";
import MeatBallSVG from "../../Icons/MeatBallSVG";
import SendSVG from "../../Icons/SendSVG";
import Context from "../../context/Context";
import { useConsts } from "../../hooks/useConsts";
import historyNavigate from "../../hooks/useHistoryNavigate";
import useComponentVisible from "../../hooks/useOutsideClick";
import { useProfile } from "../../hooks/useProfile";
import useRequest from "../../hooks/useRequest";
import { useUser } from "../../hooks/useUser";
import useUtil from "../../hooks/useUtil";
import {
  DiscussionResponseDto,
  Paginated,
  PostResponseDto,
  UsersResponseDto,
} from "../../requests/interfaces";
import { ConfirmationDisplay, ReportForm } from "../Elements/Components";
import {
  BackButton,
  Button,
  Component,
  ComponentCard,
  Icon,
  IconImageUploader,
  Image,
  Paragraph,
  Popup,
  ProfilePicture,
  Tag,
  Title,
} from "../Elements/Elements";
import { ErrorMessage, SingleCheckbox } from "../Elements/FormElements";
import { Loading } from "../Loading/Loading";
import { ProfileName } from "../util/Components";

interface DiscussionProps {
  discussion: DiscussionResponseDto;
  showComments: boolean;
  baseRoute?: string;
}

interface CommentProps {
  post: PostResponseDto;
  discussionId: string;
  baseRoute?: string;
}

interface PostProps {
  post: PostResponseDto;
  footer: any;
  discussionId: string;
  baseRoute?: string;
}

enum DiscussionTextFieldType {
  DISCUSSION,
  COMMENT,
}

export const StartDiscussion = (props: {
  onSubmit: (
    discussionText: string,
    tags: string[],
    anonym: boolean,
    uploadedImages: string[],
    taggedUsers: string[],
  ) => void;
  showTags?: boolean;
  imageRoute?: string;
  discussionQuestion?: string;
  canPostAnonymous?: boolean;
  isDisabled?: boolean;
}) => {
  const { user } = useUser();

  const [discussionText, setDiscussionText] = useState<string>("");
  const { getHashtags } = useConsts();
  const [anonymous, setAnonymous] = useState<boolean>(false);
  const { isMobile } = useContext(Context);
  const [hashtagsAreOpen, setHashtagsAreOpen] = useState<boolean>(!isMobile);
  const [tags, setTags] = useState<string[]>([]);
  const [hashtags, setHashtags] = useState<string[]>();
  const [pictures, setPictures] = useState<{ id: string; image: File }[]>();
  const [uploadedPictures, setUploadedPictures] = useState<{ id: string; image: string }[]>();
  const [errorText, setErrorText] = useState<string>("");
  const { getRandomId } = useUtil();

  const toggleHashtag = (hashtag: string) => {
    setTags((value) => {
      const finalList = [...value];
      const index = finalList.indexOf(hashtag);
      if (index !== -1) {
        finalList.splice(index, 1);
      } else {
        finalList.push(hashtag);
      }
      return finalList;
    });
  };

  useEffect(() => {
    const loadHashtags = async () => {
      setHashtags((await getHashtags()).hashtags);
    };
    loadHashtags();
  }, []);

  const submitDiscussion = () => {
    const hasTagsFilledOut = props.showTags !== false ? tags.length !== 0 : true;
    if (discussionText !== "" && hasTagsFilledOut) {
      props.onSubmit(
        discussionText,
        tags,
        anonymous,
        uploadedPictures?.map((value) => value.image) ?? [],
        getTaggedUserIds(discussionText),
      );
      setDiscussionText("");
      setTags([]);
      setAnonymous(false);
      setUploadedPictures([]);
      setErrorText("");
    } else {
      setErrorText(
        hasTagsFilledOut
          ? "Bitte schreibe etwas in die Diskussion"
          : "Bitte füge noch ein Tag hinzu unter dem es gelistet werden soll",
      );
    }
  };

  const onImageChange = (e: any) => {
    setPictures((old) => [...(old ?? []), { id: getRandomId(), image: e.target.files[0] }]);
  };

  const onImageError = () => {
    console.log("hell");
  };

  const onImageUploaded = (urls: { url: string }[], filename?: string) => {
    setUploadedPictures((old) => {
      const final = [...(old ?? [])];
      urls.map((url) => {
        final.push({ id: getRandomId(), image: url.url });
      });
      return final;
    });
    setPictures((oldValues) => {
      const final = [...(oldValues ?? [])];
      return final.filter(function (obj) {
        return obj.image.name !== filename;
      });
    });
  };

  const removeImageFromList = (id: string) => {
    setUploadedPictures((oldValues) => {
      const final = [...(oldValues ?? [])];
      return final.filter(function (obj) {
        return obj.id !== id;
      });
    });
  };

  return (
    <>
      <ComponentCard name="start-discussion">
        <Component name="welcome-widget-title">
          <Title name="start-discussion" size="s" type="h2">
            {props.discussionQuestion ?? "Diskussionen"}
          </Title>
          <Paragraph size="m">
            Tausche dich hier mit der größten deutschsprachigen Sales Community über Best Practices
            und Erfahrungen aus.
          </Paragraph>
        </Component>
        <Component name="create-post">
          {!isMobile && <ProfilePicture img={user?.picture} />}
          <Component name="discussion-text">
            <DisucssionTextField
              setText={setDiscussionText}
              text={discussionText}
              type={DiscussionTextFieldType.DISCUSSION}
              isAcademy={props.showTags ? false : true}
            />
          </Component>
          <IconImageUploader
            img={null}
            onManyImagesUploaded={onImageUploaded}
            onImageError={onImageError}
            onChange={onImageChange}
            route={props.imageRoute ?? "discussions/discussionPictures"}
            method="POST"
          />
        </Component>
        <Component name="discussion-pictures">
          {pictures && (
            <>
              {pictures.map((picture) => {
                return (
                  <Component name="discussion-image" key={picture.id}>
                    <Image img={picture.image} name="discussion-image-file" />
                  </Component>
                );
              })}
            </>
          )}
          {uploadedPictures && (
            <>
              {uploadedPictures.map((picture) => {
                return (
                  <Component name="discussion-image" key={picture.id}>
                    <Icon
                      name="remove-image"
                      svg={<CloseSVG />}
                      onClick={() => removeImageFromList(picture.id)}
                    />
                    <Image img={picture.image} name="discussion-image" />
                  </Component>
                );
              })}
            </>
          )}
        </Component>
        {props.showTags !== false && (
          <Component name="hashtags">
            {isMobile && (
              <Component
                name="hashtags-mobile-header"
                onClick={() => {
                  setHashtagsAreOpen((value) => {
                    if (value) {
                      setTags([]);
                    }
                    return !value;
                  });
                }}>
                <Title size="xxs" type="h3" name="filter">
                  Hashtags
                </Title>
                <Icon
                  svg={<ArrowSVG />}
                  name="collapse"
                  className={hashtagsAreOpen ? "" : "is-collapsed"}
                />
              </Component>
            )}
            {hashtagsAreOpen && (
              <Component name="hashtags-list">
                {hashtags?.map((hashtag) => {
                  const isSelected = tags.indexOf(hashtag) !== -1;
                  return (
                    <Tag isActive={isSelected} key={hashtag} onClick={() => toggleHashtag(hashtag)}>
                      <Paragraph name="hashtag-list" size="m">
                        {hashtag}
                      </Paragraph>
                    </Tag>
                  );
                })}
                {errorText && (
                  <ErrorMessage
                    displayErrorMessage={errorText !== ""}
                    errorMessage={errorText}
                    id={"btn"}
                  />
                )}
              </Component>
            )}
          </Component>
        )}
        {props.canPostAnonymous !== false && (
          <SingleCheckbox
            id="anonym"
            name="anonym"
            label="Anonym posten"
            labelClasses="p--l"
            onChange={(e) => setAnonymous((e.target as HTMLInputElement).checked)}
            isChecked={anonymous}
          />
        )}
        <Button
          name="discussion-submit"
          type="primary"
          onClick={submitDiscussion}
          disabled={discussionText ? false : true}>
          Einreichen
        </Button>
      </ComponentCard>
    </>
  );
};

export const DiscussionElement = (props: DiscussionProps) => {
  const { useHistoryNavigate } = historyNavigate();
  const location = useLocation();
  const [backLoc, setBackLoc] = useState<string>();
  const [comments, setComments] = useState<PostResponseDto[]>();
  const [displayTextfield, setDisplayTextfield] = useState<boolean>(false);
  const [commentText, setCommentText] = useState<string>("");
  const [anonymous, setAnonymous] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { apiRequest } = useRequest();

  useEffect(() => {
    setBackLoc(location.state?.prevLocation);
  }, [backLoc]);

  useEffect(() => {
    if (props.showComments) {
      getComments();
    }
  }, []);

  const getComments = async () => {
    const { data } = await apiRequest<Paginated<PostResponseDto>>(
      `${props.baseRoute ?? ""}discussions/${props.discussion._id}/comments`,
      "GET",
    );
    setComments(data.docs);
  };

  let className = "";
  if (props.discussion.status !== "APPROVED") {
    className = props.discussion.status === "REJECTED" ? "rejected" : "pending";
  }

  const comment = async () => {
    setIsLoading(true);
    const request: {
      content: string;
      // taggedUsers: string[];
      isAnonymous?: boolean;
    } = {
      content: commentText,
      // taggedUsers: getTaggedUserIds(commentText),
    };
    if (!props.baseRoute) {
      request.isAnonymous = anonymous;
    }
    await apiRequest(
      `${props.baseRoute ?? ""}discussions/${props.discussion._id}/comments`,
      "POST",
      { body: request },
    );
    setDisplayTextfield(false);
    setCommentText("");
    getComments();
    setIsLoading(false);
  };

  return (
    <>
      {props.showComments && <BackButton />}
      <ComponentCard name="discussion" className={className}>
        {props.discussion.status === "PENDING" && (
          <Component name="discussion-status" className="pending">
            <Tag isActive={true} className="unclickable pending">
              <Paragraph name="discussion-tag" size="m">
                In Review
              </Paragraph>
            </Tag>
          </Component>
        )}
        <PostElement
          post={props.discussion}
          discussionId={props.discussion._id}
          baseRoute={props.baseRoute}
          footer={
            <Component name="post-comments">
              {props.showComments && (
                <Component name="post-answer" onClick={() => setDisplayTextfield(true)}>
                  {!displayTextfield && (
                    <>
                      <Icon name="answer-icon" svg={<AnswerSVG />} />
                      <Paragraph>Antworten</Paragraph>
                    </>
                  )}
                  {displayTextfield && (
                    <>
                      <Component name="comment-textfield">
                        <DisucssionTextField
                          setText={setCommentText}
                          text={commentText}
                          type={DiscussionTextFieldType.COMMENT}
                          onSubmit={comment}
                          isLoading={isLoading}
                          isAcademy={props.baseRoute ? true : false}
                        />
                        {!props.baseRoute && (
                          <SingleCheckbox
                            id="anonym"
                            name="anonym"
                            label="Anonym kommentieren"
                            labelClasses="p--l"
                            onChange={(e) => setAnonymous((e.target as HTMLInputElement).checked)}
                            isChecked={anonymous}
                          />
                        )}
                      </Component>
                    </>
                  )}
                </Component>
              )}
              {!props.showComments && props.discussion.status !== "PENDING" && (
                <>
                  <Icon name="comments-icon" svg={<DiscussionSVG />} />
                  <Paragraph
                    onClick={() => {
                      console.log(props.discussion._id);
                      useHistoryNavigate(
                        `/${props.baseRoute ?? ""}discussion/${props.discussion._id}`,
                      );
                    }}>{`${
                    props.discussion.commentCount === 1
                      ? "Kommentar anzeigen"
                      : "Kommentare anzeigen"
                  }`}</Paragraph>
                </>
              )}
            </Component>
          }
        />
        {props.showComments && (
          <Component name="comments">
            {comments?.map((comment) => {
              return (
                <CommentElement
                  key={comment._id}
                  post={comment}
                  discussionId={props.discussion._id}
                  baseRoute={props.baseRoute}
                />
              );
            })}
          </Component>
        )}
      </ComponentCard>
    </>
  );
};

const CommentElement = (props: CommentProps) => {
  const { user } = useUser();
  const [displayTextfield, setDisplayTextfield] = useState<boolean>(false);
  const [commentText, setCommentText] = useState<string>("");
  const [comments, setComments] = useState<PostResponseDto[]>([]);
  const [anonymous, setAnonymous] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { apiRequest } = useRequest();

  const comment = async () => {
    setIsLoading(true);
    const request: {
      content: string;
      // taggedUsers: string[];
      isAnonymous?: boolean;
    } = {
      content: commentText,
      // taggedUsers: getTaggedUserIds(commentText),
    };
    if (!props.baseRoute) {
      request.isAnonymous = anonymous;
    }
    await apiRequest<PostResponseDto>(
      `${props.baseRoute ?? ""}discussions/${props.discussionId}/comments/${props.post._id}`,
      "POST",
      { body: request },
    );
    setDisplayTextfield(false);
    setCommentText("");
    getComments();
    setIsLoading(false);
  };

  const getComments = async () => {
    const { data } = await apiRequest<Paginated<PostResponseDto>>(
      `${props.baseRoute ?? ""}discussions/${props.discussionId}/comments/${props.post._id}`,
      "GET",
    );
    setComments(data.docs);
  };

  useEffect(() => {
    getComments();
  }, []);

  return (
    <>
      <Component name="comment-group">
        <ComponentCard
          name="comment"
          className={user?._id === props.post.user._id ? "own-comment" : ""}>
          <PostElement
            post={props.post}
            discussionId={props.discussionId}
            baseRoute={props.baseRoute}
            footer={
              <Component name="post-answer" onClick={() => setDisplayTextfield(true)}>
                {!displayTextfield && (
                  <>
                    <Icon name="answer-icon" svg={<AnswerSVG />} />
                    <Paragraph>Antworten</Paragraph>
                  </>
                )}
                {displayTextfield && (
                  <Component name="comment-textfield">
                    <DisucssionTextField
                      setText={setCommentText}
                      text={commentText}
                      type={DiscussionTextFieldType.COMMENT}
                      onSubmit={comment}
                      isLoading={isLoading}
                      isAcademy={props.baseRoute ? true : false}
                    />
                    {!props.baseRoute && (
                      <SingleCheckbox
                        id="anonym"
                        name="anonym"
                        label="Anonym kommentieren"
                        labelClasses="p--l"
                        onChange={(e) => setAnonymous((e.target as HTMLInputElement).checked)}
                        isChecked={anonymous}
                      />
                    )}
                  </Component>
                )}
              </Component>
            }
          />
        </ComponentCard>
        {comments?.length !== 0 && (
          <Component name="comment-seperator">
            <hr />
            {/* <Seperator /> */}
            <Component name={`comments-${props.post._id}`} className="comments-step">
              {comments?.map((comment) => {
                return (
                  <CommentElement
                    key={comment._id}
                    post={comment}
                    discussionId={props.discussionId}
                  />
                );
              })}
            </Component>
          </Component>
        )}
      </Component>
    </>
  );
};

const PostElement = (props: PostProps) => {
  const postUser = props.post.user;
  const { user } = useUser();
  const { onProfileClick } = useProfile();
  const { apiRequest } = useRequest();
  const [rating, setRating] = useState<number>(props.post.rating ?? 0);
  const [showMeatballs, setShowMeatballs] = useState<boolean>(false);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [reportFormOpen, setReportFormOpen] = useState<boolean>(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState<boolean>(false);
  const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false);
  const { setPopupContent } = useContext(Context);
  const [discussionImage, setDiscussionImage] = useState<any>();

  const vote = async (vote: number) => {
    const commentId = props.post._id === props.discussionId ? "" : props.post._id;
    let url = "";
    const toAdd = props.post.hasDownvoted || props.post.hasUpvoted ? vote * 2 : vote;
    if (vote === -1 && !props.post.hasDownvoted) {
      url = `${props.baseRoute ?? ""}discussions/${props.discussionId}/downvote/${commentId}`;
      props.post.hasDownvoted = true;
      props.post.hasUpvoted = false;
    } else if (vote === 1 && !props.post.hasUpvoted) {
      url = `${props.baseRoute ?? ""}discussions/${props.discussionId}/upvote/${commentId}`;
      props.post.hasUpvoted = true;
      props.post.hasDownvoted = false;
    }
    if (url !== "") {
      setRating((value) => value + toAdd);
      await apiRequest(url, "PATCH");
    }
  };

  const deletePost = async () => {
    const commentId = props.post._id === props.discussionId ? "" : props.post._id;
    const url = `${props.baseRoute ?? ""}discussions/${
      props.discussionId ?? props.post._id
    }/comments${commentId !== "" ? `/${commentId}` : ""}`;
    await apiRequest(url, "DELETE");
    window.location.reload();
  };

  useEffect(() => {
    //rerender
  }, [rating, showMeatballs]);

  useEffect(() => {
    if (setPopupContent) {
      if (popupIsOpen && discussionImage) {
        setPopupContent(<ImagePopup image={discussionImage} />);
      } else {
        setPopupContent(undefined);
        setPopupIsOpen(false);
      }
    }
  }, [popupIsOpen]);

  const ImagePopup = (props: { image: any }) => {
    return (
      <Popup
        setPopupIsOpen={(value) => {
          if (value === false) {
            setDiscussionImage(undefined);
          }
          setPopupIsOpen(value);
        }}
        size="SMALL">
        <Component
          name="discussion-image"
          className="big"
          onClick={() => {
            setPopupIsOpen(false);
            setDiscussionImage(undefined);
          }}>
          <Image img={props.image} />
        </Component>
      </Popup>
    );
  };

  useEffect(() => {
    setPopupIsOpen(true);
  }, [discussionImage]);

  return (
    <>
      {props.post && (
        <Component
          name="discussion-post"
          onMouseEnter={() => setShowMeatballs(true)}
          onMouseLeave={() => setShowMeatballs(false)}>
          <Component name="upvotes">
            <Icon
              name="upvote-arrow"
              svg={<ArrowSVG />}
              onClick={() => vote(1)}
              className={props.post.hasUpvoted ? "voted" : ""}
            />
            <Paragraph
              type="paragraph"
              name="upvote-count"
              className={props.post.hasUpvoted || props.post.hasDownvoted ? "voted" : ""}>
              {rating}
            </Paragraph>
            <Icon
              name="downvote-arrow"
              svg={<ArrowSVG />}
              onClick={() => vote(-1)}
              className={props.post.hasDownvoted ? "voted" : ""}
            />
          </Component>
          <Component name="post">
            <Component name="discussion-header">
              <Component
                name="post-infos"
                onClick={() => {
                  if (postUser && postUser._id !== null) {
                    onProfileClick(postUser._id);
                  }
                }}>
                <ProfilePicture img={postUser?.picture} />
                <Component name="post-info-text">
                  {postUser !== null && postUser._id !== null && (
                    <ProfileName
                      firstName={postUser.firstName}
                      lastName={postUser.lastName}
                      userId={postUser._id}
                      size="xs"
                    />
                  )}
                  {(postUser === null || postUser._id === null) && (
                    <Title type="h2" size="xs">
                      Anonym
                    </Title>
                  )}
                  <Paragraph type="paragraph">
                    {`${format(
                      props.post.createdAt ? new Date(props.post.createdAt) : new Date(),
                      "dd. MMMM YYY, HH:mm",
                    )}
                      Uhr`}
                  </Paragraph>
                </Component>
              </Component>
              <Component
                name="post-settings"
                className={showMeatballs ? "" : "hidden"}
                ref={ref}
                onClick={(e) => e.stopPropagation()}>
                <Icon
                  svg={<MeatBallSVG />}
                  name="post-settings"
                  onClick={() => setIsComponentVisible(true)}
                />
                {isComponentVisible && (
                  <ComponentCard name="post-settings-window" onClick={(e) => e.stopPropagation()}>
                    <Button
                      type="dangerGhost"
                      onClick={() => {
                        setReportFormOpen(true);
                      }}>
                      Melden
                    </Button>
                    {(props.post.user?._id === user?._id || user?.role === "ADMIN") && (
                      <Button type="dangerGhost" onClick={() => setDeleteDialogIsOpen(true)}>
                        Post löschen
                      </Button>
                    )}
                  </ComponentCard>
                )}
              </Component>
            </Component>
            <Component name="post-body">
              {/* <Paragraph type="paragraph" withLineBreaks={true} withLinks={true}>
                {props.post.content}
              </Paragraph> */}
              <MentionTransform content={props.post.content} />
              {props.post.pictures && (
                <Component name="post-image">
                  {props.post.pictures.map((picture: any) => {
                    return (
                      <Component
                        name="discussion-image"
                        className="small"
                        key={picture}
                        onClick={() => setDiscussionImage(picture)}>
                        <Image img={picture} name="discussion-image" />
                      </Component>
                    );
                  })}
                </Component>
              )}
            </Component>
            <Component name="post-footer">{props.footer}</Component>
          </Component>
          <ReportForm
            popupIsOpen={reportFormOpen}
            setPopupIsOpen={setReportFormOpen}
            title="Post melden?"
            subject={props.post.content}
            location={window.location.href + "/" + props.discussionId}
          />
        </Component>
      )}
      <ConfirmationDisplay
        action={deletePost}
        popupIsOpen={deleteDialogIsOpen}
        setPopupIsOpen={setDeleteDialogIsOpen}
        title="Bist du dir sicher, diesen Post zu löschen?"
      />
    </>
  );
};

const getTaggedUserIds = (content: string): string[] => {
  const mentionRegex = /@\[\s*([^\]]+)\s*\]\((\w+)\)/g;
  const matches = content.matchAll(mentionRegex);
  const taggedUserIds = Array.from(matches, (match) => match[2]);
  return taggedUserIds;
};

const DisucssionTextField = (props: {
  text: string;
  setText: Dispatch<SetStateAction<string>>;
  type: DiscussionTextFieldType;
  isLoading?: boolean;
  onSubmit?: () => void;
  isAcademy?: boolean;
}) => {
  const { apiRequest } = useRequest();

  const search = async (query: string, callback: any) => {
    const { data } = await apiRequest<Paginated<UsersResponseDto>>(
      `users/search?search=${query}&limit=10${props.isAcademy ? "&filter=academy" : ""}`,
      "GET",
    );

    const x = data.docs.map((user) => {
      return {
        id: user._id,
        display: ` ${user.firstName} ${user.lastName} `,
      };
    });

    callback(x);
  };

  return (
    <Component name="tf" id="test">
      <Component name="mentions">
        <MentionsInput
          style={{ flexGrow: 1 }}
          value={props.text}
          onChange={(e) => props.setText(e.target.value)}
          className="mention"
          placeholder={
            props.type === DiscussionTextFieldType.COMMENT
              ? "Schreibe einen Kommentar"
              : "Was denkst du?"
          }>
          <Mention appendSpaceOnAdd trigger="@" data={search} className="cmp--mention cmp" />
        </MentionsInput>
        {
          <>
            {props.type === DiscussionTextFieldType.COMMENT && props.text && (
              <>
                {props.isLoading ? (
                  <Loading />
                ) : (
                  <Icon
                    name="send-icon"
                    className={props.text.replace(/\s/g, "") !== "" ? "active" : ""}
                    svg={<SendSVG />}
                    onClick={props.onSubmit}
                  />
                )}
              </>
            )}
          </>
        }
      </Component>
    </Component>
  );
};

const MentionTransform = (props: { content: string }) => {
  // Regex pattern to match mentions with user IDs and names
  const mentionRegex = /@\[\s*([^\]]+)\s*\]\((\w+)\)/g;

  // Replace mentions with anchor tags
  const transformedContent = props.content.replaceAll(mentionRegex, '<a href="/user/$2">@$1</a>');

  // Convert the HTML string to JSX
  return (
    <Paragraph type="paragraph" name="discussion-content" withLineBreaks={true} withLinks={true}>
      {transformedContent}
    </Paragraph>
  );
};
