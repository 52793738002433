import { useEffect, useState } from "react";
import { Paginated, SupportQuestion } from "../../requests/interfaces";
import {
  Button,
  Component,
  Table,
  Paragraph,
  ProfileBubble,
  Section,
  TableColumn,
  TablePagination,
  Title,
  SectionElement,
  Link,
} from "../Elements/Elements";
import { format } from "date-fns";
import { useSearchParams } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import historyNavigate from "../../hooks/useHistoryNavigate";

const AdminSupport = () => {
  // TODO
  // - Support Questions:
  //   - find all questions and mark them as done when they are done
  const [mode, setMode] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const modeId = searchParams.get("mode");
  const [modes, setModes] = useState<any[]>([]);
  const setupModes = () => {
    const allModes = [
      {
        modeId: "LIST",
        component: (
          <AdminSupportList searchParams={searchParams} setSearchParams={setSearchParams} />
        ),
        active: modeId === null || modeId !== "details",
      },
      {
        modeId: "DETAILS",
        component: (
          <AdminSupportItem searchParams={searchParams} setSearchParams={setSearchParams} />
        ),
        active: modeId === "details",
      },
    ];
    setModes(allModes);
  };

  useEffect(() => {
    setMode(modes.find((tab: any) => tab.active === true));
  }, [modes]);

  useEffect(() => {
    setupModes();
  }, [modeId]);

  useEffect(() => {
    setupModes();
  }, []);

  return <>{mode && mode.component}</>;
};

const AdminSupportList = (props: { searchParams: any; setSearchParams: any }) => {
  const [supportQuestions, setSupportQuestions] = useState<SupportQuestion[]>();
  const [hasNext, setHasNext] = useState<boolean>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { apiRequest } = useRequest();

  const getSupportQuestions = async () => {
    const { data } = await apiRequest<Paginated<SupportQuestion>>(
      `tickets?limit=10&page=${currentPage}`,
      "GET",
    );
    setSupportQuestions(data.docs);
    setHasNext(data.hasNextPage);
  };

  useEffect(() => {
    getSupportQuestions();
  }, []);

  useEffect(() => {
    getSupportQuestions();
  }, [currentPage]);

  const supportQuestionDetails = (id: string) => {
    props.searchParams.set("mode", "details");
    props.searchParams.set("id", id);
    props.setSearchParams(props.searchParams);
  };

  return (
    <>
      <Section name="admin-support">
        <SectionElement type="header" name="admin-support">
          <Title name="admin-support-title" type="h1" size="m">
            Support
          </Title>
        </SectionElement>
        <SectionElement type="body" name="admin-support">
          <Component name="table-background">
            <Table name="admin-support">
              <thead>
                <tr className="h--xxs">
                  <th>
                    <Paragraph name="admin-support-author-title" type="paragraph">
                      Benutzer
                    </Paragraph>
                  </th>
                  <th>
                    <Paragraph name="admin-support-date-title" type="paragraph">
                      Datum
                    </Paragraph>
                  </th>
                  <th>
                    <Paragraph name="admin-support-question-title" type="paragraph">
                      Frage
                    </Paragraph>
                  </th>
                  <th>
                    <Paragraph name="admin-support-state-title" type="paragraph">
                      Status
                    </Paragraph>
                  </th>
                </tr>
              </thead>
              <tbody>
                {supportQuestions?.map((supportQuestion) => {
                  return (
                    <tr
                      key={supportQuestion._id}
                      onClick={() => supportQuestionDetails(supportQuestion._id)}>
                      <TableColumn name="admin-col">
                        <ProfileBubble user={supportQuestion.user} />
                      </TableColumn>
                      <TableColumn name="admin-col">
                        <Component name="admin-support-date">
                          <Paragraph type="paragraph" name="admin-support-date">
                            {`${format(new Date(supportQuestion.createdAt ?? ""), "dd.MM.y")}`}
                          </Paragraph>
                        </Component>
                      </TableColumn>
                      <TableColumn>
                        <Component name="admin-support-subject">
                          <Paragraph type="paragraph" name="admin-support-subject">
                            {supportQuestion.title}
                          </Paragraph>
                        </Component>
                      </TableColumn>
                      {supportQuestion.status === "open" && (
                        <TableColumn>
                          <Component name="admin-support-state">
                            <Paragraph type="paragraph" name="admin-support-state">
                              Offen
                            </Paragraph>
                          </Component>
                        </TableColumn>
                      )}
                      {supportQuestion.status === "closed" && (
                        <TableColumn>
                          <Component name="admin-support-state">
                            <Paragraph type="paragraph" name="admin-support-state">
                              Fertig
                            </Paragraph>
                          </Component>
                        </TableColumn>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {hasNext !== undefined && (
              <TablePagination
                currentPage={currentPage}
                hasNext={hasNext}
                setCurrentPage={setCurrentPage}
              />
            )}
          </Component>
        </SectionElement>
      </Section>
    </>
  );
};

const AdminSupportItem = (props: { searchParams: any; setSearchParams: any }) => {
  const id = props.searchParams.get("id");
  const [ticket, setTicket] = useState<SupportQuestion>();
  const { apiRequest } = useRequest();
  const { useHistoryNavigate } = historyNavigate();

  const getTicket = async () => {
    const { data } = await apiRequest<SupportQuestion>(`tickets/${id}`, "GET");
    setTicket(data);
  };

  useEffect(() => {
    getTicket();
  }, []);

  const markAsResolved = async () => {
    await apiRequest(`tickets/${id}/close`, "PATCH", { showToast: true });
    useHistoryNavigate("/admin-dashboard?tab=4");
  };

  return (
    <Section name="admin-ticket">
      <SectionElement type="body" name="admin-ticket">
        <Component name="ticket">
          <Component name="ticket-title">
            <Paragraph name="ticket-title-label" type="paragraph" size="l">
              Titel
            </Paragraph>
            <Paragraph name="ticket-title" type="paragraph">
              {ticket?.title}
            </Paragraph>
          </Component>
          <Component name="ticket-description">
            <Paragraph name="ticket-description-label" type="paragraph" size="l">
              Beschreibung
            </Paragraph>
            <Paragraph name="ticket-description" type="paragraph">
              {ticket?.description}
            </Paragraph>
          </Component>
          <Component name="ticket-date">
            <Paragraph name="ticket-description-label" type="paragraph" size="l">
              Eingereicht am
            </Paragraph>
            <Paragraph name="ticket-description" type="paragraph">
              {format(
                ticket?.createdAt ? new Date(ticket?.createdAt) : new Date(),
                "dd.MM.yyyy HH:mm",
              )}
            </Paragraph>
          </Component>
          <Component name="ticket-location">
            <Paragraph name="ticket-description-label" type="paragraph" size="l">
              Eingereicht von
            </Paragraph>
            <Link
              name="ticket-description"
              href={ticket?.source ?? ""}
              download={true}
              onClick={() => window.open(ticket?.source)?.focus()}>
              {ticket?.source}
            </Link>
          </Component>
          <Component name="ticket-status">
            {ticket?.status !== "open" && (
              <Title type="h2" size="m" name="ticket-done">
                Abgeschlossen
              </Title>
            )}
            {ticket?.status === "open" && (
              <Button type="primary" name="ticket-resolved" onClick={() => markAsResolved()}>
                Als abgeschlossen markieren
              </Button>
            )}
          </Component>
        </Component>
      </SectionElement>
    </Section>
  );
};

export default AdminSupport;
