import { useState, useEffect, ReactNode } from "react";
import Context from "./Context";
import { User } from "../requests/interfaces";
import { v4 as uuidv4 } from "uuid";
import useRequest from "../hooks/useRequest";
import { useAuth0 } from "@auth0/auth0-react";

export const ContextProvider = ({ children }: any) => {
  const getSitebarValue = () => {
    if (localStorage.getItem("sitebarOpen") && !isMobile) {
      return localStorage.getItem("sitebarOpen") === "true" ? true : false;
    }
    return !isMobile;
  };

  const setSessionToken = () => {
    const currentSessionToken = sessionStorage.getItem("session_token");
    if (!currentSessionToken) {
      sessionStorage.setItem("session_token", uuidv4());
    }
  };

  setSessionToken();

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const [sitebarOpen, setSitebarOpen] = useState<boolean>(getSitebarValue());
  const [accessToken, setAccessToken] = useState<string>("");
  const [user, setUser] = useState<User | undefined>();
  const [popupContent, setPopupContent] = useState<ReactNode>();
  const [userIsLoaded, setUserIsLoaded] = useState<boolean>(false);
  const [isBanned, setIsBanned] = useState<boolean>(false);
  const { apiRequest } = useRequest();
  const auth0 = useAuth0();

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("sitebarOpen", sitebarOpen + "");
  }, [sitebarOpen]);

  useEffect(() => {
    const getUser = async () => {
      if (auth0.isLoading) {
        setUserIsLoaded(false);
      } else if (auth0.user?.email_verified && !user && accessToken) {
        try {
          const { data } = await apiRequest<User>("user", "GET", {
            accessTokenHard: accessToken,
          });
          setUser(data);
        } catch (e: any) {
          // user setup is not complete
        }
        setUserIsLoaded(true);
      } else {
        if (!auth0.user?.email_verified) {
          setUserIsLoaded(true);
        }
      }
    };
    getUser();
  }, [accessToken, user, auth0]);

  useEffect(() => {
    const fetchAccessToken = async () => {
      if (setAccessToken) {
        setAccessToken(
          await auth0.getAccessTokenSilently({
            authorizationParams: { scope: "openid profile email offline_access" },
          }),
        );
      }
    };
    if (auth0.isAuthenticated) {
      fetchAccessToken();
    }
  }, [auth0.isAuthenticated]);

  const value = {
    isMobile,
    sitebarOpen,
    accessToken,
    user,
    userIsLoaded,
    isBanned,
    popupContent,
    setIsMobile,
    setSitebarOpen,
    setAccessToken,
    setUser,
    setPopupContent,
    setIsBanned,
  };
  return <Context.Provider value={value}>{children}</Context.Provider>;
};
