import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import {
  Component,
  Paragraph,
  ComponentCard,
  Title,
  Button,
  Popup,
  Icon,
  SearchSuggestion,
} from "./Elements";
import { Header, MobileBurger } from "../Navigation/Header";
import { SingleCheckbox, TagSelect, TextInputElement } from "./FormElements";
import Context from "../../context/Context";
import { useSearchParams } from "react-router-dom";
import ArrowSVG from "../../Icons/ArrowSVG";
import Select, { MultiValue } from "react-select";
import useRequest from "../../hooks/useRequest";
import { Paginated, UsersResponseDto } from "../../requests/interfaces";
import CloseSVG from "../../Icons/CloseSVG";
import { format } from "date-fns";

interface TabsProps {
  options: TabProps[];
}

export interface TabProps {
  id: number;
  label: string;
  children: any;
  isActive: boolean;
}

interface FilterProps {
  filterOptions: FilterOptionProps[];
  onFilterChange: (filters: string[]) => void;
  filterSize: "large" | "medium" | "small";
  labelHidden?: boolean;
  filterName?: string;
  filterIsCollapsible?: boolean;
  showResetButton?: boolean;
  isDefaultOpen?: boolean;
}

export interface FilterOptionProps {
  id: string;
  type: "checkbox" | "radio" | "select";
  label: string;
  name: string;
}

export const Tabs = (props: TabsProps) => {
  const titles = props.options.map((tab) => ({
    label: tab.label,
    id: tab.id as number,
  }));
  const [options, setOptions] = useState<TabProps[]>(props.options);
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabId, setTabId] = useState<number>(0);
  const { isMobile, setSitebarOpen } = useContext(Context);

  useEffect(() => {
    syncTabId();
  }, [options]);

  useEffect(() => {
    syncTabId();
  }, [searchParams]);

  const syncTabId = () => {
    const currentTabId = searchParams.get("tab") as unknown as number;
    if (currentTabId && !isNaN(currentTabId)) {
      if (options.length > currentTabId) {
        setTabId(currentTabId as number);
      } else {
        searchParams.set("tab", tabId + "");
        setSearchParams(searchParams);
      }
    }
  };

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  return (
    <>
      {!isMobile && (
        <Component name="nav">
          <Header />
          <Component name="nav-tabs">
            {titles.map((title) => {
              return (
                <Component
                  name={`nav-tab ${title.id == tabId ? "current" : ""}`}
                  onClick={() => {
                    setTabId(title.id);
                    setSearchParams({ tab: title.id + "" });
                  }}
                  key={title.id}>
                  <Paragraph name="nav-tab" size="nav" key={title.id}>
                    {title.label}
                  </Paragraph>
                </Component>
              );
            })}
          </Component>
        </Component>
      )}
      {isMobile && (
        <>
          <MobileBurger setIsOpen={setSitebarOpen}>
            <Component name="nav-tabs">
              {titles.map((title) => {
                return (
                  <Component
                    name={`nav-tab ${title.id == tabId ? "current" : ""}`}
                    onClick={() => {
                      setTabId(title.id);
                      setSearchParams({ tab: title.id + "" });
                    }}
                    key={title.id}>
                    <Paragraph name="nav-tab" size="nav" key={title.id}>
                      {title.label}
                    </Paragraph>
                  </Component>
                );
              })}
            </Component>
          </MobileBurger>
        </>
      )}
      <Tab {...options[tabId]} />
    </>
  );
};

const Tab = (props: TabProps) => {
  return <>{props.children}</>;
};

export const Filter = (props: FilterProps) => {
  let previousName = "";
  let elements: {
    name: string;
    id: string;
  }[] = [];
  let counter = 0;
  const [filters, setFilters] = useState<string[]>([]);
  const [reset, setReset] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    props.isDefaultOpen
      ? !props.isDefaultOpen
      : props.filterIsCollapsible !== undefined
      ? props.filterIsCollapsible
      : false,
  );
  const [dropdownValues, setDropdownValues] = useState<{
    name: string;
    values: MultiValue<{ value: string; label: string }>;
  }>();
  const showResetButton = props?.showResetButton ?? true;

  useEffect(() => {
    setReset(false);
    setDropdownValues(undefined);
  }, [reset, isCollapsed]);

  useEffect(() => {
    props.onFilterChange(filters);
  }, [filters]);

  const toggleFilter = (e: any) => {
    setFilters((value) => {
      const index = value.findIndex((el) => el.includes(e.target.id));
      let final = [...value];
      if (index > -1 && e.target.type === "checkbox") {
        let current = final[index];
        if (current.includes(e.target.id)) {
          const items = current.split("=").map((value) => value.split(","))[1];
          const toRemoveIndex = items.indexOf(e.target.id);
          items.splice(toRemoveIndex, 1);
          current = items.length !== 0 ? `${e.target.name}=${items.join(",")}` : "";
          current !== "" ? (final[index] = current) : final.splice(index, 1);
        } else {
          current += `,${e.target.id}`;
        }
      } else {
        if (e.target.type === "checkbox") {
          const newFilter = `${e.target.name}=${e.target.id}`;
          final.push(newFilter);
        } else {
          final = [`${e.target.name}=${e.target.id}`];
        }
      }
      return final;
    });
  };

  useEffect(() => {
    setFilters(() => {
      const all = dropdownValues?.values.map((value) => {
        return dropdownValues.name + "=" + value.value;
      });
      return all ?? [];
    });
  }, [dropdownValues]);

  const getParent = (
    id: string,
    options: {
      name: string;
      id: string;
    }[],
    type: "checkbox" | "radio" | "select",
  ) => {
    if (type === "radio") {
      return (
        <Component
          name={`filter-group${props.filterSize === "small" ? "" : ""}`}
          key={previousName}>
          <TagSelect
            id={id}
            optionLabelClasses=""
            name={previousName}
            options={options}
            type={type}
            isHidden={props.labelHidden ?? true}
            onChange={toggleFilter}
            isChecked={reset}
          />
        </Component>
      );
    } else if (type === "checkbox") {
      return (
        <Component
          name={`filter-group${props.filterSize === "small" ? "-s" : ""}`}
          key={previousName}>
          <Component name={`filter-group${props.filterSize === "small" ? "-s" : ""}`}>
            {props.labelHidden && (
              <TagSelect
                id={id}
                optionLabelClasses=""
                name={previousName}
                options={options}
                type={type}
                isHidden={true}
                onChange={toggleFilter}
                isChecked={reset}
              />
            )}
            {!props.labelHidden && (
              <>
                {options.map((option) => {
                  return (
                    <SingleCheckbox
                      labelClasses="p--l"
                      label={option.name}
                      id={option.id}
                      onChange={toggleFilter}
                      name={previousName}
                      isChecked={reset}
                      key={option.name}
                    />
                  );
                })}
              </>
            )}
          </Component>
        </Component>
      );
    }
    const selectOptions: { value: string; label: string }[] = [];
    options?.map((option) => {
      selectOptions.push({
        value: option.id,
        label: option.name,
      });
    });
    return (
      <Select
        isMulti
        name={previousName}
        options={selectOptions}
        classNamePrefix="select"
        onChange={(newValue) => {
          setDropdownValues({
            name: previousName,
            values: newValue,
          });
        }}
        value={dropdownValues?.values}
        key={id}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
    );
  };

  const filterComponent = (
    <Component name={`filters${props.filterSize === "small" ? "-s" : ""}`}>
      {props.filterOptions.map((filterOption) => {
        if (previousName === "") {
          previousName = filterOption.name;
        }
        const children = {
          name: filterOption.label,
          id: filterOption.id,
        };

        if (previousName !== filterOption.name) {
          const parent = getParent(
            filterOption.id,
            elements,
            props.filterOptions[counter - 1].type,
          );
          elements = [children];
          previousName = filterOption.name;
          counter += 1;
          return parent;
        }

        elements.push(children);
        previousName = filterOption.name;

        if (props.filterOptions.length - 1 === counter) {
          return getParent(filterOption.id, elements, props.filterOptions[counter - 1]?.type);
        }
        counter += 1;
      })}
    </Component>
  );

  return (
    <>
      {props.filterSize === "small" && <>{filterComponent}</>}
      {props.filterSize === "large" && (
        <ComponentCard
          name="filter"
          type={
            props.showResetButton !== undefined && !props.showResetButton ? "standard" : "shadow"
          }>
          <Component
            name="filter-header"
            className={props?.filterIsCollapsible ? "is-collapsable" : ""}
            onClick={() => {
              if (props?.filterIsCollapsible) {
                setIsCollapsed((value) => !value);
              }
            }}>
            <Title size="xxs" type="h3" name="filter">
              {props.filterName ?? "Filter"}
            </Title>
            {props.filterIsCollapsible && (
              <Icon
                svg={<ArrowSVG />}
                name="collapse"
                className={isCollapsed ? "is-collapsed" : ""}
              />
            )}
          </Component>
          {!isCollapsed && (
            <>
              {filterComponent}
              {showResetButton && (
                <Button
                  type="primaryGhost"
                  onClick={() => {
                    setFilters([]);
                    props.onFilterChange([]);
                    setReset(true);
                  }}>
                  Zurücksetzen
                </Button>
              )}
            </>
          )}
        </ComponentCard>
      )}
    </>
  );
};

export const SupportForm = (props: {
  popupIsOpen: boolean;
  setPopupIsOpen: (value: boolean) => void;
}) => {
  const { setPopupContent } = useContext(Context);

  useEffect(() => {
    if (setPopupContent) {
      if (props.popupIsOpen) {
        setPopupContent(<Content />);
      } else {
        setPopupContent(undefined);
      }
    }
  }, [props.popupIsOpen]);

  const Content = () => {
    const [subject, setSubject] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const { apiRequest } = useRequest();

    const contactSupport = async () => {
      const request = {
        title: subject,
        description: description,
        source: window.location.href,
      };
      await apiRequest("tickets", "POST", { body: request, showToast: true });
      props.setPopupIsOpen(false);
    };

    return (
      <Popup setPopupIsOpen={props.setPopupIsOpen} size="SMALL">
        <Component name="support-form">
          <Title type="h2" size="s" name="support-popup-title">
            Support Anfrage
          </Title>
          <Component name="support-form-input">
            <TextInputElement
              id="subject"
              label="Betreff"
              type="text"
              labelClasses="p--l"
              onInput={(e) => setSubject((e.target as HTMLInputElement).value)}
              value={subject}
            />
            <TextInputElement
              id="description"
              label="Anfrage"
              type="textarea"
              labelClasses="p--l"
              onInput={(e) => setDescription((e.target as HTMLInputElement).value)}
              value={description}
            />
          </Component>
          <Button
            type="primary"
            disabled={subject === ""}
            onClick={contactSupport}
            name="support-form-submit">
            Abschicken
          </Button>
        </Component>
      </Popup>
    );
  };

  return <></>;
};

export const ReportForm = (props: {
  popupIsOpen: boolean;
  setPopupIsOpen: (value: boolean) => void;
  title: string;
  location?: string;
  subject?: string;
}) => {
  const { setPopupContent } = useContext(Context);

  useEffect(() => {
    if (setPopupContent) {
      if (props.popupIsOpen) {
        setPopupContent(<Content />);
      } else {
        setPopupContent(undefined);
      }
    }
  }, [props.popupIsOpen]);

  const Content = () => {
    const [reason, setReason] = useState<string>("");
    const { apiRequest } = useRequest();

    const report = async () => {
      const request = {
        title: "REPORT" + (props.subject ? " - Diskussion - " + props.subject : ""),
        description: reason,
        source: props.location ?? window.location.href,
      };
      await apiRequest("tickets", "POST", { body: request, showToast: true });
      props.setPopupIsOpen(false);
    };

    return (
      <Popup setPopupIsOpen={props.setPopupIsOpen} size="SMALL">
        <Component name="report-form">
          <Title type="h2" size="s" name="report-popup-title">
            {props.title}
          </Title>
          <Component name="report-form-input">
            <TextInputElement
              id="subject"
              label="Grund"
              type="text"
              labelClasses="p--l"
              onInput={(e) => setReason((e.target as HTMLInputElement).value)}
              value={reason}
            />
          </Component>
          <Button
            type="primary"
            disabled={reason === ""}
            onClick={report}
            name="report-form-submit">
            Abschicken
          </Button>
        </Component>
      </Popup>
    );
  };

  return <></>;
};

export const ConfirmationDisplay = (props: {
  popupIsOpen: boolean;
  setPopupIsOpen: (value: boolean) => void;
  title: string;
  action: any;
  reversed?: boolean;
  onCancel?: () => void;
}) => {
  const { setPopupContent } = useContext(Context);

  useEffect(() => {
    if (setPopupContent) {
      if (props.popupIsOpen) {
        setPopupContent(<Content />);
      } else {
        setPopupContent(undefined);
      }
    }
  }, [props.popupIsOpen]);

  const Content = () => {
    return (
      <Popup
        setPopupIsOpen={() => {
          props.setPopupIsOpen(false);
          if (props.onCancel) {
            props.onCancel();
          }
        }}
        size="SMALL">
        <Component name="confirmation-dialog">
          <Title type="h2" size="s" name="report-popup-title">
            {props.title}
          </Title>
          <Component name="confirmation-buttons">
            <Button
              type={props.reversed === undefined || !props.reversed ? "primary" : "dangerGhost"}
              onClick={() => {
                props.setPopupIsOpen(false);
                if (props.onCancel) {
                  props.onCancel();
                }
              }}
              name="confirmation-break">
              Nein
            </Button>
            <Button
              type={props.reversed === undefined || !props.reversed ? "dangerGhost" : "primary"}
              onClick={async () => {
                await props.action();
                props.setPopupIsOpen(false);
              }}
              name="confirmation-submit">
              Ja
            </Button>
          </Component>
        </Component>
      </Popup>
    );
  };

  return <></>;
};

export const UserSearchField = (props: {
  setUser: (users: UsersResponseDto[]) => void;
  selected: UsersResponseDto[];
  title?: string;
  hasError?: boolean;
}) => {
  const { apiRequest } = useRequest();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [suggestions, setSuggestions] = useState<UsersResponseDto[]>([]);

  useEffect(() => {
    const getSuggestions = async () => {
      if (searchQuery.trim().length > 2) {
        const { data } = await apiRequest<Paginated<UsersResponseDto>>(
          `users/search?search=${searchQuery}`,
          "GET",
        );
        setSuggestions(data.docs);
      } else {
        setSuggestions([]);
      }
    };
    getSuggestions();
  }, [searchQuery]);

  const onUserClick = (index: number) => {
    props.setUser([suggestions[index], ...props.selected]);
    setSuggestions([]);
  };

  const removeFromList = (id: string) => {
    const filtered: UsersResponseDto[] = [];
    props.selected.map((value) => {
      if (value._id !== id) {
        filtered.push(value);
      }
    });
    props.setUser(filtered);
  };

  return (
    <>
      <TextInputElement
        id="user"
        label={props.title ?? "User"}
        type="text"
        labelClasses="p--l"
        onInput={(e) => setSearchQuery((e.target as HTMLInputElement).value)}
        value={searchQuery}
        errorMessage={props.hasError ? "Bitte wählen Sie einen User aus" : ""}
        displayErrorMessage={props.hasError}
      />
      {suggestions.length > 0 && (
        <Component name="user-suggestions">
          {suggestions.map((suggestion, index) => {
            return (
              <SearchSuggestion
                key={suggestion._id}
                text={`${suggestion.firstName} ${suggestion.lastName}`}
                onMouseDown={() => onUserClick(index)}
              />
            );
          })}
        </Component>
      )}
      {props.selected.map((user, i) => {
        return (
          <Component key={i} name="selected-user">
            <Paragraph
              type="paragraph"
              name="selected-user">{`${user.firstName} ${user.lastName}`}</Paragraph>
            <Icon svg={<CloseSVG />} name="user-remover" onClick={() => removeFromList(user._id)} />
          </Component>
        );
      })}
    </>
  );
};

export const MasterclassEarlyBirdPopup = ({
  setOpen,
}: {
  setOpen: Dispatch<SetStateAction<boolean | undefined>>;
}) => {
  return (
    <Popup setPopupIsOpen={setOpen} size={"SMALL"}>
      <Component name="remind-me-popup">
        <Icon
          name="close-popup"
          svg={<CloseSVG />}
          onClick={() => {
            setOpen ? setOpen(false) : "";
          }}
        />
        <Title name="reminde-me-title" size="s" type="h2">
          Gleich gehts los 👏
        </Title>
        <Paragraph name="remind-me-text" size="m">
          Bald kannst du mit der Masterclass starten! 😍
        </Paragraph>
        <Component name="early-bird-button-group">
          <Button
            type="primaryGhost"
            onClick={() =>
              window.open("https://sdrsofgermany.typeform.com/masterclasses24", "_blank")?.focus()
            }
            name="remind-me-button">
            Zur Warteliste
          </Button>
          <Button
            type="primary"
            onClick={() =>
              window.open("https://calendly.com/lea-sdrsog/early-bird-zugriff", "_blank")?.focus()
            }
            name="remind-me-button">
            Zum early Bird Discount
          </Button>
        </Component>
      </Component>
    </Popup>
  );
};

export const CountdownTimer = (props: { targetDate: Date; display: boolean }) => {
  const calculateTimeLeft = () => {
    return new Date(props.targetDate.getTime() - new Date().getTime());
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <>
      {props.display && (
        <Component name="countdown">
          <Component name="countdown-number">
            <Title
              type="paragraph"
              size="s"
              className={`txt--c-pri ${props.display ? "" : "hidden"}`}>
              <span>{format(timeLeft, "D", { useAdditionalDayOfYearTokens: true })}</span>
            </Title>
          </Component>
          <Title type="paragraph" size="xxl" className="txt--c-pri">
            :
          </Title>
          <Component name="countdown-number">
            <Title
              type="paragraph"
              size="s"
              className={`txt--c-pri ${props.display ? "" : "hidden"}`}>
              <span>{format(timeLeft, "hh", { useAdditionalDayOfYearTokens: true })}</span>
            </Title>
          </Component>
          <Title type="paragraph" size="xxl" className="txt--c-pri">
            :
          </Title>
          <Component name="countdown-number">
            <Title
              type="paragraph"
              size="s"
              className={`txt--c-pri ${props.display ? "" : "hidden"}`}>
              <span>{format(timeLeft, "mm", { useAdditionalDayOfYearTokens: true })}</span>
            </Title>
          </Component>
          <Title type="paragraph" size="xxl" className="txt--c-pri">
            :
          </Title>
          <Component name="countdown-number">
            <Title
              type="paragraph"
              size="s"
              className={`txt--c-pri ${props.display ? "" : "hidden"}`}>
              <span>{format(timeLeft, "ss", { useAdditionalDayOfYearTokens: true })}</span>
            </Title>
          </Component>
          <Paragraph name="countdown-prop" size="s" className="txt--c-pri">
            Tage
          </Paragraph>
          <Paragraph name="countdown-prop" size="s"></Paragraph>
          <Paragraph name="countdown-prop" size="s" className="txt--c-pri">
            Stunden
          </Paragraph>
          <Paragraph name="countdown-prop" size="s"></Paragraph>
          <Paragraph name="countdown-prop" size="s" className="txt--c-pri">
            Minuten
          </Paragraph>
          <Paragraph name="countdown-prop" size="s"></Paragraph>
          <Paragraph name="countdown-prop" size="s" className="txt--c-pri">
            Sekunden
          </Paragraph>
        </Component>
      )}
    </>
  );
};
