const MailWaitingSVG: React.FC<React.HTMLProps<HTMLOrSVGElement>> = () => {
  return (
    <svg viewBox="0 0 363 172" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M-54 108.373C-44.8422 94.5822 -16.9108 67 21.5521 67C69.6307 67 79.6691 115.45 133.031 115.995C175.721 116.43 218.798 90.0456 235 76.7989"
        stroke="#8B8B8B"
        strokeWidth="4"
        strokeDasharray="22 22"
      />
      <g clipPath="url(#clip0_1375_3635)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M225.101 35.5489C222.29 38.6712 221.022 43.4046 218.485 52.8716L208.822 88.9328C206.286 98.3998 205.017 103.133 205.891 107.243C206.659 110.858 208.552 114.137 211.299 116.609C214.421 119.421 219.154 120.689 228.621 123.226L287.221 138.927C296.688 141.464 301.421 142.732 305.531 141.859C309.146 141.091 312.425 139.197 314.898 136.451C317.709 133.329 318.977 128.595 321.514 119.128L331.176 83.0672C333.713 73.6002 334.981 68.8667 334.108 64.7572C333.339 61.1423 331.446 57.8634 328.7 55.3906C325.578 52.5793 320.844 51.311 311.377 48.7743L252.778 33.0726C243.311 30.536 238.577 29.2676 234.468 30.1411C230.853 30.9095 227.574 32.8025 225.101 35.5489ZM250.788 57.7551C248.694 55.3072 245.012 55.0206 242.564 57.115C240.116 59.2094 239.829 62.8918 241.923 65.3397L259.715 86.1339C264.418 91.6306 272.018 93.6671 278.839 91.2583L304.644 82.1456C307.682 81.0729 309.275 77.7406 308.202 74.7028C307.129 71.665 303.797 70.072 300.759 71.1448L274.954 80.2574C272.681 81.0604 270.147 80.3815 268.58 78.5493L250.788 57.7551Z"
          fill="#0500FF"
        />
      </g>
      <circle cx="332" cy="60" r="15" fill="#17A2B8" />
      <defs>
        <clipPath id="clip0_1375_3635">
          <rect
            width="140"
            height="140"
            fill="white"
            transform="translate(220.502 0.267822) rotate(15)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MailWaitingSVG;
