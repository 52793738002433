import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import {
  Component,
  ComponentCard,
  Section,
  SectionElement,
  Image,
  Paragraph,
  Title,
  Popup,
} from "../Elements/Elements";
import { EventResponseDto, Paginated } from "../../requests/interfaces";
import Context from "../../context/Context";
import { EventDetails } from "./Events";
import useRequest from "../../hooks/useRequest";
import { format } from "date-fns";
import { Loading } from "../Loading/Loading";
import { HashtagsFilter } from "../Elements/FormElements";
import InfiniteScroll from "react-infinite-scroll-component";

interface EventProps {
  isOnlineMeeting: boolean;
}

const EventsTemplate = (props: EventProps) => {
  // const [nextEvents, setNextEvents] = useState<EventResponseDto[]>();
  const [events, setEvents] = useState<EventResponseDto[]>([]);
  const [filters, setFilters] = useState<string[]>([]);
  const { apiRequest } = useRequest();
  const [reload, setReload] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);

  const [openedEvent, setOpenedEvent] = useState<EventResponseDto>();
  const { setPopupContent } = useContext(Context);

  useEffect(() => {
    if (setPopupContent) {
      if (openedEvent) {
        setPopupContent(
          <EventPopup
            event={openedEvent}
            setPopupOpen={() => setOpenedEvent(undefined)}
            setReload={setReload}
          />,
        );
      } else {
        setPopupContent(undefined);
      }
    }
  }, [openedEvent]);

  const getEvents = async (resetPage?: boolean) => {
    let filterString = "";
    if (filters.length !== 0) {
      filterString = filters.join("&");
    }
    const eventsResponse = await apiRequest<Paginated<EventResponseDto>>(
      `events?isOnlineMeeting=${props.isOnlineMeeting}&page=${resetPage ? 0 : page}${
        filterString ? `&${filterString}` : ""
      }&limit=20`,
      "GET",
    );

    setEvents((oldValues) => {
      const combined = [...(oldValues ?? []), ...eventsResponse.data.docs];
      const final: EventResponseDto[] = [];
      if (resetPage) {
        return eventsResponse.data.docs;
      } else {
        combined.map((value) => {
          const index = final.findIndex((item) => item._id === value._id);
          if (index === -1) {
            final.push(value);
          }
        });
        return final;
      }
    });

    setHasNextPage(eventsResponse.data.hasNextPage);
    setPage(eventsResponse.data.page + 1);
  };

  useEffect(() => {
    // const getNextEvents = async () => {
    //   const nextEventsResponse = await apiRequest<Paginated<EventResponseDto>>(
    //     `events?isOnlineMeeting=${props.isOnlineMeeting}&limit=5`,
    //     "GET",
    //   );
    //   setNextEvents(nextEventsResponse.data.docs);
    // };
    getEvents(true);
    // getNextEvents();
  }, [props.isOnlineMeeting, reload]);

  useEffect(() => {
    getEvents(true);
  }, [filters]);

  return (
    <Section name="events">
      {false && (
        <SectionElement name="next-events" type="header">
          {/* <Title type="h3" size="m" name="next-events">
            Aktuelle Events 🔥
          </Title>
          {!nextEvents && <Loading />}
          <Component name="next-events">
            {nextEvents?.map((event) => {
              return (
                <EventItem event={event} key={event._id} onClick={() => setOpenedEvent(event)} />
              );
            })}
          </Component> */}
        </SectionElement>
      )}
      <SectionElement name="all-events" type="body">
        <Component name="all-events">
          <HashtagsFilter onFilterChange={setFilters} showLabel={false} />
          {!events && <Loading />}
          {events?.length === 0 && (
            <Title name="no-events" type="h3" size="m">
              Keine Events gefunden
            </Title>
          )}
          <InfiniteScroll
            dataLength={events.length}
            next={getEvents}
            hasMore={hasNextPage}
            loader={<h4>Loading...</h4>}
            scrollableTarget="parent-component">
            <Component name="events">
              {events?.map((event) => {
                return (
                  <EventItem event={event} key={event._id} onClick={() => setOpenedEvent(event)} />
                );
              })}
            </Component>
          </InfiniteScroll>
        </Component>
      </SectionElement>
    </Section>
  );
};

export const EventItem = (props: {
  event: EventResponseDto;
  onClick?: () => void;
  isHome?: boolean;
}) => {
  const [popupIsOpen, setPopupIsOpen] = useState<boolean>();
  const { setPopupContent } = useContext(Context);

  useEffect(() => {
    if (setPopupContent && !props.onClick) {
      if (popupIsOpen) {
        setPopupContent(
          <EventPopup event={props.event} setPopupOpen={() => setPopupIsOpen(false)} />,
        );
      } else {
        setPopupContent(undefined);
      }
    }
  }, [popupIsOpen]);

  return (
    <ComponentCard
      name="event"
      type={props.isHome ? "standard" : "shadow"}
      className={props.event.isParticipant ? "registered" : ""}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        } else {
          setPopupIsOpen(true);
        }
      }}>
      <Image name="event-image" img={props.event.picture} />
      <Component name="event-data">
        <Component name="event-infos">
          <Title name="event-name" type="h3" size="xxs">
            {props.event.title}
          </Title>
          <Paragraph name="event-date" type="paragraph">
            {`${format(
              props.event.date ? new Date(props.event.date) : new Date(),
              "dd. MMMM YYY, HH:mm",
            )} Uhr`}
          </Paragraph>
        </Component>
      </Component>
    </ComponentCard>
  );
};

const EventPopup = (props: {
  event: EventResponseDto;
  setPopupOpen: (value: boolean) => void;
  setReload?: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <Popup setPopupIsOpen={props.setPopupOpen} size="BIG">
      <EventDetails
        event={props.event}
        setPopupOpen={props.setPopupOpen}
        setReload={props.setReload}
      />
    </Popup>
  );
};

export default EventsTemplate;
