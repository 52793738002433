import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { ExerciseResponseDto, Paginated } from "../../requests/interfaces";
import {
  Section,
  SectionElement,
  Title,
  Table,
  Paragraph,
  TableColumn,
  TablePagination,
  Component,
  ProfileBubble,
  Icon,
  Link,
  Button,
  Popup,
} from "../Elements/Elements";
import useRequest from "../../hooks/useRequest";
import CloseSVG from "../../Icons/CloseSVG";
import { useSearchParams } from "react-router-dom";
import { Loading } from "../Loading/Loading";
import TickSVG from "../../Icons/TickSVG";
import { Markup } from "interweave";
import ArrowSVG from "../../Icons/ArrowSVG";
import historyNavigate from "../../hooks/useHistoryNavigate";
import { Switch, TextInputElement } from "../Elements/FormElements";
import React from "react";
import Context from "../../context/Context";

const AdminExerciseContext = React.createContext<{
  exerciseItem?: ExerciseResponseDto;
  setExerciseItem?: Dispatch<SetStateAction<ExerciseResponseDto | undefined>>;
}>({});

const AdminExercises = () => {
  const [mode, setMode] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const modeId = searchParams.get("mode");
  const [modes, setModes] = useState<any[]>([]);
  const [exerciseItem, setExerciseItem] = useState<ExerciseResponseDto>();
  const setupModes = () => {
    const allModes = [
      {
        modeId: "LIST",
        component: (
          <AdminExercisesList searchParams={searchParams} setSearchParams={setSearchParams} />
        ),
        active: modeId === null || modeId !== "details",
      },
      {
        modeId: "DETAILS",
        component: <AdminExerciseItem />,
        active: modeId === "details",
      },
    ];
    setModes(allModes);
  };

  useEffect(() => {
    setMode(modes.find((tab: any) => tab.active === true));
  }, [modes]);

  useEffect(() => {
    setupModes();
  }, [modeId]);

  useEffect(() => {
    setupModes();
  }, []);

  return (
    <AdminExerciseContext.Provider
      value={{ exerciseItem: exerciseItem, setExerciseItem: setExerciseItem }}>
      {mode && mode.component}
    </AdminExerciseContext.Provider>
  );
};

const AdminExercisesList = (props: { searchParams: any; setSearchParams: any }) => {
  const [exercises, setExercises] = useState<ExerciseResponseDto[]>();
  const [hasNext, setHasNext] = useState<boolean>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [mode, setMode] = useState<"PENDING" | "APPROVED">("PENDING");
  const { apiRequest } = useRequest();
  const { setExerciseItem } = useContext(AdminExerciseContext);

  const getExercises = async () => {
    const { data } = await apiRequest<Paginated<ExerciseResponseDto>>(
      `academy/admin/exercises?limit=10&page=${currentPage}&status=${mode}`,
      "GET",
    );
    setExercises(data.docs);
    setHasNext(data.hasNextPage);
  };

  useEffect(() => {
    getExercises();
  }, []);

  useEffect(() => {
    getExercises();
  }, [currentPage, mode]);

  const approve = async (id: string) => {
    await apiRequest(`academy/admin/exercises/${id}/approve`, "PATCH");
    getExercises();
  };

  const decline = async (id: string) => {
    await apiRequest(`academy/admin/exercises/${id}/decline`, "PATCH");
    getExercises();
  };

  const exerciseDetails = (item: ExerciseResponseDto) => {
    props.searchParams.set("mode", "details");
    props.searchParams.set("id", item._id);
    props.setSearchParams(props.searchParams);
    if (setExerciseItem) {
      setExerciseItem(item);
    }
  };

  return (
    <>
      <Section name="admin-exercises">
        <SectionElement type="header" name="admin-exercises">
          <Title name="admin-exercises-title" type="h1" size="m">
            Exercises
          </Title>
        </SectionElement>
        <SectionElement type="body" name="admin-exercises">
          <Switch
            label={"Show Approved"}
            id="approved"
            value={mode === "PENDING" ? -1 : 1}
            type={""}
            onChange={() => {
              setMode((old) => {
                return old === "PENDING" ? "APPROVED" : "PENDING";
              });
            }}
          />
          <Component name="table-background">
            <Table name="admin-exercises">
              <thead>
                <tr className="h--xxs">
                  <th>
                    <Paragraph name="admin-exercises-submitter" type="paragraph">
                      Benutzer
                    </Paragraph>
                  </th>
                  <th>
                    <Paragraph name="admin-exercises-academy-name" type="paragraph">
                      Academy
                    </Paragraph>
                  </th>
                  <th>
                    <Paragraph name="admin-exercises-track-name" type="paragraph">
                      Lektion
                    </Paragraph>
                  </th>
                  <th>
                    <Paragraph name="admin-exercises-exercise-type" type="paragraph">
                      Exercise Type
                    </Paragraph>
                  </th>
                  <th>
                    <Paragraph name="admin-exercises-content" type="paragraph">
                      Content
                    </Paragraph>
                  </th>
                  <th>
                    <Paragraph name="admin-exercises-status" type="paragraph">
                      Status
                    </Paragraph>
                  </th>
                </tr>
              </thead>
              <tbody>
                {exercises?.map((exercise) => {
                  return (
                    <tr key={exercise._id} onClick={() => exerciseDetails(exercise)}>
                      <TableColumn name="admin-col">
                        <ProfileBubble user={exercise.user} />
                      </TableColumn>
                      <TableColumn>
                        <Component name="admin-exercises-academy-name">
                          <Paragraph type="paragraph" name="admin-exercises-academy-name">
                            {exercise.academyName}
                          </Paragraph>
                        </Component>
                      </TableColumn>
                      <TableColumn>
                        <Component name="admin-exercises-track-name">
                          <Paragraph type="paragraph" name="admin-exercises-track-name">
                            {exercise.trackName}
                          </Paragraph>
                        </Component>
                      </TableColumn>
                      <TableColumn>
                        <Component name="admin-exercises-exercise-type">
                          <Paragraph type="paragraph" name="admin-exercises-exercise-type">
                            {exercise.exerciseType}
                          </Paragraph>
                        </Component>
                      </TableColumn>
                      <TableColumn>
                        <Component name="admin-exercises-content">
                          <Paragraph
                            type="paragraph"
                            name="admin-exercises-content"
                            className="txt--3lines">
                            {exercise.content}
                          </Paragraph>
                        </Component>
                      </TableColumn>
                      <TableColumn>
                        {exercise.status === "PENDING" && (
                          <>
                            <Component name="admin-exercises-profile-state">
                              <Icon
                                name="resource-edit"
                                svg={<TickSVG />}
                                className="primary"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  approve(exercise._id);
                                }}
                              />
                            </Component>
                            <Component name="admin-exercises-profile-state">
                              <Icon
                                name="resource-edit"
                                svg={<CloseSVG />}
                                className="primary"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  decline(exercise._id);
                                }}
                              />
                            </Component>
                          </>
                        )}
                      </TableColumn>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {hasNext !== undefined && (
              <TablePagination
                currentPage={currentPage}
                hasNext={hasNext}
                setCurrentPage={setCurrentPage}
              />
            )}
          </Component>
        </SectionElement>
      </Section>
    </>
  );
};

const AdminExerciseItem = () => {
  const { exerciseItem } = useContext(AdminExerciseContext);
  const [item, setItem] = useState<ExerciseResponseDto>();
  const { useHistoryNavigate } = historyNavigate();
  const { apiRequest } = useRequest();
  const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false);
  const { setPopupContent } = useContext(Context);

  useEffect(() => {
    if (setPopupContent) {
      if (popupIsOpen) {
        setPopupContent(
          <Popup setPopupIsOpen={setPopupIsOpen} size="SMALL">
            <AdminExerciseItemFeedback
              id={item?._id ?? ""}
              onAction={() => setPopupIsOpen(false)}
            />
          </Popup>,
        );
      } else {
        setPopupContent(undefined);
      }
    }
  }, [popupIsOpen]);

  useEffect(() => {
    if (exerciseItem) {
      setItem(exerciseItem);
    }
  }, [exerciseItem]);

  const action = async (type: "approve" | "decline") => {
    await apiRequest(`academy/admin/exercises/${item?._id}/${type}`, "PATCH", {
      toastText: type === "approve" ? "Approved" : "Declined",
      showToast: true,
    });
  };

  return (
    <>
      <Section name="admin-exercise">
        <SectionElement type="body" name="admin-exercise">
          <Component name="admin-exercise">
            {item === undefined && <Loading />}
            {item && (
              <Component name="admin-exercise-content">
                <Component name="admin-exercise-item">
                  <Component name="admin-exercise-item-title">
                    <Title name="admin-exercise-item-title" type="h2" size="m">
                      Aufgaben von {`${item.user.firstName} ${item.user.lastName}`} aus der
                      {` "${item.academyName}"`} Masterclass
                    </Title>
                    <Component name="admin-exercise-item-info">
                      <Button
                        type="primary"
                        svg={<ArrowSVG />}
                        name="admin-exercise-item-info"
                        onClick={() => {
                          useHistoryNavigate(
                            `/academy/${item.academyId}/${item.trackId}?route=exercise`,
                          );
                        }}>
                        zur Academy
                      </Button>
                    </Component>
                  </Component>
                  <Component name="admin-exercise-item-content">
                    <Component name="admin-exercise-item-question">
                      <Title name="admin-exercise-item-title" type="h3" size="s">
                        {`Aufgabe: ${item.trackName}`}
                      </Title>
                      <Component name="exercise-sepeartor">
                        <hr />
                      </Component>
                      <Title name="admin-exercise-item-title" type="h3" size="s">
                        Description:
                      </Title>
                      <Markup content={item.description} />
                    </Component>
                    <Component name="exercise-sepeartor">
                      <hr />
                    </Component>
                    {item.exerciseType !== "FREE_TEXT" && (
                      <Link href={item.content} name="admin-exercise-item-content">
                        {item.exerciseType === "FILE_UPLOAD" ? "File download" : item.content}
                      </Link>
                    )}
                    {item.exerciseType === "FREE_TEXT" && (
                      <>
                        <Markup content={item.content} />
                      </>
                    )}
                  </Component>
                </Component>
                <Component name="admin-exercise-action-buttons">
                  <Button
                    type="primaryGhost"
                    name="admin-exercise-decline"
                    onClick={() => setPopupIsOpen(true)}>
                    Give Feedback
                  </Button>
                  <Button
                    type="primary"
                    name="admin-exercise-approve"
                    onClick={() => action("approve")}>
                    Approve task
                  </Button>
                </Component>
              </Component>
            )}
          </Component>
        </SectionElement>
      </Section>
    </>
  );
};

const AdminExerciseItemFeedback = ({ id, onAction }: { id: string; onAction: () => void }) => {
  const { apiRequest } = useRequest();
  const [feedback, setFeedback] = useState<string>("");

  const action = async (type: "approve" | "decline") => {
    await apiRequest(`academy/admin/exercises/${id}/${type}`, "PATCH", {
      toastText: type === "approve" ? "Approved" : "Declined",
      showToast: true,
    });
    if (feedback !== "") {
      await apiRequest(`academy/admin/exercises/${id}/feedback`, "PATCH", {
        body: {
          feedback: feedback,
        },
      });
    }
    onAction();
  };

  return (
    <>
      <Component name="admin-exercise-feedback">
        <Component name="admin-exercise-feedback-content">
          <Component name="admin-exercise-feedback-title">
            <Title name="admin-exercise-feedback-title" type="h2" size="m">
              Feedback
            </Title>
          </Component>
          <Component name="admin-exercise-feedback-content">
            <Component name="admin-exercise-feedback-item">
              <Component name="admin-exercise-feedback-item-content">
                <Component name="admin-exercise-feedback-item-content">
                  <TextInputElement
                    id="text-field-track-text"
                    type="textarea"
                    labelClasses="p--l"
                    onInput={(e) => {
                      setFeedback((e.target as HTMLInputElement).value);
                    }}
                    value={feedback}
                  />
                </Component>
              </Component>
            </Component>
          </Component>
          <Component name="admin-exercise-action-buttons">
            <Button type="danger" name="admin-exercise-decline" onClick={() => action("decline")}>
              Decline task
            </Button>
            <Button type="primary" name="admin-exercise-approve" onClick={() => action("approve")}>
              Approve task
            </Button>
          </Component>
        </Component>
      </Component>
    </>
  );
};

export default AdminExercises;
