import { AppView, Component, Section, SectionElement, Title } from "../Elements/Elements";
import Navigation from "./Navigation";
import { Notifications as NotificationsComponent } from "./Header";

const Notifications = () => {
  return (
    <>
      <AppView name="notifications">
        <Navigation />
        <Section name="notifications">
          <SectionElement type="body" name="notifications">
            <Component>
              <NotificationsComponent />
            </Component>
          </SectionElement>
        </Section>
      </AppView>
    </>
  );
};

export { Notifications };
