import LocationSVG from "../../Icons/LocationSVG";
import PlusSVG from "../../Icons/PlusSVG";
import { TabProps } from "../Elements/Components";
import {
  AppView,
  Button,
  Component,
  Icon,
  Paragraph,
  Title,
  Image,
  ProfileBubble,
  Link,
} from "../Elements/Elements";
import Navigation from "../Navigation/Navigation";
import EventsTemplate from "./EventsTemplate";
import MyEvents from "./MyEvents";
import "./events.scss";
import { EventResponseDto } from "../../requests/interfaces";
import useRequest from "../../hooks/useRequest";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { format } from "date-fns";
import CloseSVG from "../../Icons/CloseSVG";
import Context from "../../context/Context";

const Events = () => {
  const tabs: TabProps[] = [
    {
      children: <EventsTemplate isOnlineMeeting={true} />,
      id: 0,
      isActive: true,
      label: "Online",
    },
    {
      children: <EventsTemplate isOnlineMeeting={false} />,
      id: 1,
      isActive: false,
      label: "Offline",
    },
    {
      children: <MyEvents />,
      id: 2,
      isActive: false,
      label: "Meine Events",
    },
  ];

  return (
    <>
      <AppView name="home">
        <Navigation tabProps={tabs} />
      </AppView>
    </>
  );
};

const EventDetails = (props: {
  event: EventResponseDto;
  setPopupOpen?: (value: boolean) => void;
  showDetails?: boolean;
  setReload?: Dispatch<SetStateAction<boolean>>;
  showCloseIcon?: boolean;
}) => {
  const { apiRequest } = useRequest();
  const [isSignedUp, setIsSignedUp] = useState<boolean>(props.event.isParticipant);
  const { isMobile } = useContext(Context);

  const toggleSignUp = async () => {
    const route = isSignedUp ? "sign-out" : "sign-up";
    const { status } = await apiRequest(`events/${props.event._id}/${route}`, "PATCH", {
      showToast: true,
    });
    if (status === 204) {
      setIsSignedUp((value) => !value);
    }
  };

  useEffect(() => {
    if (props.setReload) {
      props.setReload((value) => !value);
    }
  }, [isSignedUp]);

  useEffect(() => {
    console.log(props.showDetails);
  }, [props.showDetails]);

  useEffect(() => {
    if (props.event.eventId) {
      if (!globalThis) {
        const script1 = document.createElement("script");
        script1.textContent = "const global = globalThis;";
        // Append the first script element to the document body
        document.body.appendChild(script1);
      }

      // Create the second script element
      const script2 = document.createElement("script");
      script2.src = props.event.flowId
        ? "https://static.zuddl.com/embed/flow.js"
        : "https://static.zuddl.com/embed/reg-form.js";
      // Append the second script element to the document body
      document.body.appendChild(script2);
    }
  }, []);

  return (
    <>
      <Component name="popup-event">
        <Component name="event-info">
          <Component name="event-head">
            <Component name="event-header">
              {isMobile && props.showCloseIcon !== false && (
                <Icon
                  name="close-popup"
                  svg={<CloseSVG />}
                  onClick={() => (props.setPopupOpen ? props.setPopupOpen(false) : "")}
                />
              )}
              {!props.event.eventId && (
                <Component name="event-thumbnail">
                  <Image img={props.event?.picture} name="event-thumbnail" />
                </Component>
              )}
            </Component>
            <Component name="event-meta">
              <Title size={isMobile ? "s" : "m"} type="h2" name="event-title">
                {props.event.title}
              </Title>
              {!props.event.eventId && (
                <>
                  <Component name="event-location">
                    <Icon name="event-location" svg={<LocationSVG />} />
                    <Link
                      name="event-location"
                      href={props.event.meetingLocationUrl}
                      onClick={() => window.open(props.event.meetingLocationUrl, "_blank")}>
                      zum Event
                    </Link>
                  </Component>
                  <Paragraph name="event-date" type="paragraph">
                    {`${format(
                      props.event.date ? new Date(props.event.date) : new Date(),
                      "dd. MMMM YYY, HH:mm",
                    )} Uhr`}
                  </Paragraph>
                  <Component name="participants">
                    {/* {props.event.participants?.map((user) => {
                  return <ProfilePicture img={user.profilePicture} key={user._id} />;
                })} */}
                  </Component>
                </>
              )}
            </Component>
            {!props.event.eventId && (
              <Component name="event-actions">
                {!isMobile && props.showCloseIcon !== false && (
                  <Icon
                    name="close-popup"
                    svg={<CloseSVG />}
                    onClick={() => (props.setPopupOpen ? props.setPopupOpen(false) : "")}
                  />
                )}
                <AddToCalendarButton
                  name={props.event.title}
                  startDate={
                    format(new Date(props.event.date), "yyyy-MM-dd") +
                    "T" +
                    format(new Date(props.event.date), "HH:mm")
                  }
                  endDate={
                    props.event.endDate
                      ? format(new Date(props.event.endDate), "yyyy-MM-dd") +
                        "T" +
                        format(new Date(props.event.endDate), "HH:mm")
                      : ""
                  }
                  description={props.event.description}
                  location={props.event.meetingLocationUrl}
                  label="Zum Kalender hinzufügen"
                  options={["Apple", "Google", "Yahoo", "iCal"]}
                  timeZone="CET"
                />
                <Button
                  name="event-sign-in"
                  type="primaryText"
                  iconBefore={true}
                  svg={<PlusSVG />}
                  onClick={() => toggleSignUp()}>
                  {isSignedUp ? "Angemeldet" : "Anmelden"}
                </Button>
              </Component>
            )}
          </Component>
          {props.showDetails !== false && (
            <Component name={"event-body-parent" + (props.event.eventId ? " active" : "")}>
              <Component name="event-body">
                {props.event.eventId && (
                  <Component name="event-thumbnail">
                    <Image img={props.event?.picture} name="event-thumbnail" />
                  </Component>
                )}
                <Component name="event-detail">
                  <Title size="xxs" type="h3" name="description-title">
                    Über dieses Event:
                  </Title>
                  <Paragraph name="description" type="paragraph" withLineBreaks={true}>
                    {props.event.description}
                  </Paragraph>
                </Component>
                {props.event.speakers?.length !== 0 && (
                  <Component name="event-detail">
                    <Title size="xxs" type="h3" name="speakers-title">
                      Präsentiert von:
                    </Title>
                    <Component name="event-speakers">
                      {props.event.speakers.map((speaker, i) => {
                        return (
                          <Component name="event-speaker" key={speaker._id + i}>
                            <ProfileBubble user={speaker} />
                          </Component>
                        );
                      })}
                    </Component>
                  </Component>
                )}
                {props.event.sponsors?.length !== 0 && (
                  <Component name="event-detail">
                    <Title size="xxs" type="h3" name="sponsors-title">
                      Sponsoren:
                    </Title>
                    <Component name="event-sponsors">
                      {props.event.sponsors?.map((sponsor, i) => {
                        return (
                          <Component
                            key={sponsor.name + i}
                            name="event-sponsor"
                            onClick={() => window.open(sponsor.homePage, "_blank")?.focus()}>
                            <Image img={sponsor.picture} name="sponsor-logo" />
                          </Component>
                        );
                      })}
                    </Component>
                  </Component>
                )}
              </Component>
              {props.event.eventId && (
                <>
                  {props.event.flowId ? (
                    <div
                      data-zuddl-widget-type="zuddl-flow"
                      data-zuddl-event-id={props.event.eventId}
                      data-zuddl-flow-id={props.event.flowId}
                      style={{ width: "50%", height: "100%" }}
                      data-zuddl-sticky-header-id=""
                      data-env=""
                    />
                  ) : (
                    <div
                      id="zuddl-embeddable-form"
                      data-zuddl-event-id={props.event.eventId}
                      data-env="prod"
                      style={{ width: "50%", height: "100%" }}
                    />
                  )}
                </>
              )}
            </Component>
          )}
        </Component>
      </Component>
    </>
  );
};

export { Events, EventDetails };
