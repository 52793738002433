const WatchSVG: React.FC<React.HTMLProps<HTMLOrSVGElement>> = () => {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C16.4183 22 20 18.4183 20 14C20 9.58172 16.4183 6 12 6C7.58172 6 4 9.58172 4 14C4 18.4183 7.58172 22 12 22ZM12 10C11.4477 10 11 10.4477 11 11V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V11C13 10.4477 12.5523 10 12 10Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 2C10.4477 2 10 2.44772 10 3C10 3.55228 10.4477 4 11 4H13C13.5523 4 14 3.55228 14 3C14 2.44772 13.5523 2 13 2H11ZM18.6 3.95C18.1582 3.61863 17.5314 3.70817 17.2 4.15C16.8686 4.59183 16.9582 5.21863 17.4 5.55L18.4 6.3C18.8418 6.63137 19.4686 6.54183 19.8 6.1C20.1314 5.65817 20.0418 5.03137 19.6 4.7L18.6 3.95Z"
      />
    </svg>
  );
};

export default WatchSVG;
