import { Main, Page } from "../components/Elements/Elements";
import Sidebar from "../components/Navigation/Sidebar";
import { Networking as NetworkingComponent } from "../components/Networking/Networking";

const Test = () => {
  return (
    <Page name="networking">
      <Sidebar />
      <Main>
        <NetworkingComponent />
      </Main>
    </Page>
  );
};

export default Test;
