import { Markup } from "interweave";
import { useContext, useEffect, useState } from "react";
import CloseSVG from "../../Icons/CloseSVG";
import Context from "../../context/Context";
import useRequest from "../../hooks/useRequest";
import {
  AcademyResponseDto,
  ExerciseSubmission,
  Paginated,
  TeamInternalResponseDto,
  TeamUserInternalAcademyResponseDtoNew,
  UsersWithTeamInternalResponseDto,
} from "../../requests/interfaces";
import { AcademyItem } from "../Academy/AllMasterclasses";
import { ConfirmationDisplay } from "../Elements/Components";
import {
  AppView,
  BackButton,
  Component,
  Icon,
  Link,
  Paragraph,
  Popup,
  ProfileBubble,
  Section,
  SectionElement,
  Table,
  TableColumn,
  Title,
} from "../Elements/Elements";
import { Loading } from "../Loading/Loading";
import Navigation from "../Navigation/Navigation";
import "./teamDashboard.scss";

const TeamDashboard = () => {
  const [team, setTeam] = useState<TeamInternalResponseDto>();
  const [detailsPopup, setDetailsPopup] = useState<boolean>(false);
  const [confirmationPopup, setConfirmationPopup] = useState<boolean>(false);
  const [userToHandle, setUserToHandle] = useState<UsersWithTeamInternalResponseDto>();
  const { setPopupContent } = useContext(Context);
  const { apiRequest } = useRequest();

  const getTeam = async () => {
    const data = await apiRequest<TeamInternalResponseDto>("teams", "GET");
    if (data.data) {
      setTeam(data.data);
    }
  };

  useEffect(() => {
    getTeam();
  }, []);

  const removeSeat = (id: string) => {
    return id;
  };

  useEffect(() => {
    if (setPopupContent) {
      if (detailsPopup && userToHandle) {
        setPopupContent(<ViewMorePopup setPopupOpen={setDetailsPopup} user={userToHandle} />);
      } else {
        setPopupContent(undefined);
      }
    }
  }, [detailsPopup]);

  return (
    <>
      <AppView name="team-dashboard">
        <Navigation />
        <Section name="team-dashboard">
          <SectionElement type="header" name="team-dashboard">
            <Component name="team-dashboard-header">
              <Title type="h2" size="xxl">
                Seats
              </Title>
            </Component>
          </SectionElement>
          <SectionElement type="body">
            {team ? (
              <TeamList
                team={team}
                setUserToHandle={setUserToHandle}
                setDetailsPopup={setDetailsPopup}
              />
            ) : (
              <Loading />
            )}
          </SectionElement>
        </Section>
      </AppView>
      <ConfirmationDisplay
        action={() => removeSeat(userToHandle?._id ?? "")}
        popupIsOpen={confirmationPopup}
        setPopupIsOpen={setConfirmationPopup}
        title="Bist du dir sicher, diesen User Seat zu entfernen?"
      />
    </>
  );
};

const TeamList = ({
  team,
  setUserToHandle,
  setDetailsPopup,
}: {
  team: TeamInternalResponseDto;
  setUserToHandle: (user: UsersWithTeamInternalResponseDto) => void;
  setDetailsPopup: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Component name="table-background">
      <Table name="team-dashboard">
        <thead>
          <tr className="h--xxs">
            <th>
              <Paragraph name="team-dashboard-seat-name" size="nav">
                Name
              </Paragraph>
            </th>
            <th>
              <Paragraph name="team-dashboard-seat-active-since" size="nav">
                Sitz aktiv seit
              </Paragraph>
            </th>
            <th>
              <Paragraph name="team-dashboard-last-active" size="nav">
                Zuletzt aktiv
              </Paragraph>
            </th>
            <th>
              <Paragraph name="team-dashboard-amount-of-masterclasses" size="nav">
                Masterclasses beendet
              </Paragraph>
            </th>
            <th>
              <Paragraph name="team-dashboard-progress" size="nav">
                in progress
              </Paragraph>
            </th>
            <th>
              <Paragraph name="team-dashboard-seats-left" size="nav">
                {`${team?.assignedSeats}/${team?.seats}`}
              </Paragraph>
            </th>
          </tr>
        </thead>
        <tbody>
          <TeamListRow
            user={team.leader}
            setUserToHandle={setUserToHandle}
            setDetailsPopup={setDetailsPopup}
          />
          {team.member.map((userSeat, i) => {
            return (
              <TeamListRow
                key={i}
                user={userSeat}
                setUserToHandle={setUserToHandle}
                setDetailsPopup={setDetailsPopup}
              />
            );
          })}
          {/* {team &&
            team.leader._id === user?._id &&
            Array.from(Array(team.seats - team.assignedSeats), (e, i) => {
              return (
                <tr key={i} onClick={() => setAddSeatPopup(true)}>
                  <TableColumn colspan={6} className="manger-dashboard-add-seat">
                    <Title name="team-dashboard-add-seat" type="h3" size="xl" className="txt--a-c">
                      +
                    </Title>
                  </TableColumn>
                </tr>
              );
            })} */}
        </tbody>
      </Table>
    </Component>
  );
};

const TeamListRow = (props: {
  user: UsersWithTeamInternalResponseDto;
  setUserToHandle: (user: UsersWithTeamInternalResponseDto) => void;
  setDetailsPopup: (value: boolean) => void;
}) => {
  const userSeat = props.user;

  return (
    <tr
      onClick={(e) => {
        e.stopPropagation();
      }}>
      <TableColumn name="team-dashboard-col">
        <ProfileBubble
          user={{
            _id: userSeat._id,
            firstName: userSeat.firstName,
            lastName: userSeat.lastName,
            role: "",
            picture: userSeat.picture,
          }}
        />
      </TableColumn>
      <TableColumn name="team-dashboard-col">
        <Paragraph type="paragraph" name="team-dashboard-user-seat-active-since">
          {/* {userSeat.teamMemberSince} */}
          {userSeat.teamMemberSince ? new Date(userSeat.teamMemberSince).toDateString() : "-"}
        </Paragraph>
      </TableColumn>
      <TableColumn name="team-dashboard-col">
        <Paragraph type="paragraph" name="team-dashboard-user-last-active">
          {userSeat.lastActive ? new Date(userSeat.lastActive).toDateString() : "-"}
          {/* {new Date().toDateString()} */}
        </Paragraph>
      </TableColumn>
      <TableColumn name="team-dashboard-col">
        <Paragraph type="paragraph" name="team-dashboard-user-amount-of-masterclasses">
          {userSeat.masterclassesFinished}
        </Paragraph>
      </TableColumn>
      <TableColumn name="team-dashboard-col">
        <Paragraph type="paragraph" name="team-dashboard-user-progress">
          {userSeat.progress}
        </Paragraph>
      </TableColumn>
      <TableColumn name="team-dashboard-col" className="team-dashboard-admin-options">
        <Paragraph
          type="paragraph"
          name="team-dashboard-view-more"
          className="txt--c-pri"
          onClick={() => {
            props.setUserToHandle(userSeat);
            props.setDetailsPopup(true);
          }}>
          View more
        </Paragraph>
        {/* <hr />
        <Paragraph
          type="paragraph"
          name="team-dashboard-remove"
          className="txt--c-error"
          onClick={() => {
            props.setUserToHandle(userSeat);
            props.setConfirmationPopup(true);
          }}>
          Remove
        </Paragraph> */}
      </TableColumn>
    </tr>
  );
};

const ViewMorePopup = (props: {
  setPopupOpen: (value: boolean) => void;
  user: UsersWithTeamInternalResponseDto;
}) => {
  const [selectedAcademy, setSelectedAcademy] = useState<AcademyResponseDto>();

  return (
    <>
      <Popup setPopupIsOpen={props.setPopupOpen} size="SMALL" aligned="RIGHT">
        <Component name="team-user-details-popup">
          <Component name="team-user-details-popup-title">
            <Title name="team-user-details-popup-title" type="h2" size="l">
              {selectedAcademy
                ? `Details von ${props.user.firstName} - ${selectedAcademy.title}`
                : ` Masterclasses von ${props.user.firstName}`}
            </Title>
            <Icon name="close-popup" svg={<CloseSVG />} onClick={() => props.setPopupOpen(false)} />
          </Component>
          <Component name="team-user-details-popup-body">
            {selectedAcademy ? (
              <BackButton onClick={() => setSelectedAcademy(undefined)} />
            ) : (
              <>
                <Paragraph name="team-user-details-last-active" size="m">
                  {`Zuletzt aktiv: ${
                    props.user.lastActive ? new Date(props.user.lastActive).toDateString() : "-"
                  }`}
                  {/* {props.user.lastActive} */}
                </Paragraph>
                <Paragraph name="team-user-details-last-active" size="m">
                  {`Sitz aktiv seit: ${
                    props.user.teamMemberSince
                      ? new Date(props.user.teamMemberSince).toDateString()
                      : "-"
                  }`}
                  {/* {props.user.teamMemberSince} */}
                </Paragraph>
              </>
            )}
            {selectedAcademy ? (
              <MasterclassesDetails user={props.user} academy={selectedAcademy} />
            ) : (
              <UserMasterclasses user={props.user} setAcademy={setSelectedAcademy} />
            )}
          </Component>
        </Component>
      </Popup>
    </>
  );
};

const UserMasterclasses = (props: {
  user: UsersWithTeamInternalResponseDto;
  setAcademy: (academy: AcademyResponseDto) => void;
}) => {
  const { apiRequest } = useRequest();
  const [academies, setAcademies] = useState<AcademyResponseDto[]>([]);

  const getMasterclasses = async () => {
    const data = await apiRequest<Paginated<AcademyResponseDto>>(
      `teams/${props.user._id}?limit=50`,
      "GET",
    );
    if (data.data) {
      setAcademies(data.data.docs);
    }
  };

  useEffect(() => {
    getMasterclasses();
  }, []);

  return (
    <>
      {academies.length !== 0 ? (
        <Component name="team-user-details-masterclasses">
          {academies?.map((academy, i) => {
            return (
              <AcademyItem
                key={i}
                academy={academy}
                filters={[""]}
                toDelete={i}
                noPopup={true}
                onClick={props.setAcademy}
              />
            );
          })}
        </Component>
      ) : (
        <Loading />
      )}
    </>
  );
};

const MasterclassesDetails = (props: {
  user: UsersWithTeamInternalResponseDto;
  academy: AcademyResponseDto;
}) => {
  const [userDetails, setUserDetails] = useState<TeamUserInternalAcademyResponseDtoNew>();

  const { apiRequest } = useRequest();

  const getMasterclassDetails = async () => {
    const data = await apiRequest<TeamUserInternalAcademyResponseDtoNew>(
      `teams/${props.user._id}/${props.academy._id}`,
      "GET",
    );
    if (data.data) {
      setUserDetails(data.data);
    }
  };

  useEffect(() => {
    getMasterclassDetails();
  }, []);

  return (
    <>
      {userDetails ? (
        <Component name="team-dashboard-exercises">
          {userDetails?.tracks.map((track, i) => {
            if (track.exerciseTrack === null) return <div key={i} />;
            return (
              <Component key={i} name="team-dashboard-exercise">
                <Title type="h3" size="m">
                  Lektion {track.index - 1} - {track.title}
                </Title>
                <Paragraph size="l" name="team-dashboard-exercise-title">
                  Aufgabe: <br />
                  <Markup content={track.exerciseTrack.description} className="p--m" />
                </Paragraph>
                <Component key={i} name="team-answer">
                  <Paragraph size="m">
                    Antwort von {props.user.firstName}:<br />
                  </Paragraph>
                  <ExerciseContent
                    item={track.exerciseTrack.exerciseSubmission}
                    exerciseType={track.exerciseTrack.exerciseType}
                  />
                </Component>

                <Component name="other-members-all-answers">
                  <Paragraph size="s" name="other-members-answers-title">
                    Andere Antworten aus dem Team:
                    <br />
                  </Paragraph>
                  <Component name="other-members-answers">
                    {track.otherMembers.map((answer, i) => {
                      return (
                        <Component key={i} name="other-members-answer">
                          <Paragraph size="s">{answer.user.firstName}:</Paragraph>
                          <ExerciseContent
                            item={answer.answer}
                            exerciseType={track.exerciseTrack.exerciseType}
                          />
                        </Component>
                      );
                    })}
                  </Component>
                </Component>
                <hr />
              </Component>
            );
          })}
        </Component>
      ) : (
        <Loading />
      )}
    </>
  );
};

const ExerciseContent = ({
  item,
  exerciseType,
}: {
  item?: ExerciseSubmission;
  exerciseType: "FILE_UPLOAD" | "LINK" | "FREE_TEXT" | "DONE";
}) => {
  return (
    <>
      {item && item !== null ? (
        <>
          {exerciseType !== "FREE_TEXT" && (
            <Link
              href={item.link ? item.link : item.filename ?? ""}
              name="admin-exercise-item-content">
              {exerciseType === "FILE_UPLOAD" ? "File download" : item.link}
            </Link>
          )}
          {exerciseType === "FREE_TEXT" && (
            <>
              <Markup className="p--s" content={item.freeText} />
            </>
          )}
        </>
      ) : (
        <Paragraph size="s">Noch nicht beantwortet</Paragraph>
      )}
    </>
  );
};

export { TeamDashboard };
