const LoadingSVG: React.FC<React.HTMLProps<HTMLOrSVGElement>> = () => {
  return (
    <svg viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        opacity="0.3"
        cx="67.5343"
        cy="50.1693"
        r="41.3908"
        fill="#1400FF"
        fillOpacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.4501 33.4199C79.9442 32.8469 80.4608 32.2904 80.9995 31.7518L99.1226 13.6287L90.0733 15.2745C85.4178 16.1212 81.1312 18.3673 77.7853 21.7133L61.2352 38.2633C61.0901 38.4084 60.9472 38.5552 60.8063 38.7037L64.6888 38.7034C70.0952 38.7031 75.3076 36.821 79.4501 33.4199ZM51.3274 38.7042C52.5972 36.6135 54.1211 34.665 55.879 32.9072L72.4291 16.3571C76.8644 11.9218 82.5467 8.94433 88.7179 7.82195L109.697 4.00643C110.968 3.77528 112.241 4.20912 113.105 5.10708C114.026 5.97116 114.474 7.26056 114.24 8.54777L110.425 29.5269C109.302 35.6981 106.325 41.3804 101.889 45.8157L85.3394 62.3658C83.581 64.1242 81.632 65.6485 79.5406 66.9185L79.5402 73.7553C79.5398 81.934 76.2906 89.7776 70.5073 95.5609L52.8647 113.204C51.7776 114.291 50.1412 114.613 48.7232 114.019C47.3052 113.425 46.387 112.033 46.3993 110.495L46.7107 71.5341L7.75137 71.8455C6.21405 71.8578 4.82182 70.9396 4.22782 69.5216C3.63383 68.1036 3.95592 66.4672 5.04301 65.3801L22.6857 47.7375C28.4689 41.9542 36.3125 38.705 44.4913 38.7046L51.3274 38.7042ZM46.7713 63.9586L16.9385 64.197L28.0419 53.0936C32.4047 48.7309 38.3218 46.2797 44.4917 46.2794L48.0221 46.2792C47.2707 48.9212 46.8694 51.6738 46.8471 54.468L46.7713 63.9586ZM54.3468 63.898L54.4217 54.5285C54.4444 51.6783 54.9898 48.8857 56.0041 46.2787L64.6892 46.2782C67.5769 46.2781 70.4228 45.8729 73.151 45.0942C72.372 47.8228 71.9668 50.6692 71.9666 53.5574L71.9661 62.2414C69.3596 63.2552 66.5676 63.8004 63.718 63.8231L54.3468 63.898ZM54.2862 71.4736L54.0478 101.308L65.1512 90.2047C69.514 85.8419 71.9651 79.9248 71.9654 73.7549L71.9656 70.2232C69.3241 70.9743 66.5722 71.3754 63.7786 71.3977L54.2862 71.4736ZM79.5412 57.4401C79.6902 57.2988 79.8376 57.1553 79.9832 57.0096L96.5333 40.4595C99.8792 37.1136 102.125 32.827 102.972 28.1715L104.618 19.1222L86.4948 37.2453C85.9558 37.7843 85.399 38.3012 84.8256 38.7956C81.424 42.9383 79.5417 48.151 79.5414 53.5578L79.5412 57.4401Z"
        fill="#0500FF"
      />
    </svg>
  );
};

export default LoadingSVG;
