import { Header } from "./Header";
import { TabProps, Tabs } from "../Elements/Components";

interface Props {
  tabProps?: TabProps[];
}

const Navigation = (props: Props) => {
  return (
    <>
      {props.tabProps && <Tabs options={props.tabProps} />}
      {!props.tabProps && <Header hasTabs={false} />}
    </>
  );
};

export default Navigation;
