import { TabProps } from "../Elements/Components";
import { AppView } from "../Elements/Elements";
import Navigation from "../Navigation/Navigation";
import Members from "./Members";
import "./networking.scss";

const Networking = () => {
  const tabs: TabProps[] = [
    {
      children: <Members />,
      id: 0,
      isActive: true,
      label: "Alle Mitglieder",
    },
    {
      children: <Members queryParamValue="AMBASSADOR" queryParam="role" />,
      id: 1,
      isActive: false,
      label: "SDRs of Germany Ambassadors",
    },
    {
      children: <Members queryParamValue="INSTRUCTOR" queryParam="role" />,
      id: 2,
      isActive: false,
      label: "Masterclass Academy Instructors",
    },
    {
      children: <Members queryParamValue="true" queryParam="filterForAcademyMembers" />,
      id: 3,
      isActive: false,
      label: "Masterclass Academy Teilnehmer",
    },
  ];

  return (
    <>
      <AppView name="home">
        <Navigation tabProps={tabs} />
      </AppView>
    </>
  );
};

export { Networking };
