import { Page, Main } from "../components/Elements/Elements";
import { Register as RegisterComponent } from "../components/Authentication/Register";

interface Props {
  currentStep: number;
}

const Register = (props: Props) => {
  return (
    <>
      <Page name="register">
        <Main>
          <RegisterComponent currentStep={props.currentStep} />
        </Main>
      </Page>
    </>
  );
};

export default Register;
