import { useContext, useEffect, useState } from "react";
import { Paginated, UsersWithRoleResponseDto } from "../../requests/interfaces";
import { UserCard } from "./UserCard";
import { Component, Section, SectionElement, Title } from "../Elements/Elements";
import useRequest from "../../hooks/useRequest";
import InfiniteScroll from "react-infinite-scroll-component";
import { SkillsFilter } from "../Elements/FormElements";
import { Loading } from "../Loading/Loading";
import Context from "../../context/Context";

interface MemberProps {
  queryParamValue?: "INSTRUCTOR" | "AMBASSADOR" | "true";
  queryParam?: "role" | "filterForAcademyMembers";
}

const Members = (props: MemberProps) => {
  const [members, setMembers] = useState<UsersWithRoleResponseDto[]>();
  const [filters, setFilters] = useState<string[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const { apiRequest } = useRequest();
  const { isMobile } = useContext(Context);

  const getUsers = async (resetPage?: boolean) => {
    const filterString = filters?.join("&");
    const requestUrl = `users?page=${resetPage ? 0 : page}${
      props.queryParamValue && props.queryParam
        ? `&${props.queryParam}=${props.queryParamValue}`
        : ""
    }${filterString ? `&${filterString}` : ""}`;

    const { data } = await apiRequest<Paginated<UsersWithRoleResponseDto>>(requestUrl, "GET");

    setMembers((oldValues) => {
      const combined = [...(oldValues ?? []), ...data.docs];
      const final: UsersWithRoleResponseDto[] = [];
      if (filterString !== "" || resetPage) {
        return data.docs;
      }
      combined.map((value) => {
        const index = final.findIndex((item) => item._id === value._id);
        if (index === -1) {
          final.push(value);
        }
      });
      return final;
    });

    setHasNextPage(data.hasNextPage);
    if (data.hasNextPage) {
      setPage(data.page + 1);
    }
  };

  useEffect(() => {
    getUsers(true);
  }, [filters]);

  useEffect(() => {
    getUsers(true);
  }, [props.queryParamValue]);

  return (
    <Section name="networking">
      {members && (
        <>
          {props.queryParam === "role" && (
            <SectionElement name="skill-filter" type="header">
              <Component name="skill-filter">
                <SkillsFilter
                  onFilterChange={(filters) => setFilters(filters)}
                  showLabel={isMobile}
                  type={isMobile ? "large" : "small"}
                />
              </Component>
            </SectionElement>
          )}
          {members.length === 0 && (
            <Title name="not-found" type="h1" size="l">
              Keine User gefunden
            </Title>
          )}
          <InfiniteScroll
            dataLength={members.length}
            next={getUsers}
            hasMore={hasNextPage}
            loader={<h4>Loading...</h4>}
            scrollableTarget="parent-component">
            <SectionElement name="networking" type="body">
              <Component name="networking">
                {members.map((instructor) => {
                  return (
                    <UserCard
                      key={instructor._id}
                      user={instructor}
                      filters={filters}
                      displaySkills={props.queryParam === "role" ? true : false}
                      isInAcademy={props.queryParam === "filterForAcademyMembers"}
                    />
                  );
                })}
              </Component>
            </SectionElement>
          </InfiniteScroll>
        </>
      )}
      {!members && <Loading />}
    </Section>
  );
};

export default Members;
