import { Dispatch, SetStateAction, forwardRef, useEffect, useRef } from "react";
import { Button, Component } from "../Elements/Elements";
import shaka from "shaka-player/dist/shaka-player.compiled";

type Props = {
  onMouseMove?: () => void;
  onClick?: () => void;
  controls: boolean;
  video: string;
  onTimeUpdate?: (event: HTMLVideoElement) => void;
};

const ShakaPlayer = forwardRef(
  ({ onMouseMove, onClick, controls, video, onTimeUpdate }: Props, ref: any) => {
    const playerRef = useRef<any>(null);

    const fullscreen = () => {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        if (ref.current.requestFullscreen) {
          ref.current.requestFullscreen();
        } else if (ref.current.webkitRequestFullscreen) {
          ref.current.webkitRequestFullscreen();
        } else if (ref.current.msRequestFullscreen) {
          ref.current.msRequestFullscreen();
        }
      }
    };

    useEffect(() => {
      shaka.polyfill.installAll();
      const player = new shaka.Player(ref.current);
      playerRef.current = player;
      const initPlayer = async () => {
        player.addEventListener("error", (e: any) => {
          console.log(e);
        });
        player.configure({
          abr: {
            enabled: false,
          },
        });

        try {
          await player.load(video);
        } catch (e) {
          console.log(e);
          // onError is executed if the asynchronous load fails.
        }
      };
      if (shaka.Player.isBrowserSupported()) {
        initPlayer();
      }
      return () => {
        player.destroy();
      };
    }, []);

    return (
      <Component name="shaka-player">
        <video
          ref={ref}
          onMouseMove={onMouseMove}
          onPlay={() => {
            if (ref.current.currentTime < 0.01) {
              ref.current.currentTime = 0;
              ref.current.play();
            }
          }}
          onClick={() => {
            if (controls) {
              if (
                ref.current.currentTime > 0 &&
                !ref.current.paused &&
                !ref.current.ended &&
                ref.current.readyState > 2
              ) {
                ref.current.play();
              } else {
                ref.current.pause();
              }
              if (onClick) {
                onClick();
              }
            }
          }}
          onDoubleClick={() => {
            if (controls) {
              fullscreen();
            }
          }}
          onTimeUpdate={(e) => {
            if (onTimeUpdate) {
              onTimeUpdate(e.target as HTMLVideoElement);
            }
          }}
          controls={controls}
          autoPlay={!controls}
        />
        {false && (
          <Button type="primary" onClick={() => fullscreen()}>
            Fullscreen
          </Button>
        )}
      </Component>
    );
  },
);
ShakaPlayer.displayName = "ShakaPlayer";

export const TrackShakaPlayer = (props: {
  video: string;
  trackId: string;
  trackType: "content" | "exercise" | "discussion";
  seekTo: number;
  setContinueButton: Dispatch<SetStateAction<boolean>>;
}) => {
  const ref = useRef<any>(null);

  const onTimeUpdate = (video: HTMLVideoElement) => {
    const currentTime = video.currentTime;
    if (currentTime + 12 > ref.current.duration) {
      props.setContinueButton(true);
    }
    localStorage.setItem(
      "currentAcademy",
      JSON.stringify({
        currentTime: currentTime,
        trackId: props.trackId,
        trackType: props.trackType,
      }),
    );
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.defaultPlaybackRate = 1.25;
      // ref.current.currentTime = props.seekTo;
      if (ref.current.currentTime + 12 > ref.current.duration) {
        props.setContinueButton(true);
      }
    }
  }, []);

  return <ShakaPlayer controls={true} video={props.video} onTimeUpdate={onTimeUpdate} ref={ref} />;
};

export default ShakaPlayer;
