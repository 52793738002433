import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Auth0ProviderWithNavigate } from "./auth/Auth0ProviderWithNavigate";
import Routes from "./components/Routes/Routes";
import "./index.scss";
import { ContextProvider } from "./context/ContextProvider";
import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

Bugsnag.start({
  apiKey: "a2ecc1e58081a47535023601ac24d303",
  plugins: [new BugsnagPluginReact()],
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <BrowserRouter>
    <Auth0ProviderWithNavigate>
      <ContextProvider>
        <Routes />
      </ContextProvider>
    </Auth0ProviderWithNavigate>
  </BrowserRouter>,
);
