import { useState } from "react";
import PlusSVG from "../../Icons/PlusSVG";
import { useUser } from "../../hooks/useUser";
import { WelcomeMessageResponseDto } from "../../requests/interfaces";
import { Component, Icon, Paragraph, ProfilePicture } from "../Elements/Elements";
import { useProfile } from "../../hooks/useProfile";
import { DetailedDate, ProfileName } from "../util/Components";
import { MessageText } from "../Messages/Components";

const emojis = ["🎉", "😍", "🔥", "🥳", "😎"];

const WelcomeMessage = (props: WelcomeMessageResponseDto) => {
  const { user } = useUser();
  const [emojiTabIsOpen, setEmojiTabOpen] = useState<boolean>(false);
  const isOwnMessage = user?._id === props.belongsToUserId;
  const { onProfileClick } = useProfile();

  return (
    <>
      <Component name="welcome-message">
        <ProfilePicture img={props.picture} onClick={() => onProfileClick(props.belongsToUserId)} />
        <Component name="welcome-message-info">
          <ProfileName
            userId={props.belongsToUserId}
            firstName={props.firstName}
            lastName={props.lastName}
          />
          <Component name="message-text">
            <MessageText isOwn={isOwnMessage} messageText={props.welcomeMessage} />
            {false && (
              <Component name="reaction-tab">
                <Icon
                  name="reaction-icon"
                  svg={<PlusSVG />}
                  onClick={() => setEmojiTabOpen((value) => !value)}
                />
              </Component>
            )}
            {emojiTabIsOpen && (
              <Component name="emoji-tab">
                {emojis.map((emoji, i) => {
                  return (
                    <Paragraph name={emoji} key={i} onClick={() => setEmojiTabOpen(false)} size="l">
                      {emoji}
                    </Paragraph>
                  );
                })}
              </Component>
            )}
          </Component>
          <Component name="message-add-ons">
            <DetailedDate date={props.createdAt} />
            {false && (
              <Component name="reactions">
                <Paragraph name="reaction" className={``} type="paragraph">{`3 😍`}</Paragraph>
              </Component>
            )}
          </Component>
        </Component>
      </Component>
    </>
  );
};

export default WelcomeMessage;
