import { useEffect, useState } from "react";
import {
  AcademyResponseDto,
  Paginated,
  UsersAcademy,
  UsersResponseDto,
} from "../../requests/interfaces";
import { UserSearchField } from "../Elements/Components";
import { Button, Component, Paragraph, Section, SectionElement, Title } from "../Elements/Elements";
import useRequest from "../../hooks/useRequest";

const AdminMasterclassAssign = () => {
  const [selectedUser, setSelectedUser] = useState<UsersResponseDto>();
  const [academies, setAcademies] = useState<AcademyResponseDto[]>();
  const [selectedUsersAcademy, setSelectedUsersAcademy] = useState<UsersAcademy[]>();
  const [reload, setReload] = useState<boolean>();
  const { apiRequest } = useRequest();

  const getAcademies = async () => {
    const academiesResponse = await apiRequest<Paginated<AcademyResponseDto>>(
      `academy/admin?limit=50`,
      "GET",
    );
    setAcademies(academiesResponse.data.docs);
  };

  const getUserAcademies = async () => {
    const academiesResponse = await apiRequest<UsersAcademy[]>(
      `academy/admin/user/${selectedUser?._id}`,
      "GET",
    );
    setSelectedUsersAcademy(academiesResponse.data);
  };

  useEffect(() => {
    getAcademies();
  }, []);

  useEffect(() => {
    if (selectedUser && !selectedUser.isAcademyMember) {
      getUserAcademies();
    }
  }, [selectedUser, reload]);

  const grantAcademyAccess = async (id?: string) => {
    if (selectedUser && !selectedUser.isAcademyMember) {
      if (id) {
        await apiRequest(`academy/admin/${id}/grantAccess/${selectedUser._id}`, "POST", {
          showToast: true,
          toastText: `Granted academy access to ${selectedUser.firstName}`,
        });
      } else {
        await apiRequest(`academy/admin/grantFullAccess/${selectedUser._id}`, "POST", {
          showToast: true,
          toastText: `Granted academy access to ${selectedUser.firstName}`,
        });
        selectedUser.isAcademyMember = true;
      }
      setReload((value) => !value);
    }
  };

  const revokeAcadmeyAccess = async (id?: string) => {
    if (selectedUser) {
      if (id) {
        await apiRequest(`academy/admin/${id}/revokeAccess/${selectedUser._id}`, "POST", {
          showToast: true,
          toastText: `Revoked the masterclass access from ${selectedUser.firstName}`,
        });
      } else if (selectedUser.isAcademyMember) {
        await apiRequest(`academy/admin/revokeFullAccess/${selectedUser._id}`, "POST", {
          showToast: true,
          toastText: `Revoked academy access from ${selectedUser.firstName}`,
        });
        selectedUser.isAcademyMember = false;
      }
      setReload((value) => !value);
    }
  };

  const userHasAcademyAccess = (academyId: string) => {
    return selectedUsersAcademy?.find((academy) => academy._id === academyId);
  };

  return (
    <Section name="admin-masterclass-assigner">
      <SectionElement type="header" name="admin-masterclass-assigner">
        <Title name="admin-masterclass-assigner-title" type="h1" size="m">
          Masterclasses zuweisen
        </Title>
      </SectionElement>
      <SectionElement type="body" name="admin-masterclass-assigner">
        <Component name="admin-masterclass-assigner">
          <Component name="admin-masterclass-assigner-user-search">
            <UserSearchField setUser={(users) => setSelectedUser(users[0])} selected={[]} />
          </Component>
          {selectedUser && (
            <Component name="admin-masterclass-assigner-user-info">
              <Component name="admin-masterclass-assigner-user-header">
                <Title name="admin-masterclass-assigner-client" type="h1" size="m">
                  Masterclasses von {`${selectedUser.firstName} ${selectedUser.lastName}`}
                </Title>
                {!selectedUser.isAcademyMember ? (
                  <Button
                    type="primary"
                    onClick={() => grantAcademyAccess()}
                    name="admin-revoke-full-academy-access">
                    Assign Academy
                  </Button>
                ) : (
                  <Button
                    type="danger"
                    onClick={() => revokeAcadmeyAccess()}
                    name="admin-grant-full-academy-access">
                    Revoke Academy
                  </Button>
                )}
              </Component>
              <Component name="admin-masterclass-assigner-all-academies">
                {!selectedUser.isAcademyMember &&
                  academies?.map((academy) => {
                    return (
                      <Component key={academy._id} name="admin-masterclass-assigner-academy">
                        <Component name="admin-masterclass-assigner-academy-info">
                          <Paragraph
                            type="paragraph"
                            size="m"
                            name="admin-masterclass-assigner-academy-title">
                            {`${academy.title} by ${academy.instructors[0].firstName} ${academy.instructors[0].lastName}`}
                          </Paragraph>
                          {userHasAcademyAccess(academy._id) ? (
                            <Button
                              name="admin-revoke-academy-access"
                              type="danger"
                              onClick={() => revokeAcadmeyAccess(academy._id)}>
                              Revoke Access
                            </Button>
                          ) : (
                            <Button
                              name="admin-grant-academy-access"
                              type="primary"
                              onClick={() => grantAcademyAccess(academy._id)}>
                              Grant Access
                            </Button>
                          )}
                        </Component>
                        <hr />
                      </Component>
                    );
                  })}
              </Component>
            </Component>
          )}
        </Component>
      </SectionElement>
    </Section>
  );
};

export default AdminMasterclassAssign;
