import { format } from "date-fns";
import { useProfile } from "../../hooks/useProfile";
import { useUser } from "../../hooks/useUser";
import { Paragraph, Title } from "../Elements/Elements";
import "./components.scss";

export const ProfileName = (props: {
  userId: string;
  firstName: string;
  lastName: string;
  size?: "m" | "xs" | "xxs";
  clickable?: boolean;
}) => {
  const { onProfileClick } = useProfile();
  const { user } = useUser();
  const isOwnMessage = user?._id === props.userId;

  return (
    <Title
      type="h2"
      size={props.size ?? "xxs"}
      name="profile-name"
      className={props.clickable === false ? "unclickable" : ""}
      onClick={() => {
        if (props.clickable !== false) {
          onProfileClick(props.userId);
        }
      }}>
      {isOwnMessage ? "Du" : `${props?.firstName} ${props?.lastName}`}
    </Title>
  );
};

export const DetailedDate = (props: {
  date: Date;
  paragraphSize?: "l" | "m" | "s" | "btn" | "nav";
}) => {
  return (
    <Paragraph name="detailed-date" type="paragraph" size={props.paragraphSize ?? "s"}>
      {`${format(props?.date ? new Date(props?.date) : new Date(), "dd. MMMM YYY, HH:mm")} Uhr`}
    </Paragraph>
  );
};

export const NormalDate = (props: {
  date: Date;
  paragraphSize?: "l" | "m" | "s" | "btn" | "nav";
}) => {
  return (
    <Paragraph name="date" type="paragraph" size={props.paragraphSize ?? "s"}>
      {`${format(props?.date ? new Date(props?.date) : new Date(), "yyy-MM-dd hh:mm")} Uhr`}
    </Paragraph>
  );
};

export const SmallDate = (props: {
  date: Date;
  paragraphSize?: "l" | "m" | "s" | "btn" | "nav";
}) => {
  return (
    <Paragraph name="date" type="paragraph" size={props.paragraphSize ?? "s"}>{`${format(
      new Date(props.date ?? ""),
      "dd.MM.y",
    )}`}</Paragraph>
  );
};

export const Time = (props: { date: Date; paragraphSize?: "l" | "m" | "s" | "btn" | "nav" }) => {
  return (
    <Paragraph type="paragraph" name="time" size={props.paragraphSize ?? "s"}>{`${format(
      new Date(props.date),
      "HH:mm",
    )} Uhr`}</Paragraph>
  );
};
