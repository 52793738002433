import { useEffect, useRef, useState } from "react";
import {
  Paragraph,
  Section,
  SectionElement,
  Image,
  Table,
  TableColumn,
  Icon,
  Title,
  Button,
  Component,
  TablePagination,
  ProfileBubble,
  ImageUploader,
  RichTextEditor,
  TableSearch,
} from "../Elements/Elements";
import { Resource, Paginated } from "../../requests/interfaces";
import { format } from "date-fns";
import { useSearchParams } from "react-router-dom";
import EditSVG from "../../Icons/EditSVG";
import TrashSVG from "../../Icons/TrashSVG";
import historyNavigate from "../../hooks/useHistoryNavigate";
import { HashtagSelect, ResourceTypeSelect, TextInputElement } from "../Elements/FormElements";
import { CreateResourceRequestDto } from "../../requests/interfaces";
import useRequest from "../../hooks/useRequest";

const AdminResource = () => {
  const [mode, setMode] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const modeId = searchParams.get("mode");
  const [modes, setModes] = useState<any[]>([]);
  // TODO form change content link after changing the type

  const setupModes = () => {
    const allModes = [
      {
        modeId: "LIST",
        component: (
          <AdminResourceList searchParams={searchParams} setSearchParams={setSearchParams} />
        ),
        active: modeId === null || (modeId !== "create" && modeId !== "edit"),
      },
      {
        modeId: "CREATE",
        component: (
          <AdminResourceItem
            mode="create"
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        ),
        active: modeId === "create",
      },
      {
        modeId: "EDIT",
        component: (
          <AdminResourceItem
            mode="edit"
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        ),
        active: modeId === "edit",
      },
    ];
    setModes(allModes);
  };

  useEffect(() => {
    setMode(modes.find((tab: any) => tab.active === true));
  }, [modes]);

  useEffect(() => {
    setupModes();
  }, [modeId]);

  useEffect(() => {
    setupModes();
  }, []);

  return <>{mode && mode.component}</>;
};

const AdminResourceList = (props: { searchParams: any; setSearchParams: any }) => {
  const [resources, setResources] = useState<Resource[]>([]);
  const [hasNext, setHasNext] = useState<boolean>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { useHistoryNavigate } = historyNavigate();
  const { apiRequest } = useRequest();

  const getResources = async () => {
    const resourcesResponse = await apiRequest<Paginated<Resource>>(
      `resources/admin?limit=10&page=${currentPage}`,
      "GET",
    );
    setResources(resourcesResponse.data.docs);
    setHasNext(resourcesResponse.data.hasNextPage);
  };

  useEffect(() => {
    getResources();
  }, []);

  const edit = (id: string) => {
    props.searchParams.set("mode", "edit");
    props.searchParams.set("id", id);
    props.setSearchParams(props.searchParams);
  };

  const deleteResource = async (id: string) => {
    await apiRequest(`resources/${id}`, "DELETE");
    getResources();
  };

  const create = () => {
    props.searchParams.set("mode", "create");
    props.setSearchParams(props.searchParams);
  };

  useEffect(() => {
    getResources();
  }, [currentPage]);

  return (
    <Section name="admin-resources">
      <SectionElement type="header" name="admin-resource">
        <Title name="admin-resources-title" type="h1" size="m">
          Resourcen
        </Title>
        <Button type="primary" name="create-new-resource" onClick={() => create()}>
          + Neu
        </Button>
      </SectionElement>
      <SectionElement type="body" name="admin-resource">
        <Component name="table-background">
          <TableSearch
            route="resources"
            setItem={setResources}
            resetSearched={() => {
              if (currentPage !== 0) {
                setCurrentPage(0);
              } else {
                getResources();
              }
            }}
          />
          <Table name="admin-resources">
            <thead>
              <tr className="h--xxs">
                <th>
                  <Paragraph name="admin-resource-thumbnail">Vorschaubild</Paragraph>
                </th>
                <th>
                  <Paragraph name="admin-resource-title">Titel</Paragraph>
                </th>
                <th>
                  <Paragraph name="admin-resource-author">Autor</Paragraph>
                </th>
                <th>
                  <Paragraph name="admin-resource-date">Datum</Paragraph>
                </th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {resources?.map((resource) => {
                return (
                  <tr
                    key={resource._id}
                    onClick={() => useHistoryNavigate(`/resource/${resource._id}`)}>
                    <TableColumn name="admin-col">
                      <Image img={resource.picture} name="admin-resource-thumbnail" />
                    </TableColumn>
                    <TableColumn name="admin-col">
                      <Paragraph type="paragraph" name="admin-resource-title">
                        {resource.title}
                      </Paragraph>
                    </TableColumn>
                    <TableColumn name="admin-col">
                      {resource.author && <ProfileBubble user={resource.author} />}
                    </TableColumn>
                    <TableColumn name="admin-col">
                      <Paragraph type="paragraph" name="admin-resource-date">{`${format(
                        new Date(resource.createdAt),
                        "dd.MM.y",
                      )}`}</Paragraph>
                    </TableColumn>
                    <TableColumn name="admin-col">
                      <Icon
                        name="resource-edit"
                        svg={<EditSVG />}
                        className="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          edit(resource._id);
                        }}
                      />
                    </TableColumn>
                    <TableColumn>
                      <Icon
                        name="resource-delete"
                        svg={<TrashSVG />}
                        className="danger"
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteResource(resource._id);
                        }}
                      />
                    </TableColumn>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {hasNext !== undefined && (
            <TablePagination
              currentPage={currentPage}
              hasNext={hasNext}
              setCurrentPage={setCurrentPage}
            />
          )}
        </Component>
      </SectionElement>
    </Section>
  );
};

const AdminResourceItem = (props: {
  mode: "create" | "edit";
  searchParams: any;
  setSearchParams: any;
}) => {
  const id = props.searchParams.get("id");
  const [item, setItem] = useState<Resource>();
  const [title, setTitle] = useState<string>(item?.title ?? "");
  const [thumbnail, setThumbnail] = useState<File | null | string>(item?.picture ?? null);
  const [description, setDescription] = useState<string>(item?.description ?? "");
  const [tags, setTags] = useState<string[]>(item?.hashtags ?? []);
  const [contentType, setContentType] = useState<string>(item?.contentType ?? "");
  const [contentLink, setContentLink] = useState<string>(item?.contentLink ?? "");
  const [isValid, setIsValid] = useState<boolean>(false);
  const [contentLinkError, setContentLinkError] = useState<string>("");
  const { apiRequest } = useRequest();
  const { useHistoryNavigate } = historyNavigate();

  const editorRef: any = useRef();

  useEffect(() => {
    if (id) {
      const getResourceById = async () => {
        const { data } = await apiRequest<Resource>(`resources/${id}`, "GET");
        setItem(data);
      };
      getResourceById();
    }
  }, []);

  useEffect(() => {
    setTitle(item?.title ?? "");
    setThumbnail(item?.picture ?? "");
    setDescription(item?.description ?? "");
    editorRef.current?.setContent(item?.description ?? "");
    setTags(item?.hashtags ?? []);
    setContentType(item?.contentType ?? "");
    setContentLink(item?.contentLink ?? "");
  }, [item]);

  const createResource = async () => {
    const request: CreateResourceRequestDto = {
      title: title,
      contentLink: contentLink,
      contentType: contentType,
      description: description,
      hashtags: tags,
      picture: typeof thumbnail === "string" ? thumbnail : "",
    };
    if (id) {
      const { status } = await apiRequest(`resources/${id}`, "PATCH", {
        body: request,
        showToast: true,
      });
      if (status === 200) {
        useHistoryNavigate("/admin-dashboard?tab=0");
      }
    } else {
      const { status } = await apiRequest("resources", "POST", { body: request, showToast: true });
      if (status === 201) {
        useHistoryNavigate("/admin-dashboard?tab=0");
      }
    }
  };

  useEffect(() => {
    if (
      title === item?.title &&
      description === item.description &&
      contentLink === item.contentLink &&
      contentType === item.contentType &&
      tags === item.hashtags &&
      thumbnail === item.picture
    ) {
      return;
    }
    if (title === "") {
      return;
    }
    if (description === "") {
      return;
    }
    if (tags.length === 0) {
      return;
    }
    if (!isValidLink()) {
      return;
    }
    if (contentType === "") {
      return;
    }
    setIsValid(true);
  }, [description, tags, contentLink, contentType, title]);

  useEffect(() => {
    //reload
  }, [editorRef.current]);

  const onContentLinkBlur = () => {
    if (contentLink !== "") {
      if (!isValidLink()) {
        setContentLinkError(`Kein gültiger ${contentType} Link`);
        setIsValid(false);
      } else {
        setContentLinkError("");
      }
    }
  };

  const isValidLink = () => {
    if (contentType === "pdf") {
      if (!contentLink.includes("https://publuu.com/flip-book/") && contentLink !== "") {
        return false;
      }
    } else if (contentType === "youtube") {
      if (
        !contentLink.includes("https://www.youtube.com/watch?v=") &&
        !contentLink.includes("https://www.youtube.com/playlist?list=") &&
        !contentLink.includes("https://youtube.com/playlist?list=") &&
        contentLink !== ""
      ) {
        return false;
      }
    } else if (contentType === "spotify") {
      if (
        !contentLink.includes("https://open.spotify.com/intl-de/track") &&
        !contentLink.includes("https://open.spotify.com/episode") &&
        !contentLink.includes("https://open.spotify.com/playlist") &&
        contentLink !== ""
      ) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    onContentLinkBlur();
  }, [contentType]);

  const changeImage = (e: any) => {
    setThumbnail(e.target.files[0]);
  };

  const onImageUploaded = (url: string) => {
    setThumbnail(url);
  };

  return (
    <>
      <Section name="admin-resource-item-builder">
        <SectionElement name="admin-resource-item-builder" type="body">
          <Component>
            <Component name="form-inputs">
              <Component name="form-row">
                <Component name="form-input">
                  <ImageUploader
                    img={thumbnail}
                    type="large"
                    onImageUploaded={onImageUploaded}
                    onChange={changeImage}
                    route="resources/resourcePicture"
                    method="POST"
                  />
                </Component>
              </Component>
              <Component name="form-row">
                <Component name="form-input">
                  <TextInputElement
                    id="title"
                    label="Titel"
                    type="text"
                    labelClasses="p--l"
                    onInput={(e) => setTitle((e.target as HTMLInputElement).value)}
                    value={title}
                  />
                </Component>
              </Component>
              <Component name="form-row">
                <Component name="form-input">
                  <RichTextEditor ref={editorRef} setText={setDescription} text={description} />
                </Component>
              </Component>
              <Component name="form-row">
                <Component name="form-input">
                  <HashtagSelect
                    onInput={(newValue) => {
                      setTags(newValue.map((value) => value.value));
                    }}
                  />
                </Component>
              </Component>
              <Component name="form-row">
                <Component name="form-input">
                  <ResourceTypeSelect
                    onInput={(newValue) => {
                      setContentType(newValue?.value ?? "");
                    }}
                    defaultValue={contentType}
                  />
                </Component>
              </Component>
              <Component name="form-row">
                <Component name="form-input">
                  <TextInputElement
                    id="contentLink"
                    label="Content Link"
                    type="text"
                    labelClasses="p--l"
                    onInput={(e) => setContentLink((e.target as HTMLInputElement).value)}
                    value={contentLink}
                    errorMessage={contentLinkError}
                    displayErrorMessage={contentLinkError !== ""}
                    onBlur={onContentLinkBlur}
                  />
                </Component>
              </Component>
              <Component name="form-row">
                <Component name="form-input">
                  <Button type="primary" onClick={createResource} disabled={!isValid}>
                    {id ? "Speichern" : "Erstellen"}
                  </Button>
                </Component>
              </Component>
            </Component>
          </Component>
        </SectionElement>
      </Section>
    </>
  );
};

export default AdminResource;
