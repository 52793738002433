import {
  AppView,
  Component,
  Main,
  Page,
  Section,
  SectionElement,
  Title,
} from "../components/Elements/Elements";
import Navigation from "../components/Navigation/Navigation";
import Sidebar from "../components/Navigation/Sidebar";

const PageNotFound = () => {
  return (
    <Page name="404">
      <Sidebar />
      <Main>
        <AppView name="home">
          <Navigation />
          <Section name="page-not-found">
            <SectionElement name="page-not-found" type="body">
              <Component name="404">
                <Title type="h1" size="xl" name="page-not-found">
                  PAGE NOT FOUND
                </Title>
              </Component>
            </SectionElement>
          </Section>
        </AppView>
      </Main>
    </Page>
  );
};

export default PageNotFound;
