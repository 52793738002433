import { Main, Page } from "../components/Elements/Elements";
import Sidebar from "../components/Navigation/Sidebar";
import { LearningParent as LearningComponent } from "../components/Learning/Learning";
import { useParams } from "react-router-dom";

const Learning = () => {
  const { id } = useParams();
  return (
    <Page name="learning">
      <Sidebar />
      <Main>
        <LearningComponent id={id} />
      </Main>
    </Page>
  );
};

export default Learning;
