import { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Main, Page } from "../components/Elements/Elements";
import { JobDashboard as JobDashboardComponent } from "../components/JobDashboard/JobDashboard";
import {
  JobDetailsParent as JobDetails,
  JobDetailsMode,
} from "../components/JobDashboard/JobDetails";
import Sidebar from "../components/Navigation/Sidebar";
export const JobDashboardContext = createContext<{
  isPublic: boolean;
}>({
  isPublic: true,
});

const JobDashboard = (props: { isPublic: boolean }) => {
  const { id } = useParams();
  const [mode, setMode] = useState<JobDetailsMode>();

  useEffect(() => {
    if (window.location.href.includes("create") && !props.isPublic) {
      setMode(JobDetailsMode.CREATE);
    } else if (window.location.href.includes("edit") && !props.isPublic) {
      setMode(JobDetailsMode.EDIT);
    } else {
      setMode(JobDetailsMode.READ);
    }
  }, [window.location.href]);

  return (
    <Page name="jobs">
      {!props.isPublic && <Sidebar />}
      <Main>
        <JobDashboardContext.Provider value={{ isPublic: props.isPublic }}>
          {mode !== undefined && (mode === JobDetailsMode.CREATE || id) ? (
            <JobDetails mode={mode} id={id} />
          ) : (
            <JobDashboardComponent />
          )}
        </JobDashboardContext.Provider>
      </Main>
    </Page>
  );
};

export default JobDashboard;
