import { useEffect, useState } from "react";
import { DiscussionResponseDto, Paginated } from "../../requests/interfaces";
import {
  Section,
  SectionElement,
  Title,
  Table,
  Paragraph,
  TableColumn,
  TablePagination,
  Component,
  ProfileBubble,
  Icon,
  TableSearch,
} from "../Elements/Elements";
import useRequest from "../../hooks/useRequest";
import CloseSVG from "../../Icons/CloseSVG";
import TrashSVG from "../../Icons/TrashSVG";
import TickSVG from "../../Icons/TickSVG";

const AdminDiscussions = () => {
  const [discussions, setDiscussions] = useState<DiscussionResponseDto[]>([]);
  const [hasNext, setHasNext] = useState<boolean>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { apiRequest } = useRequest();

  const getDiscussions = async () => {
    const discussions = await apiRequest<Paginated<DiscussionResponseDto>>(
      `discussions?page=${currentPage}&limit=10`,
      "GET",
    );
    const submittedDiscussions = await apiRequest<Paginated<DiscussionResponseDto[]>>(
      `discussions/submitted?page=${currentPage}&limit=10`,
      "GET",
    );
    const allDiscussions: any[] = submittedDiscussions.data.docs.concat(discussions.data.docs);
    setDiscussions(allDiscussions);
    setHasNext(discussions.data.hasNextPage);
  };

  useEffect(() => {
    getDiscussions();
  }, []);

  useEffect(() => {
    getDiscussions();
  }, [currentPage]);

  const approve = async (id: string) => {
    await apiRequest(`discussions/submitted/${id}/approve`, "PATCH");
    getDiscussions();
  };

  const reject = async (id: string) => {
    await apiRequest(`discussions/submitted/${id}/reject`, "PATCH");
    getDiscussions();
  };

  const deleteDiscussion = async (id: string) => {
    await apiRequest(`discussions/${id}/comments`, "DELETE");
    getDiscussions();
  };

  return (
    <>
      <Section name="admin-discussions">
        <SectionElement type="header" name="admin-discussions">
          <Title name="admin-discussions-title" type="h1" size="m">
            Diskussionen
          </Title>
        </SectionElement>
        <SectionElement type="body" name="admin-discussions">
          <Component name="table-background">
            <TableSearch
              route="discussions"
              setItem={setDiscussions}
              resetSearched={() => {
                if (currentPage !== 0) {
                  setCurrentPage(0);
                } else {
                  getDiscussions();
                }
              }}
            />
            <Table name="admin-discussions">
              <thead>
                <tr className="h--xxs">
                  <th>
                    <Paragraph name="admin-discussions-discussion" type="paragraph">
                      Benutzer
                    </Paragraph>
                  </th>
                  <th>
                    <Paragraph name="admin-discussions-login-state" type="paragraph">
                      Content
                    </Paragraph>
                  </th>
                  <th>
                    <Paragraph name="admin-discussions-profile-role" type="paragraph">
                      Status
                    </Paragraph>
                  </th>
                  <th>
                    <Paragraph name="admin-discussions-profile-state" type="paragraph">
                      Kommentare
                    </Paragraph>
                  </th>
                  <th>
                    <Paragraph name="admin-discussions-profile-upvote" type="paragraph">
                      Upvotes
                    </Paragraph>
                  </th>
                </tr>
              </thead>
              <tbody>
                {discussions?.map((discussion) => {
                  return (
                    <tr key={discussion._id}>
                      <TableColumn name="admin-col">
                        {discussion.user && <ProfileBubble user={discussion.user} />}
                      </TableColumn>
                      <TableColumn name="admin-col" className="main-content">
                        <Component name="admin-discussions-login-state">
                          <Paragraph type="paragraph" name="admin-discussions-login-state">
                            {discussion.content}
                          </Paragraph>
                        </Component>
                      </TableColumn>
                      <TableColumn>
                        <Component name="admin-discussions-profile-state">
                          <Paragraph type="paragraph" name="admin-discussions-profile-state">
                            {discussion.status}
                          </Paragraph>
                        </Component>
                      </TableColumn>
                      <TableColumn>
                        <Component name="admin-discussions-profile-state">
                          <Paragraph type="paragraph" name="admin-discussions-profile-state">
                            {discussion.comments?.length ?? "0"}
                          </Paragraph>
                        </Component>
                      </TableColumn>
                      <TableColumn>
                        <Component name="admin-discussions-profile-state">
                          <Paragraph type="paragraph" name="admin-discussions-profile-state">
                            {discussion.rating}
                          </Paragraph>
                        </Component>
                      </TableColumn>
                      <TableColumn>
                        {discussion.status !== "PENDING" && (
                          <Component name="admin-discussions-profile-state">
                            <Icon
                              name="discussions-delete"
                              svg={<TrashSVG />}
                              className="danger"
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteDiscussion(discussion._id);
                              }}
                            />
                          </Component>
                        )}
                        {discussion.status === "PENDING" && (
                          <>
                            <Component name="admin-discussions-profile-state">
                              <Icon
                                name="resource-edit"
                                svg={<TickSVG />}
                                className="primary"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  approve(discussion._id);
                                }}
                              />
                            </Component>
                            <Component name="admin-discussions-profile-state">
                              <Icon
                                name="resource-edit"
                                svg={<CloseSVG />}
                                className="primary"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  reject(discussion._id);
                                }}
                              />
                            </Component>
                          </>
                        )}
                      </TableColumn>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {hasNext !== undefined && (
              <TablePagination
                currentPage={currentPage}
                hasNext={hasNext}
                setCurrentPage={setCurrentPage}
              />
            )}
          </Component>
        </SectionElement>
      </Section>
    </>
  );
};

export default AdminDiscussions;
