import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Section, SectionElement, Component, Title, Button } from "../Elements/Elements";
import { NotificationRuleSet, NotificationRuleSets } from "../../requests/interfaces";
import useRequest from "../../hooks/useRequest";
import { Loading } from "../Loading/Loading";
import { TagSelect } from "../Elements/FormElements";
import useFormOptions from "../../hooks/useFormOptions";

enum NotificationTypes {
  message = "Nachrichten",
  discussion = "Diskussionen",
  massNotification = "Allgemeine Nachrichten",
  general = "Generelle Nachrichten",
  boughtMasterclass = "Masterclass gekauft",
  boughtAcademy = "Academy gekauft",
  academy = "Academy",
  others = "andere",
  exercise = "Aufgaben",
}

const Settings = () => {
  const [possibleNotifications, setPossibleNotifications] = useState<NotificationRuleSet[]>();
  const [changedSettings, setChangedSettings] = useState<
    { notificationGroup: string; key: string; value: boolean }[]
  >([]);
  const { apiRequest } = useRequest();

  const getNotificationGroups = async () => {
    const notificationGroupsResult = await apiRequest<NotificationRuleSets>(
      "user/notificationRuleSet",
      "GET",
    );
    setPossibleNotifications(notificationGroupsResult.data.rules);
  };

  useEffect(() => {
    getNotificationGroups();
  }, []);

  const save = async () => {
    if (changedSettings.length !== 0) {
      for (let index = 0; index < changedSettings.length; index++) {
        const element = changedSettings[index];
        await apiRequest(
          `user/notificationRuleSet?notificationGroup=${element.notificationGroup}&${element.key}=${element.value}`,
          "PUT",
          {
            showToast: true,
            toastText: "Erfolgreich die Benachrichtigungseinstellungen geändert",
          },
        );
      }
    }
  };

  return (
    <Section name="settings">
      <SectionElement type="body" name="settings">
        <Component name="settings">
          <Component name="notification-settings">
            <Title name="notification-settings-title" type="h2" size="m">
              Notification Settings
            </Title>
            <Component name="notification-settings-items">
              {possibleNotifications ? (
                <>
                  {possibleNotifications?.map((possibleNotification) => {
                    return (
                      <PossibleNotificationMenuSet
                        key={possibleNotification.notificationGroup}
                        notification={possibleNotification}
                        setChangedSettings={setChangedSettings}
                      />
                    );
                  })}
                </>
              ) : (
                <Loading />
              )}
            </Component>
            {changedSettings.length !== 0 && (
              <Button type="primary" onClick={save}>
                Speichern
              </Button>
            )}
          </Component>
        </Component>
      </SectionElement>
    </Section>
  );
};

const PossibleNotificationMenuSet = (props: {
  notification: NotificationRuleSet;
  setChangedSettings: Dispatch<
    SetStateAction<{ notificationGroup: string; key: string; value: boolean }[]>
  >;
}) => {
  const [selectedValue, setSelectedValue] = useState<string[]>([]);

  const [formOptions, setFormOptions] = useState<any>();

  const getFormOptions = async () => {
    setFormOptions(await useFormOptions().getFormOptions());
  };

  const toggleItem = (value: any, setArrayState: (value: any) => void, prevArray: any[]) => {
    const index = prevArray.indexOf(value);
    const newArr = [...prevArray];
    if (index > -1) {
      newArr.splice(index, 1);
    } else {
      newArr.push(value);
    }
    setArrayState(newArr);
  };

  useEffect(() => {
    getFormOptions();
  }, []);

  useEffect(() => {
    if (!formOptions || !formOptions.notificationSettingOptions) {
      return;
    }
    const newArray = [];
    for (let index = 0; index < formOptions.notificationSettingOptions.length; index++) {
      const element = formOptions.notificationSettingOptions[index];

      newArray.push({
        notificationGroup: props.notification.notificationGroup,
        key: element.id,
        value: selectedValue.includes(element.id),
      });
    }
    props.setChangedSettings(newArray);
  }, [selectedValue]);

  useEffect(() => {
    setSelectedValue(() => {
      const alreadySelectedValues: string[] = [];
      if (props.notification.mailNotification) {
        alreadySelectedValues.push("mailNotification");
      }
      if (props.notification.inAppNotification) {
        alreadySelectedValues.push("inAppNotification");
      }
      return alreadySelectedValues;
    });
  }, [props.notification]);

  return (
    <Component name="notification-settings-item">
      {formOptions && formOptions.notificationSettingOptions && (
        <TagSelect
          id={`notification-settings-item-${props.notification.notificationGroup}`}
          label={
            NotificationTypes[
              props.notification.notificationGroup as keyof typeof NotificationTypes
            ]
          }
          labelClasses="p--l"
          optionLabelClasses="p--m"
          name="cs-notification-settings"
          isHidden={true}
          options={formOptions.notificationSettingOptions}
          type="checkbox"
          onChange={(e) => {
            toggleItem((e.target as HTMLInputElement).id, setSelectedValue, selectedValue);
          }}
          value={selectedValue.join(",")}
        />
      )}
    </Component>
  );
};

export default Settings;
