import { useAuth0 } from '@auth0/auth0-react';
import { io } from 'socket.io-client';

export const useWebsocket = () => {
  const URL = `${process.env.REACT_APP_API}/messages`;
  const { user } = useAuth0();
  const auth = { "sdrs-auth": user?.sub ?? "" }
  const socket = io(URL, {
    autoConnect: false,
    extraHeaders: {
      ...auth
    },
    transports: ['polling', 'websocket']
  });

  return { socket }
}