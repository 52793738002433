export enum Role {
  ADMIN = "admin",
  AMBASSADOR = "ambassador",
  INSTRUCTOR = "instructor",
  MEMBER = "member",
  BANNED = "banned",
}

export enum MessageType {
  IMAGE,
  FILE,
  TEXT,
}

export enum Skill {
  COLD_CALLING = "Cold Calling",
  HOT_CALLING = "Hot Calling",
  DMC = "DMC",
  EMAIL = "E-mail",
}

export interface SkillResponseDto {
  _id: string;
  skill: string;
}

export interface SkillRequest {
  skill: string;
}

export enum Hashtag {
  socialSelling = "Social Selling",
  personalBranding = "Personal Branding",
  interviewing = "Interviewing",
  careerDevelopment = "Career Development",
  coldEmail = "Cold Mailing",
  coldCalls = "Cold Calling",
  discovery = "Discovery",
  demo = "Demo",
  strategy = "Strategie",
  training = "Training",
}

export enum TargetLocation {
  BERLIN = "Berlin",
  MUNICH = "München",
  REMOTE1 = "Remote",
  REMOTE2 = "Remote",
  REMOTE3 = "Remote",
  REMOTE4 = "Remote",
  REMOTE5 = "Remote",
  REMOTE6 = "Remote",
  REMOTE7 = "Remote",
  REMOTE8 = "Remote",
  REMOTE9 = "Remote",
  REMOTE10 = "Remote",
  REMOTE11 = "Remote",
  REMOTE12 = "Remote",
  REMOTE13 = "Remote",
  REMOTE14 = "Remote",
  REMOTE15 = "Remote",
  REMOTE16 = "Remote",
  REMOTE17 = "Remote",
  REMOTE18 = "Remote",
  REMOTE19 = "Remote",
  REMOTE20 = "Remote",
}

export interface PersonalTemplate {
  firstName: string;
  lastName: string;
  birthDate: Date;
  linkedInUrl: string;
  profilePicture: File | string;
}

export interface Career {
  industry: string[];
  experience: string;
  interests: string[];
  leads: string[];
  location: {
    mapBoxId: string;
    name: string;
    placeFormatted: string;
  };
  germanLevel: string;
  company: string;
  position: string;
}

export interface User {
  _id: string;
  firstName: string;
  email: string;
  lastName: string;
  birthDate: Date;
  picture: string;
  role: string;
  career: Career;
  detailedUserInfo: DetailedUserInfo;
  chats: Chat[];
  hyre?: HyreQuestions;
  welcomeMessage?: string;
  level: Level;
  linkedInUrl?: string;
  isAcademyMember?: boolean;
  teamMemberSince?: Date;
}

export interface Level {
  level: number;
  points: number;
  pointsToNextLevel: number;
  nextLevel: number;
  title: string;
}

export interface UsersWithRoleResponseDto {
  _id: string;
  firstName: string;
  lastName: string;
  picture?: string;
  role: string;
  isReported?: boolean;
  hyre?: HyreQuestions;
  career?: Career;
  skills: SkillResponseDto[];
  welcomeMessage: string;
  createdAt: Date;
  isAcademyMember?: boolean;
  linkedInUrl?: string;
}

export interface WelocomeMessage {
  id: string;
  user: User;
  time: Date;
  reactions: Reaction[];
  content: string;
}

export interface HyreQuestions {
  minSalary: number;
  minFixedSalary: number;
  targetLocations: TargetLocation[];
  leadWarmth: string;
}

export interface Message {
  content: string;
  type: MessageType;
  date: Date;
  isRead: boolean;
  sender: User;
}

export interface Chat {
  id: string;
  participant: User;
  messages: Message[];
}

interface DetailedUserInfo {
  skills: Skill[];
  personDescription: string;
  reviews: Review[];
  discussions?: Discussion[];
  isReported: boolean;
}

export interface PostResponseDto {
  _id: string;
  content: string;
  rating: number;
  createdAt: Date;
  comments: PostResponseDto[];
  pictures?: string[];
  user: UsersResponseDto;
  hasUpvoted: boolean;
  hasDownvoted: boolean;
}

export interface MessagesResponseDto {
  _id: string;
  message: string;
  status: string;
  createdAt: Date;
  isSender: boolean;
  pictures: string[];
}

export interface ChatResponseDto extends MessagesResponseDto {
  user: UsersResponseDto;
  countUnreadMessages: number;
}

export interface DiscussionResponseDto extends PostResponseDto {
  status: string;
  hashtags: string[];
  commentCount: number;
}

export interface CreateEventRequestDto {
  title: string;
  description: string;
  hashtags: string[];
  speakers: UsersResponseDto[] | string[];
  sponsors: Company[] | string[];
  date: Date;
  endDate: Date;
  isOnlineMeeting: boolean;
  meetingLocationUrl: string;
  flowId?: string;
  eventId?: string;
  picture: string;
}

export interface EventResponseDto {
  _id: string;
  title: string;
  description: string;
  hashtags: string[];
  speakers: UsersResponseDto[];
  date: Date;
  endDate: Date;
  isOnlineMeeting: boolean;
  meetingLocationUrl: string;
  eventId?: string;
  flowId?: string;
  picture?: string;
  participantsCount: number;
  isParticipant: boolean;
  sponsors?: Company[];
}

export interface MediaResponseDto {
  url: string;
  id?: string;
}

export interface Discussion extends Post {
  hashtags: Hashtag[];
  status: "ONLINE" | "SUBMITTED" | "DECLINED" | "DELETED";
}

export interface HashtagsResponseDto {
  hashtags: string[];
}

export interface StorageFileDto {
  filename: string;
}

export interface TopicsResponseDto {
  topics: string[];
}

export interface ResourceTypesDto {
  resourceTypes: string[];
}

export interface UserRolesDto {
  userRoles: string[];
}

export interface CreateResourceRequestDto {
  title: string;
  description: string;
  hashtags: string[];
  contentLink: string;
  contentType: string;
  picture: string;
}

export interface Post {
  id: string;
  user: User;
  text: string;
  upvoteCount: number;
  date: Date;
  comments: Post[];
  parent?: Post;
  images?: string[];
}

interface Review {
  starCount: number;
  description: string;
  date: Date;
  user: User;
}

export interface Event {
  id: string;
  title: string;
  description: string;
  thumbnail: string;
  images: string[];
  speakers: string[];
  location: string; // either zoom or normal location. If normal location google maps link
  sponsors: Company[];
  date: Date;
  tags: string[];
  participants: User[];
  link?: string; // only zoom
}

export interface Company {
  _id: string;
  picture: string;
  name: string;
  homePage: string;
}

export interface Reaction {
  user: User;
  emoji: string;
}

export interface ResourceReviewResponse {
  _id: string;
  hashtags: string[];
  title: string;
  author: UsersResponseDto;
  contentType: string;
  picture: string;
}

export interface Resource {
  _id: string;
  title: string;
  description: string;
  hashtags: string[];
  author: UsersResponseDto;
  createdAt: Date;
  contentLink: string;
  contentType: string;
  picture: string;
}

export interface SupportQuestion {
  _id: string;
  title: string;
  description: string;
  status: string;
  createdAt: Date;
  user: UsersResponseDto;
  source: string;
}

export interface QandA {
  topic: string;
  question: string;
  answer: string;
  _id: string;
}

export interface Topic {
  topic: "HYRE" | "SDRs" | "Demo" | string;
}

export interface Paginated<T> {
  limit: number;
  hasNextPage: boolean;
  offset: number;
  docs: T[];
  page: number;
}

export interface UsersResponseDto {
  _id: string;
  firstName: string;
  lastName: string;
  createdAt?: Date;
  picture?: string;
  role: string;
  skills?: SkillResponseDto[];
  isAcademyMember?: boolean;
  linkedInUrl?: string;
}

export interface WelcomeMessageResponseDto {
  _id: string;
  belongsToUserId: string;
  welcomeMessage: string;
  createdAt: Date;
  firstName: string;
  lastName: string;
  picture?: string;
}

export interface NotificationsDto {
  _id: string;
  text: string;
  type: string;
  notificationObject: "discussion" | "message" | "track" | "academy"
  notificationObjectId: string;
  notificationDetails?: string;
}

export interface UsersWithDetailsResponseDto {
  _id: string;
  firstName: string;
  lastName: string;
  picture: string;
  role: string;
  skills: SkillResponseDto[];
}

export interface ApiRequestTemplate {
  body?: any;
  accessTokenHard?: string;
  showToast?: boolean;
  toastText?: string;
  showToastError?: string;
}

export interface HyreResponseDto {
  positionName: string;
  companyName: string;
  location: string;
  salaryMin: number;
  salaryMax: number;
  industries: string[];
  logoUrl: string;
  jobAdUrl: string;
}

export interface UsersAcademy {
  _id: string;
  title: string;
}

export enum CredlyBadgeStatus {
  OPEN = "OPEN",
  DONE = "DONE",
  NOT_AVAILABLE = "NOT AVAILABLE",
  PENDING = "PENDING",
}

export interface AcademyResponseDto {
  _id: string;
  title: string;
  description: string;
  createdAt: Date;
  isListed: boolean;
  isFeatured: boolean;
  index: number;
  instructors: UsersResponseDto[];
  skills: SkillResponseDto[];
  tracks: TrackResponseDto[];
  thumbnailUrl: string;
  marketingVideoUrl: string;
  videoProcessingStatus: string;
  releaseDate: Date;
  tracksCount: number;
  paymentUrl: string;
  productId: string;
  thumbnailStatus: string;
  badgeId: string;
  isBought: boolean;
  academyProgress: number;
  credlyBadgeStatus: CredlyBadgeStatus;
}

export interface CreateTrackRequestDto {
  title: string;
  index: number;
  learningObjective: string;
  discussionTrack: DiscussionTrackRequestDto;
  exerciseTrack: ExerciseTrackRequest;
  contentTrack: ContentTrackRequest;
}

export interface CreateAcademyRequestDto {
  title: string;
  productId: string;
  paymentUrl: string;
  badgeId: string;
  description: string;
  isListed: boolean;
  releaseDate: Date;
  instructors: string[];
  skills: string[];
  isFeatured: boolean;
}

export interface SmallTrackResponseDto {
  _id: string;
  title: string;
  index: number;
  learningObjective: string;
  createdAt: Date;
  isAvailable: boolean;
}

export interface TrackResponseDto {
  _id: string;
  index: number;
  title: string;
  learningObjective: string;
  createdAt: Date;
  thumbnailStatus: string;
  contentTrack: ContentTrackResponse;
  exerciseTrack: ExerciseTrackResponse;
  discussionTrack: DiscussionTrackResponse;
  thumbnailUrl: string;
  trackStatus: "FINISHED" | "STARTED" | "UNTOUCHED";
}

export interface TrackBaseRequestDto {
  title: string;
  description: string;
}

export interface TrackBaseResponseDto {
  title: string;
  description: string;
  videoUrl: string;
  filename: string;
  fileUrl: string;
  videoProcessingStatus: string;
  fileStatus: string;
  videoProgress: number;
}

export interface ContentTrackRequest extends TrackBaseRequestDto {
  contentFile?: string;
}

export type ContentTrackResponse = TrackBaseResponseDto;

export interface ExerciseTrackRequest extends TrackBaseRequestDto {
  subject: string;
  exerciseType: "FILE_UPLOAD" | "LINK" | "FREE_TEXT" | "DONE";
  contentFile?: string;
}

export interface ExerciseTrackResponse extends TrackBaseResponseDto {
  subject: string;
  exerciseType: "FILE_UPLOAD" | "LINK" | "FREE_TEXT" | "DONE";
  exerciseSubmission?: ExerciseSubmission;
}

export interface ExerciseSubmission {
  freeText?: string;
  link?: string;
  filename?: string;
  status: "PENDING" | "APPROVED" | "DECLINED";
  adminFeedback?: string;
}

export interface DiscussionTrackRequestDto extends TrackBaseRequestDto {
  question: string;
}

export interface DiscussionTrackResponse extends TrackBaseResponseDto {
  question: string;
  // discussions: DiscussionResponseDto[];
}

export interface AcademyDiscussionResponseDto {
  _id: string;
  user: UsersResponseDto;
  content: string;
  pictures: string[];
  upvotes: number;
  downvotes: number;
  createdAt: Date;
  comments: PostResponseDto[];
  rating: number;
  commentCount: number;
  hasUpvoted: boolean;
  hasDownvoted: boolean;
}

// All Media Librarys
export interface AcademyMediaLibraryResponse {
  completedAcademies: number;
  completedLessons: number;
  approvedTasks: number;
  lastVideo: VideoPreviewResponse;
  started: AcademyPreviewResponse[];
  saved: AcademyPreviewResponse[];
  finished: AcademyPreviewResponse[];
}

export interface VideoPreviewResponse {
  trackId: string;
  videoType: "CONTENT" | "TASK" | "DISCUSSION";
  trackThumbnail: string;
  academyTitle: string;
  academyId: string;
  trackTitle: string;
}

export interface AcademyPreviewResponse {
  academy: AcademyResponseSmallDto;
  trackAmount: number;
  doneTracksAmount: number;
}

export interface AcademyResponseSmallDto {
  _id: string;
  thumbnail: string;
  title: string;
  instructors: UsersResponseDto[];
}

export interface CredlyBadgeResponse {
  id: string;
  description: string;
  level: string;
  name: string;
  url: string;
  image_url: string;
  skills: string[];
}

export interface ExerciseResponseDto {
  _id: string;
  academyName: string;
  trackName: string;
  academyId: string;
  trackId: string;
  subject: string;
  description: string;
  exerciseType: "FILE_UPLOAD" | "LINK" | "FREE_TEXT";
  status: "PENDING" | "APPROVED" | "DECLINED";
  content: string;
  user: UsersResponseDto;
}

export interface StripeResponseDto {
  id: string;
  name: string;
  description: string;
  url: string;
}

export interface NotificationGroups {
  resourceTypes: string[]
}

export interface NotificationRuleSets {
  rules: NotificationRuleSet[]
}

export interface NotificationRuleSet {
  notificationGroup: string;
  mailNotification: boolean;
  inAppNotification: boolean;
  thirdPartyNotification: boolean;
}

export interface CredlyBadgeSubmissionDto {
  academyProgressId: string;
  user: UsersResponseDto;
  academyTitle: string;
  credlyBadgeStatus: "OPEN" | "DONE" | "NOT AVAILABLE" | "PENDING";
  academyId: string;
}

export interface JobPostSimple {
  companyImage: string;
  company: string;
  position: string;
  _id: string;
  salaryStart: number;
  salaryEnd: number;
  isFeatured: boolean;
  location: string;
  industry: string;
  isOwn: boolean;
}

export interface JobPost extends JobPostCreate {
  _id: string;
  isOwn: boolean;
}

export interface JobPostCreate {
  companyImage: string;
  teamImage: string;
  company: string;
  position: string;
  experience: string;
  salaryStart: number;
  salaryEnd: number;
  isFeatured: boolean;
  locationType: string;
  location: string;
  industry: string;
  icp: string[];
  techStack: string[];
  companySize: string;
  learningAndDevelopment: string[];
  teamAthmosphere: string;
  progressionPlan: string;
  roleSpec: string;
  aboutYou: string;
  companyInfos: string;
  caldendlyLink: string;
  targetComissionSplit: string;
  salesReps: string;
}

export interface CreateTeamRequestDto {
  seats: number;
  belongs_to_leader_id: string;
}

export interface TeamResponseDto {
  _id: string;
  assignedSeats: number;
  seats: number;
  leader: UsersResponseDto;
  member: UsersResponseDto[]
}

export interface TeamInternalResponseDto {
  leader: UsersWithTeamInternalResponseDto;
  member: UsersWithTeamInternalResponseDto[];
  assignedSeats: number;
  seats: number;
}

export interface UsersWithTeamInternalResponseDto {
  _id: string
  firstName: string
  lastName: string
  picture: string
  lastActive: Date
  teamMemberSince: Date
  progress: string
  masterclassesCount: string
  masterclassesFinished: string
}

export interface TeamUserInternalAcademyResponseDto {
  _id: string
  firstName: string
  lastName: string
  picture: string
  lastActive: Date
  teamMemberSince: Date
  academy: AcademyResponseDto
}

export interface TeamUserInternalAcademyResponseDtoNew {
  _id: string
  firstName: string
  lastName: string
  picture: string
  lastActive: Date
  teamMemberSince: Date
  tracks: TeamUserInternalTracksResponseDtoNew[]
}

export interface TeamUserInternalTracksResponseDtoNew {
  _id: string
  title: string
  index: number
  learningObjective: string
  trackStatus: string
  exerciseTrack: ExerciseTrackResponse
  thumbnailUrl: string
  otherMembers: TeamUserInternalTracksResponseAnswersDto[]
}
export interface TeamUserInternalTracksResponseAnswersDto {
  user: UsersResponseDto
  answer: ExerciseSubmission
}
