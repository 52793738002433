import React, { SetStateAction, Dispatch, ReactNode } from "react";
import { User } from "../requests/interfaces";

interface IContext {
  isMobile: boolean;
  sitebarOpen: boolean;
  accessToken: string;
  isBanned: boolean;
  user?: User;
  popupContent?: ReactNode;
  userIsLoaded: boolean;
  setIsMobile?: Dispatch<SetStateAction<boolean>>;
  setSitebarOpen?: Dispatch<SetStateAction<boolean>>;
  setIsBanned?: Dispatch<SetStateAction<boolean>>;
  setAccessToken?: Dispatch<SetStateAction<string>>;
  setUser?: Dispatch<SetStateAction<User | undefined>>;
  setPopupContent?: Dispatch<SetStateAction<ReactNode | undefined>>;
}

const Context = React.createContext<IContext>({
  isMobile: false,
  sitebarOpen: false,
  accessToken: "",
  userIsLoaded: false,
  isBanned: false,
});
export default Context;
