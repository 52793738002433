import { useState } from "react";
import ArrowSVG from "../../Icons/ArrowSVG";
import { QandA } from "../../requests/interfaces";
import { Component, ComponentCard, Title, Button, Paragraph, Icon } from "../Elements/Elements";
// Home Tab
export const HelloMessage = (props: { name: string }) => {
  return (
    <Title name="welcome" type="h1" size="m">
      Hi, {props.name}! 👋🏻
    </Title>
  );
};

export const WelcomeWidget = (props: {
  title: string;
  desc: string;
  children: any;
  ctaTitle: string;
  onCtaClick: () => void;
}) => {
  return (
    <>
      <ComponentCard name="welcome-widget">
        <Component name="welcome-widget-title">
          <Title name="welcome-cta" type="h2" size="m">
            {props.title}
          </Title>
          <Paragraph size="l" className="txt--c-surface-on-l">
            {props.desc}
          </Paragraph>
        </Component>
        <Component name="welcome-ctas">{props.children}</Component>
        <Button
          type="darkText"
          name="navigate-networking"
          svg={<ArrowSVG />}
          onClick={props.onCtaClick}>
          {props.ctaTitle}
        </Button>
      </ComponentCard>
    </>
  );
};

// Q and A Tab
export const FAQGroup = (props: { faqItems: QandA[]; title: string }) => {
  return (
    <Component name="faq-group">
      <Title name="faq-group" type="h2" size="xs" className="txt--c-bg-on-l">
        {props.title}
      </Title>
      {props.faqItems.map((item) => {
        return <FAQ faqItem={item} key={item._id} />;
      })}
    </Component>
  );
};

const FAQ = (props: { faqItem: QandA }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <ComponentCard
      name="faq"
      onClick={() => {
        setIsOpen((value) => !value);
      }}
      className={isOpen ? "is_open" : ""}>
      <Component name="faq-question">
        <Title name="faq-question" size="xs" type="h2">
          {props.faqItem.question}
        </Title>
        <Icon name="faq-question" svg={<ArrowSVG />} />
      </Component>
      <Component name="faq-answer" className={isOpen ? "active" : ""}>
        <Paragraph name="faq-answer">{props.faqItem.answer}</Paragraph>
      </Component>
    </ComponentCard>
  );
};
