import LoadingSVG from "../../Icons/LoadingSVG";
import { Icon } from "../Elements/Elements";
import "./loading.scss";

const Loading = () => {
  return (
    <>
      <Icon name="loading-icon" className="pulse" svg={<LoadingSVG />} />
    </>
  );
};

export { Loading };
