import { useContext, useEffect, useState } from "react";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import AcademySVG from "../../Icons/AcademySVG";
import ArrowSVG from "../../Icons/ArrowSVG";
import BookmarkSVG from "../../Icons/BookmarkSVG";
import CareerSVG from "../../Icons/CareerSVG";
import DiscussionSVG from "../../Icons/DiscussionSVG";
import EditSVG from "../../Icons/EditSVG";
import EventSVG from "../../Icons/EventSVG";
import HomeSVG from "../../Icons/HomeSVG";
import LearningSVG from "../../Icons/LearningSVG";
import LogoSVG from "../../Icons/LogoSVG";
import NetworkingSVG from "../../Icons/NetworkingSVG";
import Context from "../../context/Context";
import historyNavigate from "../../hooks/useHistoryNavigate";
import { useUser } from "../../hooks/useUser";
import { Button, Component, ComponentCard, Icon, Paragraph } from "../Elements/Elements";
import { MobileHeader } from "./Header";
import "./navigation.scss";

export const blockedJobBoardUsers = [
  "659bcc10f5aebf0c87650983",
  "659bccf4f5aebf0c87650da0",
  "659bce2cf5aebf0c87650ed5",
  "659bf4a7f5aebf0c87657394",
  "659c20d13d776be1b7da36f7",
  "659cf9813ce45e96db4a7354",
  "659cfc823ce45e96db4a7db0",
  "659d002e3ce45e96db4a86f0",
  "659e4cb83563ceec11109a10",
  "659e4d0c3563ceec11109b54",
  "659e4e173563ceec1110a1db",
  "659e4fa83563ceec1110a6e8",
  "65a12cd3527d523d4e3a7208",
  "65aa85b11f7ab1ee92089687",
  "65ae81694cd6ed0bb6a64ee5",
  "65411526b1eef4db889f2bff",
];

enum NavCategory {
  ABOVE = "",
  SOCIAL = "Community",
  KNOWLEDGE = "Sales Education",
  CAREER = "Karriere",
  ADMIN = "Admin",
}

interface NavItem {
  category: NavCategory;
  icon: any;
  href: string;
  label: string;
  selector: string;
  isOpen?: boolean;
}

const items: NavItem[] = [
  {
    category: NavCategory.ABOVE,
    href: "/",
    icon: <HomeSVG />,
    label: "Startseite",
    selector: "home",
  },
  {
    category: NavCategory.SOCIAL,
    href: "/discussion",
    icon: <DiscussionSVG />,
    label: "Diskussionen",
    selector: "discussion",
  },
  {
    category: NavCategory.SOCIAL,
    href: "/networking",
    icon: <NetworkingSVG />,
    label: "Networking",
    selector: "networking",
  },
  {
    category: NavCategory.SOCIAL,
    href: "/events",
    icon: <EventSVG />,
    label: "Events",
    selector: "events",
  },
  {
    category: NavCategory.KNOWLEDGE,
    href: "/wissen",
    icon: <LearningSVG />,
    label: "Sales Wissen",
    selector: "learnings",
  },
  {
    category: NavCategory.KNOWLEDGE,
    href: "/academy",
    icon: <AcademySVG />,
    label: "Masterclass Academy",
    selector: "academy",
  },
  // {
  //   category: NavCategory.OTHER,
  //   href: "/jobs",
  //   icon: <CareerSVG />,
  //   label: "Sales Jobs",
  //   selector: "career",
  // },
  // {
  //   category: NavCategory.OTHER,
  //   href: "/journal",
  //   icon: <JournalSVG />,
  //   label: "Journal",
  // },
];

const steps = [
  {
    target: "#home",
    content: "Dies ist die Startseite. Hier findest du den Willkommensfeed und alle Updates.",
    disableBeacon: true,
  },
  {
    target: "#discussion",
    content:
      "Wir alle stehen mal vor Herausforderungen oder haben Fragen. In unserem Diskussionsforum kannst du andere, erfahrene Vertriebler nach ihrer Meinung oder Perspektive bitten oder einfach über die neuesten Trends im Sales diskutieren.",
    disableBeacon: true,
  },
  {
    target: "#networking",
    content:
      "Erweitere dein Netzwerk und schließe Freundschaften in der deutschen B2B Sales Szene. Schließlich hat es noch nie geschadet, die „richtigen Leute“ zu kennen.",
    disableBeacon: true,
  },
  {
    target: "#events",
    content:
      "Wir veranstalten monatliche On- und Offline Events, bei denen du dich weiterentwickeln und neue Leute aus der Szene treffen kannst.",
    disableBeacon: true,
  },
  {
    target: "#learnings",
    content:
      "Hier findest du Insights unserer Instructors oder Ambassadors, sowie Tipps & Tricks zu unterschiedlichen Sales Themen.",
    disableBeacon: true,
  },
  {
    target: "#academy",
    content:
      "Unsere Masterclass Academy bietet dir Zugriff auf über 100 Jahre Erfahrung der besten Experten und Coaches. Mehr als 25 Sales-Koryphäen teilen mit dir ihr Wissen. Nur, um dich in deiner Karriere weiterzubringen. Geht eben nichts über eine starke Community.",
    disableBeacon: true,
  },
  // {
  //   target: "#career",
  //   content:
  //     "Ob erster Job in Sales oder nächstes Level erreichen, hier findest du deine nächste Job Chance!",
  //   disableBeacon: true,
  // },
];

// walk through career

const Sidebar = () => {
  const [itemsByCategory, setItemsByCategory] =
    useState<{ category: NavCategory; items: NavItem[] }[]>();

  const hasSeenTour = () => {
    return !(localStorage.getItem("tour") === "true" ? true : false);
  };

  const { useHistoryNavigate } = historyNavigate();
  const { isMobile, sitebarOpen, setSitebarOpen } = useContext(Context);
  const showTour = hasSeenTour();
  const { hasSetupDone } = useUser();
  const [start, setStart] = useState<boolean>(false);
  const [run, setRun] = useState<boolean>();
  const [stepIndex, setStepIndex] = useState<number>();
  const { user, isRecruiter } = useUser();

  if (isRecruiter) {
    items.length = 0;
  }

  if (
    user?.role &&
    // (user?.role.toLowerCase() === "ambassador" ||
    //   user?.role.toLowerCase() === "admin" ||
    //   user?.role.toLowerCase() === "instructor") &&
    !blockedJobBoardUsers.includes(user._id)
  ) {
    if (!items.find((item) => item.label === "Job Board")) {
      items.push({
        category: NavCategory.CAREER,
        icon: <CareerSVG />,
        href: "/jobs",
        label: "Job Board",
        selector: "jobs",
      });
    }
  }

  if (!items.find((item) => item.label === "Team Dashboard") && user?.teamMemberSince) {
    items.push({
      category: NavCategory.KNOWLEDGE,
      href: "/team-dashboard",
      icon: <BookmarkSVG />,
      label: "Team Dashboard",
      selector: "team-dashboard",
    });
  }

  if (user?.role && user?.role.toLowerCase() === "admin") {
    if (!items.find((item) => item.label === "Admin")) {
      items.push({
        category: NavCategory.ADMIN,
        href: "/admin-dashboard",
        icon: <EditSVG />,
        label: "Admin",
        selector: "admin",
      });
    }

    if (!steps.find((item) => item.target === "admin")) {
      steps.push({
        content: "Hier kannst du all deine Resourcen oder Events bearbeiten, editieren und löschen",
        disableBeacon: true,
        target: "#admin",
      });
    }
  }

  useEffect(() => {
    const navCategories = Array.from(new Set(items.map((item) => item.category)));
    setItemsByCategory(
      navCategories.map((category) => {
        return {
          category,
          items: items.filter((item) => item.category === category),
        };
      }),
    );
  }, []);

  useEffect(() => {
    if (showTour && hasSetupDone) {
      setStart(true);
    }
  }, [showTour, hasSetupDone]);

  const handleCallback = (data: any) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      setStepIndex(() => index + (action === ACTIONS.PREV ? -1 : 1));
    } else if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      [ACTIONS.CLOSE].includes(action)
    ) {
      setRun(false);
      localStorage.setItem("tour", true + "");
    }
  };

  useEffect(() => {
    const chat = document.getElementById("LeadboosterContainer");
    const root = document.getElementById("root");
    if (sitebarOpen && isMobile) {
      const parent = document.createElement("div");
      parent.id = "hidden-chat";
      parent.classList.add("hidden");
      root?.appendChild(parent);
      if (chat) {
        parent.appendChild(chat);
      }
    } else {
      const parent = document.getElementById("hidden-chat");
      if (parent && root && chat) {
        root.appendChild(chat);
        parent.remove();
      }
    }
  }, [sitebarOpen]);

  return (
    <>
      <ComponentCard
        name="sb"
        className={`${sitebarOpen || !isMobile ? "is-open" : ""}`}
        onClick={() => {
          setSitebarOpen && isMobile ? setSitebarOpen(false) : "";
        }}>
        <Component name="sidebar">
          <Icon
            name="sb-logo"
            svg={<LogoSVG />}
            onClick={() => {
              if (!isRecruiter) {
                useHistoryNavigate("/");
              }
            }}
            className="clickable"></Icon>
          <Component name="sb-categories" onClick={(e) => e.stopPropagation()}>
            {itemsByCategory?.map((categoryItems) => {
              return (
                <Component name="sb-category" key={categoryItems.category}>
                  <Paragraph
                    name="sb-category"
                    className={`p--nav txt--c-on-surface-l ${
                      sitebarOpen || !isMobile ? "" : "hidden"
                    }`}>
                    {categoryItems.category}
                  </Paragraph>
                  {categoryItems.items?.map((item) => {
                    return (
                      <SidebarElement
                        {...item}
                        key={item.label}
                        isOpen={sitebarOpen || !isMobile}
                      />
                    );
                  })}
                </Component>
              );
            })}
          </Component>
          {isMobile && <MobileHeader />}
          {false && (
            <Button
              name="sb-toggle"
              type="lightText"
              svg={<ArrowSVG />}
              onClick={() =>
                setSitebarOpen ? setSitebarOpen((value) => !value) : console.log("ouff")
              }>
              {sitebarOpen ? "Schliessen" : ">"}
            </Button>
          )}
          {!isRecruiter && start && !isMobile && (
            <Joyride
              steps={steps}
              continuous
              run={run}
              stepIndex={stepIndex}
              callback={handleCallback}
              showSkipButton={false}
              styles={{
                options: {
                  primaryColor: "#0500ff",
                },
              }}
            />
          )}
        </Component>
      </ComponentCard>
    </>
  );
};

const SidebarElement = (props: NavItem) => {
  const { useHistoryNavigate } = historyNavigate();

  const isActive =
    props.href.split("?")[0] === "/" + window.location.pathname.split("/")[1].split("&")[0];
  const { isMobile, setSitebarOpen } = useContext(Context);

  return (
    <Component
      name="sb-link"
      onClick={() => {
        setSitebarOpen && isMobile ? setSitebarOpen(false) : "";
        useHistoryNavigate(props.href);
      }}
      id={props.selector}
      className={isActive ? "active" : ""}>
      <Icon name={"sb-link"} svg={props.icon} />
      {props.label && props.isOpen && (
        <Paragraph name="sb-link" size="nav">
          {props.label}
        </Paragraph>
      )}
    </Component>
  );
};

export default Sidebar;
