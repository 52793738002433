import { useEffect } from "react";
import { Main, Page } from "../components/Elements/Elements";
import { Header } from "../components/Navigation/Header";
import SiteBar from "../components/Navigation/Sidebar";
import historyNavigate from "../hooks/useHistoryNavigate";

interface Props {
  location: string;
}

const Redirect = (props: Props) => {
  const { useHistoryNavigate } = historyNavigate();

  useEffect(() => {
    useHistoryNavigate(props.location);
    window.location.reload();
  }, [useHistoryNavigate]);

  return (
    <Page name="redirect">
      <SiteBar />
      <Main>
        <Header />
      </Main>
    </Page>
  );
};

export default Redirect;
