import { useEffect, useState } from "react";
import { Button, Component, Paragraph, ProfilePictureEdit } from "../../Elements/Elements";
import { TextInputElement } from "../../Elements/FormElements";
import { StepProps } from "../Register";
import { useAuth0 } from "@auth0/auth0-react";

enum ErrorFields {
  "IMAGE",
  "FIRSTNAME",
  "LASTNAME",
  "WELCOMEMESSAGE",
  "BIRTHDATE",
  "LINKEDIN",
}

const PersonalStep = (props: StepProps) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [birthday, setBirthday] = useState("");
  const [linkedInUrl, setLinkedInUrl] = useState("");
  const [selectedImage, setSelectedImage] = useState<File | null | string>(null);
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [errorFields, setErrorFields] = useState<ErrorFields[]>();
  const { logout } = useAuth0();

  useEffect(() => {
    validate(false);
  }, [firstname, lastname, welcomeMessage, selectedImage, birthday]);

  const validate = (withError: boolean) => {
    const errors = [];
    if (typeof selectedImage !== "string" || selectedImage === "") {
      errors.push(ErrorFields.IMAGE);
    }
    if (firstname === "") {
      errors.push(ErrorFields.FIRSTNAME);
    }
    if (lastname === "") {
      errors.push(ErrorFields.LASTNAME);
    }
    if (welcomeMessage === "") {
      errors.push(ErrorFields.WELCOMEMESSAGE);
    }
    if (birthday === "") {
      errors.push(ErrorFields.BIRTHDATE);
    }
    if (!/^https:\/\/www\.linkedin\.com\/in\/.*$/.test(linkedInUrl)) {
      errors.push(ErrorFields.LINKEDIN);
    }
    if (withError && errors.length !== 0) {
      setErrorFields(errors);
      return false;
    }
    return true;
  };

  const submit = (e: any) => {
    const isValid = validate(true);
    if (isValid) {
      const request = {
        firstName: firstname,
        lastName: lastname,
        birthDate: birthday,
        welcomeMessage: welcomeMessage,
        linkedInUrl: linkedInUrl,
        picture: selectedImage,
      };
      props.action(e, 1, request);
    }
  };

  const changeImage = (e: any) => {
    setSelectedImage(e.target.files[0]);
  };

  const onImageUploaded = (url: string) => {
    setSelectedImage(url);
  };

  return (
    <>
      <ProfilePictureEdit
        img={selectedImage}
        onChange={changeImage}
        onImageUploaded={onImageUploaded}
        errorMessage="Bitte ergänzen Sie ein Profilbild"
        displayErrorMessage={errorFields?.includes(ErrorFields.IMAGE)}
      />
      <Component name="form-row">
        <Component name="form-item">
          <TextInputElement
            id="firstname"
            label="Vorname"
            type="text"
            labelClasses="p--l"
            placeHolder="Max"
            onInput={(e) => setFirstname((e.target as HTMLInputElement).value)}
            value={firstname}
            errorMessage="Bitte ergänzen Sie ihren Vorname"
            displayErrorMessage={errorFields?.includes(ErrorFields.FIRSTNAME)}
          />
        </Component>
      </Component>
      <Component name="form-row">
        <Component name="form-item">
          <TextInputElement
            id="lastname"
            label="Nachname"
            type="text"
            labelClasses="p--l"
            placeHolder="Muster"
            onInput={(e) => setLastname((e.target as HTMLInputElement).value)}
            value={lastname}
            errorMessage="Bitte ergänzen Sie ihren Nachnamen"
            displayErrorMessage={errorFields?.includes(ErrorFields.LASTNAME)}
          />
        </Component>
      </Component>
      <Component name="form-row">
        <Component name="form-item">
          <TextInputElement
            id="linkedinUrl"
            label="Linkedin Url"
            type="text"
            labelClasses="p--l"
            placeHolder="https://www.linkedin.com/in/mattiaschaper"
            onInput={(e) => setLinkedInUrl((e.target as HTMLInputElement).value)}
            value={linkedInUrl}
            errorMessage="Bitte ergänzen Sie ihr Linkedin Profil"
            displayErrorMessage={errorFields?.includes(ErrorFields.LINKEDIN)}
          />
        </Component>
      </Component>
      <Component name="form-row">
        <Component name="form-item">
          <TextInputElement
            id="birthday"
            label="Geburtstag"
            type="date"
            labelClasses="p--l"
            onChange={(e) => setBirthday((e.target as HTMLInputElement).value)}
            value={birthday}
            errorMessage="Bitte ergänzen Sie ihr Geburtsdatum"
            displayErrorMessage={errorFields?.includes(ErrorFields.BIRTHDATE)}
          />
        </Component>
      </Component>
      <Component name="form-row">
        <Component name="form-item">
          <TextInputElement
            id="welcome-message"
            label="Willkommensnachricht an die Community"
            type="textarea"
            labelClasses="p--l"
            placeHolder="Hey Leute, ich bin neu hier und freue mich darauf, euch alle zu treffen!"
            onChange={(e) => setWelcomeMessage((e.target as HTMLInputElement).value)}
            value={welcomeMessage}
            errorMessage="Bitte ergänzen Sie eine Welcome Message"
            displayErrorMessage={errorFields?.includes(ErrorFields.WELCOMEMESSAGE)}
          />
          <Paragraph name="" type="paragraph" size="s">
            Diese Nachricht wird nach deinem Beitritt im Willkommensfeed angezeigt
          </Paragraph>
        </Component>
      </Component>
      <Component name="form-row">
        <Component name="form-item">
          <Button onClick={submit} name="submit" type="primary">
            Weiter
          </Button>
          <Button type="danger" name="logout" onClick={() => logout()}>
            Zurück
          </Button>
        </Component>
      </Component>
    </>
  );
};

export default PersonalStep;
