import { useLocation, useParams } from "react-router-dom";
import {
  AppView,
  BackButton,
  Button,
  Component,
  Icon,
  Paragraph,
  ProfilePicture,
  Section,
  SectionElement,
  Title,
} from "../Elements/Elements";
import Navigation from "../Navigation/Navigation";
import { useEffect, useState } from "react";
import {
  DiscussionResponseDto,
  Paginated,
  UsersWithRoleResponseDto,
} from "../../requests/interfaces";
import "./userprofile.scss";
import { DiscussionElement } from "../Discussion/Components";
import { useUser } from "../../hooks/useUser";
import { TextInputElement } from "../Elements/FormElements";
import StarSVG from "../../Icons/StarSVG";
import historyNavigate from "../../hooks/useHistoryNavigate";
import { ReportForm } from "../Elements/Components";
import useRequest from "../../hooks/useRequest";
import { Loading } from "../Loading/Loading";
import useFormOptions from "../../hooks/useFormOptions";
import { ProfileName, SmallDate } from "../util/Components";
import LinkedInIcon from "../../Icons/LinkedInIcon";

const maxStarAmount = 5;

const UserProfile = () => {
  const location = useLocation();
  const [backLoc, setBackLoc] = useState<string>();
  const [reportFormOpen, setReportFormOpen] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<UsersWithRoleResponseDto>();
  const { user } = useUser();
  const { apiRequest } = useRequest();
  const { id } = useParams();
  const { useHistoryNavigate } = historyNavigate();
  const [discussions, setDiscussions] = useState<DiscussionResponseDto[]>();
  const [getLabels, setGetLabels] = useState<(id: string) => any>(() => (id: string) => {
    return [id];
  });
  const { getFormOptions } = useFormOptions();

  useEffect(() => {
    setBackLoc(location.state?.prevLocation);
  }, [backLoc]);

  const getUserById = async () => {
    const { data } = await apiRequest<UsersWithRoleResponseDto>(`users/${id}`, "GET");
    setUserProfile(data);
  };

  useEffect(() => {
    getUserById();
    const getDiscussions = async () => {
      const discussions = await apiRequest<Paginated<DiscussionResponseDto>>(
        `discussions/users/${id}`,
        "GET",
      );
      setDiscussions(discussions.data.docs);
      const labelId = (await getFormOptions()).getLabelById;
      setGetLabels(() => labelId);
    };
    getDiscussions();
  }, []);

  useEffect(() => {
    // media
  }, [getLabels]);

  return (
    <AppView name="user-profile">
      <Navigation />
      <Section name="user-profile">
        <SectionElement type="header" name="user-profile">
          <Component name="user-profile-back">
            <BackButton />
          </Component>
        </SectionElement>
        <SectionElement type="body" name="user-profile">
          <Component name="user-profile">
            {userProfile && (
              <>
                <Component name="user-profile-header">
                  <Component name="user-profile-info">
                    <ProfilePicture img={userProfile.picture} clickable={false} />
                    <Component name="user-profile-name">
                      <ProfileName
                        firstName={userProfile.firstName}
                        lastName={userProfile.lastName}
                        userId={userProfile._id}
                        size="m"
                        clickable={false}
                      />
                      {userProfile.career && (
                        <>
                          <Paragraph name="user-profile-information">
                            {`${getLabels(userProfile.career.position).name} @ ${
                              userProfile.career.company
                            } | ${userProfile.career.location?.name ?? ""}`}
                          </Paragraph>
                          <Component name="user-profile-created-at" className="fd-row gap-0-5 fg-0">
                            <Paragraph name="user-profile-created-at">Mitglied seit: </Paragraph>
                            <SmallDate date={userProfile.createdAt} paragraphSize="m" />
                          </Component>
                        </>
                      )}
                      {/* <Paragraph type="paragraph">bio</Paragraph> */}
                    </Component>
                  </Component>
                  {userProfile._id !== user?._id && (
                    <Component name="user-profile-action">
                      <Button
                        name="message"
                        type="primary"
                        onClick={() => useHistoryNavigate(`/messages?id=${userProfile._id}`)}>
                        Nachricht
                      </Button>
                      {userProfile.linkedInUrl && (
                        <Button
                          name="message"
                          type="primaryGhost"
                          svg={<LinkedInIcon />}
                          onClick={() => window.open(userProfile.linkedInUrl, "_blank")?.focus()}>
                          LinkedIn
                        </Button>
                      )}
                      <Button
                        name="report"
                        type="dangerGhost"
                        onClick={() => setReportFormOpen(true)}>
                        Melden
                      </Button>
                    </Component>
                  )}
                </Component>
                <Component name="user-profile-body">
                  <Component name="user-profile-about">
                    <Title type="h2" size="s" name="about">
                      About:
                    </Title>
                    {userProfile.skills && userProfile.skills.length !== 0 && (
                      <Component name="skills">
                        {userProfile?.skills.map((skill) => {
                          return (
                            <Component key={skill._id} name="tag" className="active unclickable">
                              {skill.skill}
                            </Component>
                          );
                        })}
                      </Component>
                    )}
                    <Component name="user-welcome-message">
                      <Title name="user-profile-about-title-position" type="paragraph" size="xxs">
                        Willkommensnachricht:
                      </Title>
                      <Paragraph name="user-profile-about-position" type="paragraph" size="m">
                        {userProfile.welcomeMessage}
                      </Paragraph>
                    </Component>
                    {userProfile.career && (
                      <Component name="user-profile-about-infos">
                        <Component name="user-profile-about-position">
                          <Title
                            name="user-profile-about-title-position"
                            type="paragraph"
                            size="xxs">
                            Position:
                          </Title>
                        </Component>
                        <Component name="user-profile-about-position">
                          <Paragraph name="user-profile-about-position" type="paragraph" size="m">
                            {getLabels(userProfile.career.position).name}
                          </Paragraph>
                        </Component>
                        <Component name="user-profile-about-experience">
                          <Title
                            name="user-profile-about-title-experience"
                            type="paragraph"
                            size="xxs">
                            Erfahrung:
                          </Title>
                        </Component>
                        <Component name="user-profile-about-experience">
                          <Paragraph name="user-profile-about-experience" type="paragraph" size="m">
                            {getLabels(userProfile.career.experience).name}
                          </Paragraph>
                        </Component>
                        <Component name="user-profile-about-location">
                          <Title
                            name="user-profile-about-title-location"
                            type="paragraph"
                            size="xxs">
                            Wohnort:
                          </Title>
                        </Component>
                        <Component name="user-profile-about-location">
                          <Paragraph name="user-profile-about-location" type="paragraph" size="m">
                            {userProfile.career?.location?.name ?? "nicht angegeben"}
                          </Paragraph>
                        </Component>
                        <Component name="user-profile-about-industry">
                          <Title
                            name="user-profile-about-title-industry"
                            type="paragraph"
                            size="xxs">
                            Industrie:
                          </Title>
                        </Component>
                        <Component name="user-profile-about-industry">
                          <Paragraph name="user-profile-about-industry" type="paragraph" size="m">
                            {userProfile.career?.industry.map((item, index) => {
                              const addOn =
                                index === (userProfile?.career?.industry.length ?? 0) - 1
                                  ? ""
                                  : ", ";
                              return <span key={item}>{getLabels(item).name + addOn}</span>;
                            })}
                          </Paragraph>
                        </Component>
                        <Component name="user-profile-about-interests">
                          <Title
                            name="user-profile-about-title-interests"
                            type="paragraph"
                            size="xxs">
                            Offen für:
                          </Title>
                        </Component>
                        <Component name="user-profile-about-interests">
                          <Paragraph name="user-profile-about-interests" type="paragraph" size="m">
                            {userProfile.career?.interests.map((item, index) => {
                              const addOn =
                                index === (userProfile?.career?.interests.length ?? 0) - 1
                                  ? ""
                                  : ", ";
                              return <span key={item}>{getLabels(item)?.name + addOn}</span>;
                            })}
                          </Paragraph>
                        </Component>
                      </Component>
                    )}
                    {discussions && discussions.length !== 0 && (
                      <Component name="posts">
                        <Title type="h2" size="xs" name="posts">
                          Beiträge
                        </Title>
                        {discussions?.map((discussion) => {
                          return (
                            <DiscussionElement
                              key={discussion._id}
                              discussion={discussion}
                              showComments={false}
                            />
                          );
                        })}
                      </Component>
                    )}
                  </Component>
                  {(userProfile.role === "AMBASSADOR" || userProfile.role === "INSTRUCTOR") &&
                    false && (
                      <Component name="user-profile-reviews">
                        <Title type="h3" size="xs" name="review">
                          Bewertungen:
                        </Title>
                        <UserReviewAction />
                        <Component name="all-reviews">
                          {/* {userProfile.detailedUserInfo?.reviews?.map((review) => {
                          return (
                            <Component key={review.user._id} name="review-item">
                              <Component name="review-header">
                                <Component
                                  name="review-profile"
                                  onClick={() => onProfileClick(review.user._id)}>
                                  <ProfilePicture img={review.user?.picture} />
                                  <Component name="post-info-text">
                                    <Title
                                      type="h2"
                                      size="xs"
                                      className={
                                        user?._id === review.user._id ? "own-discussion" : ""
                                      }>
                                      {user?._id === review.user._id
                                        ? "Du"
                                        : `${review.user?.firstName} ${review.user?.lastName}`}
                                    </Title>
                                  </Component>
                                </Component>
                                <Component name="review-stars">
                                  {[...Array(maxStarAmount)].map((e, index) => {
                                    return (
                                      <Component name="star" key={index}>
                                        <Icon
                                          svg={<StarSVG />}
                                          className={`star ${
                                            index <= review.starCount ? "active" : ""
                                          }`}
                                          name="star"
                                        />
                                      </Component>
                                    );
                                  })}
                                </Component>
                              </Component>
                              <Component name="review">{review.description}</Component>
                              <Paragraph type="paragraph">{review.date.toDateString()}</Paragraph>
                            </Component>
                          );
                        })} */}
                        </Component>
                      </Component>
                    )}
                </Component>
              </>
            )}
            {!userProfile && <Loading />}
          </Component>
          <ReportForm
            popupIsOpen={reportFormOpen}
            setPopupIsOpen={setReportFormOpen}
            title="Person melden"
          />
        </SectionElement>
      </Section>
    </AppView>
  );
};

const UserReviewAction = () => {
  const [reviewMessage, setReviewMessage] = useState<string>();
  const [starAmount, setStarAmount] = useState<number>(-1);
  const [fixed, setFixed] = useState<number>(-1);

  const submitReview = () => {
    //TODO send submit to backend
  };

  return (
    <>
      <Component name="review-action-field">
        <TextInputElement
          id="review"
          type="textarea"
          placeHolder="Bewertung schreiben"
          onChange={(e) => setReviewMessage((e.target as HTMLInputElement).value)}
          value={reviewMessage}
        />
        <Component name="review-footer">
          <Component
            name="review-stars"
            onMouseLeave={() => {
              setStarAmount(-1);
            }}>
            {[...Array(maxStarAmount)].map((e, index) => {
              return (
                <Component
                  name="star"
                  key={index}
                  onMouseOver={() => {
                    setStarAmount(index);
                  }}
                  onClick={() => setFixed(index)}>
                  <Icon
                    svg={<StarSVG />}
                    className={`star ${index <= starAmount || index <= fixed ? "active" : ""}`}
                    name="star"
                  />
                </Component>
              );
            })}
          </Component>
          <Button name="submit" type="primary" onClick={submitReview}>
            Bewerten
          </Button>
        </Component>
      </Component>
    </>
  );
};

export { UserProfile };
