const AnswerSVG: React.FC<React.HTMLProps<HTMLOrSVGElement>> = () => {
  return (
    <svg viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.99967 3.00002V1.41002C6.99967 0.520018 5.91967 0.0700175 5.28967 0.700017L0.699671 5.29002C0.606967 5.38253 0.533419 5.49242 0.483238 5.61339C0.433057 5.73437 0.407227 5.86405 0.407227 5.99502C0.407227 6.12599 0.433057 6.25567 0.483238 6.37664C0.533419 6.49761 0.606967 6.6075 0.699671 6.70002L5.28967 11.29C5.91967 11.92 6.99967 11.48 6.99967 10.59V8.90002C11.9997 8.90002 15.4997 10.5 17.9997 14C16.9997 9.00002 13.9997 4.00002 6.99967 3.00002Z"
        fill="#8B8B8B"
      />
    </svg>
  );
};

export default AnswerSVG;
