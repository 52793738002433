import { Main, Page } from "../components/Elements/Elements";
import Sidebar from "../components/Navigation/Sidebar";
import { Academy as AcademyComponent } from "../components/Academy/Academy";
import { useParams } from "react-router-dom";

const Academy = () => {
  const { academyId } = useParams();
  const { trackId } = useParams();

  return (
    <Page name="academy">
      <Sidebar />
      <Main>
        <AcademyComponent academyId={academyId} trackId={trackId} />
      </Main>
    </Page>
  );
};

export default Academy;
