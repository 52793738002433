import { useNavigate } from "react-router-dom";

const historyNavigate = () => {
  const navigate = useNavigate();

  const useHistoryNavigate = (route: string) => {
    if (window.location.pathname !== route) {
      navigate(route, {
        state: { prevLocation: window.location.pathname },
      });
    }
  };

  return { useHistoryNavigate };
};

export default historyNavigate;
