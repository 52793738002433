import { useEffect, useState } from "react";
import {
  Paragraph,
  Section,
  SectionElement,
  Image,
  Table,
  TableColumn,
  Icon,
  Title,
  Button,
  Component,
  TablePagination,
  ImageUploader,
  TableSearch,
} from "../Elements/Elements";
import {
  CreateEventRequestDto,
  Paginated,
  EventResponseDto,
  Company,
} from "../../requests/interfaces";
import { format } from "date-fns";
import { useSearchParams } from "react-router-dom";
import EditSVG from "../../Icons/EditSVG";
import TrashSVG from "../../Icons/TrashSVG";
import historyNavigate from "../../hooks/useHistoryNavigate";
import {
  DateTimeInputElement,
  HashtagSelect,
  SponsorsSelect,
  Switch,
  TextInputElement,
} from "../Elements/FormElements";
import useRequest from "../../hooks/useRequest";
import { UsersResponseDto } from "../../requests/interfaces";
import { UserSearchField } from "../Elements/Components";

const AdminEvents = () => {
  const [mode, setMode] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const modeId = searchParams.get("mode");
  const [modes, setModes] = useState<any[]>([]);

  const setupModes = () => {
    const allModes = [
      {
        modeId: "LIST",
        component: <AdminEventList searchParams={searchParams} setSearchParams={setSearchParams} />,
        active: modeId === null || (modeId !== "create" && modeId !== "edit"),
      },
      {
        modeId: "CREATE",
        component: (
          <AdminEventItem
            mode="create"
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        ),
        active: modeId === "create",
      },
      {
        modeId: "EDIT",
        component: (
          <AdminEventItem
            mode="edit"
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        ),
        active: modeId === "edit",
      },
    ];
    setModes(allModes);
  };

  useEffect(() => {
    setMode(modes.find((tab: any) => tab.active === true));
  }, [modes]);

  useEffect(() => {
    setupModes();
  }, [modeId]);

  useEffect(() => {
    setupModes();
  }, []);

  return <>{mode && mode.component}</>;
};

const AdminEventList = (props: { searchParams: any; setSearchParams: any }) => {
  const [events, setEvents] = useState<EventResponseDto[]>([]);
  const [hasNext, setHasNext] = useState<boolean>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { apiRequest } = useRequest();

  const getEvents = async () => {
    const eventsResponse = await apiRequest<Paginated<EventResponseDto>>(
      `events/all?limit=10&page=${currentPage}&sortBy=descending`,
      "GET",
    );
    setEvents(eventsResponse.data.docs);
    setHasNext(eventsResponse.data.hasNextPage);
  };

  useEffect(() => {
    getEvents();
  }, []);

  useEffect(() => {
    getEvents();
  }, [currentPage]);

  const edit = (id: string) => {
    props.searchParams.set("mode", "edit");
    props.searchParams.set("id", id);
    props.setSearchParams(props.searchParams);
  };

  const deleteEvent = async (id: string) => {
    await apiRequest(`events/${id}`, "DELETE");
    getEvents();
  };

  const create = () => {
    props.searchParams.set("mode", "create");
    props.setSearchParams(props.searchParams);
  };

  return (
    <Section name="admin-events">
      <SectionElement type="header" name="admin-event">
        <Title name="admin-events-title" type="h1" size="m">
          Event
        </Title>
        <Button type="primary" name="create-new-event" onClick={() => create()}>
          + Neu
        </Button>
      </SectionElement>
      <SectionElement type="body" name="admin-event">
        <Component name="table-background">
          <TableSearch
            route="events"
            setItem={setEvents}
            resetSearched={() => {
              if (currentPage !== 0) {
                setCurrentPage(0);
              } else {
                getEvents();
              }
            }}
          />
          <Table name="admin-events">
            <thead>
              <tr className="h--xxs">
                <th>
                  <Paragraph name="admin-event-thumbnail">Vorschaubild</Paragraph>
                </th>
                <th>
                  <Paragraph name="admin-event-title">Titel</Paragraph>
                </th>
                <th>
                  <Paragraph name="admin-event-location">Location</Paragraph>
                </th>
                <th>
                  <Paragraph name="admin-event-date">Datum</Paragraph>
                </th>
                <th>
                  <Paragraph name="admin-event-participants">Teilnehmerzahl</Paragraph>
                </th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {events?.map((event) => {
                return (
                  <tr
                    key={event._id}
                    onClick={(e) => {
                      e.stopPropagation();
                      edit(event._id);
                    }}>
                    <TableColumn name="admin-col">
                      <Image img={event.picture} name="admin-event-thumbnail" />
                    </TableColumn>
                    <TableColumn name="admin-col">
                      <Paragraph type="paragraph" name="admin-event-title">
                        {event.title}
                      </Paragraph>
                    </TableColumn>
                    <TableColumn name="admin-col">
                      <Paragraph type="paragraph" name="admin-event-location">
                        {event.isOnlineMeeting ? "Online" : "Real Life"}
                      </Paragraph>
                    </TableColumn>
                    <TableColumn name="admin-col">
                      <Paragraph type="paragraph" name="admin-event-date">{`${format(
                        new Date(event.date),
                        "dd.MM.y",
                      )}`}</Paragraph>
                    </TableColumn>
                    <TableColumn name="admin-col">
                      <Paragraph type="paragraph" name="admin-event-participants">
                        {event.participantsCount}
                      </Paragraph>
                    </TableColumn>
                    <TableColumn name="admin-col">
                      <Icon
                        name="event-edit"
                        svg={<EditSVG />}
                        className="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          edit(event._id);
                        }}
                      />
                    </TableColumn>
                    <TableColumn>
                      <Icon
                        name="event-delete"
                        svg={<TrashSVG />}
                        className="danger"
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteEvent(event._id);
                        }}
                      />
                    </TableColumn>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {hasNext !== undefined && (
            <TablePagination
              currentPage={currentPage}
              hasNext={hasNext}
              setCurrentPage={setCurrentPage}
            />
          )}
        </Component>
      </SectionElement>
    </Section>
  );
};

const AdminEventItem = (props: {
  mode: "create" | "edit";
  searchParams: any;
  setSearchParams: any;
}) => {
  const id = props.searchParams.get("id");
  const [item, setItem] = useState<EventResponseDto>();
  const [title, setTitle] = useState<string>(item?.title ?? "");
  const [date, setDate] = useState<Date>(item?.date ?? new Date());
  const [endDate, setEndDate] = useState<Date>(item?.endDate ?? new Date());
  const [thumbnail, setThumbnail] = useState<File | null | string>(item?.picture ?? null);
  const [description, setDescription] = useState<string>(item?.description ?? "");
  const [meetingLocationUrl, setMeetingLocationUrl] = useState<string>(
    item?.meetingLocationUrl ?? "",
  );
  const [eventId, setEventId] = useState<string>(item?.eventId ?? "");
  const [flowId, setFlowId] = useState<string>(item?.flowId ?? "");
  const [speakers, setSpeakers] = useState<UsersResponseDto[]>(item?.speakers ?? []);
  const [sponsors, setSponsors] = useState<Company[]>(item?.sponsors ?? []);
  const [tags, setTags] = useState<string[]>(item?.hashtags ?? []);
  const [isValid, setIsValid] = useState<boolean>(false);
  const { apiRequest } = useRequest();
  const { useHistoryNavigate } = historyNavigate();
  const [isOnlineMeeting, setIsOnlineMeeting] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      const getEventById = async () => {
        const { data } = await apiRequest<EventResponseDto>(`events/${id}`, "GET");
        setItem(data);
      };
      getEventById();
    }
  }, []);

  useEffect(() => {
    setTitle(item?.title ?? "");
    setThumbnail(item?.picture ?? "");
    setDescription(item?.description ?? "");
    setEventId(item?.eventId ?? "");
    setFlowId(item?.flowId ?? "");
    // setImages(item?.images ?? []);
    setMeetingLocationUrl(item?.meetingLocationUrl ?? "");
    setSpeakers(item?.speakers ?? []);
    setSponsors(item?.sponsors ?? []);
    setDate(item?.date ?? new Date());
    setEndDate(item?.endDate ?? new Date());
    setTags(item?.hashtags ?? []);
  }, [item]);

  const getEventIdFromString = (str: string) => {
    const regex = /data-zuddl-event-id="([^"]+)"/;
    const match = str.match(regex);
    return match ? match[1] : undefined;
  };

  const getFlowIdFromString = (str: string) => {
    const regex = /data-zuddl-flow-id="([^"]+)"/;
    const match = str.match(regex);
    return match ? match[1] : undefined;
  };

  useEffect(() => {
    if (
      title === item?.title &&
      description === item?.description &&
      meetingLocationUrl === item?.meetingLocationUrl &&
      speakers === item?.speakers &&
      date === item?.date &&
      endDate === item?.endDate &&
      tags === item?.hashtags &&
      thumbnail === item?.picture &&
      eventId === item?.eventId &&
      flowId === item?.flowId
    ) {
      return;
    }
    if (title === "") {
      return;
    }
    if (date === new Date()) {
      return;
    }
    if (endDate === new Date()) {
      return;
    }
    if (description === "") {
      return;
    }
    if (tags.length === 0) {
      return;
    }
    if (
      meetingLocationUrl === "" &&
      (getEventIdFromString(eventId) === undefined || getFlowIdFromString(flowId) === "")
    ) {
      return;
    }
    if (speakers.length === 0) {
      return;
    }
    setIsValid(true);
  }, [
    date,
    description,
    tags,
    meetingLocationUrl,
    speakers,
    title,
    thumbnail,
    endDate,
    eventId,
    flowId,
  ]);

  const createEvent = async () => {
    const request: CreateEventRequestDto = {
      date: date,
      endDate: endDate,
      description: description,
      hashtags: tags,
      isOnlineMeeting: isOnlineMeeting,
      meetingLocationUrl:
        meetingLocationUrl === "" ? "https://maps.google.com/?q=123+Main+St" : meetingLocationUrl,
      speakers: speakers.map((speaker) => speaker._id),
      sponsors: sponsors.map((sponsor) => sponsor._id),
      eventId: getEventIdFromString(eventId),
      flowId: flowId,
      title: title,
      picture: typeof thumbnail === "string" ? thumbnail : "",
    };
    if (id) {
      const { status } = await apiRequest(`events/${id}`, "PATCH", {
        body: request,
        showToast: true,
      });
      if (status === 200) {
        useHistoryNavigate("/admin-dashboard?tab=2");
      }
    } else {
      const { status } = await apiRequest("events", "POST", { body: request, showToast: true });
      if (status === 201) {
        useHistoryNavigate("/admin-dashboard?tab=2");
      }
    }
  };

  const changeImage = (e: any) => {
    setThumbnail(e.target.files[0]);
  };

  const onImageUploaded = (url: string) => {
    setThumbnail(url);
  };

  return (
    <>
      <Section name="admin-event-item-builder">
        <SectionElement name="admin-event-item-builder" type="body">
          <Component name="form-inputs">
            <Component name="form-row">
              <Component name="form-input">
                <ImageUploader
                  img={thumbnail}
                  type="large"
                  onImageUploaded={onImageUploaded}
                  onChange={changeImage}
                  route="events/eventPicture"
                  method="POST"
                />
              </Component>
            </Component>
            <Component name="form-row">
              <Component name="form-input">
                <TextInputElement
                  id="title"
                  label="Titel"
                  type="text"
                  labelClasses="p--l"
                  onInput={(e) => setTitle((e.target as HTMLInputElement).value)}
                  value={title}
                />
              </Component>
            </Component>
            <Component name="form-row">
              <Component name="form-input">
                <TextInputElement
                  id="description"
                  label="description"
                  type="textarea"
                  labelClasses="p--l"
                  onInput={(e) => setDescription((e.target as HTMLInputElement).value)}
                  value={description}
                />
              </Component>
            </Component>
            <Component name="form-row">
              <Component name="form-input">
                <DateTimeInputElement
                  id="date-start"
                  label="Start"
                  labelClasses="p--l"
                  onInput={(e) => setDate(new Date((e.target as HTMLInputElement).value))}
                  value={`${format(new Date(date), "yyy-MM-dd")}T${format(
                    new Date(date),
                    "HH:mm",
                  )}`}
                />
              </Component>
              <Component name="form-input">
                <DateTimeInputElement
                  id="date-end"
                  label="Ende"
                  labelClasses="p--l"
                  onInput={(e) => setEndDate(new Date((e.target as HTMLInputElement).value))}
                  value={`${format(new Date(endDate), "yyy-MM-dd")}T${format(
                    new Date(endDate),
                    "HH:mm",
                  )}`}
                />
              </Component>
            </Component>
            <Component name="form-row">
              <Component name="form-input">
                <Switch
                  id="location"
                  label={"Online"}
                  value={isOnlineMeeting ? 1 : -1}
                  customChange={() => setIsOnlineMeeting((value) => !value)}
                  type=""
                />
              </Component>
            </Component>
            <Component name="form-row">
              <Component name="form-input">
                {isOnlineMeeting ? (
                  <TextInputElement
                    id="location"
                    label={"Event"}
                    type="text"
                    labelClasses="p--l"
                    onInput={(e) => {
                      setEventId((e.target as HTMLInputElement).value);
                      setFlowId(getFlowIdFromString((e.target as HTMLInputElement).value) ?? "");
                    }}
                    value={eventId}
                  />
                ) : (
                  <TextInputElement
                    id="location"
                    label={isOnlineMeeting ? "Meeting Link" : "Google Maps Link"}
                    type="text"
                    labelClasses="p--l"
                    onInput={(e) => setMeetingLocationUrl((e.target as HTMLInputElement).value)}
                    value={meetingLocationUrl}
                  />
                )}
              </Component>
              <Component name="form-input">
                {isOnlineMeeting && (
                  <TextInputElement
                    id="flowId"
                    label="Flow Id - will be inserted automatically if it is a flow signup"
                    type="text"
                    labelClasses="p--l"
                    disabled={true}
                    value={flowId}
                  />
                )}
              </Component>
            </Component>
            <Component name="form-row">
              <Component name="form-input">
                <UserSearchField
                  setUser={(user) => {
                    setSpeakers(user);
                  }}
                  selected={speakers}
                  title="Speakers"
                />
              </Component>
            </Component>
            <Component name="form-row">
              <Component name="form-input">
                <HashtagSelect
                  onInput={(newValue) => {
                    setTags(newValue.map((value) => value.value));
                  }}
                  defaultValue={tags}
                />
              </Component>
            </Component>
            <Component name="form-row">
              <Component name="form-input">
                <SponsorsSelect
                  onInput={(newValue) => {
                    setSponsors(newValue);
                  }}
                  defaultValue={sponsors}
                />
              </Component>
            </Component>
            <Button type="primary" onClick={createEvent} disabled={!isValid}>
              {id ? "Speichern" : "Erstellen"}
            </Button>
          </Component>
        </SectionElement>
      </Section>
    </>
  );
};

export default AdminEvents;
