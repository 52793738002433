import { Main, Page } from "../components/Elements/Elements";
import Sidebar from "../components/Navigation/Sidebar";
import { Discussions as DiscussionComponent } from "../components/Discussion/Discussion";
import { useParams } from "react-router-dom";

const Discussion = () => {
  const { id } = useParams();
  return (
    <Page name="discussion">
      <Sidebar />
      <Main>
        <DiscussionComponent discussionId={id} />
      </Main>
    </Page>
  );
};

export default Discussion;
