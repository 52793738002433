import { Main, Page } from "../components/Elements/Elements";
import { Profile as ProfileComponent } from "../components/Profile/Profile";
import Sidebar from "../components/Navigation/Sidebar";

const Profile = () => {
  return (
    <Page name="profile">
      <Sidebar />
      <Main>
        <ProfileComponent />
      </Main>
    </Page>
  );
};

export default Profile;
