import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useState } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import Context from "../../context/Context";
import useRequest from "../../hooks/useRequest";
import { useUser } from "../../hooks/useUser";
import Academy from "../../pages/Academy";
import AdminDashboard from "../../pages/AdminDashboard";
import Banned from "../../pages/Banned";
import Discussion from "../../pages/Discussion";
import Events from "../../pages/Events";
import Home from "../../pages/Home";
import JobDashboard from "../../pages/JobDashboard";
import Journal from "../../pages/Journal";
import Learning from "../../pages/Learning";
import Loading from "../../pages/Loading";
import Messages from "../../pages/Messages";
import Networking from "../../pages/Networking";
import NotAllowed from "../../pages/NotAllowed";
import Notifications from "../../pages/Notifications";
import PageNotFound from "../../pages/PageNotFound";
import Profile from "../../pages/Profile";
import Redirect from "../../pages/Redirect";
import Register from "../../pages/Register";
import ResetPassword from "../../pages/ResetPassword";
import TeamDashboard from "../../pages/TeamDashboard";
import Test from "../../pages/Test";
import UserProfile from "../../pages/UserProfile";
import { blockedJobBoardUsers } from "../Navigation/Sidebar";
import ErrorComponent from "../error/ErrorComponent";

const Routes = () => {
  const [element, setElement] = useState(<Loading />);
  const { isAuthenticated, user, getAccessTokenSilently, isLoading, loginWithRedirect } =
    useAuth0();
  const [isProfileComplete, setProfileComplete] = useState(false);
  const loggedInUser = useUser();
  const { setAccessToken, userIsLoaded, isMobile, isBanned } = useContext(Context);
  const { apiRequest } = useRequest();

  useEffect(() => {
    if (isLoading) {
      setElement(<Loading />);
      return;
    }
    if (isBanned) {
      setElement(<Redirect location="/banned" />);
      return;
    }
    if (!userIsLoaded) {
      setElement(<Loading />);
      return;
    }
    if (location.pathname.includes("reset") && !isAuthenticated) {
      setElement(<ResetPassword />);
      return;
    }
    if (!isAuthenticated) {
      console.log("trying here");
      const login = async () => {
        await loginWithRedirect({
          appState: {
            returnTo: "/",
          },
        });
      };
      login();
      return;
    }
    if (!user?.email_verified) {
      setElement(<Register currentStep={0} />);
      return;
    }
    if (!loggedInUser.hasSetupDone) {
      setElement(<Redirect location="/register" />);
      return;
    }
    if (loggedInUser.isRecruiter && !location.pathname.includes("jobs")) {
      setElement(<Redirect location="/jobs" />);
      return;
    }
    setProfileComplete(true);
  }, [isAuthenticated, user, loggedInUser.hasSetupDone, userIsLoaded, isBanned]);

  useEffect(() => {
    const fetchAccessToken = async () => {
      if (setAccessToken) {
        setAccessToken(
          await getAccessTokenSilently({
            authorizationParams: { scope: "openid profile email offline_access" },
          }),
        );
      }
    };
    if (isAuthenticated) {
      fetchAccessToken();
    }
  }, [isAuthenticated, setAccessToken, getAccessTokenSilently]);

  const location = useLocation();

  useEffect(() => {
    const chat = document.getElementById("LeadboosterContainer");
    const root = document.getElementById("root");
    if (window.location.href.includes("admin")) {
      const parent = document.createElement("div");
      parent.id = "hidden-chat";
      parent.classList.add("hidden");
      root?.appendChild(parent);
      if (chat) {
        parent.appendChild(chat);
      }
    } else {
      const parent = document.getElementById("hidden-chat");
      if (parent && root && chat) {
        root.appendChild(chat);
        parent.remove();
      }
    }

    saveAcademyProgress(apiRequest);
  }, [location]);

  const getAdminPage = () => {
    if (isProfileComplete) {
      return loggedInUser.user?.role && loggedInUser.user?.role.toLowerCase() !== "member" ? (
        <AdminDashboard />
      ) : (
        <NotAllowed />
      );
    }
    return element;
  };

  const getJobPage = () => {
    if (isProfileComplete) {
      return loggedInUser.user?.role && !blockedJobBoardUsers.includes(loggedInUser.user?._id) ? (
        <JobDashboard isPublic={false} />
      ) : (
        <NotAllowed />
      );
    }
    return element;
  };

  const getRegisterPage = () => {
    if (isBanned) {
      return <Redirect location="/banned" />;
    }
    if (isLoading) {
      return <Loading />;
    }
    if (isAuthenticated) {
      if (!user?.email_verified) {
        return <Register currentStep={0} />;
      }
      if (!loggedInUser.hasSetupDone) {
        return <Register currentStep={1} />;
      }
      return <Redirect location="/" />;
    }
    return element;
  };

  const routes = useRoutes([
    // { path: "/login", element: <Login /> },
    {
      path: "/register",
      element: getRegisterPage(),
    },
    { path: "/", element: isProfileComplete ? <Home /> : element },
    { path: "/banned", element: isBanned ? <Banned /> : element },
    { path: "/profile", element: isProfileComplete ? <Profile /> : element },
    { path: "/discussion", element: isProfileComplete ? <Discussion /> : element },
    { path: "/discussion/:id", element: isProfileComplete ? <Discussion /> : element },
    { path: "/user/:id", element: isProfileComplete ? <UserProfile /> : element },
    { path: "/user", element: isProfileComplete ? <Redirect location="/" /> : element },
    { path: "/networking", element: isProfileComplete ? <Networking /> : element },
    { path: "/events", element: isProfileComplete ? <Events /> : element },
    { path: "/wissen", element: isProfileComplete ? <Learning /> : element },
    { path: "/resource/:id", element: isProfileComplete ? <Learning /> : element },
    { path: "/academy/discussion/:id", element: isProfileComplete ? <Academy /> : element },
    { path: "/academy", element: isProfileComplete ? <Academy /> : element },
    { path: "/academy/:academyId", element: isProfileComplete ? <Academy /> : element },
    {
      path: "/academy/:academyId/:trackId",
      element: isProfileComplete ? <Academy /> : element,
    },
    // { path: "/jobs", element: isProfileComplete ? <Career /> : element },
    { path: "/team-dashboard", element: isProfileComplete ? <TeamDashboard /> : element },
    { path: "/public/jobs", element: <JobDashboard isPublic={true} /> },
    { path: "/public/jobs/:id", element: <JobDashboard isPublic={true} /> },
    { path: "/jobs", element: getJobPage() },
    { path: "/jobs/create", element: getJobPage() },
    { path: "/jobs/:id/edit", element: getJobPage() },
    { path: "/jobs/:id", element: getJobPage() },
    { path: "/journal", element: isProfileComplete ? <Journal /> : element },
    { path: "/messages", element: isProfileComplete ? <Messages /> : element },
    { path: "/admin-dashboard", element: getAdminPage() },
    {
      path: "/notifications",
      element: isProfileComplete && isMobile ? <Notifications /> : element,
    },
    { path: "/test", element: isProfileComplete ? <Test /> : element },
    { path: "/reset", element: !isAuthenticated ? element : <Redirect location="/" /> },
    { path: "/error", element: isProfileComplete ? <ErrorComponent /> : element },
    { path: "/*", element: isProfileComplete ? <PageNotFound /> : element },
  ]);

  return routes;
};

export default Routes;

export const saveAcademyProgress = async (apiRequest: any) => {
  const academyItem = localStorage.getItem("currentAcademy");
  if (academyItem) {
    const parsed = JSON.parse(academyItem) as {
      trackId: string;
      currentTime: number;
      trackType: string;
    };
    if (parsed.currentTime > 10) {
      try {
        await apiRequest(`academy/progress/track/${parsed.trackId}/video`, "POST", {
          body: {
            videoProgress: parsed.currentTime,
            trackType: parsed.trackType,
          },
        });
        localStorage.removeItem("currentAcademy");
        return {
          videoProgress: parsed.currentTime,
          trackType: parsed.trackType,
        };
      } catch {
        // not authorized
      }
    }
  }
};
