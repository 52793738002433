import {
  Dispatch,
  forwardRef,
  HTMLAttributes,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Component, Paragraph, Tag, Tags, Wrapper, Icon, Title, LabelComponent } from "./Elements";
import { useConsts } from "../../hooks/useConsts";
import Select, { MultiValue, SingleValue } from "react-select";
import { Filter, FilterOptionProps } from "./Components";
import FileSVG from "../../Icons/FileSVG";
import useRequest from "../../hooks/useRequest";
import {
  Company,
  CredlyBadgeResponse,
  MediaResponseDto,
  Paginated,
  SkillResponseDto,
  StripeResponseDto,
} from "../../requests/interfaces";
import Context from "../../context/Context";
import PodcastSVG from "../../Icons/PodcastSVG";
import PlaySVG from "../../Icons/PlaySVG";

interface Props extends HTMLAttributes<HTMLElement> {
  label?: string;
  value?: string | number;
  placeHolder?: string;
  labelClasses?: string;
  errorMessage?: string;
  displayErrorMessage?: boolean;
  isHidden?: boolean;
  name?: string;
  isChecked?: boolean;
}

interface CheckProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  type: "" | "info" | "success" | "warning" | "error" | "disabled";
  isHidden?: boolean;
  customChange?: (checked?: boolean) => void;
}

interface SubmitProps {
  children: string;
}

interface TextInputProps extends Props {
  type: "text" | "password" | "mail" | "textarea" | "date";
  svg?: any;
  iconAfter?: boolean;
  disabled?: boolean;
}

interface DateInputProps extends Props {
  svg?: any;
  iconAfter?: boolean;
}

interface SliderProps extends Props {
  minValue: number;
  maxValue: number;
  showMinAndMaxValue: boolean;
  factor: number;
  step?: number;
  displayValue?: string;
  withPlus?: boolean;
}

interface SelectProps extends Props {
  options: string[] | { name: string; id: string }[];
  optionLabelClasses: string;
  name?: string;
  type?: "checkbox" | "radio";
}

const Label = ({ labelClasses, label, ...props }: Props) => {
  return (
    <Wrapper wrType="label" name={props.id}>
      <label className={`${labelClasses}`} htmlFor={props.id}>
        {label}
      </label>
    </Wrapper>
  );
};

const Slider = ({
  labelClasses,
  label,
  value,
  errorMessage,
  displayErrorMessage,
  minValue,
  maxValue,
  showMinAndMaxValue,
  factor,
  displayValue,
  withPlus,
  step,
  ...props
}: SliderProps) => {
  const inputField: any = useRef();

  const getDisplayValue = () => {
    return (value as unknown as number) * factor;
  };

  const formatNumber = (toFormat: number) => {
    const parts = toFormat.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return numberPart.replace(thousands, ".") + (decimalPart ? "," + decimalPart : "");
  };

  return (
    <>
      {label !== "" && <Label label={label} labelClasses={labelClasses} {...props} />}
      <Component name="slider">
        {showMinAndMaxValue && (
          <Paragraph name="maxValue">{formatNumber(minValue * factor)}</Paragraph>
        )}
        <Component name="slider-input" {...props}>
          <Wrapper wrType="input" name="slider" {...props}>
            <input
              ref={inputField}
              id={props.id}
              type="range"
              className="inp"
              onInput={props.onInput}
              value={value}
              onChange={props.onChange}
              min={minValue}
              max={maxValue}
              step={step}
            />
          </Wrapper>
          {!displayValue && (
            <Paragraph
              name="current-value"
              className={`${getDisplayValue() === -1 ? "hidden" : ""}`}>
              {formatNumber(getDisplayValue()) +
                (withPlus && getDisplayValue() === maxValue * factor ? "+" : "")}
            </Paragraph>
          )}
          {displayValue && <Paragraph name="current-value">{displayValue}</Paragraph>}
        </Component>
        {showMinAndMaxValue && (
          <Paragraph name="maxValue">
            {formatNumber(maxValue * factor) + (withPlus ? "+" : "")}
          </Paragraph>
        )}
      </Component>
      {errorMessage && (
        <ErrorMessage
          displayErrorMessage={displayErrorMessage}
          errorMessage={errorMessage}
          id={props.id ?? ""}
        />
      )}
    </>
  );
};

const DateInputElement = ({
  labelClasses,
  svg,
  label,
  placeHolder,
  errorMessage,
  displayErrorMessage,
  iconAfter,
  value,
  ...props
}: DateInputProps) => {
  return (
    <>
      {label !== "" && <Label label={label} labelClasses={labelClasses} {...props} />}
      <Component name="tf" {...props}>
        {svg && !iconAfter && <>{svg}</>}
        <Wrapper wrType="input" name="date" {...props}>
          <input
            id={props.id}
            type="date"
            className="inp"
            onInput={props.onInput}
            placeholder={placeHolder}
            onChange={props.onChange}
            value={value}
          />
        </Wrapper>
        {svg && iconAfter && <>{svg}</>}
      </Component>
      {errorMessage && (
        <ErrorMessage
          displayErrorMessage={displayErrorMessage}
          errorMessage={errorMessage}
          id={props.id ?? ""}
        />
      )}
    </>
  );
};

const DateTimeInputElement = ({
  labelClasses,
  svg,
  label,
  placeHolder,
  errorMessage,
  displayErrorMessage,
  iconAfter,
  value,
  ...props
}: DateInputProps) => {
  return (
    <>
      {label !== "" && <Label label={label} labelClasses={labelClasses} {...props} />}
      <Component name="tf" {...props}>
        {svg && !iconAfter && <>{svg}</>}
        <Wrapper wrType="input" name="date" {...props}>
          <input
            id={props.id}
            type="datetime-local"
            className="inp"
            onInput={(e) => {
              props.onInput ? props.onInput(e) : "asdf";
            }}
            placeholder={placeHolder}
            onChange={props.onChange}
            value={value}
          />
        </Wrapper>
        {svg && iconAfter && <>{svg}</>}
      </Component>
      {errorMessage && (
        <ErrorMessage
          displayErrorMessage={displayErrorMessage}
          errorMessage={errorMessage}
          id={props.id ?? ""}
        />
      )}
    </>
  );
};

const TextInputElement = ({
  type,
  labelClasses,
  svg,
  label,
  placeHolder,
  value,
  errorMessage,
  displayErrorMessage,
  iconAfter,
  onKeyUp,
  disabled,
  ...props
}: TextInputProps) => {
  const inputField: any = useRef();
  const [classes, setClasses] = useState("");
  const [disabledClass, setDisabledClass] = useState("");

  const action = () => {
    inputField.current?.focus();
    if (type === "date") {
      inputField.current?.showPicker();
    }
    setClasses("tf-focus");
  };

  const onBlur = (e: any) => {
    setClasses("");
    if (props.onBlur) props.onBlur(e);
  };

  useEffect(() => {
    setDisabledClass(disabled ? "tf-disabled" : "");
  }, [disabled]);

  return (
    <>
      {label !== "" && label !== undefined && (
        <Label label={label} labelClasses={labelClasses} {...props} />
      )}
      <Component
        name="tf"
        onClick={action}
        {...props}
        className={
          [classes, disabledClass, props.className].join(" ") + (displayErrorMessage ? "error" : "")
        }>
        {svg && !iconAfter && <>{svg}</>}
        <Wrapper wrType="input" name="tf" {...props}>
          {type !== "textarea" && type !== "date" && (
            <input
              ref={inputField}
              id={props.id}
              type={type}
              className="inp"
              onBlur={onBlur}
              onInput={props.onInput}
              value={value}
              placeholder={placeHolder}
              onChange={props.onChange}
              onKeyUp={onKeyUp}
              disabled={disabled}
            />
          )}
          {type === "textarea" && (
            <textarea
              ref={inputField}
              id={props.id}
              className="inp"
              onBlur={onBlur}
              onInput={props.onInput}
              value={value}
              placeholder={placeHolder}
              onChange={props.onChange}
              disabled={disabled}
            />
          )}
          {type === "date" && (
            <input
              ref={inputField}
              id={props.id}
              type={type}
              className="inp"
              onBlur={onBlur}
              onInput={props.onInput}
              value={value}
              placeholder={placeHolder}
              min="1920-01-01"
              max={new Date().toDateString()}
              onChange={props.onChange}
            />
          )}
        </Wrapper>
        {svg && iconAfter && <>{svg}</>}
      </Component>
      {errorMessage && (
        <ErrorMessage
          displayErrorMessage={displayErrorMessage}
          errorMessage={errorMessage}
          id={"tf"}
        />
      )}
    </>
  );
};

const NumberInputElement = ({
  labelClasses,
  label,
  placeHolder,
  value,
  errorMessage,
  displayErrorMessage,
  minValue,
  showMinAndMaxValue,
  maxValue,
  onKeyUp,
  ...props
}: SliderProps) => {
  const inputField: any = useRef();
  const [classes, setClasses] = useState("");

  const action = () => {
    inputField.current?.focus();
    setClasses("tf-focus");
  };

  const onBlur = (e: any) => {
    setClasses("");
    if (props.onBlur) props.onBlur(e);
  };
  return (
    <>
      {label !== "" && label !== undefined && (
        <Label label={label} labelClasses={labelClasses} {...props} />
      )}
      <Component
        name="tf"
        onClick={action}
        {...props}
        className={[classes, props.className].join(" ") + (displayErrorMessage ? "error" : "")}>
        <Wrapper wrType="input" name="tf" {...props}>
          <input
            ref={inputField}
            id={props.id}
            type="number"
            className="inp"
            onBlur={onBlur}
            onInput={props.onInput}
            value={value}
            placeholder={placeHolder}
            onChange={props.onChange}
            onKeyUp={onKeyUp}
          />
        </Wrapper>
      </Component>
      {errorMessage && (
        <ErrorMessage
          displayErrorMessage={displayErrorMessage}
          errorMessage={errorMessage}
          id={"tf"}
        />
      )}
    </>
  );
};

const ErrorMessage = ({ displayErrorMessage, errorMessage, id }: Props) => {
  return (
    <>
      {displayErrorMessage && (
        <Paragraph name={`${id}-error`} size="s" className="txt--c-error">
          {displayErrorMessage && errorMessage}
        </Paragraph>
      )}
    </>
  );
};

const HashtagSelect = (props: {
  onInput: (
    newValue: MultiValue<{
      value: string;
      label: string;
    }>,
  ) => void;
  defaultValue?: string[];
}) => {
  const { getHashtags } = useConsts();
  const [hashtagOptions, setHashtagOptions] = useState<{ value: string; label: string }[]>([]);
  const [values, setValues] = useState<MultiValue<{ value: string; label: string }>>([]);
  const [hashtags, setHashtags] = useState<string[]>();

  useEffect(() => {
    const options: { value: string; label: string }[] = [];
    hashtags?.map((hashtag) => {
      options.push({
        value: hashtag,
        label: hashtag,
      });
    });
    setHashtagOptions(options);
  }, [hashtags]);

  useEffect(() => {
    const loadHashtags = async () => {
      setHashtags((await getHashtags()).hashtags);
    };
    loadHashtags();
  }, []);

  useEffect(() => {
    const oldValues: { value: string; label: string }[] = [];
    props.defaultValue?.map((value) => {
      oldValues.push({
        value: value,
        label: value,
      });
    });
    setValues(oldValues);
  }, [props.defaultValue]);

  return (
    <>
      <Label label="Hashtags" labelClasses="p--l" id="hashtags" />
      <Select
        isMulti
        name="hashtags"
        id="hashtags"
        options={hashtagOptions}
        className="hashtags"
        classNamePrefix="select"
        onChange={(newValue) => {
          setValues(newValue);
          props.onInput(newValue);
        }}
        value={values}
      />
    </>
  );
};

const SkillSelect = (props: {
  onInput: (
    newValue: MultiValue<{
      value: string;
      label: string;
    }>,
  ) => void;
  defaultValue?: SkillResponseDto[];
}) => {
  const { getSkills } = useConsts();
  const [skillOptions, setSkillOptions] = useState<{ value: string; label: string }[]>([]);
  const [values, setValues] = useState<MultiValue<{ value: string; label: string }>>([]);
  const [skills, setSkills] = useState<SkillResponseDto[]>();

  useEffect(() => {
    const options: { value: string; label: string }[] = [];
    skills?.map((skill) => {
      options.push({
        value: skill._id,
        label: skill.skill,
      });
    });
    setSkillOptions(options);
  }, [skills]);

  useEffect(() => {
    const loadSkills = async () => {
      setSkills((await getSkills()).skills);
    };
    loadSkills();
  }, []);

  useEffect(() => {
    const oldValues: { value: string; label: string }[] = [];
    props.defaultValue?.map((value) => {
      oldValues.push({
        value: value._id,
        label: value.skill,
      });
    });
    setValues(oldValues);
  }, [props.defaultValue]);

  return (
    <>
      <Label label="Skills" labelClasses="p--l" id="skills" />
      <Select
        isMulti
        name="skills"
        id="skills"
        options={skillOptions}
        className="skills"
        classNamePrefix="select"
        onChange={(newValue) => {
          setValues(newValue);
          props.onInput(newValue);
        }}
        value={values}
      />
    </>
  );
};

const AcadmeyFilesSelect = (props: {
  route: string;
  onUploaded: (url: string) => void;
  method: "PATCH" | "POST" | "PUT";
  status: string;
  defaultValue?: string;
  trackType?: "content" | "exercise" | "discussion";
  title?: string;
}) => {
  const { getFiles } = useConsts();
  const [fileOptions, setFileOptions] = useState<{ value: string; label: string }[]>([]);
  const [values, setValues] = useState<SingleValue<{ value: string; label: string }>>();
  const [files, setFiles] = useState<string[]>();
  const { apiRequest } = useRequest();

  const uploadFileToAcademy = async (newValue: SingleValue<{ value: string; label: string }>) => {
    const { data } = await apiRequest<MediaResponseDto>(props.route, props.method, {
      body: {
        filename: newValue?.label,
        trackType: props.trackType,
      },
      showToast: true,
    });
    if (props.onUploaded) {
      props.onUploaded(data?.url);
    }
  };

  useEffect(() => {
    const options: { value: string; label: string }[] = [];
    files?.map((file) => {
      options.push({
        value: file,
        label: file,
      });
    });
    setFileOptions(options);
  }, [files]);

  useEffect(() => {
    const loadFiles = async () => {
      setFiles((await getFiles()).files.map((value) => value.filename));
    };
    loadFiles();
  }, []);

  useEffect(() => {
    if (props.defaultValue) {
      setValues({ label: props.defaultValue, value: props.defaultValue });
    }
  }, [props.defaultValue]);

  return (
    <>
      <Label
        label={`${props.title ?? "Academy Files"} ${props.status}`}
        labelClasses="p--l"
        id="academy-files"
      />
      <Select
        name="academy-files"
        id="academy-files"
        options={fileOptions}
        className="academy-files"
        classNamePrefix="select"
        onChange={(newValue) => {
          setValues(newValue);
          uploadFileToAcademy(newValue);
        }}
        value={values}
      />
    </>
  );
};

const TopicSelect = (props: {
  onInput: (
    newValue: SingleValue<{
      value: string;
      label: string;
    }>,
  ) => void;
  defaultValue?: string;
}) => {
  const { getQandATopics } = useConsts();
  const [topicOptions, setTopicOptions] = useState<{ value: string; label: string }[]>([]);
  const [values, setValues] = useState<SingleValue<{ value: string; label: string }>>();
  const [topics, setTopics] = useState<string[]>();

  useEffect(() => {
    const options: { value: string; label: string }[] = [];
    topics?.map((topic) => {
      options.push({
        value: topic,
        label: topic,
      });
    });
    setTopicOptions(options);
  }, [topics]);

  useEffect(() => {
    const loadTopics = async () => {
      setTopics((await getQandATopics()).topics);
    };
    loadTopics();
  }, []);

  useEffect(() => {
    if (props.defaultValue) {
      setValues({ label: props.defaultValue, value: props.defaultValue });
    }
  }, [props.defaultValue]);

  return (
    <>
      <Label label="Topics" labelClasses="p--l" id="topics" />
      <Select
        name="topics"
        id="topics"
        options={topicOptions}
        className="topics"
        classNamePrefix="select"
        onChange={(newValue) => {
          setValues(newValue);
          props.onInput(newValue);
        }}
        value={values}
      />
    </>
  );
};

const StripeSelect = (props: {
  onInput: (
    newValue: SingleValue<{
      value: string;
      label: string;
    }>,
  ) => void;
  defaultValue?: string;
}) => {
  const { getStripeProducts } = useConsts();
  const [produtOptions, setProductOptions] = useState<{ value: string; label: string }[]>([]);
  const [values, setValues] = useState<SingleValue<{ value: string; label: string }>>();
  const [prodcuts, setProducts] = useState<StripeResponseDto[]>();

  useEffect(() => {
    const options: { value: string; label: string }[] = [];
    prodcuts?.map((product) => {
      options.push({
        value: `${product.id};${product.url}`,
        label: product.name,
      });
    });
    setProductOptions(options);
  }, [prodcuts]);

  useEffect(() => {
    const loadProducts = async () => {
      setProducts((await getStripeProducts()).products);
    };
    loadProducts();
  }, []);

  useEffect(() => {
    if (props.defaultValue && prodcuts) {
      const product = prodcuts.find((product) => product.id === props.defaultValue);
      if (product) {
        setValues({ label: product.name, value: `${product.id};${product.url}` });
      }
    }
  }, [props.defaultValue, prodcuts]);

  return (
    <>
      <Label label="Stripe Product" labelClasses="p--l" id="topics" />
      <Select
        name="products"
        id="products"
        options={produtOptions}
        className="products"
        classNamePrefix="select"
        onChange={(newValue) => {
          setValues(newValue);
          props.onInput(newValue);
        }}
        value={values}
      />
    </>
  );
};

const CredlyBadgeSelect = (props: {
  onInput: (
    newValue: SingleValue<{
      value: string;
      label: string;
    }>,
  ) => void;
  defaultValue?: string;
}) => {
  const { getCredlyBadges, getCredlyBadgeById } = useConsts();
  const [badgeOptions, setBadgeOptions] = useState<{ value: string; label: string }[]>([]);
  const [values, setValues] = useState<SingleValue<{ value: string; label: string }>>();
  const [badges, setBadges] = useState<CredlyBadgeResponse[]>();

  useEffect(() => {
    const options: { value: string; label: string }[] = [];
    badges?.map((badge) => {
      options.push({
        value: badge.id,
        label: badge.name,
      });
    });
    setBadgeOptions(options);
  }, [badges]);

  useEffect(() => {
    const loadBadges = async () => {
      setBadges((await getCredlyBadges()).badges);
    };
    loadBadges();
  }, []);

  useEffect(() => {
    const getDefaultItem = async () => {
      if (props.defaultValue) {
        const item = await getCredlyBadgeById(props.defaultValue);
        setValues({ label: item.badge.name, value: item.badge.id });
      }
    };

    getDefaultItem();
  }, [props.defaultValue]);

  return (
    <>
      <Label label="Badges" labelClasses="p--l" id="badges" />
      <Select
        name="badges"
        id="badges"
        options={badgeOptions}
        className="badges"
        classNamePrefix="select"
        onChange={(newValue) => {
          setValues(newValue);
          props.onInput(newValue);
        }}
        value={values}
      />
    </>
  );
};

const ResourceTypeSelect = (props: {
  onInput: (
    newValue: SingleValue<{
      value: string;
      label: string;
    }>,
  ) => void;
  defaultValue?: string;
}) => {
  const { getResourcesTypes } = useConsts();
  const [resourceTypeOptions, setResourceTypeOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [values, setValues] = useState<SingleValue<{ value: string; label: string }>>();
  const [resourceTypes, setResourceTypes] = useState<string[]>();

  useEffect(() => {
    const options: { value: string; label: string }[] = [];
    resourceTypes?.map((resourceType) => {
      options.push({
        value: resourceType,
        label: resourceType,
      });
    });
    setResourceTypeOptions(options);
  }, [resourceTypes]);

  useEffect(() => {
    const loadResourceTypes = async () => {
      setResourceTypes((await getResourcesTypes()).resourceTypes);
    };
    loadResourceTypes();
  }, []);

  useEffect(() => {
    if (props.defaultValue) {
      setValues({ label: props.defaultValue, value: props.defaultValue });
    }
  }, [props.defaultValue]);

  return (
    <>
      <Label label="Typ" labelClasses="p--l" id="resourceTypes" />
      <Select
        name="resourceTypes"
        id="resourceTypes"
        options={resourceTypeOptions}
        className="resourceTypes"
        classNamePrefix="select"
        onChange={(newValue) => {
          setValues(newValue);
          props.onInput(newValue);
        }}
        value={values}
      />
    </>
  );
};

const SponsorsSelect = (props: {
  onInput: (newValue: Company[]) => void;
  defaultValue?: Company[];
}) => {
  const [companyOptions, setCompanyOptions] = useState<{ value: string; label: string }[]>([]);
  const [values, setValues] = useState<MultiValue<{ value: string; label: string }>>([]);
  const [sponsors, setSponsors] = useState<Company[]>();
  const { apiRequest } = useRequest();

  useEffect(() => {
    const options: { value: string; label: string }[] = [];
    sponsors?.map((sponsor) => {
      options.push({
        value: sponsor._id,
        label: sponsor.name,
      });
    });
    setCompanyOptions(options);
  }, [sponsors]);

  useEffect(() => {
    const loadCompanies = async () => {
      setSponsors((await apiRequest<Paginated<Company>>("companies", "GET")).data.docs);
    };
    loadCompanies();
  }, []);

  useEffect(() => {
    const oldValues: { value: string; label: string }[] = [];
    props.defaultValue?.map((value) => {
      oldValues.push({
        value: value._id,
        label: value.name,
      });
    });
    setValues(oldValues);
  }, [props.defaultValue]);

  return (
    <>
      <Label label="Sponsoren" labelClasses="p--l" id="sponsors" />
      <Select
        isMulti
        name="sponsors"
        id="sponsors"
        options={companyOptions}
        className="sponsors"
        classNamePrefix="select"
        onChange={(newValue) => {
          setValues(newValue);
          const allValues: Company[] = newValue.map((value) => {
            const res = sponsors?.find((sponsor) => {
              return sponsor._id === value.value;
            });
            if (res) {
              return res;
            } else {
              return {
                _id: "",
                homePage: "",
                name: "",
                picture: "",
              };
            }
          });
          props.onInput(allValues);
        }}
        value={values}
      />
    </>
  );
};

const UserRolesSelect = (props: {
  onInput: (
    newValue: SingleValue<{
      value: string;
      label: string;
    }>,
  ) => void;
  defaultValue?: string;
  showLabel?: boolean;
}) => {
  const { getUserRoles } = useConsts();
  const [userRolesOptions, setUserRolesOptions] = useState<{ value: string; label: string }[]>([]);
  const [values, setValues] = useState<SingleValue<{ value: string; label: string }>>();
  const [userRoles, setUserRoles] = useState<string[]>();

  useEffect(() => {
    const options: { value: string; label: string }[] = [];
    userRoles?.map((topic) => {
      options.push({
        value: topic,
        label: topic,
      });
    });
    setUserRolesOptions(options);
  }, [userRoles]);

  useEffect(() => {
    const loadUserRoles = async () => {
      setUserRoles((await getUserRoles()).userRoles);
    };
    loadUserRoles();
  }, []);

  useEffect(() => {
    if (props.defaultValue) {
      setValues({ label: props.defaultValue, value: props.defaultValue });
    }
  }, [props.defaultValue]);

  return (
    <>
      {props.showLabel && <Label label="User Rollen" labelClasses="p--l" id="user-roles" />}
      <Select
        name="user-roles"
        id="user-roles"
        options={userRolesOptions}
        className="user-roles"
        classNamePrefix="select"
        onChange={(newValue) => {
          setValues(newValue);
          props.onInput(newValue);
        }}
        value={values}
      />
    </>
  );
};

const HashtagsFilter = (props: {
  onFilterChange: Dispatch<SetStateAction<string[]>>;
  otherFilterOptions?: FilterOptionProps[];
  type?: "small" | "medium" | "large";
  showLabel?: boolean;
}) => {
  const [hashtags, setHashtags] = useState<string[]>();
  const [filterOptions, setFilterOptions] = useState<FilterOptionProps[]>([]);
  const { getHashtags } = useConsts();
  const { isMobile } = useContext(Context);

  useEffect(() => {
    const loadHashtags = async () => {
      setHashtags((await getHashtags()).hashtags);
    };
    loadHashtags();
  }, []);

  useEffect(() => {
    let prevFilterOptions: FilterOptionProps[] = [];
    if (hashtags?.length !== 0) {
      if (props.otherFilterOptions) {
        prevFilterOptions = prevFilterOptions.concat(props.otherFilterOptions);
      }
      hashtags?.map((hashtag) => {
        prevFilterOptions.push({
          id: hashtag,
          label: hashtag,
          name: "hashtags[]",
          type: "checkbox",
        });
      });
      setFilterOptions(prevFilterOptions);
    }
  }, [hashtags]);

  let labelHidden;
  if (props.showLabel !== undefined) {
    labelHidden = { labelHidden: !props.showLabel ?? true };
  }

  return (
    <Filter
      filterOptions={filterOptions}
      filterSize={isMobile ? "large" : props.type ?? "small"}
      filterName={"Filter"}
      onFilterChange={props.onFilterChange}
      filterIsCollapsible={props.type !== "small"}
      isDefaultOpen={isMobile ? false : true}
      {...labelHidden}
    />
  );
};

const SkillsFilter = (props: {
  onFilterChange: Dispatch<SetStateAction<string[]>>;
  otherFilterOptions?: FilterOptionProps[];
  type?: "small" | "medium" | "large";
  showLabel?: boolean;
}) => {
  const [skills, setSkills] = useState<SkillResponseDto[]>();
  const [filterOptions, setFilterOptions] = useState<FilterOptionProps[]>([]);
  const { getSkills } = useConsts();
  const { isMobile } = useContext(Context);

  useEffect(() => {
    const loadSkills = async () => {
      setSkills((await getSkills()).skills);
    };
    loadSkills();
  }, []);

  useEffect(() => {
    let prevFilterOptions: FilterOptionProps[] = [];
    if (props.otherFilterOptions) {
      prevFilterOptions = prevFilterOptions.concat(props.otherFilterOptions);
    }
    skills?.map((skill) => {
      prevFilterOptions.push({
        id: skill._id,
        label: skill.skill,
        name: "skills[]",
        type: "checkbox",
      });
    });
    setFilterOptions(prevFilterOptions);
  }, [skills]);

  let labelHidden;
  if (props.showLabel !== undefined) {
    labelHidden = { labelHidden: !props.showLabel ?? true };
  }

  return (
    <Filter
      filterOptions={filterOptions}
      filterSize={isMobile ? "large" : props.type ?? "small"}
      isDefaultOpen={isMobile ? false : true}
      onFilterChange={props.onFilterChange}
      filterIsCollapsible={props.type !== "small"}
      filterName={"Filter"}
      {...labelHidden}
    />
  );
};

const SelectInput = ({
  optionLabelClasses,
  options,
  label,
  labelClasses,
  errorMessage,
  displayErrorMessage,
  ...props
}: SelectProps) => {
  const select = useRef<any>(null);
  let toAdd = "";

  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf("safari") != -1) {
    if (userAgent.indexOf("chrome") > -1) {
      //browser is chrome
    } else if (userAgent.indexOf("opera") > -1 || userAgent.indexOf("opr") > -1) {
      //browser is opera
    } else {
      toAdd = "safari-sl-height";
    }
  }

  return (
    <>
      <Label label={label} labelClasses={labelClasses} />
      <Component name="sl" className={`${displayErrorMessage ? "error" : ""} ${toAdd}`}>
        <Wrapper wrType="input" name="sl">
          <select name="sl" id="cars" ref={select} {...props}>
            {options.map((option) => {
              let optionId;
              let optionLabel;
              if (typeof option !== "string") {
                optionLabel = option.name;
                optionId = option.id;
              } else {
                optionId = option.replaceAll(" ", "_").replaceAll("-", "_").toLowerCase();
                optionLabel = option;
              }
              return (
                <option value={optionId} key={optionId} className={optionLabelClasses}>
                  {optionLabel.charAt(0).toUpperCase() + optionLabel.slice(1)}
                </option>
              );
            })}
          </select>
        </Wrapper>
      </Component>
      {errorMessage && (
        <ErrorMessage
          displayErrorMessage={displayErrorMessage}
          errorMessage={errorMessage}
          id={"tf"}
        />
      )}
    </>
  );
};

const RadioButton = forwardRef(({ isChecked, name, isHidden, ...props }: Props, ref: any) => {
  return (
    <Wrapper name={props.id} wrType="input">
      <input
        checked={isChecked}
        {...props}
        type="radio"
        name={name}
        className={`${isHidden ? "hidden" : "rb"}`}
        ref={ref}
      />
    </Wrapper>
  );
});
RadioButton.displayName = "RadioButton";

const Switch = (props: CheckProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(
    (props.checked || props.defaultChecked) ?? false,
  );

  useEffect(() => {
    setIsChecked(props.value === 1 ?? false);
  }, [props.value]);

  return (
    <>
      <LabelComponent
        name="sw"
        className={`${isChecked ? "checked" : ""} ${props.type}`}
        onClick={(e) => {
          if (props.onClick) {
            props.onClick(e as React.MouseEvent<HTMLInputElement, MouseEvent>);
          }
          setIsChecked((value) => !value);
        }}>
        <div className="sw" />
        <input
          {...props}
          type="checkbox"
          checked={isChecked}
          onChange={(e) => {
            setIsChecked((value) => !value);
            if (props.customChange) {
              props.customChange(isChecked);
            }
          }}
        />
        <span className="lbl--sw">{props.label}</span>
      </LabelComponent>
    </>
  );
};

const TagSelect = ({
  optionLabelClasses,
  isHidden,
  options,
  label,
  labelClasses,
  type,
  errorMessage,
  displayErrorMessage,
  isChecked: reset,
  ...props
}: SelectProps) => {
  const labelProps = { ...props };
  const [selected, setSelected] = useState<string>();

  return (
    <>
      <Label labelClasses={labelClasses} label={label} />
      <Tags>
        {options.map((option) => {
          if (typeof option !== "string") {
            label = option.name;
            labelProps.id = option.id;
          } else {
            label = option;
            labelProps.id = option.replaceAll(" ", "_").replaceAll("-", "_").toLowerCase();
          }
          const switcher = useRef<any>(null);
          const [isChecked, setIsChecked] = useState<boolean>(reset ?? labelProps.id === selected);
          if (props.value && !isChecked) {
            if (type === "checkbox" && typeof props.value === "string") {
              if (props.value.split(",").includes(labelProps.id)) {
                setIsChecked(true);
              }
            } else if (props.value === labelProps.id) {
              setIsChecked(true);
            }
          }
          useEffect(() => {
            if (reset) {
              setSelected("");
            } else {
              setIsChecked(switcher.current.id === selected);
            }
          }, [selected, reset]);
          return (
            <Tag
              key={label}
              onClick={(e) => {
                switcher.current?.click();
                e.stopPropagation();
              }}
              isActive={isChecked}>
              {type === "checkbox" && (
                <CheckBox
                  ref={switcher}
                  isHidden={isHidden}
                  checked={isChecked}
                  onChange={labelProps.onChange}
                  onClick={() => setIsChecked((value) => !value)}
                  label=""
                  type=""
                  {...labelProps}
                />
              )}
              {type === "radio" && (
                <RadioButton
                  {...labelProps}
                  ref={switcher}
                  isHidden={isHidden}
                  isChecked={isChecked}
                  onChange={labelProps.onChange}
                  onClick={() => {
                    setSelected(switcher.current.id);
                  }}
                />
              )}
              <Paragraph name="tag">{label}</Paragraph>
            </Tag>
          );
        })}
      </Tags>
      {errorMessage && (
        <ErrorMessage
          displayErrorMessage={displayErrorMessage}
          errorMessage={errorMessage}
          id={"tf"}
        />
      )}
    </>
  );
};

const SubmitButton = ({ children, ...props }: SubmitProps) => {
  return (
    <Wrapper name="submit-button" wrType="button">
      <button className="btn--submit btn" {...props}>
        {children}
      </button>
    </Wrapper>
  );
};

const ContentTypeTag = (props: { contentType: string; isActive: boolean }) => {
  let svg = undefined;
  let label = "";
  if (props.contentType.toLowerCase() === "Playbook") {
    label = "Playbook";
    svg = <FileSVG />;
  } else if (props.contentType === "Aufzeichnung") {
    label = "Aufzeichnung";
    svg = <PlaySVG />;
  } else if (props.contentType === "Podcast") {
    label = "Podcast";
    svg = <PodcastSVG />;
  } else {
    label = "Content";
    svg = <FileSVG />;
  }

  return (
    <Component name="tag-file" className="unclickable">
      <Icon svg={svg} name="tag-file" />
      <Title name="tag-file" type="paragraph" size="xxs">
        {label}
      </Title>
    </Component>
  );
};

const CheckBox = forwardRef(({ label, type, isHidden, ...restProps }: CheckProps, ref: any) => {
  // <Wrapper name={props.id} wrType="input">
  //   <input
  //     type="checkbox"
  //     onChange={props.onChange}
  //     checked={isChecked}
  //     {...props}
  //     className={`${isHidden ? "hidden" : "cb"}`}
  //     ref={ref}
  //   />
  // </Wrapper>
  return (
    <>
      <LabelComponent
        name="cb"
        className={`${restProps.checked || restProps.defaultChecked ? "checked" : ""} ${type}`}>
        <div className={`${isHidden ? "hidden" : "cb"}`} />
        <input
          type="checkbox"
          defaultChecked={restProps.defaultChecked}
          {...restProps}
          className={`${isHidden ? "hidden" : "cb"}`}
          ref={ref}
        />
        <span className="lbl--cb">{label}</span>
      </LabelComponent>
    </>
  );
});

CheckBox.displayName = "CheckBox";

const SingleCheckbox = ({ isChecked, labelClasses, ...props }: Props) => {
  const [stateIsChecked, setIsChecked] = useState<boolean>(
    isChecked === undefined ? false : isChecked,
  );

  useEffect(() => {
    setIsChecked(isChecked === undefined ? false : isChecked);
  }, [isChecked]);

  return (
    <Component name="cb">
      <CheckBox
        type=""
        checked={stateIsChecked}
        onClick={() => setIsChecked((value) => !value)}
        label={props.label ?? ""}
        {...props}
      />
    </Component>
  );
};

/*const CheckboxGroup = ({ isChecked, labelClasses, ...props }: Props) => {
  return <></>;
};

const RadiobuttonGroup = ({ isChecked, labelClasses, ...props }: Props) => {
  return <></>;
};

const SwitchGroup = ({ isChecked, labelClasses, ...props }: Props) => {
  return <></>;
};*/

export {
  TextInputElement,
  SubmitButton,
  SingleCheckbox,
  SelectInput,
  TagSelect,
  Switch,
  ErrorMessage,
  Slider,
  DateInputElement,
  HashtagSelect,
  HashtagsFilter,
  TopicSelect,
  ResourceTypeSelect,
  ContentTypeTag,
  SponsorsSelect,
  UserRolesSelect,
  DateTimeInputElement,
  SkillsFilter,
  AcadmeyFilesSelect,
  SkillSelect,
  CredlyBadgeSelect,
  StripeSelect,
  NumberInputElement,
};
