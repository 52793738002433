import { useAuth0 } from "@auth0/auth0-react";
import { Button, ComponentCard, Paragraph, Title } from "../Elements/Elements";
import "./banned.scss";

const Banned = () => {
  const onBannedButtonClick = () => {
    window.open("https://calendly.com/d/gr7-h25-cr4/meet-sdrs-of-germany", "_blank");
  };

  const { logout } = useAuth0();

  const signOutAction = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <ComponentCard name="banned">
      <Title name="banned" size="m" type="h1" className="txt--c-surface">
        <span>Hi 🙋🏻‍♀️</span>
      </Title>
      <Paragraph name="banned" size="m">
        <span className="lb">
          Wir freuen uns über dein Interesse an der größten deutschen Sales Community 😊
        </span>
        <span>
          Leider müssen wir dir mitteilen, dass die SDRs of Germany Community ausschliesslich für
          aktive Seller gedacht ist und aktives Recruiting innerhalb der Community Plattform nicht
          erlaubt ist. Wir hoffen, du kannst das verstehen!
        </span>
        <span>
          Aber natürlich wären wir nicht SDRs of Germany, wenn wir dich mit deinen Recruiting-Needs
          alleine lassen würden 😉
        </span>
        <span>
          Wir helfen in unterschiedlichsten Weisen Unternehmen dabei ambitionierte und wissbegierige
          Sales Talent in DACH zu finden - von Employer Branding Events bis hin zu active Sourcing.
        </span>
        <span>Lust mal mit uns darüber zu sprechen? 😋</span>
      </Paragraph>
      <Button name="banned" type="primary" onClick={onBannedButtonClick}>
        Jetzt Termin Buchen
      </Button>
      {process.env.REACT_APP_NODE_ENV === "development" && (
        <Button type="danger" name="logout" onClick={signOutAction}>
          Ausloggen
        </Button>
      )}
    </ComponentCard>
  );
};

export { Banned };
