import { useState } from "react";
import {
  Section,
  SectionElement,
  ComponentCard,
  Component,
  Paragraph,
  Title,
  Button,
} from "../Elements/Elements";
import useRequest from "../../hooks/useRequest";
import { TextInputElement } from "../Elements/FormElements";
import historyNavigate from "../../hooks/useHistoryNavigate";
import { Loading } from "../Loading/Loading";

const ResetPassword = () => {
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { apiRequest } = useRequest();
  const { useHistoryNavigate } = historyNavigate();

  const resetPassword = async () => {
    const { status } = await apiRequest<any>("user/requestPasswordChangeByMail", "PATCH", {
      body: {
        email: email,
      },
      showToast: true,
    });
    if (status === 200) {
      setIsLoading(true);
      setTimeout(() => {
        useHistoryNavigate("/login");
      }, 2500);
    }
  };

  return (
    <>
      <Section name="register">
        <SectionElement name="register" type="body">
          <ComponentCard name="form" type="outline">
            <Component name="form-info">
              <Title name="form-info" className="txt--c-on-surface" type="h1" size="xs">
                Passwort zurücksetzen
              </Title>
              <Paragraph name="form-info" className="txt--c-on-surface-l txt--l">
                Um dein Passwort zurückzusetzen benötigen wir deine Email Adresse
              </Paragraph>
            </Component>
            <TextInputElement
              id="email"
              label="Email Adresse"
              labelClasses="p--l"
              type="text"
              onChange={(e) => setEmail((e.target as HTMLInputElement).value)}
              value={email}
            />
            {!isLoading && (
              <Button
                onClick={resetPassword}
                name="resend-mail"
                type="primary"
                disabled={
                  !(email !== "" && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))
                }>
                Password zurücksetzen
              </Button>
            )}
            {isLoading && <Loading />}
          </ComponentCard>
        </SectionElement>
      </Section>
    </>
  );
};

export { ResetPassword };
