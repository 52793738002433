import React, { ErrorInfo, ReactNode, useEffect, useState } from "react";
import historyNavigate from "../../hooks/useHistoryNavigate";

interface ErrorBoundaryProps {
  children: ReactNode;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const { useHistoryNavigate } = historyNavigate();

  useEffect(() => {
    if (hasError) {
      useHistoryNavigate("/error");
    }
  }, [hasError]);

  const componentDidCatch = (error: Error, errorInfo: ErrorInfo): void => {
    console.error("ErrorBoundary caught an error", error, errorInfo);
    setHasError(true);
  };

  if (hasError) {
    return <></>;
  }

  return <ErrorBoundaryWrapper onError={componentDidCatch}>{children}</ErrorBoundaryWrapper>;
};

interface ErrorBoundaryWrapperProps {
  onError: (error: Error, errorInfo: ErrorInfo) => void;
  children: ReactNode;
}

class ErrorBoundaryWrapper extends React.Component<ErrorBoundaryWrapperProps> {
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.props.onError(error, errorInfo);
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
