import { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Context from "../../context/Context";
import historyNavigate from "../../hooks/useHistoryNavigate";
import useRequest from "../../hooks/useRequest";
import {
  CreateTeamRequestDto,
  Paginated,
  TeamResponseDto,
  UsersResponseDto,
} from "../../requests/interfaces";
import { ConfirmationDisplay, UserSearchField } from "../Elements/Components";
import {
  Button,
  Component,
  Paragraph,
  ProfileBubble,
  Section,
  SectionElement,
  Table,
  TableColumn,
  TablePagination,
  Title,
  UserSearchPopup,
} from "../Elements/Elements";
import { NumberInputElement } from "../Elements/FormElements";

const AdminTeamContext = createContext<{
  setConfirmationPopup?: (value: boolean) => void;
  setUserToHandle?: (user: UsersResponseDto) => void;
  team?: TeamResponseDto;
}>({});

const AdminTeamdashboard = () => {
  const [mode, setMode] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const modeId = searchParams.get("mode");
  const [modes, setModes] = useState<any[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<TeamResponseDto>();

  const setupModes = () => {
    const allModes = [
      {
        modeId: "LIST",
        component: (
          <AdminTeamdashboardList
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            setSelectedTeam={setSelectedTeam}
          />
        ),
        active:
          modeId === null || (modeId !== "create" && modeId !== "edit" && modeId !== "details"),
      },
      {
        modeId: "CREATE",
        component: <AdminTeamdashboardItem />,
        active: modeId === "create",
      },
      {
        modeId: "DETAILS",
        component: <AdminTeamdashboardDetails team={selectedTeam} />,
        active: modeId === "details",
      },
      {
        modeId: "EDIT",
        component: <AdminTeamdashboardItem />,
        active: modeId === "edit",
      },
    ];
    setModes(allModes);
  };

  useEffect(() => {
    setMode(modes.find((tab: any) => tab.active === true));
  }, [modes]);

  useEffect(() => {
    setupModes();
  }, [modeId]);

  useEffect(() => {
    setupModes();
  }, []);

  return <>{mode && mode.component}</>;
};

const AdminTeamdashboardList = (props: {
  searchParams: any;
  setSearchParams: any;
  setSelectedTeam: (team: TeamResponseDto) => void;
}) => {
  const [teams, setTeams] = useState<TeamResponseDto[]>([]);
  const [hasNext, setHasNext] = useState<boolean>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { apiRequest } = useRequest();

  const getTeams = async () => {
    const { data } = await apiRequest<Paginated<TeamResponseDto>>(
      `teams/admin?limit=10&page=${currentPage}`,
      "GET",
    );
    setTeams(data.docs);
    setHasNext(data.hasNextPage);
  };

  useEffect(() => {
    getTeams();
  }, []);

  useEffect(() => {
    getTeams();
  }, [currentPage]);

  const create = () => {
    props.searchParams.set("mode", "create");
    props.setSearchParams(props.searchParams);
  };

  const details = (team: TeamResponseDto) => {
    props.searchParams.set("mode", "details");
    props.setSelectedTeam(team);
    props.setSearchParams(props.searchParams);
  };

  return (
    <>
      <Section name="admin-teams">
        <SectionElement type="header" name="admin-teams">
          <Title name="admin-teams-title" type="h1" size="m">
            Teams
          </Title>
          <Button type="primary" name="create-new-skill" onClick={() => create()}>
            + Neu
          </Button>
        </SectionElement>
        <SectionElement type="body" name="admin-teams">
          <Component name="table-background">
            <Table name="admin-teams">
              <thead>
                <tr className="h--xxs">
                  <th>
                    <Paragraph name="admin-teams-leader" type="paragraph">
                      Team leader
                    </Paragraph>
                  </th>
                  <th>
                    <Paragraph name="admin-teams-seats" type="paragraph">
                      seats
                    </Paragraph>
                  </th>
                  <th>
                    <Paragraph name="admin-teams-assigned-seats" type="paragraph">
                      Assigned seats
                    </Paragraph>
                  </th>
                  <th>
                    <Paragraph name="admin-teams-active-since" type="paragraph">
                      Active since
                    </Paragraph>
                  </th>
                </tr>
              </thead>
              <tbody>
                {teams?.map((user, i) => {
                  return <TeamListItem team={user} key={i} onClick={details} />;
                })}
              </tbody>
            </Table>
            {hasNext !== undefined && (
              <TablePagination
                currentPage={currentPage}
                hasNext={hasNext}
                setCurrentPage={setCurrentPage}
              />
            )}
          </Component>
        </SectionElement>
      </Section>
    </>
  );
};

const TeamListItem = (props: {
  team: TeamResponseDto;
  onClick: (team: TeamResponseDto) => void;
}) => {
  const team = props.team;

  return (
    <tr onClick={() => props.onClick(team)}>
      <TableColumn name="admin-col">
        <ProfileBubble user={team.leader} />
      </TableColumn>
      <TableColumn name="admin-col">
        <Component name="admin-team-seats">
          <Paragraph type="paragraph" name="admin-team-seats">
            {team.seats}
          </Paragraph>
        </Component>
      </TableColumn>
      <TableColumn>
        <Component name="admin-team-assigned-seats">
          <Paragraph type="paragraph" name="admin-team-assigned-seats">
            {team.assignedSeats}
          </Paragraph>
        </Component>
      </TableColumn>
      <TableColumn>
        <Component name="admin-team-active-since">
          <Paragraph type="paragraph" name="admin-team-active-since">
            coming soon
          </Paragraph>
        </Component>
      </TableColumn>
    </tr>
  );
};

const AdminTeamdashboardItem = () => {
  enum ErrorFields {
    "SEATS",
    "USER",
  }
  const [errors, setErrors] = useState<ErrorFields[]>([]);
  const { useHistoryNavigate } = historyNavigate();

  const [seats, setSeats] = useState<number>();
  const [selectedUser, setSelectedUser] = useState<UsersResponseDto>();

  const { apiRequest } = useRequest();

  const validate = (withError?: boolean) => {
    const errorsLocal = [];
    if (!selectedUser) {
      errorsLocal.push(ErrorFields.USER);
    }
    if (!seats) {
      errorsLocal.push(ErrorFields.SEATS);
    }
    if (withError) {
      setErrors(errorsLocal);
    }
    return errorsLocal.length === 0;
  };

  const create = async () => {
    const valid = validate(true);
    if (!valid) return;
    const request: CreateTeamRequestDto = {
      belongs_to_leader_id: selectedUser?._id ?? "",
      seats: seats ?? 0,
    };
    const { status } = await apiRequest(`teams/admin`, "POST", {
      body: request,
    });

    if (status === 201) {
      useHistoryNavigate("/admin-dashboard?tab=13");
    }
  };

  return (
    <>
      <Section name="admin-team-item-builder">
        <SectionElement name="admin-team-item-builder" type="body">
          <Component name="admin-team-create">
            <Title name="admin-teams-title" type="h1" size="m">
              Create Team
            </Title>
            <Component name="admin-team-create-form">
              <Component name="admin-team-leader">
                <UserSearchField
                  setUser={(user) => setSelectedUser(user.length !== 0 ? user[0] : undefined)}
                  selected={selectedUser ? [selectedUser] : []}
                  hasError={errors.includes(ErrorFields.USER)}
                />
              </Component>
              <Component name="admin-team-seats">
                <NumberInputElement
                  minValue={1}
                  maxValue={1000}
                  showMinAndMaxValue={false}
                  value={seats}
                  onInput={(e) =>
                    setSeats((e.target as HTMLInputElement).value as unknown as number)
                  }
                  factor={0}
                  label="Seats"
                  errorMessage={errors.includes(ErrorFields.SEATS) ? "Please enter a number" : ""}
                  displayErrorMessage={errors.includes(ErrorFields.SEATS)}
                />
              </Component>
            </Component>
            <Button type="primary" onClick={create}>
              Team erstellen
            </Button>
          </Component>
        </SectionElement>
      </Section>
    </>
  );
};

const AdminTeamdashboardDetails = ({ team }: { team?: TeamResponseDto }) => {
  const { useHistoryNavigate } = historyNavigate();
  const [confirmationPopup, setConfirmationPopup] = useState<boolean>(false);
  const [userToHandle, setUserToHandle] = useState<UsersResponseDto>();
  const { apiRequest } = useRequest();

  if (!team) {
    useHistoryNavigate("/admin-dashboard?tab=13");
    return <></>;
  }

  const deleteAction = () => {
    if (!userToHandle) {
      deleteTeam();
    } else {
      removeTeamMember();
    }
  };

  const deleteTeam = async () => {
    await apiRequest(`teams/admin/${team._id}`, "DELETE", {
      toastText: "Team gelöscht",
      showToast: true,
    });
    useHistoryNavigate("/admin-dashboard?tab=13");
  };

  const removeTeamMember = async () => {
    await apiRequest(`teams/admin/${team._id}/${userToHandle?._id}`, "DELETE", {
      toastText: "User entfernt",
      showToast: true,
    });
    useHistoryNavigate("/admin-dashboard?tab=13");
  };

  // delete option
  // edit option
  // list of users - with option to remove user from team
  // possibilty to add user to team

  return (
    <>
      <Section name="admin-team-item-details">
        <SectionElement type="header" name="admin-teams">
          <Title name="admin-teams-title" type="h1" size="m">
            Team details
          </Title>
          <Button type="danger" name="delete-team" onClick={() => setConfirmationPopup(true)}>
            Delete Team
          </Button>
        </SectionElement>
        <SectionElement type="body" name="admin-teams">
          <AdminTeamContext.Provider value={{ setConfirmationPopup, setUserToHandle, team }}>
            <TeamList team={team} />
          </AdminTeamContext.Provider>
        </SectionElement>
      </Section>
      <ConfirmationDisplay
        action={deleteAction}
        popupIsOpen={confirmationPopup}
        setPopupIsOpen={setConfirmationPopup}
        onCancel={() => {
          setUserToHandle(undefined);
        }}
        title={
          userToHandle
            ? "Bist du dir sicher, diesen User zu entfernen?"
            : "Bist du dir sicher, dieses Team zu löschen?"
        }
      />
    </>
  );
};

const TeamList = ({ team }: { team: TeamResponseDto }) => {
  const [addSeatPopup, setAddSeatPopup] = useState<boolean>(false);
  const { apiRequest } = useRequest();
  const { setPopupContent } = useContext(Context);
  const { useHistoryNavigate } = historyNavigate();

  const addSeat = async (user: UsersResponseDto) => {
    await apiRequest(`teams/admin/${team._id}/${user._id}`, "POST", {
      toastText: "Seat hinzugefügt",
      showToast: true,
    });
    useHistoryNavigate("/admin-dashboard?tab=13");
    setAddSeatPopup(false);
  };

  useEffect(() => {
    if (setPopupContent) {
      if (addSeatPopup) {
        setPopupContent(
          <UserSearchPopup
            setPopupOpen={setAddSeatPopup}
            setUser={(user) => addSeat(user)}
            popupTitle="Seat hinzufügen"
          />,
        );
      } else {
        setPopupContent(undefined);
      }
    }
  }, [addSeatPopup]);

  return (
    <Component name="table-background">
      <Table name="team-dashboard">
        <thead>
          <tr className="h--xxs">
            <th>
              <Paragraph name="team-dashboard-seat-name" size="nav">
                Name
              </Paragraph>
            </th>
            <th>
              <Paragraph name="team-dashboard-seats-left" size="nav">
                {`${team?.assignedSeats}/${team?.seats}`}
              </Paragraph>
            </th>
          </tr>
        </thead>
        <tbody>
          <TeamListRow user={team.leader} isTeamLeaderRow={true} />
          {team.member.map((user, i) => {
            return <TeamListRow key={i} user={user} />;
          })}
          {team &&
            Array.from(Array(team.seats - team.assignedSeats), (e, i) => {
              return (
                <tr key={i} onClick={() => setAddSeatPopup(true)}>
                  <TableColumn colspan={6} className="manger-dashboard-add-seat">
                    <Title name="team-dashboard-add-seat" type="h3" size="xl" className="txt--a-c">
                      +
                    </Title>
                  </TableColumn>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Component>
  );
};

const TeamListRow = (props: { user: UsersResponseDto; isTeamLeaderRow?: boolean }) => {
  const userSeat = props.user;
  const [changeTeamLeaderPopup, setChangeTeamLeaderPopup] = useState<boolean>(false);
  const { setConfirmationPopup, setUserToHandle, team } = useContext(AdminTeamContext);
  const { setPopupContent } = useContext(Context);
  const { apiRequest } = useRequest();

  if (!setConfirmationPopup || !setUserToHandle || !team) {
    return <></>;
  }

  const changeLeader = async (user: UsersResponseDto) => {
    await apiRequest(`teams/admin/${team._id}/${team.leader._id}/${user._id}`, "PUT", {
      toastText: "Leader geändert",
      showToast: true,
    });
    setChangeTeamLeaderPopup(false);
  };

  useEffect(() => {
    if (setPopupContent) {
      if (changeTeamLeaderPopup) {
        setPopupContent(
          <UserSearchPopup
            setPopupOpen={setChangeTeamLeaderPopup}
            setUser={(user) => changeLeader(user)}
            popupTitle="Leader ändern"
          />,
        );
      } else {
        setPopupContent(undefined);
      }
    }
  }, [changeTeamLeaderPopup]);

  return (
    <tr
      onClick={(e) => {
        e.stopPropagation();
      }}>
      <TableColumn name="team-dashboard-col">
        <ProfileBubble user={props.user} />
      </TableColumn>
      <TableColumn name="team-dashboard-col">
        {props.isTeamLeaderRow ? (
          <Component name="team-dashboard-detail-change-teamleader">
            <Button type="primary" onClick={() => setChangeTeamLeaderPopup(true)}>
              Change Teamleader
            </Button>
          </Component>
        ) : (
          <Paragraph
            type="paragraph"
            name="team-dashboard-remove"
            className="txt--c-error"
            onClick={() => {
              setUserToHandle(userSeat);
              setConfirmationPopup(true);
            }}>
            Remove
          </Paragraph>
        )}
      </TableColumn>
    </tr>
  );
};

export default AdminTeamdashboard;
