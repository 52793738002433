import { useContext, useState } from "react";
import Context from "../context/Context";
import { toast } from "react-toastify";
import { ApiRequestTemplate } from "../requests/interfaces";
import { Button, Component, Paragraph } from "../components/Elements/Elements";

const useRequest = () => {
  const { accessToken, setIsBanned } = useContext(Context);

  async function apiRequest<T>(
    route: string,
    method: "POST" | "DELETE" | "GET" | "PUT" | "PATCH",
    options?: ApiRequestTemplate,
  ) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${options?.accessTokenHard ?? accessToken}`);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: method,
      headers: myHeaders,
      body: JSON.stringify(options?.body),
    };

    const responsePromise = fetch(`${process.env.REACT_APP_API}/v1.0/${route}`, requestOptions);
    let responseData;

    if (options?.showToast) {
      toast.loading("loading");
    }

    const response = await responsePromise;

    try {
      responseData = await response.json();
    } catch (e: any) {
      // no json
    }
    const error = responseData?.error;
    const data = responseData as unknown as T;
    const status = response.status;

    if (options?.showToast) {
      toast.dismiss();
      if (status >= 200 && status <= 299) {
        toast.success(options.toastText ?? "Aktion war erfolgreich");
      } else {
        toast.error(
          route.includes("admin") ? (
            <AdminErrorToast error={JSON.stringify(responseData)} status={status} />
          ) : (
            <ErrorToast status={status} />
          ),
        );
      }
    }

    if (!response.ok) {
      if (responseData.message === "banned" && setIsBanned) {
        setIsBanned(true);
      }
      throw new Error(responseData.error, {
        cause: status,
      });
    }

    return { data, error, status };
  }

  async function apiFileRequest<T>(
    route: string,
    method: "POST" | "DELETE" | "GET" | "PUT" | "PATCH",
    file: any,
    options?: {
      formdataName?: string;
      trackType?: "content" | "exercise" | "discussion";
    },
  ) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    const formdata = new FormData();
    if (options?.formdataName) {
      formdata.append(options?.formdataName, file, options?.formdataName);
    } else {
      formdata.append(
        Array.isArray(file) ? "files" : "file",
        Array.isArray(file) ? file[0] : file,
        "file",
      );
    }
    if (options?.trackType) {
      formdata.append("trackType", options?.trackType);
    }

    const requestOptions = {
      method: method,
      headers: myHeaders,
      body: formdata,
    };

    const response = await fetch(`${process.env.REACT_APP_API}/v1.0/${route}`, requestOptions);
    let responseData;
    try {
      responseData = await response.json();
    } catch (e: any) {
      // no json
    }
    console.log(responseData);
    const error = responseData?.error;
    const data = responseData as unknown as T;
    const status = response.status;

    if (status >= 200 && status <= 299) {
      toast.success("File erfolgreich hochgeladen");
    } else {
      toast.error(
        "Es gab leider ein Problem. Bitte versuche es später wieder oder kontaktiere den Support",
      );
    }

    if (!response.ok) {
      throw new Error(responseData.error);
    }

    return { data, error, status };
  }

  const searchLocationRequest = async (query: string) => {
    const requestOptions = {
      method: "GET",
    };

    const searchParams = new URLSearchParams();
    searchParams.append("q", query);
    searchParams.append("limit", "4");
    searchParams.append("session_token", sessionStorage.getItem("session_token") ?? "");
    searchParams.append("proximity", "ip");
    searchParams.append("types", "place");
    searchParams.append("language", "de");
    searchParams.append("access_token", process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? "Na");

    const response = await fetch(
      `${process.env.REACT_APP_MAPBOX_API}?${searchParams}`,
      requestOptions,
    );
    const data = await response.json();
    const error = data.error;

    if (!response.ok) {
      throw new Error(data.error);
    }

    return { data, error };
  };

  const hyreRequest = async (
    route: string,
    method: "POST" | "DELETE" | "GET" | "PUT" | "PATCH",
    body?: any,
  ) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: method,
      body: JSON.stringify(body),
      headers: myHeaders,
    };

    const response = await fetch(`${process.env.REACT_APP_HYRE_API}/${route}`, requestOptions);
    const data = await response.json();
    const error = data.error;

    if (!response.ok) {
      throw new Error(data.error);
    }

    return { data, error };
  };

  return { apiRequest, apiFileRequest, searchLocationRequest, hyreRequest };
};

export default useRequest;

const AdminErrorToast = (props: { error: string; status: number }) => {
  const copyError = () => {
    navigator.clipboard.writeText(props.error);
    setTimeout(() => toast.dismiss(), 1000);
  };

  return (
    <Component name="error-toast">
      <Paragraph name="error-toast" type="paragraph" size="m">
        Es gab leider ein Problem
      </Paragraph>
      <Button
        type="dark"
        name="error-toast"
        onClick={(e) => {
          e.stopPropagation();
          copyError();
        }}>
        Kopiere den Fehler und schicke ihn in die Gruppe - {props.status}
      </Button>
    </Component>
  );
};

const ErrorToast = (props: { status: number }) => {
  const [send, setSend] = useState<boolean>(false);

  const sendSupport = () => {
    setSend(true);
    setTimeout(() => toast.dismiss(), 1000);
  };

  return (
    <Component name="error-toast">
      <Paragraph name="error-toast" type="paragraph" size="m">
        Es gab leider ein Problem Fehlernummer {props.status}
      </Paragraph>
      {!send && (
        <Button
          type="dark"
          name="error-toast"
          onClick={(e) => {
            e.stopPropagation();
            sendSupport();
          }}>
          Kontaktiere den Support
        </Button>
      )}
      {send && (
        <Paragraph name="error-toast" type="paragraph" size="l">
          Danke für den Report
        </Paragraph>
      )}
    </Component>
  );
};
