import { Page, Main, AppView } from "../components/Elements/Elements";
import SiteBar from "../components/Navigation/Sidebar";
import Navigation from "../components/Navigation/Navigation";
import { Loading as LoadingComponent } from "../components/Loading/Loading";

const Loading = () => {
  return (
    <Page name="loading">
      <SiteBar />
      <Main>
        <AppView name="home">
          <Navigation />
          <LoadingComponent />
        </AppView>
      </Main>
    </Page>
  );
};

export default Loading;
