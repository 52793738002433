import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Component,
  ComponentCard,
  Section,
  SectionElement,
  Title,
} from "../Elements/Elements";
import { EventResponseDto, Paginated } from "../../requests/interfaces";
import { EventDetails } from "./Events";
import useRequest from "../../hooks/useRequest";
import { Loading } from "../Loading/Loading";

const MyEvents = () => {
  const [myEvents, setMyEvents] = useState<EventResponseDto[]>([]);
  const { apiRequest } = useRequest();
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    const getEvents = async () => {
      const events = await apiRequest<Paginated<EventResponseDto>>("events/me", "GET");
      setMyEvents(events.data.docs);
    };
    getEvents();
  }, [reload]);

  return (
    <Section name="my-events">
      <SectionElement name="my-events" type="body">
        <Component name="my-events">
          {myEvents.length === 0 && (
            <Title name="not-found" type="h1" size="l">
              Du hast dich bisher noch für keine Events angemeldet.
            </Title>
          )}
          {myEvents.map((event) => {
            return <MyEvent key={event._id} event={event} setReload={setReload} />;
          })}
        </Component>
      </SectionElement>
    </Section>
  );
};

const MyEvent = (props: {
  event: EventResponseDto;
  setReload: Dispatch<SetStateAction<boolean>>;
}) => {
  const [detailsAreOpen, setDetailsAreOpen] = useState<boolean>(false);

  return (
    <ComponentCard name="my-event">
      <EventDetails
        event={props.event}
        showDetails={detailsAreOpen}
        setReload={props.setReload}
        showCloseIcon={false}
      />
      <Button
        type="lightText"
        name="show-details"
        onClick={() => setDetailsAreOpen((value) => !value)}>
        {`${detailsAreOpen ? "Weniger" : "Mehr"} Anzeigen`}
      </Button>
    </ComponentCard>
  );
};

export default MyEvents;
