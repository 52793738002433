import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import EditSVG from "../../Icons/EditSVG";
import TrashSVG from "../../Icons/TrashSVG";
import useRequest from "../../hooks/useRequest";
import {
  AcademyResponseDto,
  ContentTrackRequest,
  ContentTrackResponse,
  CreateAcademyRequestDto,
  CreateTrackRequestDto,
  DiscussionTrackRequestDto,
  DiscussionTrackResponse,
  ExerciseTrackRequest,
  ExerciseTrackResponse,
  Paginated,
  SkillResponseDto,
  TrackResponseDto,
  UsersResponseDto,
} from "../../requests/interfaces";
import { ConfirmationDisplay, UserSearchField } from "../Elements/Components";
import {
  Button,
  Component,
  ComponentCard,
  Icon,
  Image,
  ImageUploader,
  Paragraph,
  ProfileBubble,
  RichTextEditor,
  Section,
  SectionElement,
  Table,
  TableColumn,
  TablePagination,
  Title,
} from "../Elements/Elements";
import {
  CredlyBadgeSelect,
  SkillSelect,
  StripeSelect,
  Switch,
  TextInputElement,
} from "../Elements/FormElements";

import React from "react";
import ArrowSVG from "../../Icons/ArrowSVG";
import { MediaTrackPart, TrackPart } from "../Academy/AcademyCreateContentTracks";
import SortableList from "../Academy/DragableList/SortableList";
import { Loading } from "../Loading/Loading";

interface IMasterclassAdminContext {
  academyId?: string;
}

const MasterclassAdminContext = React.createContext<IMasterclassAdminContext>({});

const TrackAdminContext = React.createContext<{
  trackId?: string;
}>({
  trackId: "",
});

const AdminMasterclass = () => {
  const [mode, setMode] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const modeId = searchParams.get("mode");
  const [modes, setModes] = useState<any[]>([]);

  const setupModes = () => {
    const allModes = [
      {
        modeId: "LIST",
        component: (
          <AdminMasterclassList searchParams={searchParams} setSearchParams={setSearchParams} />
        ),
        active:
          modeId === null || (modeId !== "create" && modeId !== "edit" && modeId !== "details"),
      },
      {
        modeId: "CREATE",
        component: (
          <AdminMasterclassItem
            mode="create"
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        ),
        active: modeId === "create",
      },
      {
        modeId: "EDIT",
        component: (
          <AdminMasterclassItem
            mode="edit"
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        ),
        active: modeId === "edit",
      },
      {
        modeId: "DETAILS",
        component: (
          <AdminMasterclassDetails searchParams={searchParams} setSearchParams={setSearchParams} />
        ),
        active: modeId === "details",
      },
    ];
    setModes(allModes);
  };

  useEffect(() => {
    setMode(modes.find((tab: any) => tab.active === true));
  }, [modes]);

  useEffect(() => {
    setupModes();
  }, [modeId]);

  useEffect(() => {
    setupModes();
  }, []);

  return <>{mode && mode.component}</>;
};

const AdminMasterclassList = (props: { searchParams: any; setSearchParams: any }) => {
  const [academies, setAcademies] = useState<AcademyResponseDto[]>();
  const [hasNext, setHasNext] = useState<boolean>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [toDeleteId, setToDeleteId] = useState<string>("");
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState<boolean>(false);
  const { apiRequest } = useRequest();

  const getAcademies = async () => {
    const academiesResponse = await apiRequest<Paginated<AcademyResponseDto>>(
      `academy/admin?limit=10&page=${currentPage}`,
      "GET",
    );
    setAcademies(academiesResponse.data.docs);
    setHasNext(academiesResponse.data.hasNextPage);
  };

  useEffect(() => {
    getAcademies();
  }, []);

  useEffect(() => {
    getAcademies();
  }, [currentPage]);

  useEffect(() => {
    if (toDeleteId !== "") {
      setDeleteDialogIsOpen(true);
    }
  }, [toDeleteId]);

  const edit = (id: string) => {
    props.searchParams.set("mode", "edit");
    props.searchParams.set("id", id);
    props.setSearchParams(props.searchParams);
  };

  const deleteAcademy = async (id: string) => {
    await apiRequest(`academy/admin/${id}`, "DELETE");
    setToDeleteId("");
    getAcademies();
  };

  const create = () => {
    props.searchParams.set("mode", "create");
    props.setSearchParams(props.searchParams);
  };

  const trackDetails = (id: string) => {
    props.searchParams.set("mode", "details");
    props.searchParams.set("id", id);
    props.setSearchParams(props.searchParams);
  };

  return (
    <Section name="admin-academies">
      <SectionElement type="header" name="admin-academy">
        <Title name="admin-academies-title" type="h1" size="m">
          Academy
        </Title>
        <Button type="primary" name="create-new-academy" onClick={() => create()}>
          + Neu
        </Button>
      </SectionElement>
      <SectionElement type="body" name="admin-academy">
        <Component name="table-background">
          <Table name="admin-academies">
            <thead>
              <tr className="h--xxs">
                <th>
                  <Paragraph name="admin-academy-thumbnail">Vorschaubild</Paragraph>
                </th>
                <th>
                  <Paragraph name="admin-academy-title">Titel</Paragraph>
                </th>
                <th>
                  <Paragraph name="admin-academy-instructor">Instruktor</Paragraph>
                </th>
                <th>
                  <Paragraph name="admin-academy-status">Status</Paragraph>
                </th>
                <th>
                  <Paragraph name="admin-academy-tracks-amount">Anzahl Tracks</Paragraph>
                </th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {academies?.map((academy) => {
                return (
                  <tr
                    key={academy._id}
                    onClick={(e) => {
                      e.stopPropagation();
                      trackDetails(academy._id);
                    }}>
                    <TableColumn name="admin-col">
                      <Image img={academy.thumbnailUrl} name="admin-academy-thumbnail" />
                    </TableColumn>
                    <TableColumn name="admin-col">
                      <Paragraph type="paragraph" name="admin-academy-title">
                        {academy.title}
                      </Paragraph>
                    </TableColumn>
                    <TableColumn name="admin-col">
                      {academy.instructors[0] !== undefined && (
                        <ProfileBubble user={academy.instructors[0]} />
                      )}
                    </TableColumn>
                    <TableColumn name="admin-col">
                      <Paragraph type="paragraph" name="admin-academy-status">
                        {academy.isListed ? "Gelistet" : "Nicht gelistet"}
                      </Paragraph>
                    </TableColumn>
                    <TableColumn name="admin-col">
                      <Paragraph type="paragraph" name="admin-academy-tracks-amount">
                        {academy.tracksCount}
                      </Paragraph>
                    </TableColumn>
                    <TableColumn name="admin-col">
                      <Icon
                        name="academy-edit"
                        svg={<EditSVG />}
                        className="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          edit(academy._id);
                        }}
                      />
                    </TableColumn>
                    <TableColumn>
                      <Icon
                        name="academy-delete"
                        svg={<TrashSVG />}
                        className="danger"
                        onClick={(e) => {
                          e.stopPropagation();
                          setToDeleteId(academy._id);
                        }}
                      />
                    </TableColumn>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {hasNext !== undefined && (
            <TablePagination
              currentPage={currentPage}
              hasNext={hasNext}
              setCurrentPage={setCurrentPage}
            />
          )}
        </Component>
        <ConfirmationDisplay
          action={() => deleteAcademy(toDeleteId)}
          popupIsOpen={deleteDialogIsOpen}
          setPopupIsOpen={setDeleteDialogIsOpen}
          title="Bist du dir sicher, diese Academy zu löschen?"
        />
      </SectionElement>
    </Section>
  );
};

const AdminMasterclassItem = (props: {
  mode: "create" | "edit";
  searchParams: any;
  setSearchParams: any;
}) => {
  const id = props.searchParams.get("id");
  const [item, setItem] = useState<AcademyResponseDto>();
  const [title, setTitle] = useState<string>(item?.title ?? "");
  const [description, setDescription] = useState<string>(item?.description ?? "");
  const [skills, setSkills] = useState<SkillResponseDto[]>(item?.skills ?? []);
  const [badge, setBadge] = useState<{ value: string; label: string }>();
  const [thumbnail, setThumbnail] = useState<string>(item?.thumbnailUrl ?? "");
  const [productId, setProductId] = useState<string>("");
  const [paymentUrl, setPaymentUrl] = useState<string>("");
  const [instructor, setInstructor] = useState<UsersResponseDto>();
  const [isListed, setIsListed] = useState<boolean>(item?.isListed ?? false);
  const [isFeatured, setIsFeatured] = useState<boolean>(item?.isFeatured ?? false);
  const [tracks, setTracks] = useState<TrackResponseDto[]>(item?.tracks ?? []);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [academyClosed, setAcademyClosed] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const editorRef: any = useRef();
  const { apiRequest } = useRequest();

  useEffect(() => {
    if (id || item?._id) {
      const getAcademyById = async () => {
        const { data } = await apiRequest<AcademyResponseDto>(
          `academy/admin/${id ?? item?._id}`,
          "GET",
        );
        setItem(() => {
          if (data.tracks.filter((data) => data.index === 0).length !== 0) {
            data.tracks.map((track) => track.index++);
          }
          data.tracks.sort((a, b) => a.index - b.index);
          return data;
        });
      };
      getAcademyById();
    }
  }, [reload]);

  useEffect(() => {
    if (item) {
      setTitle(item.title);
      setDescription(item.description);
      setTracks(item.tracks);
      setInstructor(item.instructors[0]);
      setThumbnail(item.thumbnailUrl);
      setSkills(item.skills);
      setIsListed(item.isListed);
      setIsFeatured(item.isFeatured);
      setPaymentUrl(item.paymentUrl);
      setProductId(item.productId);
    }
  }, [item]);

  useEffect(() => {
    //reload
  }, [tracks]);

  useEffect(() => {
    if (
      title === item?.title &&
      description === item?.description &&
      thumbnail === item.thumbnailUrl &&
      instructor === item.instructors[0] &&
      isListed === item.isListed &&
      paymentUrl === item.paymentUrl &&
      productId === item.productId &&
      item.isFeatured === isFeatured
    ) {
      return;
    }
    if (title === "") {
      return;
    }
    if (description === "") {
      return;
    }
    if (productId === "") {
      return;
    }
    if (paymentUrl === "") {
      return;
    }
    if (instructor === undefined) {
      return;
    }
    if (skills.length === 0) {
      return;
    }
    // if (tracks.length === 0) {
    //   return;
    // }
    setIsValid(true);
  }, [
    description,
    skills,
    title,
    thumbnail,
    instructor,
    tracks,
    isFeatured,
    isListed,
    paymentUrl,
    productId,
  ]);

  const createAcademy = async () => {
    const request: CreateAcademyRequestDto = {
      description: description,
      skills: skills.map((value) => value._id),
      title: title,
      instructors: [instructor?._id ?? ""],
      isListed: isListed,
      badgeId: badge?.value ?? "",
      isFeatured: isFeatured,
      releaseDate: item?.releaseDate ?? new Date(),
      productId: productId,
      paymentUrl: paymentUrl,
    };
    if (id) {
      const { data } = await apiRequest<AcademyResponseDto>(`academy/admin/${id}`, "PATCH", {
        body: request,
        showToast: true,
      });
      setItem(data);
    } else {
      const { data } = await apiRequest<AcademyResponseDto>("academy/admin", "POST", {
        body: request,
        showToast: true,
      });
      setItem(data);
    }
  };

  const changeImage = (e: any) => {
    setThumbnail(e.target.files[0]);
  };

  return (
    <>
      <Section name="admin-academy-item-builder">
        <SectionElement
          name="admin-academy-item-builder"
          type="header"
          onClick={() => setAcademyClosed((value) => !value)}>
          <Component name="admin-academy-item-builder">
            <Title name="admin-academies-title" type="h1" size="m">
              Masterclass {item?._id ? `${item.title}` : ""}
            </Title>
            <Icon
              name="admin-academy-close"
              svg={<ArrowSVG />}
              className={academyClosed ? "closed" : ""}
            />
          </Component>
        </SectionElement>
        <SectionElement name="admin-academy-item-builder" type="body">
          <Component name="academy-form-inputs">
            {!academyClosed && (
              <Component name="form-inputs">
                <Component name="form-row">
                  <Component name="form-input">
                    <UserSearchField
                      setUser={(user) => {
                        setInstructor(user[0]);
                      }}
                      selected={instructor ? [instructor] : []}
                      title="Instructor"
                    />
                  </Component>
                </Component>
                <Component name="form-row">
                  <Component name="form-input">
                    <TextInputElement
                      id="title"
                      label="Titel"
                      type="text"
                      labelClasses="p--l"
                      onInput={(e) => {
                        setTitle((e.target as HTMLInputElement).value);
                      }}
                      value={title}
                    />
                  </Component>
                </Component>
                <Component name="form-row">
                  <Component name="form-input">
                    <label className="p--l">{"Description"}</label>
                    <RichTextEditor setText={setDescription} text={description} ref={editorRef} />
                  </Component>
                </Component>
                <Component name="form-row">
                  <Component name="form-input">
                    <SkillSelect
                      onInput={(newValue) => {
                        setSkills(
                          newValue.map((value) => {
                            return {
                              _id: value.value,
                              skill: value.label,
                            };
                          }),
                        );
                      }}
                      defaultValue={skills}
                    />
                  </Component>
                </Component>
                <Component name="form-row">
                  <Component name="form-input">
                    <CredlyBadgeSelect
                      onInput={(newValue) =>
                        setBadge({
                          label: newValue?.label ?? "",
                          value: newValue?.value ?? "",
                        })
                      }
                      defaultValue={item?.badgeId}
                    />
                  </Component>
                </Component>
                <Component name="form-row">
                  <Component name="form-input">
                    <StripeSelect
                      onInput={(value) => {
                        const splitted = value?.value.split(";");
                        if (splitted) {
                          setProductId(splitted[0]);
                          setPaymentUrl(splitted[1]);
                        }
                      }}
                      defaultValue={productId}
                    />
                  </Component>
                </Component>
                <Component name="form-row">
                  <Component name="form-input">
                    <Switch
                      type=""
                      id="listed"
                      label={"Gelistet"}
                      value={isListed ? 1 : -1}
                      onChange={() => setIsListed((value) => !value)}
                    />
                  </Component>
                </Component>
                <Component name="form-row">
                  <Component name="form-input">
                    <Switch
                      type=""
                      id="featured"
                      label={"Feautered (wird im Header bei den Academies angezeigt)"}
                      value={isFeatured ? 1 : -1}
                      onChange={() => setIsFeatured((value) => !value)}
                    />
                  </Component>
                </Component>
                <Button type="primary" onClick={createAcademy} disabled={!isValid}>
                  {id ? "Speichern" : "Medien hinzufügen"}
                </Button>
                {item?._id && (
                  <>
                    <Title name="academy-files-upload" size="m" type="h2">
                      Change Academy Files
                    </Title>
                    <Component name="form-row">
                      <Component name="form-input">
                        <label className="p--l">{"Thumbnail"}</label>
                        <ImageUploader
                          img={thumbnail}
                          type="small"
                          onChange={changeImage}
                          onImageUploaded={() => setReload((value) => !value)}
                          route={`academy/files/${item._id}/thumbnail`}
                          method={"POST"}
                        />
                      </Component>
                    </Component>
                    <hr />
                  </>
                )}
              </Component>
            )}
            {item?._id && item?.tracks && item.thumbnailStatus !== "NOT_ASSIGNED" && (
              <>
                <Component name="form-row">
                  <Component name="form-input">
                    <Title name="admin-academies-tracks-title" type="h2" size="m">
                      Tracks
                    </Title>
                    <MasterclassAdminContext.Provider
                      value={{
                        academyId: item._id,
                      }}>
                      <Tracks setTracks={setTracks} tracks={tracks} setReload={setReload} />
                    </MasterclassAdminContext.Provider>
                  </Component>
                </Component>
              </>
            )}
          </Component>
        </SectionElement>
      </Section>
    </>
  );
};

const Tracks = (props: {
  setTracks: Dispatch<SetStateAction<TrackResponseDto[]>>;
  tracks: TrackResponseDto[];
  setReload: Dispatch<SetStateAction<boolean>>;
}) => {
  const [addTrack, setAddTrack] = useState<boolean>();

  const { apiRequest } = useRequest();
  const { academyId } = useContext(MasterclassAdminContext);

  const onSave = async (
    track: CreateTrackRequestDto,
    method: "POST" | "PATCH",
    trackId: string,
  ) => {
    track.index = track.index !== 0 ? track.index : props.tracks.length;
    const { status } = await apiRequest<TrackResponseDto>(
      `academy/admin/${method === "POST" ? `${academyId}/track` : `track/${trackId}`}`,
      method,
      {
        body: track,
        showToast: true,
      },
    );
    if (status > 200 || status < 300) {
      props.setReload((value) => !value);
      setAddTrack(undefined);
    }
  };

  const onDelete = async (track: TrackResponseDto) => {
    if (track && track?._id !== "") {
      await apiRequest(`academy/admin/track/${track._id}`, "DELETE", {
        showToast: true,
      });
      props.setReload((value) => !value);
    } else {
      setAddTrack(undefined);
    }
  };

  useEffect(() => {
    //reload
  }, [addTrack]);

  const editIndexes = (tracks: TrackResponseDto[]) => {
    const tracksRequest: { _id: string; index: number }[] = [];
    tracks.map((track) => {
      tracksRequest.push({
        _id: track._id,
        index: track.index,
      });
    });
    const request = {
      tracks: tracksRequest,
    };
    apiRequest(`academy/admin/${academyId}/track/index`, "PATCH", {
      body: request,
    });
  };

  const onRuleMove = (newTracks: TrackResponseDto[]) => {
    const tracks: TrackResponseDto[] = [];
    newTracks.map((track, i) => {
      track.index = i + 1;
      tracks.push(track);
    });
    editIndexes(tracks);
    props.setTracks(() => {
      newTracks.sort((a, b) => a.index - b.index);
      return newTracks;
    });
  };

  return (
    <Component name="admin-academy-tracks">
      <SortableList
        items={props.tracks ?? []}
        onChange={onRuleMove}
        renderItem={(item) => {
          return (
            <SortableList.Item id={item.index}>
              <Track track={item} onSave={onSave} onDelete={onDelete} defaultOpen={false} />
              <SortableList.DragHandle />
            </SortableList.Item>
          );
        }}
      />
      {addTrack !== undefined && (
        <ComponentCard>
          <Track
            track={undefined}
            onSave={onSave}
            onDelete={onDelete}
            index={props.tracks.length + 1}
            defaultOpen={true}
          />
        </ComponentCard>
      )}
      <Button type="primaryGhost" onClick={() => setAddTrack(true)}>
        Neue Lektion
      </Button>
    </Component>
  );
};

const Track = (props: {
  track: TrackResponseDto | undefined;
  onSave: (id: any, method: "POST" | "PATCH", trackId: string) => any;
  onDelete: (id: any) => any;
  index?: number;
  defaultOpen?: boolean;
}) => {
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState<boolean>(false);
  const [index, setIndex] = useState<number>();
  const [title, setTitle] = useState<string>("");
  const [learningObjective, setLearningObjective] = useState<string>("");

  const [contentTrackRequest, setContentTrackRequest] = useState<ContentTrackRequest>();
  const [exerciseTrackRequest, setExerciseTrackRequest] = useState<ExerciseTrackRequest>();
  const [discussionTrackRequest, setDiscussionTrackRequest] = useState<DiscussionTrackRequestDto>();

  const [contentTrackResponse, setContentTrackResponse] = useState<ContentTrackResponse>();
  const [exerciseTrackResponse, setExerciseTrackResponse] = useState<ExerciseTrackResponse>();
  const [discussionTrackResponse, setDiscussionTrackResponse] = useState<DiscussionTrackResponse>();

  const [isValid, setIsValid] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(
    props.defaultOpen !== undefined ? props.defaultOpen : false,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (props.track) {
      setIndex(props.track.index);
      setTitle(props.track.title);
      setLearningObjective(props.track.learningObjective);
      setContentTrackResponse(props.track.contentTrack);
      setExerciseTrackResponse(props.track.exerciseTrack);
      setDiscussionTrackResponse(props.track.discussionTrack);
    }
  }, [props.track]);

  useEffect(() => {
    if (props.index) {
      setIndex(props.index);
    }
  }, [props.index]);

  useEffect(() => {
    if (
      title === props.track?.title &&
      learningObjective === props.track?.learningObjective &&
      contentTrackResponse?.title === props.track?.contentTrack?.title &&
      contentTrackResponse?.description === props.track?.contentTrack?.description &&
      exerciseTrackResponse?.title === props.track?.exerciseTrack?.title &&
      exerciseTrackResponse?.description === props.track?.exerciseTrack?.description &&
      exerciseTrackResponse?.exerciseType === props.track?.exerciseTrack?.exerciseType &&
      exerciseTrackResponse?.subject === props.track?.exerciseTrack?.subject &&
      discussionTrackResponse?.title === props.track?.discussionTrack?.title &&
      discussionTrackResponse?.description === props.track?.discussionTrack?.description &&
      discussionTrackResponse?.question === props.track?.discussionTrack?.question
    ) {
      return;
    }
    if (title === "") {
      return;
    }
    if (learningObjective === "") {
      return;
    }
    if (contentTrackRequest === undefined) {
      return;
    }

    if (exerciseTrackRequest || !props.track?._id) {
      return;
    }
    if (discussionTrackRequest || !props.track?._id) {
      return;
    }
    setIsValid(true);
  }, [learningObjective, contentTrackRequest, title, exerciseTrackRequest, discussionTrackRequest]);

  const addTrackToAcademy = async () => {
    if (contentTrackRequest && exerciseTrackRequest && discussionTrackRequest) {
      const request: CreateTrackRequestDto = {
        title: title ?? "",
        index: index ?? 0,
        learningObjective: learningObjective ?? "",
        discussionTrack: discussionTrackRequest,
        exerciseTrack: exerciseTrackRequest,
        contentTrack: contentTrackRequest,
      };
      setIsLoading(true);
      await props.onSave(request, props.track?._id ? "PATCH" : "POST", props.track?._id ?? "");
    } else if (contentTrackRequest && props.track?._id) {
      const request = {
        title: title ?? "",
        index: index ?? 0,
        learningObjective: learningObjective ?? "",
        contentTrack: contentTrackRequest,
      };
      props.onSave(request, "PATCH", props.track?._id);
      setIsOpen(false);
    }
  };

  return (
    <TrackAdminContext.Provider value={{ trackId: props.track?._id }}>
      <Component name="admin-academy-track">
        <Component name="admin-academy-track-title" onClick={() => setIsOpen((value) => !value)}>
          <Title size="xs" type="h2">
            {`${props.track?.title ? "" : "Neue"} Lektion ${props.track?.index ?? ""}: ${
              props.track?.title ?? ""
            } - `}
            <span
              className={
                props.track?.contentTrack.videoProcessingStatus !== null &&
                props.track?.contentTrack.videoProcessingStatus !== "NOT_ASSIGNED"
                  ? "txt--c-pri"
                  : "txt--c-error"
              }>
              {props.track?.contentTrack.videoProcessingStatus !== null &&
              props.track?.contentTrack.videoProcessingStatus !== "NOT_ASSIGNED"
                ? "Finished"
                : "Not finished"}
            </span>
          </Title>
        </Component>
        {isOpen && (
          <Component name="base-inputs">
            <Component name="form-inputs">
              <Component name="form-row">
                <Component name="form-input">
                  <TextInputElement
                    id="content-title"
                    label="Lektionstitel"
                    type="text"
                    labelClasses="p--l"
                    onInput={(e) => {
                      setTitle((e.target as HTMLInputElement).value);
                    }}
                    value={title}
                  />
                </Component>
              </Component>
              <Component name="form-row">
                <Component name="form-input">
                  <TextInputElement
                    id="track-learning-objective"
                    label="Learning objective"
                    type="textarea"
                    labelClasses="p--l"
                    onChange={(e) => setLearningObjective((e.target as HTMLInputElement).value)}
                    value={learningObjective}
                  />
                </Component>
              </Component>
            </Component>
            <Component name="form-inputs">
              <hr />
              <TrackPart
                track={{
                  item: contentTrackResponse,
                  setItem: setContentTrackRequest,
                  type: "content",
                }}
              />
              {(exerciseTrackResponse || !props.track?._id) && (
                <>
                  <hr />
                  <TrackPart
                    track={{
                      item: exerciseTrackResponse,
                      setItem: setExerciseTrackRequest,
                      type: "exercise",
                    }}
                  />
                </>
              )}
              {(discussionTrackResponse || !props.track?._id) && (
                <>
                  <hr />
                  <TrackPart
                    track={{
                      item: discussionTrackResponse,
                      setItem: setDiscussionTrackRequest,
                      type: "discussion",
                    }}
                  />
                </>
              )}
              <Component name="add-track-buttons">
                <Button
                  type="dangerGhost"
                  onClick={() => props.onDelete(props.track)}
                  name="delete-track">
                  Löschen
                </Button>
                <Button
                  type="primary"
                  onClick={addTrackToAcademy}
                  // disabled={!isValid}
                  name="add-track">
                  {props.track?._id ? "Speichern" : "Medien hinzufügen"}
                </Button>
              </Component>
              {props.track?._id && (
                <>
                  <hr />
                  <MediaTrackPart track={props.track} />
                </>
              )}
            </Component>
            {isLoading && <Loading />}
          </Component>
        )}
      </Component>
    </TrackAdminContext.Provider>
  );
};

const AdminMasterclassDetails = (props: { searchParams: any; setSearchParams: any }) => {
  const id = props.searchParams.get("id");
  const [item, setItem] = useState<AcademyResponseDto>();
  const { apiRequest } = useRequest();

  useEffect(() => {
    if (id) {
      const getAcademyById = async () => {
        const { data } = await apiRequest<AcademyResponseDto>(`academy/admin/${id}`, "GET");
        setItem(data);
      };
      getAcademyById();
    }
  }, []);

  return (
    <>
      <Section name="admin-academy-details">
        <SectionElement name="admin-academy-details" type="body">
          <Component name="admin-academy-details">
            <Component name="admin-academy-details-tracks">
              {item &&
                item.tracks?.map((track, i) => {
                  return <AdminTrackItem key={i} track={track} />;
                })}
            </Component>
          </Component>
        </SectionElement>
      </Section>
    </>
  );
};

const AdminTrackItem = (props: { track: TrackResponseDto }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <ComponentCard name="admin-academy-track-item" onClick={() => setIsOpen((value) => !value)}>
      <Component name="admin-academy-track-item-title">
        <Image img={props.track.thumbnailUrl} name="admin-academy-track-thumbnail" />
        <Title name="admin-academy-track-item-title" size="xs" type="h3">
          {props.track.title}
        </Title>
      </Component>
      {isOpen && (
        <Component name="admin-academy-track-details">
          <Component>{props.track.contentTrack.title}</Component>
          <Component>{props.track.exerciseTrack.title}</Component>
          <Component>{props.track.discussionTrack.title}</Component>
        </Component>
      )}
    </ComponentCard>
  );
};

export default AdminMasterclass;
