import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import TrashSVG from "../../Icons/TrashSVG";
import { QandA } from "../../requests/interfaces";
import {
  Button,
  Component,
  Table,
  Icon,
  Paragraph,
  Section,
  SectionElement,
  TableColumn,
  TablePagination,
  Title,
} from "../Elements/Elements";
import { TextInputElement, TopicSelect } from "../Elements/FormElements";
import useRequest from "../../hooks/useRequest";
import historyNavigate from "../../hooks/useHistoryNavigate";

const AdminQandA = () => {
  const [mode, setMode] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const modeId = searchParams.get("mode");
  const [modes, setModes] = useState<any[]>([]);

  const setupModes = () => {
    const allModes = [
      {
        modeId: "LIST",
        component: <AdminQandAList searchParams={searchParams} setSearchParams={setSearchParams} />,
        active: modeId === null || modeId !== "create",
      },
      {
        modeId: "CREATE",
        component: <AdminQandAItem />,
        active: modeId === "create",
      },
    ];
    setModes(allModes);
  };

  useEffect(() => {
    setMode(modes.find((tab: any) => tab.active === true));
  }, [modes]);

  useEffect(() => {
    setupModes();
  }, [modeId]);

  useEffect(() => {
    setupModes();
  }, []);

  return <>{mode && mode.component}</>;
};

const AdminQandAList = (props: { searchParams: any; setSearchParams: any }) => {
  const [qAndAs, setQAndAs] = useState<QandA[]>();
  const [hasNext, setHasNext] = useState<boolean>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { apiRequest } = useRequest();

  const getQandA = async () => {
    const { data } = await apiRequest<QandA[]>(`questions?limit=10&page=${currentPage}`, "GET");
    setQAndAs(data);
    setHasNext(false);
  };

  useEffect(() => {
    getQandA();
  }, []);

  useEffect(() => {
    getQandA();
  }, [currentPage]);

  const deleteqAndA = async (id: string) => {
    await apiRequest(`questions/${id}`, "DELETE");
    getQandA();
  };

  const create = () => {
    props.searchParams.set("mode", "create");
    props.setSearchParams(props.searchParams);
  };

  return (
    <Section name="admin-qAndAs">
      <SectionElement type="header" name="admin-qAndA">
        <Title name="admin-qAndAs-title" type="h1" size="m">
          Q and A
        </Title>
        <Button type="primary" name="create-new-qAndA" onClick={() => create()}>
          + Neu
        </Button>
      </SectionElement>
      <SectionElement type="body" name="admin-qAndA">
        <Component name="table-background">
          <Table name="admin-qAndAs">
            <thead>
              <tr className="h--xxs">
                <th>
                  <Paragraph name="admin-qAndA-thumbnail">Frage</Paragraph>
                </th>
                <th>
                  <Paragraph name="admin-qAndA-title">Antwort</Paragraph>
                </th>
                <th>
                  <Paragraph name="admin-qAndA-location">Topic</Paragraph>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {qAndAs?.map((qAndA) => {
                return (
                  <tr key={qAndA.question}>
                    <TableColumn name="admin-col">
                      <Paragraph type="paragraph" name="admin-qAndA-title">
                        {qAndA.question}
                      </Paragraph>
                    </TableColumn>
                    <TableColumn name="admin-col">
                      <Paragraph type="paragraph" name="admin-qAndA-location">
                        {qAndA.answer}
                      </Paragraph>
                    </TableColumn>
                    <TableColumn name="admin-col">
                      <Paragraph type="paragraph" name="admin-qAndA-participants">
                        {qAndA.topic}
                      </Paragraph>
                    </TableColumn>
                    <TableColumn>
                      <Icon
                        name="qAndA-delete"
                        svg={<TrashSVG />}
                        className="danger"
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteqAndA(qAndA._id);
                        }}
                      />
                    </TableColumn>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {hasNext !== undefined && (
            <TablePagination
              currentPage={currentPage}
              hasNext={hasNext}
              setCurrentPage={setCurrentPage}
            />
          )}
        </Component>
      </SectionElement>
    </Section>
  );
};

const AdminQandAItem = () => {
  const [question, setQuestion] = useState<string>("");
  const [answer, setAnswer] = useState<string>("");
  const [topic, setTopic] = useState<string>("");
  const { apiRequest } = useRequest();
  const { useHistoryNavigate } = historyNavigate();
  const [isValid, setIsValid] = useState<boolean>(false);

  const createQandA = async () => {
    const request = {
      question: question,
      answer: answer,
      topic: topic,
    };
    const { status } = await apiRequest("questions", "POST", { body: request, showToast: true });
    if (status === 201) {
      useHistoryNavigate("/admin-dashboard?tab=6");
    }
  };

  useEffect(() => {
    if (question === "") {
      return;
    }
    if (answer === "") {
      return;
    }
    if (topic === "") {
      return;
    }
    setIsValid(true);
  }, [question, answer, topic]);

  return (
    <>
      <Section name="admin-qAndA-item-builder">
        <SectionElement name="admin-qAndA-item-builder" type="body">
          <Component name="form-inputs">
            <Component name="form-row">
              <Component name="form-input">
                <TextInputElement
                  id="question"
                  label="Frage"
                  type="text"
                  labelClasses="p--l"
                  onInput={(e) => setQuestion((e.target as HTMLInputElement).value)}
                  value={question}
                />
              </Component>
            </Component>
            <Component name="form-row">
              <Component name="form-input">
                <TextInputElement
                  id="answer"
                  label="Antwort"
                  type="text"
                  labelClasses="p--l"
                  onInput={(e) => setAnswer((e.target as HTMLInputElement).value)}
                  value={answer}
                />
              </Component>
            </Component>
            <Component name="form-row">
              <Component name="form-input">
                <TopicSelect
                  onInput={(newValue) => {
                    setTopic(newValue?.value ?? "");
                  }}
                  defaultValue={topic}
                />
              </Component>
            </Component>
            <Button type="primary" onClick={createQandA} disabled={!isValid}>
              Erstellen
            </Button>
          </Component>
        </SectionElement>
      </Section>
    </>
  );
};

export default AdminQandA;
