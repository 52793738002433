import { useEffect, useState } from "react";
import LocationSVG from "../../../Icons/LocationSVG";
import {
  Button,
  Component,
  ComponentCard,
  Icon,
  Paragraph,
  SearchSuggestion,
  Title,
} from "../../Elements/Elements";
import {
  ErrorMessage,
  SelectInput,
  Slider,
  TagSelect,
  TextInputElement,
} from "../../Elements/FormElements";
import { StepProps } from "../Register";
import { TargetLocation } from "../../../requests/interfaces";
import useRequest from "../../../hooks/useRequest";
import { useUser } from "../../../hooks/useUser";
import useFormOptions from "../../../hooks/useFormOptions";

enum ErrorFields {
  "CITY",
  "GERMANLEVEL",
  "COMPANY",
  "POSITION",
  "INDUSTRY",
  "EXPERIENCE",
  "INTERESTS",
  "LEADS",
}

const CareerStep = (props: StepProps) => {
  const firstStepAction = (e: any, operator: any, request: any) => {
    if (request === undefined) {
      secondStepAction(e, operator, request);
      return;
    }
    setRequest(request);
    setCurrentStepId((value) => value + (operator ?? 0));
  };

  const secondStepAction = (e: any, operator: any, request: any) => {
    setRequest({ career: { ...finalRequest, ...request } });
    props.action(e, operator, { career: { ...finalRequest, ...request } });
  };

  const hyreAction = (e: any, operator: any, request: any) => {
    setRequest(request);
    props.action(e, operator, { ...finalRequest, ...request });
  };

  const steps = [
    {
      id: 0,
      item: <FirstStep key="first" action={firstStepAction} />,
    },
    {
      id: 1,
      item: <SecondStep key="second" action={secondStepAction} />,
    },
    {
      id: 2,
      item: <HyreStep key="hyre" action={hyreAction} />,
    },
  ];

  const [finalRequest, setRequest] = useState<any>();
  const [currentStepId, setCurrentStepId] = useState(props.id ?? 0);
  const [currentStep, setCurrentStep] = useState(steps[currentStepId]);

  useEffect(() => {
    setCurrentStep(steps[currentStepId]);
  }, [currentStepId]);

  return (
    <>
      <Component name="form-info">
        <Title name="form-info" className="txt--c-on-surface" type="h1" size="s">
          {props.id !== undefined ? "Profil bearbeiten" : "Profil aufsetzen"}
        </Title>
      </Component>
      {currentStep.item}
    </>
  );
};

const toggleItem = (value: any, setArrayState: (value: any) => void, prevArray: any[]) => {
  const index = prevArray.indexOf(value);
  const newArr = [...prevArray];
  if (index > -1) {
    newArr.splice(index, 1);
  } else {
    newArr.push(value);
  }
  setArrayState(newArr);
};

const othersSelected = (selectedOptions: any[], allOptions: any[]) => {
  if (selectedOptions.includes("others")) {
    return selectedOptions;
  }
  return allOptions
    .filter((element) => selectedOptions.includes(element.id))
    .map((element) => element.id);
};

const FirstStep = (props: StepProps) => {
  const { user } = useUser();
  const [germanLevel, setGermanLevel] = useState("");
  const [company, setCompany] = useState("");
  const [companyChecked, setCompanyChecked] = useState(true);
  const [position, setPosition] = useState("");
  const [errorFields, setErrorFields] = useState<ErrorFields[]>();
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [finalCity, setFinalCity] = useState<{
    name: string;
    placeFormatted: string;
    mapBoxId: string;
  }>();
  const [cityUserInput, setCityUserInput] = useState<string>("");
  const { searchLocationRequest } = useRequest();
  const [formOptions, setFormOptions] = useState<any>();

  useEffect(() => {
    if (user?.career) {
      const careerDetails = user.career;
      setGermanLevel(careerDetails.germanLevel ?? "");
      setCompany(careerDetails.company ?? "");
      setCompanyChecked(careerDetails.company === "" ? true : false);
      setPosition(careerDetails.position ?? "");
      setFinalCity(careerDetails.location ?? undefined);
    }
  }, [user]);

  useEffect(() => {
    setSuggestions([]);
    const request = async () => {
      const { data, error } = await searchLocationRequest(cityUserInput);
      if (data) {
        setSuggestions(data.suggestions);
      }
      if (error) {
        console.log(error);
      }
    };
    if (cityUserInput !== "") {
      request();
    }
  }, [cityUserInput]);

  useEffect(() => {
    const setupOptions = async () => {
      setFormOptions(await useFormOptions().getFormOptions());
    };
    setupOptions();
  }, []);

  useEffect(() => {
    validate(false);
  }, [finalCity, germanLevel, companyChecked, position]);

  const validate = (withError: boolean) => {
    const errors = [];
    if (!finalCity || finalCity?.name === "") {
      errors.push(ErrorFields.CITY);
    }
    if (germanLevel === "") {
      errors.push(ErrorFields.GERMANLEVEL);
    }
    if ((companyChecked ? "arbeitssuchend" : company) === "") {
      errors.push(ErrorFields.COMPANY);
    }
    if (position === "" || position === "select") {
      errors.push(ErrorFields.POSITION);
    }
    if (withError && errors.length !== 0) {
      setErrorFields(errors);
      return false;
    }
    return true;
  };

  const submit = (e: any) => {
    const isValid = validate(true);
    console.log(isValid);
    if (isValid) {
      const request = {
        location: finalCity,
        germanLevel: germanLevel,
        company: companyChecked ? "arbeitssuchend" : company,
        position: position,
      };
      props.action(e, 1, request);
    }
  };

  const companyChange = (isTextField: boolean, value?: string) => {
    if (isTextField) {
      setCompanyChecked(false);
      setCompany(value ?? "");
    } else {
      setCompanyChecked(true);
      setCompany("");
    }
  };

  const onCityClick = (index: number) => {
    setFinalCity({
      name: suggestions[index].name,
      placeFormatted: suggestions[index].place_formatted,
      mapBoxId: suggestions[index].mapbox_id,
    });
  };

  return (
    <>
      <Component name="form-inputs">
        {formOptions && (
          <>
            <Component name="form-row">
              <Component name="form-item">
                <TextInputElement
                  id="city"
                  svg={<Icon name="tf" svg={<LocationSVG />} />}
                  label="Wohnort"
                  type="text"
                  labelClasses="p--l"
                  placeHolder="Berlin"
                  value={finalCity?.name ?? cityUserInput}
                  onBlur={() => setSuggestions([])}
                  onInput={(e) => {
                    setFinalCity(undefined);
                    setCityUserInput((e.target as HTMLInputElement).value);
                  }}
                  errorMessage="Bitte ergänzen Sie ihren Wohnort"
                  displayErrorMessage={errorFields?.includes(ErrorFields.CITY)}
                />
                {suggestions.length > 0 && (
                  <ComponentCard name="suggestions">
                    {suggestions.map((suggestion, index) => {
                      return (
                        <SearchSuggestion
                          key={suggestion.name + suggestion.place_formatted}
                          text={suggestion.name}
                          sub={suggestion.place_formatted}
                          onMouseDown={() => onCityClick(index)}
                        />
                      );
                    })}
                  </ComponentCard>
                )}
              </Component>
            </Component>
            <Component name="form-row">
              <Component name="form-input">
                <TagSelect
                  id="germanLevel"
                  label="Sprichst du Deutsch"
                  labelClasses="p--l"
                  optionLabelClasses="p--m"
                  name="cs-lang"
                  isHidden={true}
                  options={formOptions.germanLevelOptions}
                  type="radio"
                  value={germanLevel}
                  onChange={(e) => setGermanLevel((e.target as HTMLInputElement).id)}
                  errorMessage="Bitte ergänzen Sie ihr Deutschlevel"
                  displayErrorMessage={errorFields?.includes(ErrorFields.GERMANLEVEL)}
                />
              </Component>
            </Component>

            <Component name="form-row">
              <Component name="form-input">
                <TextInputElement
                  id="company"
                  label="Firma"
                  type="text"
                  labelClasses="p--l"
                  placeHolder="SDRs of Germany"
                  onInput={(e) => companyChange(true, (e.target as HTMLInputElement).value)}
                  value={company}
                  errorMessage="Bitte ergänzen Sie ihre Firma"
                  displayErrorMessage={errorFields?.includes(ErrorFields.COMPANY)}
                />
              </Component>
              <Component name="form-input">
                <TagSelect
                  id="company"
                  label=""
                  labelClasses="p--l"
                  optionLabelClasses="p--m"
                  name="cs-company"
                  isHidden={true}
                  options={["Arbeitssuchend"]}
                  type="checkbox"
                  isChecked={companyChecked}
                  onChange={(e) => companyChange(false, (e.target as HTMLInputElement).value)}
                />
              </Component>
            </Component>
            <Component name="form-row">
              <Component name="form-input">
                <SelectInput
                  id="position"
                  label="Position"
                  labelClasses="p--l"
                  optionLabelClasses="p--m"
                  name="cs-position"
                  value={position}
                  options={formOptions.positionOptions}
                  onInput={(e) => {
                    setPosition((e.target as HTMLInputElement).value);
                  }}
                  errorMessage="Bitte ergänzen Sie ihre Firma"
                  displayErrorMessage={errorFields?.includes(ErrorFields.POSITION)}
                />
              </Component>
            </Component>
            <Component name="form-row">
              <Component name="form-item">
                <Button onClick={submit} name="submit" type="primary">
                  Weiter
                </Button>
              </Component>
            </Component>
          </>
        )}
      </Component>
    </>
  );
};

const SecondStep = (props: StepProps) => {
  const [industry, setIndustry] = useState<string[]>([]);
  const [experience, setExperience] = useState("");
  const [interests, setInterests] = useState<string[]>([]);
  const [leads, setLeads] = useState<string[]>([]);
  const [otherLeads, setOtherLeads] = useState<string>("");
  const [otherInterests, setOtherInterests] = useState<string>("");
  const [errorFields, setErrorFields] = useState<ErrorFields[]>();
  const { user } = useUser();
  const [formOptions, setFormOptions] = useState<any>();

  useEffect(() => {
    if (user?.career) {
      const careerDetails = user.career;
      setIndustry(careerDetails.industry ?? []);
      setExperience(careerDetails.experience ?? "");
      setInterests(careerDetails.interests ?? []);
      setLeads(careerDetails.leads ?? []);
    }
  }, [user]);

  useEffect(() => {
    const setupOptions = async () => {
      setFormOptions(await useFormOptions().getFormOptions());
    };
    setupOptions();
  }, []);

  useEffect(() => {
    validate(false);
  }, [industry, experience, interests, leads]);

  const validate = (withError: boolean) => {
    const errors = [];
    if (industry.length === 0) {
      errors.push(ErrorFields.INDUSTRY);
    }
    if (experience === "") {
      errors.push(ErrorFields.EXPERIENCE);
    }
    if (formOptions && othersSelected(interests, formOptions.interestsOptions).length === 0) {
      errors.push(ErrorFields.INTERESTS);
    }
    if (formOptions && othersSelected(leads, formOptions.leadsOptions).length === 0) {
      errors.push(ErrorFields.LEADS);
    }
    if (withError && errors.length !== 0) {
      setErrorFields(errors);
      return false;
    }
    return true;
  };

  const submit = (e: any) => {
    const isValid = validate(true);
    if (isValid) {
      const request: {
        industry: string[];
        experience: string;
        interests: string[];
        otherInterests?: string;
        leads: string[];
        otherLeads?: string;
      } = {
        industry: industry,
        experience: experience,
        interests: othersSelected(interests, formOptions.interestsOptions),
        leads: othersSelected(leads, formOptions.leadsOptions),
      };
      if (leads.includes("others")) {
        request.otherLeads = otherLeads === "" ? "NONE" : otherLeads;
      }
      if (interests.includes("others")) {
        request.otherInterests = otherInterests === "" ? "NONE" : otherInterests;
      }
      props.action(e, 1, request);
    }
  };

  return (
    <>
      {formOptions && (
        <>
          <Component name="form-row">
            <Component name="form-input">
              <TagSelect
                id="industry"
                label="In welcher Industrie arbeitest du?"
                labelClasses="p--l"
                optionLabelClasses="p--m"
                name="cs-industry"
                isHidden={true}
                options={formOptions?.industryOptions}
                type="checkbox"
                onChange={(e) =>
                  toggleItem((e.target as HTMLInputElement).id, setIndustry, industry)
                }
                value={industry.join(",")}
                errorMessage="Bitte ergänzen Sie ihre Industrie"
                displayErrorMessage={errorFields?.includes(ErrorFields.INDUSTRY)}
              />
            </Component>
          </Component>
          <Component name="form-row">
            <Component name="form-input">
              <Slider
                id="experience"
                label="Wie viel Erfahrung hast du?"
                labelClasses="p--l"
                maxValue={formOptions.yearsExperienceOptions.length - 1}
                minValue={0}
                value={formOptions.yearsExperienceOptions.findIndex(
                  (item: any) => item.id === experience,
                )}
                displayValue={
                  formOptions.yearsExperienceOptions.find((item: any) => item.id === experience)
                    ?.name
                }
                onInput={(e) => {
                  const value =
                    formOptions.yearsExperienceOptions[
                      (e.target as HTMLInputElement).value as unknown as number
                    ];
                  setExperience(value.id);
                }}
                showMinAndMaxValue={false}
                factor={1}
                errorMessage="Bitte ergänzen Sie ihre Erfahrungen"
                displayErrorMessage={errorFields?.includes(ErrorFields.EXPERIENCE)}
              />
            </Component>
          </Component>
          <Component name="form-row">
            <Component name="form-input">
              <TagSelect
                id="interests"
                label="Was erhoffst du dir?"
                labelClasses="p--l"
                optionLabelClasses="p--m"
                name="cs-interests"
                isHidden={true}
                options={formOptions.interestsOptions}
                type="checkbox"
                onChange={(e) =>
                  toggleItem((e.target as HTMLInputElement).id, setInterests, interests)
                }
                value={interests.join(",")}
                errorMessage="Bitte ergänzen Sie ihre Interessen"
                displayErrorMessage={errorFields?.includes(ErrorFields.INTERESTS)}
              />
              {interests.includes("others") && (
                <TextInputElement
                  id="interests-others"
                  type="text"
                  labelClasses="p--l"
                  onChange={(e) => setOtherInterests((e.target as HTMLInputElement).value)}
                  value={otherInterests}
                />
              )}
            </Component>
          </Component>
          <Component name="form-row">
            <Component name="form-input">
              <TagSelect
                id="lead"
                label="Woher kennst du uns?"
                labelClasses="p--l"
                optionLabelClasses="p--m"
                name="cs-lead"
                isHidden={true}
                options={formOptions.leadsOptions}
                type="checkbox"
                onChange={(e) => toggleItem((e.target as HTMLInputElement).id, setLeads, leads)}
                value={leads.join(",")}
                errorMessage="Bitte ergänzen Sie woher sie uns kennen"
                displayErrorMessage={errorFields?.includes(ErrorFields.LEADS)}
              />
              {leads.includes("others") && (
                <TextInputElement
                  id="leads-others"
                  type="text"
                  labelClasses="p--l"
                  onChange={(e) => setOtherLeads((e.target as HTMLInputElement).value)}
                  value={otherLeads}
                />
              )}
            </Component>
          </Component>
          <Component name="form-row">
            <Component name="form-input">
              <Button onClick={submit} name="submit" type="primary">
                {"Speichern"}
              </Button>
            </Component>
          </Component>
        </>
      )}
    </>
  );
};

const HyreStep = (props: StepProps) => {
  const [minSalary, setMinSalary] = useState<number>(25);
  const [minFixedSalary, setMinFixedSalary] = useState<number>(25);
  const [selectedTargetLocations, setTargetLocations] = useState<TargetLocation[]>([]);
  const [leadWarmth, setLeadWarmth] = useState<string>();
  const [error, setError] = useState("");
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const [formOptions, setFormOptions] = useState<any>();
  const { user } = useUser();

  useEffect(() => {
    if (user?.hyre) {
      const hyre = user.hyre;
      setMinSalary(hyre.minSalary / 1000 ?? 0);
      setMinFixedSalary(hyre.minFixedSalary / 1000 ?? 0);
      setTargetLocations(hyre.targetLocations ?? []);
      setLeadWarmth(hyre.leadWarmth ?? []);
    }
  }, [user]);

  useEffect(() => {
    const setupOptions = async () => {
      setFormOptions(await useFormOptions().getFormOptions());
    };
    setupOptions();
  }, []);

  useEffect(() => {
    setIsValidated(validate(false));
  }, [selectedTargetLocations, leadWarmth, minSalary, minFixedSalary]);

  const validate = (withError: boolean) => {
    let errorMessage = "";
    const isValid = true;
    if (selectedTargetLocations.length === 0) {
      errorMessage = "Bitte ergänzen Sie eine Ortschaft wo Sie arbeiten wollen";
    } else if (leadWarmth === "") {
      errorMessage = "Bitte ergänzen Sie ihre Erfahrungen";
    }
    if (withError) {
      setError(errorMessage);
    }
    return isValid;
  };

  const submit = (e: any) => {
    const isValid = validate(true);
    if (isValid) {
      const request = {
        hyre: {
          minSalary: minSalary * 1000,
          minFixedSalary: minFixedSalary * 1000,
          targetLocations: selectedTargetLocations,
          leadWarmth: leadWarmth,
        },
      };
      props.action(e, 1, request);
      setIsValidated(false);
    }
  };

  return (
    <>
      {formOptions && (
        <>
          <Paragraph name="hyre-future">Wie sehen deine Langfristigen Karriereziele aus?</Paragraph>
          <Component name="form-row">
            <Component name="form-input">
              <Slider
                id="minSalary"
                label="Wie viel möchtest du pro Jahr insgesamt verdienen?"
                labelClasses="p--l"
                maxValue={125}
                minValue={25}
                value={minSalary}
                onInput={(e) =>
                  setMinSalary((e.target as HTMLInputElement).value as unknown as number)
                }
                showMinAndMaxValue={true}
                factor={1000}
              />
            </Component>
          </Component>
          <Component name="form-row">
            <Component name="form-input">
              <Slider
                id="fixedMinSalary"
                label="Wie hoch sollte dein jährliches Fixum sein?"
                maxValue={125}
                minValue={25}
                showMinAndMaxValue={true}
                factor={1000}
                labelClasses="p--l"
                value={minFixedSalary}
                onInput={(e) =>
                  setMinFixedSalary((e.target as HTMLInputElement).value as unknown as number)
                }
              />
            </Component>
          </Component>
          <Component name="form-row">
            <Component name="form-input">
              <TagSelect
                id="targetLocations"
                label="Wo willst du arbeiten?"
                labelClasses="p--l"
                optionLabelClasses="p--m"
                name="cs-industry"
                isHidden={true}
                options={formOptions.targetLocationsOptions}
                type="checkbox"
                onChange={(e) =>
                  toggleItem(
                    (e.target as HTMLInputElement).id,
                    setTargetLocations,
                    selectedTargetLocations,
                  )
                }
                value={selectedTargetLocations.join(",")}
              />
            </Component>
          </Component>
          <Component name="form-row">
            <Component name="form-input">
              <TagSelect
                id="leadWarmth"
                label="Was für eine Art Sales möchtest du machen?"
                labelClasses="p--l"
                optionLabelClasses="p--m"
                name="cs-leadWarmth"
                isHidden={true}
                options={formOptions.leadWarmthOptions}
                type="radio"
                onChange={(e) => setLeadWarmth((e.target as HTMLInputElement).id)}
                value={leadWarmth}
              />
            </Component>
          </Component>
          <Component name="form-row">
            <Component name="form-input">
              <Button onClick={submit} name="submit" type="primary" disabled={!isValidated}>
                Speichern
              </Button>
            </Component>
          </Component>
          <ErrorMessage displayErrorMessage={error !== ""} errorMessage={error} id="personal" />
        </>
      )}
    </>
  );
};

export default CareerStep;
