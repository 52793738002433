import {
  Dispatch,
  forwardRef,
  HTMLAttributes,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ProfilePictureSVG from "../../Icons/ProfilePictureSVG";
import "./elements.scss";
import { ErrorMessage, TextInputElement } from "./FormElements";
import EditSVG from "../../Icons/EditSVG";
import Context from "../../context/Context";
import historyNavigate from "../../hooks/useHistoryNavigate";
import ArrowSVG from "../../Icons/ArrowSVG";
import { useLocation } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import {
  ChatResponseDto,
  EventResponseDto,
  MediaResponseDto,
  Paginated,
  PostResponseDto,
  SkillResponseDto,
  UsersResponseDto,
  WelcomeMessageResponseDto,
} from "../../requests/interfaces";
import React from "react";
import useRequest from "../../hooks/useRequest";
import { useProfile } from "../../hooks/useProfile";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";
import ImageUploadSVG from "../../Icons/ImageUploadSVG";
import SettingsSVG from "../../Icons/SettingsSVG";
import UploadSVG from "../../Icons/UploadSVG";
import CloseSVG from "../../Icons/CloseSVG";
import { Editor } from "@tinymce/tinymce-react";
import ExclamationMarkSVG from "../../Icons/ExclamationMarkSVG";
import UnlockSVG from "../../Icons/UnlockSVG";
import LockSVG from "../../Icons/LockSVG";
import SearchSVG from "../../Icons/SearchSVG";
import ErrorBoundary from "../error/ErrorBoundary";

interface Props extends HTMLAttributes<HTMLElement> {
  name?: string;
  children?: any;
  disabled?: boolean;
}

interface LinkProps extends Props {
  href: string;
  download?: boolean;
  target?: string;
}

interface ComponentCardProps extends Props {
  type?: "shadow" | "outline" | "standard";
  showClickable?: boolean;
}

interface ListProps extends Props {
  type: "big" | "medium" | "small";
}

interface SectionElementProps extends Props {
  type: "header" | "body" | "footer";
}

interface IconProps extends Props {
  svg?: any;
  iconBefore?: boolean;
}

interface ImageProps extends Props {
  alt?: string;
  img: any;
  title?: string;
  loadingState?: "loading" | "done";
  size?: "xs" | "s" | "m" | "l" | "xl";
  clickable?: boolean;
}

interface MediaUploadProps extends ImageProps {
  onImageUploaded?: (url: string) => void;
  onManyImagesUploaded?: (urls: { url: string }[], filename?: string) => void;
  method?: "POST" | "PUT" | "DELETE" | "PATCH";
  route?: string;
  type?: "small" | "large";
  onImageError?: (url: string) => void;
  trackType?: "content" | "exercise" | "discussion";
  errorMessage?: string;
  displayErrorMessage?: boolean;
}

interface FileUploadProps extends Props {
  onFileUploaded?: (url: string) => void;
  method?: "POST" | "PUT" | "DELETE" | "PATCH";
  route?: string;
  type?: "small" | "large";
  onFileError?: (url: string) => void;
  file?: any;
  trackType?: "content" | "exercise" | "discussion";
}

interface WrapperProps extends Props {
  wrType:
    | "paragraph"
    | "title"
    | "label"
    | "input"
    | "button"
    | "icon"
    | "image"
    | "element"
    | "link";
}

interface ContentProps extends Props {
  type?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "paragraph";
}

interface TitleProps extends ContentProps {
  size?: "xxl" | "xl" | "l" | "m" | "s" | "xs" | "xxs";
}

interface ParagraphProps extends ContentProps {
  size?: "l" | "m" | "s" | "btn" | "nav";
  withLineBreaks?: boolean;
  withLinks?: boolean;
  target?: "_blank" | "";
}

interface ButtonProps extends IconProps {
  type:
    | "primary"
    | "onPrimary"
    | "primaryText"
    | "onPrimaryText"
    | "primaryGhost"
    | "onPrimaryGhost"
    | "darkText"
    | "darkGhost"
    | "dark"
    | "lightText"
    | "danger"
    | "dangerGhost";
  errorMessage?: string;
  displayErrorMessage?: boolean;
}

interface SearchSuggestionProps extends IconProps {
  text: string;
  sub?: string;
}

type TableSearchProps<T> = {
  route: string;
  setItem: Dispatch<SetStateAction<T>>;
  resetSearched: () => void;
};

interface TableColumnProps extends Props {
  colspan?: number;
}

interface TagProps extends Props {
  isActive: boolean;
}
type FeaturedItem = UsersResponseDto | EventResponseDto;

const Page = ({ children, name }: Props) => {
  const { popupContent } = useContext(Context);
  return (
    <>
      {popupContent && <div className="popup">{popupContent}</div>}
      <div className={`pg--${name} pg`}>{children}</div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

const Main = ({ children }: Props) => {
  return (
    <ErrorBoundary>
      <main>{children}</main>
    </ErrorBoundary>
  );
};

const AppView = ({ children }: Props) => {
  const { sitebarOpen, isMobile } = useContext(Context);
  return (
    <>
      <div
        className={`interface ${sitebarOpen && isMobile ? "not-scrollable" : ""}`}
        id="parent-component">
        {children}
      </div>
    </>
  );
};

const Section = ({ children, name }: Props) => {
  return (
    <>
      <section className={`sc--${name} sc`}>
        <Container name={name}>{children}</Container>
      </section>
    </>
  );
};

const Container = ({ children, name }: Props) => {
  return (
    <>
      <div className={`cr--${name} cr`}>{children}</div>
    </>
  );
};

const SectionElement = ({ type, name, children, ...props }: SectionElementProps) => {
  let className;

  if (type === "header") {
    className = "sc_h";
  } else if (type === "body") {
    className = "sc_b";
  } else if (type === "footer") {
    className = "sc_f";
  }
  if (React.Children.count(children) === 0) {
    return null; // Don't render anything if there are no children
  }

  return (
    <>
      <div className={`${className}--${name} ${className}`} {...props}>
        {children}
      </div>
    </>
  );
};

const Component = forwardRef(({ name, children, ...props }: Props, ref: any) => {
  return (
    <>
      <div {...props} className={`cmp--${name} cmp ${props.className ?? ""}`} id="" ref={ref}>
        <Layout {...props} name={name}>
          {children}
        </Layout>
      </div>
    </>
  );
});
Component.displayName = "Component";

const LabelComponent = forwardRef(({ name, children, ...props }: Props, ref: any) => {
  return (
    <>
      <div {...props} className={`cmp--${name} cmp ${props.className ?? ""}`} id="" ref={ref}>
        <label className={`lyt--${name} lyt`}>{children}</label>
      </div>
    </>
  );
});
LabelComponent.displayName = "LabelComponent";

const ComponentCard = forwardRef(
  ({ name, children, type, showClickable, ...props }: ComponentCardProps, ref: any) => {
    let className = "";
    if (props.onClick && name !== "sb") {
      if (showClickable === undefined || showClickable) {
        className = "clickable";
      }
    }

    return (
      <>
        <div {...props} className={`cmp--${name} cmp ${props.className ?? ""}`} ref={ref}>
          <div
            className={`cd--${name} cd ${type ?? (name !== "sb" ? "shadow" : "standard")} ${
              props.className ?? ""
            } ${className}`}>
            <Layout name={name}>{children}</Layout>
          </div>
        </div>
      </>
    );
  },
);
ComponentCard.displayName = "ComponentCard";

const Layout = ({ name, children, ...props }: Props) => {
  const id = props.id ? { id: props.id } : {};

  return (
    <>
      <div className={`lyt--${name} lyt ${props.className ?? ""}`} {...id}>
        {children}
      </div>
    </>
  );
};

const Wrapper = ({ wrType, children, name, ...props }: WrapperProps) => {
  let className;

  if (wrType === "paragraph") {
    className = "wr_p";
  } else if (wrType === "title") {
    className = "wr_h";
  } else if (wrType === "label") {
    className = "wr_lbl";
  } else if (wrType === "image") {
    className = "wr_img";
  } else if (wrType === "input") {
    className = "wr_inp";
  } else if (wrType === "button") {
    className = "wr_btn";
  } else if (wrType === "element") {
    className = "wr_el";
  } else if (wrType === "icon") {
    className = "wr_ico";
  } else if (wrType === "link") {
    className = "wr_a";
  } else {
    className = "sc_f";
  }

  return (
    <>
      <div
        {...props}
        className={`${className}--${name} ${className} ${props.className ?? ""}`}
        id="">
        {children}
      </div>
    </>
  );
};

const Title = ({ size: size, type, children, name, ...props }: TitleProps) => {
  return (
    <>
      <Wrapper {...props} wrType={type === "paragraph" ? "paragraph" : "title"} name={name}>
        {type === "h1" && <h1 className={`h--${size} ${props.className ?? ""}`}>{children}</h1>}
        {type === "h2" && <h2 className={`h--${size} ${props.className ?? ""}`}>{children}</h2>}
        {type === "h3" && <h3 className={`h--${size} ${props.className ?? ""}`}>{children}</h3>}
        {type === "h4" && <h4 className={`h--${size} ${props.className ?? ""}`}>{children}</h4>}
        {type === "h5" && <h5 className={`h--${size} ${props.className ?? ""}`}>{children}</h5>}
        {type === "h6" && <h6 className={`h--${size} ${props.className ?? ""}`}>{children}</h6>}
        {type === "paragraph" && (
          <p className={`h--${size} ${props.className ?? ""}`}>{children}</p>
        )}
      </Wrapper>
    </>
  );
};

const Paragraph = ({
  name,
  children,
  size,
  withLineBreaks,
  withLinks,
  target,
  dangerouslySetInnerHTML,
  ...props
}: ParagraphProps) => {
  let lines = [];
  if ((withLineBreaks || withLinks) && children) {
    lines = children.split("\n");
  }

  const innerHtml: { dangerouslySetInnerHTML?: any } = {};

  if (dangerouslySetInnerHTML) {
    innerHtml.dangerouslySetInnerHTML = dangerouslySetInnerHTML;
  }

  if (withLinks) {
    const httpRegex = /(https?:\/\/[^\s]+)/g;
    const final = [];
    for (let index = 0; index < lines.length; index++) {
      const line = lines[index];
      const replaced = line.replace(httpRegex, function (url: any) {
        return (
          '<a href="' +
          url +
          '" target="' +
          (target ?? "_blank") +
          '" class="txt--c-pri">' +
          url +
          "</a>"
        );
      });
      final.push(replaced);
    }
    lines = [...final];
  }

  return (
    <>
      <Wrapper name={name} wrType="paragraph" {...props} className="">
        <p className={`p--${size ?? "m"} ${props.className ?? ""}`} {...innerHtml}>
          {(withLineBreaks === true || withLinks === true) &&
            lines.map((line: string, i: number) => {
              return (
                <span
                  key={line + i}
                  dangerouslySetInnerHTML={{
                    __html: `
                        ${line}
                        <br />`,
                  }}
                />
              );
            })}
          {!withLineBreaks && <>{children}</>}
        </p>
      </Wrapper>
    </>
  );
};

const Link = ({ name, children, ...props }: LinkProps) => {
  return (
    <>
      <Wrapper wrType="link" name={name} {...props}>
        <a
          {...props}
          href={props.href}
          download={props.download}
          target={props.target ?? "_blank"}
          rel="noreferrer">
          {children}
        </a>
      </Wrapper>
    </>
  );
};

const TextSeperator = ({ name, children, ...props }: Props) => {
  return (
    <>
      <Wrapper name={name} wrType="element" {...props}>
        <hr className={`el--${name} el`}></hr>
        {children}
        <hr className={`el--${name} el`}></hr>
      </Wrapper>
    </>
  );
};

const Seperator = () => {
  return (
    <>
      <Wrapper name="sep" wrType="element">
        <hr className={`el--sep el`}></hr>
      </Wrapper>
    </>
  );
};

const ComponentButton = ({ name, children, disabled, ...props }: Props) => {
  return (
    <>
      <button {...props} className={`cmp--${name} cmp ${props.className}`} disabled={disabled}>
        <Layout name={name}>{children}</Layout>
      </button>
    </>
  );
};

const Button = ({ name, children, iconBefore, svg, ...props }: ButtonProps) => {
  iconBefore = iconBefore ?? false;
  let classNames = "bg--";

  if (props.type === "primary") {
    classNames += "pri txt--c-on-pri";
  } else if (props.type === "onPrimary") {
    classNames += "on-pri txt--c-pri";
  } else if (props.type === "primaryGhost") {
    classNames += "pri ghost txt--c-pri";
  } else if (props.type === "onPrimaryGhost") {
    classNames += "on-pri ghost txt--c-on-pri";
  } else if (props.type === "darkGhost") {
    classNames += "on-bg ghost txt--c-on-bg";
  } else if (props.type === "primaryText") {
    classNames = "text txt--c-pri";
  } else if (props.type === "onPrimaryText") {
    classNames = "text txt--c-on-pri";
  } else if (props.type === "darkText") {
    classNames = "text txt--c-on-bg";
  } else if (props.type === "dark") {
    classNames += "dark txt--c-on-pri";
  } else if (props.type === "lightText") {
    classNames = "text txt--c-on-surface-l";
  } else if (props.type === "danger") {
    classNames += "error txt--c-on-pri";
  } else if (props.type === "dangerGhost") {
    classNames += "error ghost txt--c-error";
  }
  classNames += " txt--no-deco txt--a-c";

  return (
    <>
      <Wrapper wrType="button" name={name}>
        <ComponentButton {...props} name="btn" className={classNames}>
          {iconBefore && svg !== undefined && <Icon name="btn" svg={svg} />}
          <Paragraph name="btn" size="nav">
            {children}
          </Paragraph>
          {iconBefore === false && svg !== undefined && <Icon name="btn" svg={svg} />}
        </ComponentButton>
      </Wrapper>
    </>
  );
};

const BackButton = (props: { fixedLocation?: string; onClick?: any }) => {
  const [backLoc, setBackLoc] = useState<string>();
  const location = useLocation();
  const { useHistoryNavigate } = historyNavigate();

  useEffect(() => {
    setBackLoc(location.state?.prevLocation);
  }, [backLoc]);

  return (
    <Button
      type="darkGhost"
      svg={<ArrowSVG />}
      iconBefore={true}
      name="back"
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        } else {
          useHistoryNavigate(props.fixedLocation ?? backLoc ?? "/");
        }
      }}>
      Zurück
    </Button>
  );
};

const Icon = ({ svg, name, ...props }: IconProps) => {
  return (
    <>
      <Wrapper wrType="icon" name={`${name}`} {...props}>
        {svg}
      </Wrapper>
    </>
  );
};

const Image = ({ name, img, alt, ...props }: ImageProps) => {
  let src;
  if (typeof img !== "string" && img) {
    src = URL.createObjectURL(img);
  } else {
    src =
      !img || img === "" ? "/standard-profile-picture.ico" : img + `?param=${new Date().getTime()}`;
  }

  return (
    <>
      <Wrapper {...props} wrType="image" name={`${name}`}>
        <img src={src} alt={alt} />
      </Wrapper>
    </>
  );
};

const ProfilePictures = ({ children }: Props) => {
  return <Component name="profile-pictures">{children}</Component>;
};

const ProfilePicture = ({ img, loadingState, size, clickable, ...props }: ImageProps) => {
  const className = [];
  if (loadingState) {
    className.push(loadingState === "loading" ? "is-loading " : "");
  }

  if (size === "xl") {
    className.push("extralarge");
  } else if (size === "l") {
    className.push("large");
  } else if (size === "s") {
    className.push("small");
  } else if (size === "xs") {
    className.push("extrasmall");
  } else {
    className.push("medium");
  }

  if (clickable === false) {
    className.push("unclickable");
  }

  return (
    <>
      <Component name={`profile-pic`} {...props} className={className.join(" ")}>
        {/* <Image name="profile" img={undefined}></Image> */}
        {img !== null && img && (
          <Wrapper wrType="image" name="profile-picture">
            <img
              className="profile-picture"
              alt="profile-picture"
              width={"250px"}
              src={
                typeof img === "string"
                  ? img + `?param=${new Date().getTime()}`
                  : URL.createObjectURL(img)
              }
            />
          </Wrapper>
        )}
        {(img === null || !img) && <Icon name="profile-pic" svg={<ProfilePictureSVG />}></Icon>}
      </Component>
    </>
  );
};

const ProfilePictureEdit = ({ img, method, ...props }: MediaUploadProps) => {
  const [, setError] = useState<string>("");
  const fileInput: any = useRef();
  const dropContainer: any = useRef();
  const { apiFileRequest: apiImageRequest } = useRequest();

  // const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const isImageFile = (fileType: any): boolean => {
    if (fileType.includes("png") || fileType.includes("jpeg")) return true;
    return false;
  };
  // const fileTypes = ["JPEG", "PNG", "GIF"];

  // const handleChange = (event: any) => {
  //   if (event.target.files != null) {
  //     if (event.target.files[0].size > 2097152) {
  //       setError("file is to big. Max file size is 2 mb");
  //     } else if (!isImageFile(event.target.files[0].type)) {
  //       setError("Please select an image with the ending .png or .jpg or .jpeg");
  //     } else {
  //       setError("");
  //       setSelectedImage(event.target.files[0]);
  //     }
  //   }
  // };

  const uploadImage = async (event: any) => {
    if (props.onChange) {
      props.onChange(event);
      if (method !== "PUT") {
        const { data } = await apiImageRequest<MediaResponseDto>(
          "user/profilePicture",
          "POST",
          event.target.files[0],
        );
        if (props.onImageUploaded) {
          props.onImageUploaded(data.url ?? data.id ?? "");
        }
      }
    }
  };

  return (
    <>
      <Component name="profile-edit" onClick={() => fileInput?.current.click()} ref={dropContainer}>
        <ProfilePicture
          img={img === null ? "" : img}
          alt="profile-picture"
          loadingState={img !== null && typeof img !== "string" ? "loading" : "done"}
        />
        {/* <FileUploader multiple={true} handleChange={handleChange} name="file" types={fileTypes} /> */}
        <input
          type="file"
          name="myImage"
          className="hidden"
          ref={fileInput}
          onChange={(event) => {
            if (event.target.files != null) {
              if (!isImageFile(event.target.files[0].type)) {
                setError("Please select an image with the ending .png or .jpg or .jpeg");
              } else {
                setError("");
                uploadImage(event);
              }
            }
          }}
        />
        <Icon name="profile-edit" svg={<EditSVG />}></Icon>
        <ErrorMessage
          displayErrorMessage={props.displayErrorMessage}
          errorMessage={props.errorMessage}
          id="file-upload"
        />
      </Component>
    </>
  );
};

const ProfileBubble = (props: { user: UsersResponseDto }) => {
  const { user } = useUser();
  const { onProfileClick } = useProfile();

  return (
    <Component
      name="profile-bubble"
      onClick={() => {
        if (props.user._id) {
          onProfileClick(props.user._id);
        }
      }}>
      <ProfilePicture size="s" img={props.user.picture + `?param=${new Date().getTime()}`} />
      {props.user._id !== null && (
        <Paragraph size="l" type="paragraph" name="admin-resource-author">
          {props.user._id === user?._id ? "Du" : `${props.user.firstName} ${props.user.lastName}`}
        </Paragraph>
      )}
      {props.user._id === null && (
        <Paragraph size="l" type="paragraph" name="admin-resource-author">
          Anonym
        </Paragraph>
      )}
    </Component>
  );
};

const IconImageUploader = ({ method, route, ...props }: MediaUploadProps) => {
  const fileInput: any = useRef();
  const { apiFileRequest: apiImageRequest } = useRequest();

  const isImageFile = (fileType: any): boolean => {
    if (fileType.includes("png") || fileType.includes("jpeg")) return true;
    return false;
  };

  const uploadImage = async (event: any) => {
    if (props.onChange) {
      props.onChange(event);
      if (method !== "PUT" && route) {
        if (props.onImageUploaded) {
          const { data } = await apiImageRequest<MediaResponseDto>(
            route,
            "POST",
            event.target.files[0],
          );
          props.onImageUploaded(data.url ?? data.id ?? "");
        }
        if (props.onManyImagesUploaded) {
          const { data } = await apiImageRequest<MediaResponseDto[]>(route, "POST", [
            event.target.files[0],
          ]);
          props.onManyImagesUploaded(data, event.target.files[0].name);
        }
      }
    }
  };

  return (
    <>
      <input
        type="file"
        name="image-uploader"
        className="hidden"
        ref={fileInput}
        onChange={(event) => {
          if (event.target.files != null) {
            if (!isImageFile(event.target.files[0].type) && props.onImageError) {
              props.onImageError("Please select an image with the ending .png or .jpg or .jpeg");
            } else {
              if (props.onImageError) {
                props?.onImageError("");
                uploadImage(event);
              }
            }
          }
        }}
      />
      <Icon
        svg={<ImageUploadSVG />}
        name="image-upload-icon"
        onClick={() => fileInput?.current.click()}
      />
    </>
  );
};

const ImageUploader = ({ img, method, route, type, trackType, ...props }: MediaUploadProps) => {
  const [error, setError] = useState<string>("");
  const fileInput: any = useRef();
  const dropContainer: any = useRef();
  const { apiFileRequest: apiImageRequest } = useRequest();

  const isImageFile = (fileType: any): boolean => {
    if (fileType.includes("png") || fileType.includes("jpeg")) return true;
    return false;
  };

  const uploadImage = async (event: any) => {
    if (props.onChange) {
      props.onChange(event);
      if (route) {
        const { data } = await apiImageRequest<MediaResponseDto>(
          route,
          method ?? "POST",
          event.target.files[0],
          {
            trackType: trackType,
          },
        );
        if (props.onImageUploaded) {
          props.onImageUploaded(data?.url ?? data?.id ?? "");
        }
      }
    }
  };

  return (
    <>
      <Component
        name={`image-edit-${type ?? "small"}`}
        onClick={() => fileInput?.current.click()}
        ref={dropContainer}>
        <Image img={img} name="image-upload" />
        <input
          type="file"
          name="image-uploader"
          className="hidden"
          ref={fileInput}
          onChange={(event) => {
            if (event.target.files != null) {
              if (!isImageFile(event.target.files[0].type)) {
                setError("Please select an image with the ending .png or .jpg or .jpeg");
              } else {
                setError("");
                uploadImage(event);
              }
            }
          }}
        />
      </Component>
      <ErrorMessage displayErrorMessage={error !== ""} errorMessage={error} id="file-upload" />
    </>
  );
};

const VideoUploader = ({ img, method, route, type, trackType, ...props }: MediaUploadProps) => {
  const [error, setError] = useState<string>("");
  const fileInput: any = useRef();
  const dropContainer: any = useRef();
  const { apiFileRequest: apiImageRequest } = useRequest();

  const isImageFile = (fileType: any): boolean => {
    if (fileType.includes("mp4")) return true;
    return false;
  };

  const uploadImage = async (event: any) => {
    if (props.onChange) {
      props.onChange(event);
      if (method !== "PUT" && route) {
        const { data } = await apiImageRequest<MediaResponseDto>(
          route,
          "POST",
          event.target.files[0],
          {
            trackType: trackType,
          },
        );
        if (props.onImageUploaded) {
          props.onImageUploaded(data.url);
        }
      }
    }
  };

  return (
    <>
      <Component
        name={`image-edit-${type ?? "small"}`}
        onClick={() => fileInput?.current.click()}
        ref={dropContainer}>
        <Image img={img} name="image-upload" />
        <input
          type="file"
          name="image-uploader"
          className="hidden"
          ref={fileInput}
          onChange={(event) => {
            if (event.target.files != null) {
              if (!isImageFile(event.target.files[0].type)) {
                setError("Please select an image with the ending .png or .jpg or .jpeg");
              } else {
                setError("");
                uploadImage(event);
              }
            }
          }}
        />
      </Component>
      <ErrorMessage displayErrorMessage={error !== ""} errorMessage={error} id="file-upload" />
    </>
  );
};

const FileUploader = ({ method, route, type, ...props }: FileUploadProps) => {
  const fileInput: any = useRef();
  const dropContainer: any = useRef();
  const [file, setFile] = useState<File | undefined | string>(props.file);
  const { apiFileRequest } = useRequest();

  const uploadFile = async (event: any) => {
    if (props.onChange) {
      props.onChange(event);
      if (route) {
        const { data } = await apiFileRequest<MediaResponseDto>(route, method ?? "POST", file, {
          trackType: props.trackType,
        });
        if (props.onFileUploaded) {
          props.onFileUploaded(data?.url);
        }
      }
    }
  };

  return (
    <Component name="file-uploader">
      <Paragraph name="file-uploader" size="s">
        {" "}
        Lade hier deine fertige Aufgabe wieder hoch, um sie einzureichen.
      </Paragraph>
      <Component
        name={`file-edit-${type ?? "small"}`}
        onClick={() => fileInput?.current.click()}
        ref={dropContainer}>
        <Icon name="upload" svg={<UploadSVG />} />
        <input
          type="file"
          name="file-uploader"
          className="hidden"
          ref={fileInput}
          onChange={(event) => {
            if (event.target.files != null) {
              setFile(event.target.files[0]);
            }
          }}
        />
      </Component>
      {typeof file !== "string" && (
        <>
          <Component name="uploaded-file">
            <Paragraph name="file-name" size="s">
              {file?.name ?? "Dein File"}
            </Paragraph>
            <Icon svg={<CloseSVG />} name="remove-file" onClick={() => setFile(undefined)} />
          </Component>
          <Button type="primary" onClick={uploadFile}>
            Bestätigen
          </Button>
        </>
      )}
      {props.file && typeof props.file === "string" && (
        <a download href={props.file} target="_blank" rel="noreferrer">
          Hier kannst du deine fertige Aufgabe wieder herunterladen.
        </a>
      )}
    </Component>
  );
};

const RichTextEditor = forwardRef(
  (props: { setText: Dispatch<SetStateAction<string>>; text: string }, ref: any) => {
    const [reload, setReload] = useState<boolean>(false);

    useEffect(() => {
      if (ref.current) {
        // ref.current.setContent(props.text);
      }
    }, [props.text, reload]);

    useEffect(() => {
      if (ref.current) {
        ref.current.setContent(props.text);
      }
    }, [reload]);

    return (
      <Editor
        apiKey="z8gok3eyn89mehayd38tfjxyxq0fu7qjxf1qqh5719rk37og"
        onInit={(evt, editor) => {
          ref.current = editor;
          setReload((value) => !value);
        }}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "link image media fontsize |" +
            "bold italic strikethrough underline forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | ",
          content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
        onChange={() => {
          props.setText(() => {
            if (ref.current) {
              return ref.current.getContent();
            }
            return "";
          });
        }}
      />
    );
  },
);

RichTextEditor.displayName = "RichTextEditor";

const SearchSuggestion = ({ text, sub, svg, iconBefore, ...props }: SearchSuggestionProps) => {
  return (
    <Component name="search-suggestion" {...props}>
      {iconBefore && svg !== undefined && svg}
      <Component name="search-suggestion-item">
        <Paragraph name="main-suggestion-text" type="paragraph" size="l">
          {text}
        </Paragraph>
        {sub && <Paragraph name="sub-suggestion-text">{sub}</Paragraph>}
      </Component>
      {!iconBefore && svg !== undefined && svg}
    </Component>
  );
};

const Tags = (props: { children: any }) => {
  return <Component name="tags">{props.children}</Component>;
};

const Tag = ({ isActive, children, ...props }: TagProps) => {
  return (
    <Component
      name="tag"
      {...props}
      className={`${isActive ? "active" : ""} ${props.className ?? ""} ${
        props.onClick && !props.className?.includes("unclickable") ? "clickable" : ""
      }`}>
      {children}
    </Component>
  );
};

const TagsFiltered = (props: { hashtags: string[] | SkillResponseDto[]; filters?: string[] }) => {
  return (
    <>
      <Tags>
        {props.hashtags.map((tag) => {
          let isActive = true;
          let label;
          let id: string;
          if (typeof tag !== "string") {
            label = tag.skill;
            id = tag._id;
          } else {
            id = tag;
            label = tag;
          }
          if (props.filters) {
            isActive =
              props.filters.filter((s) => {
                return s.includes(id);
              }).length !== 0;
          }
          return (
            <Tag
              isActive={isActive}
              onClick={(e) => e.stopPropagation()}
              className="unclickable"
              key={id}>
              <Paragraph name="tag">{label}</Paragraph>
            </Tag>
          );
        })}
      </Tags>
    </>
  );
};

const List = ({ type, children, ...props }: ListProps) => {
  return (
    <Component name={`list-${type}`} {...props}>
      {children}
    </Component>
  );
};

const ListItem = ({ children, ...props }: Props) => {
  return (
    <Component name="list-item" {...props}>
      {children}
    </Component>
  );
};

const Table = ({ name, children, ...props }: Props) => {
  return (
    <>
      <table {...props} className={`tbl--${name} tbl ${props.className ?? ""}`}>
        {children}
      </table>
    </>
  );
};

const TableColumn = ({ children, ...props }: TableColumnProps) => {
  return (
    <>
      <td className={`cmp--col cmp ${props.className ?? ""}`} colSpan={props.colspan ?? 1}>
        <Layout name={`col`}>{children}</Layout>
      </td>
    </>
  );
};

const TableSearch = <T,>(props: TableSearchProps<T[]>) => {
  const [searchText, setSearchText] = useState<string>("");
  const { apiRequest } = useRequest();

  const getSearched = async () => {
    const { data } = await apiRequest<Paginated<T>>(
      `${props.route}/search?search=${searchText}`,
      "GET",
    );
    props.setItem(data.docs);
  };

  useEffect(() => {
    if (searchText.length > 1) {
      getSearched();
    } else {
      props.resetSearched();
    }
  }, [searchText]);

  return (
    <Component name="table-search">
      <TextInputElement
        id="city"
        svg={<Icon name="tf" svg={<SearchSVG />} />}
        type="text"
        labelClasses="p--l"
        placeHolder="Suchen"
        value={searchText}
        onInput={(e) => {
          setSearchText((e.target as HTMLInputElement).value);
        }}
      />
    </Component>
  );
};

const TablePagination = (props: {
  hasNext: boolean;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const movePageOneStep = (stepToMove: number) => {
    if (props.currentPage + stepToMove >= 0 && (stepToMove === 1 ? props.hasNext : true)) {
      props.setCurrentPage((value: number) => value + stepToMove);
    }
  };

  const movePageToIndex = (index: number) => {
    if (
      index >= 0 &&
      index !== props.currentPage &&
      (index > props.currentPage ? props.hasNext : true)
    ) {
      props.setCurrentPage(index);
    }
  };

  // always show one next and last and first -> example 3:  1 ... 2 (3-active) 4 ... 10
  const displayPage = (index: number) => {
    if (
      index === props.currentPage + 1 ||
      index === props.currentPage ||
      index === props.currentPage - 1
    ) {
      return true;
    }
    if (index === 0) {
      return true;
    }
    return false;
  };

  const displayDots = (index: number) => {
    if (index === 0) {
      return false;
    }
    if (index === props.currentPage + 2 || index === props.currentPage - 2) {
      return true;
    }
    return false;
  };

  return (
    <Component name="pagination-options">
      <Button
        type="darkGhost"
        name="table-back"
        iconBefore={true}
        svg={<ArrowSVG />}
        onClick={() => movePageOneStep(-1)}>
        Zurück
      </Button>
      <Component name="table-pages">
        {[...Array(100)].map((e, i) => {
          if (props.hasNext || i <= props.currentPage) {
            if (displayPage(i)) {
              return (
                <Component
                  name="table-pagination-page"
                  key={i}
                  className={i === props.currentPage ? "active" : ""}
                  onClick={() => movePageToIndex(i)}>
                  <Paragraph name="table-pagination-page" type="paragraph">
                    {i + 1}
                  </Paragraph>
                </Component>
              );
            }
            if (displayDots(i)) {
              return (
                <Component name="table-pagination-dots" key={i}>
                  <Paragraph name="table-pagination-dots" type="paragraph">
                    ...
                  </Paragraph>
                </Component>
              );
            }
          }
        })}
      </Component>
      <Button
        type="darkGhost"
        name="table-next"
        iconBefore={false}
        svg={<ArrowSVG />}
        onClick={() => movePageOneStep(1)}>
        Vor
      </Button>
    </Component>
  );
};

const Popup = (props: {
  setPopupIsOpen: (value: boolean) => void;
  children: any;
  size: "SMALL" | "BIG";
  aligned?: "RIGHT" | "LEFT";
  isClosable?: boolean;
}) => {
  let alignement = "";
  if (props.aligned) {
    alignement = props.aligned === "LEFT" ? "align-left" : "align-right";
  }

  const className = `popup-${props.size === "BIG" ? "big" : "small"} ${alignement}`;

  return (
    <Component
      name="popup-overlay"
      onClick={() => {
        if (props.isClosable === undefined || props.isClosable) {
          props.setPopupIsOpen(false);
        } else {
          // don't close
        }
      }}>
      <ComponentCard name="popup" className={className}>
        <Component name="popup-content" onClick={(e) => e.stopPropagation()}>
          {/* {React.cloneElement(props.children, { onClick: (e) => e.stopPropagation() })} */}
          {props.children}
          {/*<Icon name="close-popup" svg={<CloseSVG />} onClick={() => props.setPopupIsOpen(false)} />*/}
        </Component>
      </ComponentCard>
    </Component>
  );
};

const ComingSoon = (props: { isMasterClassVersion?: boolean; isSwag?: boolean }) => {
  const remindMe = () => {
    window.open("https://www.wonderlink.de/@sdrsofgermany", "_blank")?.focus();
  };

  return (
    <ComponentCard name="coming-soon" className="justify-center">
      <Component name="coming-soon-content">
        <Title name="coming-soon" size="m" type="h2" className="txt--a-c">
          {props.isMasterClassVersion ? "Verfügbar ab September 2023!" : "🚧 Under Construction 🚧"}
        </Title>
        <Paragraph type="paragraph" name="coming-soon">
          {props.isMasterClassVersion || props.isSwag
            ? props.isMasterClassVersion
              ? "Unsere Masterclass Academy bietet dir Zugriff auf über 100 Jahre Erfahrung der besten Experten und Coaches. Mehr als 25 Sales-Koryphäen teilen mit dir ihr Wissen. Nur, um dich in deiner Karriere weiterzubringen wird. Geht eben nichts über eine starke Community."
              : "Hier gibt es bald exklusiven SDRs of Germany Swag für dich - stay tuned"
            : ""}
        </Paragraph>
      </Component>
      {props.isMasterClassVersion && (
        <>
          <Button type="primary" name="coming-soon" onClick={remindMe}>
            Jetzt Early-Access anfordern
          </Button>
        </>
      )}
      {!props.isMasterClassVersion && (
        <>
          <Icon svg={<SettingsSVG />} name="rotating" />
        </>
      )}
    </ComponentCard>
  );
};

const MasterclassBuyButton = (props: {
  type: "lock" | "hero" | "mediaLibrary" | "track";
  productLink?: string;
}) => {
  let text = "";
  const { user } = useUser();

  if (props.type === "lock") {
    text = "Schnapp dir jetzt die Masterclasses, um deine Sales Performance direkt zu steigern!";
  } else if (props.type === "mediaLibrary") {
    text = "Hol dir jetzt die Academy und bekomm Zugriff auf alle Masterclasses!";
  }

  const [popupIsOpen, setPopupIsOpen] = useState<boolean>();
  const { setPopupContent } = useContext(Context);
  const [iconHovered, setIconHovered] = useState<boolean>(false);

  useEffect(() => {
    if (setPopupContent) {
      if (popupIsOpen && props.productLink) {
        setPopupContent(
          <AcademiesCTAPopup setPopupOpen={setPopupIsOpen} productLink={props.productLink} />,
        );
      } else {
        setPopupContent(false);
      }
    }
  }, [popupIsOpen]);

  const onBuyButtonClick = () => {
    setPopupIsOpen(true);
  };

  return (
    <Component name="masterclass-buy-button">
      {props.type === "hero" && !user?.isAcademyMember && (
        <>
          <Title name="masterclass-buy-button" type="paragraph" size="s">
            {text}
          </Title>
          <Button
            name="masterclass-buy-button"
            type="primary"
            className="txt--c-pri"
            svg={<ArrowSVG />}
            onClick={() => window.open("https://calendly.com/lea-sdrsog/30min", "_blank")}>
            Sprich mit uns und hol dir die Academy!
          </Button>
        </>
      )}
      {props.type !== "lock" && props.type !== "hero" && (
        <>
          <Title name="masterclass-buy-button" type="paragraph" size="s">
            {text}
          </Title>
          <Button
            name="masterclass-buy-button"
            type="primary"
            className="txt--c-pri"
            svg={<ArrowSVG />}
            onClick={onBuyButtonClick}>
            Jetzt freischalten
          </Button>
        </>
      )}
      {props.type === "lock" && (
        <ComponentCard name="masterclass-unlock">
          <Icon
            name="masterclass-unlock"
            svg={iconHovered ? <UnlockSVG /> : <LockSVG />}
            onMouseEnter={() => {
              if (!iconHovered) {
                setIconHovered(true);
              }
            }}
            onMouseLeave={() => {
              if (iconHovered) {
                setIconHovered(false);
              }
            }}
            onClick={onBuyButtonClick}
          />
          <Title name="masterclass-unlock" type="h2" size="xs" className="txt--a-c">
            Du musst diese Masterclass erst freischalten, <br />
            um Zugriff auf die Inhalte zu bekommen.
          </Title>
          <Button type={"primary"} onClick={onBuyButtonClick}>
            Jetzt Freischalten
          </Button>
        </ComponentCard>
      )}
    </Component>
  );
};

const AcademiesCTAPopup = (props: {
  setPopupOpen: (value: boolean) => void;
  productLink: string;
}) => {
  const { user } = useUser();

  return (
    <Popup setPopupIsOpen={props.setPopupOpen} size="SMALL">
      <Icon
        name="close-popup"
        svg={<CloseSVG />}
        onClick={() => {
          props.setPopupOpen ? props.setPopupOpen(false) : "";
        }}
      />
      <Component name="academies-cta-popup">
        <Icon svg={<ExclamationMarkSVG />} name="academies-cta-icon" />
        <Title name="academies-cta-popup-concern" size="xxs" type="paragraph">
          Stell sicher, dass du beim Eingeben deiner Daten für die Bezahlung die E-Mail Adresse
          angibst, mit der du auch in der SDRs of Germany Community angemeldet bist, damit du direkt
          Zugriff auf deine Masterclasses hast! 🚀
        </Title>
        <Button
          type="primary"
          name="go-to-stripe"
          svg={<ArrowSVG />}
          onClick={() => {
            window.open(props.productLink + `?prefilled_email=${user?.email}`, "_blank")?.focus();
          }}>
          Zum Zahlungsvorgang
        </Button>
      </Component>
    </Popup>
  );
};

const AllAcademiesCTAPopup = (props: { setPopupOpen: (value: boolean) => void }) => {
  return (
    <Popup setPopupIsOpen={props.setPopupOpen} size="SMALL">
      <Icon
        name="close-popup"
        svg={<CloseSVG />}
        onClick={() => {
          props.setPopupOpen ? props.setPopupOpen(false) : "";
        }}
      />
      <iframe
        src={`https://pipedrivewebforms.com/form/6b8YPqWcZ4Ti2ldmgGZm5L2vh5TAbZpQhDsaVu1LWa1fgfkDbi7FIXY3r6rraeQrIv?embeded=1&uuid=asdfiojnkadsfihunji`}
        style={{
          border: "none",
          overflow: "hidden",
          width: "100%",
          maxWidth: "768px",
          minWidth: "320px",
          height: "100%",
          position: "relative",
        }}
      />
    </Popup>
  );
};

const LevelBadge = (props: { size: "s" | "m"; level?: number }) => {
  return (
    <>
      <Component name={`level-badge-${props.size}`}>
        {props.level && <Image name="level" img={`/levels/${props.level}.webp`} />}
      </Component>
    </>
  );
};

const LevelBar = (props: {
  size: "s" | "m" | "l";
  pointsToNextLevel?: number;
  points?: number;
  withHover?: boolean;
}) => {
  const points = {
    points: props.points ?? 1,
    pointsToNextLevel: props.pointsToNextLevel ?? 1,
  };

  const [showHover, setShowHover] = useState<boolean>(false);

  return (
    <>
      <Component
        name={`level-range-parent-${props.size}`}
        onMouseOver={() => {
          if (props.withHover) {
            setShowHover(true);
          }
        }}
        onMouseLeave={() => {
          if (props.withHover) {
            setShowHover(false);
          }
        }}>
        <Component name="level-range" className={`${props.withHover ? "level-hover" : ""}`} />
        {points && (
          <Component
            name={`level-achieved`}
            style={{
              width: `${(points.points / points.pointsToNextLevel) * 100}%`,
            }}
          />
        )}
        {showHover && props.size === "m" && (
          <Component
            name="points-hover"
            style={{
              left: `${(points.points / points.pointsToNextLevel) * 100}%`,
            }}>
            <Paragraph
              name="points-hover"
              type="paragraph"
              size="m">{`${points.points} / ${points.pointsToNextLevel}`}</Paragraph>
          </Component>
        )}
      </Component>
    </>
  );
};

const UserSearchPopup = (props: {
  setPopupOpen: (value: boolean) => void;
  setUser: (user: UsersResponseDto) => void;
  popupTitle: string;
}) => {
  const [users, setUsers] = useState<UsersResponseDto[]>([]);
  const [inputFieldValue, setInputFieldValue] = useState<string>("");
  const { apiRequest } = useRequest();

  useEffect(() => {
    const getSuggestions = async () => {
      if (inputFieldValue.trim().length > 3) {
        const { data } = await apiRequest<Paginated<UsersResponseDto>>(
          `users/search?search=${inputFieldValue}`,
          "GET",
        );
        setUsers(data.docs);
      } else {
        setUsers([]);
      }
    };
    getSuggestions();
  }, [inputFieldValue]);

  return (
    <Popup setPopupIsOpen={props.setPopupOpen} size="SMALL" aligned="RIGHT">
      <Component name="user-search-popup">
        <Component name="user-search-popup-title">
          <Title name="user-search-popup-title" type="h2" size="l">
            {props.popupTitle}
          </Title>
          <Icon name="close-popup" svg={<CloseSVG />} onClick={() => props.setPopupOpen(false)} />
        </Component>
        <Component name="user-search-popup-body">
          <TextInputElement
            id="person"
            type="text"
            placeHolder="Person finden"
            value={inputFieldValue}
            onInput={(e) => setInputFieldValue((e.target as HTMLInputElement).value)}
          />
          <Component name="possible-user">
            {users.map((user) => {
              return (
                <Component
                  onClick={() => {
                    props.setUser(user);
                  }}
                  name="user-popup-search-item"
                  key={user._id}>
                  <ProfilePicture img={user.picture} />
                  <Component name="user-search-item">
                    <Title
                      name="user-search-name"
                      size="xs"
                      type="h3">{`${user.firstName} ${user.lastName}`}</Title>
                  </Component>
                </Component>
              );
            })}
          </Component>
        </Component>
      </Component>
    </Popup>
  );
};

// FROM HERE NEW

// STILL WORKING ON EM

const ImageGroup = (props: { images: string[] }) => {
  return (
    <Component name="image-group">
      {props.images.map((image) => {
        <Image name="image-group" key={image} img={image} alt={"post-image"} />;
      })}
    </Component>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ProfilePictureNEW = (props: { img: string | undefined }) => {
  return <Component name="profile-picture"></Component>;
};

const UserName = (props: { user: UsersResponseDto }) => {
  const { user } = useUser();
  return (
    <Title name="user-name" type="h2" size="xs">
      {props.user._id === user?._id ? "Du" : `${props.user.firstName} ${props.user.lastName}`}
    </Title>
  );
};

const UserAbout = () => {
  return <Paragraph name="user-about">User About</Paragraph>;
};

const Message = () => {
  return (
    <Component name="message">
      <Paragraph name="message">Message</Paragraph>
    </Component>
  );
};

const DateComponent = (props: { date: Date }) => {
  return (
    <Paragraph name="date" size="s">
      {format(props.date, "dd.MM.y")}
    </Paragraph>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DateWithTime = (props: { date: Date }) => {
  return (
    <Paragraph name="date" size="s">
      {`${format(props.date, "dd. MMMM YYY, HH:mm")} Uhr`}
    </Paragraph>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Reactions = () => {
  return (
    <Component name="reactions">
      <Reaction />
    </Component>
  );
};

const Reaction = () => {
  return (
    <Component name="reaction">
      <Icon name="reaction"></Icon>
      <Paragraph name="reaction"></Paragraph>
    </Component>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ReactionHub = () => {
  return (
    <Component name="reaction-hub">
      <Icon name="reaction-hub"></Icon>
    </Component>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WelcomeMessages = (props: { welcomeMessages?: WelcomeMessageResponseDto[] }) => {
  return (
    <Component name="welcome-messages">
      {props.welcomeMessages?.map((welcomeMessage) => {
        <WelcomeMessage key={welcomeMessage._id} welcomeMessage={welcomeMessage} />;
      })}
    </Component>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WelcomeMessage = (props: { welcomeMessage: WelcomeMessageResponseDto }) => {
  return (
    <Component name="welcome-message">
      <ProfilePictureNEW img={props.welcomeMessage.picture} />
      <Component name="welcome-message-content">
        <UserName
          user={{
            _id: props.welcomeMessage.belongsToUserId,
            firstName: props.welcomeMessage.firstName,
            lastName: props.welcomeMessage.lastName,
            role: "",
          }}
        />
        <Message />
        <DateComponent date={props.welcomeMessage.createdAt} />
      </Component>
    </Component>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Featured = (props: {
  featuredItems: FeaturedItem[];
  title: string;
  buttonText: string;
  route: string;
}) => {
  const isUsersResponseDto = "firstName" in props.featuredItems[0];
  return (
    <ComponentCard name="featured">
      <Title name="featured" type="h2" size="xs">
        {props.title}
      </Title>
      <Component name="featured-items">
        {props.featuredItems.map((item) => {
          if (isUsersResponseDto) {
            return <FeaturedUser user={item as UsersResponseDto} key={item._id} />;
          } else {
            return <FeaturedEvent event={item as EventResponseDto} key={item._id} />;
          }
        })}
      </Component>
      <Button name="featured-cta" type="darkText">
        {props.buttonText}
      </Button>
    </ComponentCard>
  );
};

const FeaturedUser = (props: { user: UsersResponseDto }) => {
  return (
    <Component name="featured-user">
      <Image name="featured-user-thumbnail" img={props.user.picture} alt={"profile-picture"} />
      <Component name="featured-user-core">
        <UserName user={props.user} />
        <Paragraph name="featured-user-core" size="m">
          Neuling
        </Paragraph>
      </Component>
    </Component>
  );
};

const FeaturedEvent = (props: { event: EventResponseDto }) => {
  return (
    <Component name="featured-event">
      <Image name="featured-event-thumbnail" img={props.event.picture} alt={"event-picture"} />
      <Component name="featured-event-core">
        <Title name="feature-event-core" type="h3" size="xs">
          {props.event.title}
        </Title>
        <Paragraph name="feature-event-core" size="l">
          {props.event.description}
        </Paragraph>
      </Component>
    </Component>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FilterGroup = () => {
  return (
    <ComponentCard name="filter-group">
      <Title name="filter-group" type="h2" size="xxs">
        Filter
      </Title>
      <Filter />
      <Button name="filter-group" type={"primaryGhost"}>
        Zurücksetzen
      </Button>
    </ComponentCard>
  );
};

const Filter = () => {
  return <Component name="filter"></Component>;
};

const Votes = (props: { rating: number; hasUpvoted: boolean; hasDownvoted: boolean }) => {
  return (
    <Component name="votes">
      <Icon name="upvote" svg={<ArrowSVG />} className={props.hasUpvoted ? "voted" : ""} />
      <Paragraph name="votes">{props.rating}</Paragraph>
      <Icon name="downvote" svg={<ArrowSVG />} className={props.hasDownvoted ? "voted" : ""} />
    </Component>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Posts = (props: { posts: PostResponseDto[] }) => {
  return (
    <Component name="posts">
      {props.posts.map((post) => {
        return <Post post={post} key={post._id} />;
      })}
    </Component>
  );
};

const Post = (props: { post: PostResponseDto }) => {
  return (
    <ComponentCard name="post">
      <Votes
        rating={props.post.rating}
        hasUpvoted={props.post.hasUpvoted}
        hasDownvoted={props.post.hasDownvoted}
      />
      <PostContent post={props.post} />
    </ComponentCard>
  );
};

const PostContent = (props: { post: PostResponseDto }) => {
  return (
    <Component name="post-content">
      <PostMeta date={props.post.createdAt} user={props.post.user} />
      <Paragraph name="post-content">{props.post.content}</Paragraph>
      {props.post.pictures && <ImageGroup images={props.post.pictures} />}
    </Component>
  );
};

const PostMeta = (props: { user: UsersResponseDto; date: Date }) => {
  return (
    <Component name="post-meta">
      <ProfilePictureNEW img={props.user.picture} />
      <Component name="post-username-date">
        <UserName user={props.user} />
        <DateComponent date={props.date} />
      </Component>
    </Component>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PostWithComment = (props: { post: PostResponseDto }) => {
  return (
    <ComponentCard name="post">
      <Votes
        rating={props.post.rating}
        hasUpvoted={props.post.hasUpvoted}
        hasDownvoted={props.post.hasDownvoted}
      />
      <PostContent post={props.post} />
    </ComponentCard>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Networking = (props: { users: UsersResponseDto[] }) => {
  return (
    <Component name="networking">
      {props.users.map((user) => {
        <NetworkingUser key={user._id} user={user} />;
      })}
    </Component>
  );
};

const NetworkingUser = (props: { user: UsersResponseDto; activeSkills?: string[] }) => {
  return (
    <ComponentCard name="networking-user">
      <Component name="networking-user-">
        <ProfilePictureNEW img={props.user.picture} />
        <Component name="networking-user-core">
          <UserName user={props.user} />
          <UserAbout />
        </Component>
      </Component>
      <Tags>
        {props.user.skills?.map((skill) => {
          return (
            <Tag key={skill._id} isActive={props.activeSkills?.includes(skill._id) ?? false}>
              <Paragraph name="networking-user" size="m">
                {skill}
              </Paragraph>
            </Tag>
          );
        })}
      </Tags>
    </ComponentCard>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Contacts = (props: { contacts: ChatResponseDto[] }) => {
  return (
    <Component name="contacts">
      {props.contacts.map((contact) => {
        return <Contact key={contact._id} contact={contact} />;
      })}
    </Component>
  );
};

const Contact = (props: { contact: ChatResponseDto }) => {
  return (
    <ComponentCard name="contact" type="shadow">
      <ProfilePictureNEW img={props.contact.user.picture} />
      <Component name="contact-core">
        <UserName user={props.contact.user} />
        <LastMessage
          message={props.contact.message}
          isRead={props.contact.status === "read"}
          isSender={props.contact.isSender}
        />
      </Component>
      <MessageCounter counter={5}></MessageCounter>
    </ComponentCard>
  );
};

const LastMessage = (props: { message: string; isRead: boolean; isSender: boolean }) => {
  return (
    <Component name="last-message">
      <Icon name="last-message" />
      <Paragraph name="last-message">{props.message}</Paragraph>
    </Component>
  );
};

const MessageCounter = (props: { counter: number }) => {
  return (
    <Component name="message-counter">
      <Paragraph name="message-counter" className="p--xs">
        {props.counter}
      </Paragraph>
    </Component>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HyreJobs = () => {
  return (
    <Component name="hyre-jobs">
      <Component name="hyre-">
        <Button name="" type="darkText">
          Button Text
        </Button>
      </Component>
      Children
    </Component>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HyreJob = () => {
  return (
    <ComponentCard name="hyre-job">
      <ProfilePictureNEW img=""></ProfilePictureNEW>
      <Component name="">
        <Title></Title>
        <Paragraph name="hyre-locations" size="l" className="txt--c-on-pri">
          HyreLocations <span className="txt--c-on-bg-l">+ X weitere</span>
        </Paragraph>
        <Component name="">
          <Paragraph name="hyre-salary" size="s">
            Salary
          </Paragraph>
          <Paragraph name="hyre-industry" size="s">
            Industrie
          </Paragraph>
        </Component>
      </Component>
      <Icon name="hyre-arrow" svg={<ArrowSVG />}></Icon>
    </ComponentCard>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AdminTable = () => {
  return <ComponentCard name="admin-table"></ComponentCard>;
};
export {
  Page,
  Main,
  AppView,
  Section,
  Container,
  SectionElement,
  Component,
  ComponentCard,
  RichTextEditor,
  Layout,
  Wrapper,
  Title,
  Paragraph,
  Link,
  TextSeperator,
  Seperator,
  ComponentButton,
  Button,
  BackButton,
  Icon,
  Image,
  ProfilePictures,
  ProfilePicture,
  ProfilePictureEdit,
  ProfileBubble,
  ImageUploader,
  SearchSuggestion,
  Tags,
  Tag,
  List,
  ListItem,
  Table,
  TableColumn,
  TablePagination,
  Popup,
  ComingSoon,
  IconImageUploader,
  LevelBadge,
  LevelBar,
  FileUploader,
  VideoUploader,
  TagsFiltered,
  MasterclassBuyButton,
  AllAcademiesCTAPopup,
  LabelComponent,
  TableSearch,
  UserSearchPopup,
};
