const BurgerSVG: React.FC<React.HTMLProps<HTMLOrSVGElement>> = () => {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44772 22 6C22 6.55229 21.5523 7 21 7L3 7C2.44772 7 2 6.55228 2 6Z" />
      <path d="M2 12C2 11.4477 2.44772 11 3 11L21 11C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13L3 13C2.44772 13 2 12.5523 2 12Z" />
      <path d="M3 17C2.44772 17 2 17.4477 2 18C2 18.5523 2.44772 19 3 19L21 19C21.5523 19 22 18.5523 22 18C22 17.4477 21.5523 17 21 17L3 17Z" />
    </svg>
  );
};

export default BurgerSVG;
