import {
  Button,
  Component,
  ComponentCard,
  Icon,
  LevelBadge,
  LevelBar,
  Link,
  Paragraph,
  Popup,
  ProfileBubble,
  ProfilePicture,
  Seperator,
  Title,
} from "../Elements/Elements";
import { TextInputElement } from "../Elements/FormElements";
import "./navigation.scss";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import Context from "../../context/Context";
import { useUser } from "../../hooks/useUser";
import ChatSVG from "../../Icons/ChatSVG";
import NotificationBellSVG from "../../Icons/NotificationBellSVG";
import ProfileSVG from "../../Icons/ProfileSVG";
import BurgerSVG from "../../Icons/BurgerSVG";
import historyNavigate from "../../hooks/useHistoryNavigate";
import useRequest from "../../hooks/useRequest";
import { Level, NotificationsDto, Paginated } from "../../requests/interfaces";
import TrashSVG from "../../Icons/TrashSVG";
import { useAuth0 } from "@auth0/auth0-react";
import useComponentVisible from "../../hooks/useOutsideClick";
import DiscussionSVG from "../../Icons/DiscussionSVG";
import LearningSVG from "../../Icons/LearningSVG";
import MessagesSVG from "../../Icons/MessagesSVG";
import CareerSVG from "../../Icons/CareerSVG";

interface Props {
  onClick: () => void;
  label?: string;
  icon?: any;
  children?: any;
  reference?: any;
  messageCount?: number;
  classAddOn?: string;
}

interface HeaderProps {
  hasTabs?: boolean;
}

interface ChildrenProps {
  children?: any;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
}

interface SearchResponse {
  svg: any;
  title: string;
  responseItems: any[];
}

const requests: {
  route: string;
  icon: any;
  title: string;
}[] = [
  {
    route: "user",
    icon: <ProfileSVG />,
    title: "Personen",
  },
  {
    route: "discussion",
    icon: <DiscussionSVG />,
    title: "Diskussionen",
  },
  {
    route: "resource",
    icon: <LearningSVG />,
    title: "Sales Wissen",
  },
  {
    route: "job",
    icon: <CareerSVG />,
    title: "Jobs",
  },
];

const HeaderParent = (props: HeaderProps) => {
  const { hasSetupDone, isRecruiter } = useUser();

  if (!hasSetupDone) {
    return <></>;
  }
  if (isRecruiter) {
    return <ProfileMenuItem />;
  }

  return <Header {...props} />;
};

const ProfileMenuItem = () => {
  const { useHistoryNavigate } = historyNavigate();
  const { user, isRecruiter } = useUser();
  const [profilePicture, setProfilePicture] = useState<string>();

  const profileBubbleAction = () => {
    useHistoryNavigate("/profile");
  };

  useEffect(() => {
    setProfilePicture(user?.picture);
  }, [user?.picture]);

  return (
    <Component name={isRecruiter ? "recruiter-header" : "profile-menu-item"}>
      <ProfilePicture onClick={profileBubbleAction} img={profilePicture}></ProfilePicture>
    </Component>
  );
};

const Header = (props: HeaderProps) => {
  const { isMobile, setSitebarOpen } = useContext(Context);
  const { useHistoryNavigate } = historyNavigate();
  const [level, setLevel] = useState<Level>();
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [messageCount, setMessageCount] = useState<number>(0);
  const [searchResponses, setSearchResponses] = useState<SearchResponse[]>([]);
  const [academyNotifications, setAcdemyNotifications] = useState<NotificationsDto[]>([]);
  const { setPopupContent } = useContext(Context);

  const { apiRequest } = useRequest();
  const [searchText, setSearchText] = useState<string>("");
  const { user, isRecruiter } = useUser();
  const { getAccessTokenSilently } = useAuth0();
  const {
    ref: refNotification,
    isComponentVisible: isNotificationsVisible,
    setIsComponentVisible: setNotificationsVisible,
  } = useComponentVisible(false);
  const {
    ref: refSearch,
    isComponentVisible: isSearchVisible,
    setIsComponentVisible: setIsSearchVisible,
  } = useComponentVisible(false);

  useEffect(() => {
    setLevel(user?.level);
  }, [user?.picture]);

  useEffect(() => {
    if (setPopupContent) {
      if (academyNotifications.length !== 0) {
        setPopupContent(
          <AcademyPopup
            notifications={academyNotifications}
            setPopupIsOpen={() => setAcdemyNotifications([])}
          />,
        );
      } else {
        setPopupContent(undefined);
      }
    }
  }, [academyNotifications]);

  const loadNotifications = async () => {
    const { data } = await apiRequest<Paginated<NotificationsDto>>(
      "notifications?notificationGroup=noMessages",
      "GET",
      {
        accessTokenHard: await getAccessTokenSilently(),
      },
    );
    const { data: messageData } = await apiRequest<Paginated<NotificationsDto>>(
      "notifications?notificationGroup=messages",
      "GET",
      {
        accessTokenHard: await getAccessTokenSilently(),
      },
    );

    const { data: boughtNotifications } = await apiRequest<Paginated<NotificationsDto>>(
      "notifications?notificationGroup=boughtNotifications",
      "GET",
      {
        accessTokenHard: await getAccessTokenSilently(),
      },
    );

    setAcdemyNotifications(boughtNotifications.docs);
    setNotificationCount(removeAcademyNotifications(data.docs).length);
    setMessageCount(messageData.docs.length);
  };

  useEffect(() => {
    loadNotifications();
  }, []);

  const linkRedirect = (link: string) => {
    useHistoryNavigate(link);
  };

  const search = async () => {
    if (searchText.length > 1) {
      const finalSuggestions: SearchResponse[] = [];
      for (let index = 0; index < requests.length; index++) {
        const request = requests[index];
        const searchResponse = await apiRequest<Paginated<any>>(
          `${request.route}s/search?search=${searchText}`,
          "GET",
        );
        let items;
        if (searchResponse.data.docs[0]?.firstName) {
          items = searchResponse.data.docs.map((userResponse: any) => {
            return (
              <Component
                key={userResponse._id}
                name="search-suggestion"
                onClick={() => linkRedirect(`/${request.route}/${userResponse._id}`)}>
                <ProfileBubble user={userResponse} />
              </Component>
            );
          });
        } else {
          items = searchResponse.data.docs.map((response: any) => {
            return (
              <Component
                key={response._id}
                name="search-suggestion"
                onClick={() =>
                  linkRedirect(
                    `/${request.route}${request.route === "job" ? "s" : ""}/${response._id}`,
                  )
                }>
                <Paragraph name="search-suggestion" type="h3" size="m">
                  {response?.content ?? response.title ?? response.company}
                </Paragraph>
              </Component>
            );
          });
        }
        if (items.length !== 0) {
          const item = {
            responseItems: items,
            svg: request.icon,
            title: request.title,
          };
          finalSuggestions.push(item);
        }
      }
      if (finalSuggestions.length === 0) {
        setSearchResponses([]);
      } else {
        setSearchResponses(finalSuggestions);
      }
      setIsSearchVisible(true);
    }
  };

  useEffect(() => {
    search();
  }, [searchText]);

  const swag = () => {
    useHistoryNavigate("/profile?tab=1");
  };

  const messageAction = () => {
    useHistoryNavigate("/messages");
  };

  if (isRecruiter) {
    return <></>;
  }

  const Children = (
    <>
      {!isMobile && (
        <>
          <Component name="hd">
            <Component name="search">
              <TextInputElement
                type="text"
                id="search"
                label=""
                labelClasses="p--xl"
                placeHolder="Suche..."
                onInput={(e) => setSearchText((e.target as HTMLInputElement).value)}
                value={searchText}
              />
              {isSearchVisible && searchText.length > 1 && (
                <ComponentCard name="suggestions" ref={refSearch}>
                  {searchResponses.length > 0 && (
                    <>
                      {searchResponses.map((response) => {
                        return (
                          <Component key={response.title} name="suggestion-category">
                            <Component
                              key={response.title}
                              name="suggestion-category-title"
                              className="txt--c-pri">
                              <Icon svg={response.svg} name="search-suggestion-icon" />
                              <Title name="search-suggestion-title" type="h2" size="xs">
                                {response.title}
                              </Title>
                            </Component>
                            {response.responseItems}
                          </Component>
                        );
                      })}
                    </>
                  )}
                  {searchResponses.length === 0 && (
                    <Title name="search-nothing-found" type="h2" size="xs">
                      Nichts gefunden
                    </Title>
                  )}
                </ComponentCard>
              )}
            </Component>
            <Component name="hd-nav">
              {false && <LevelMenuItem level={level} onClick={swag} />}
              {false && <MenuItem onClick={swag} label="Swag" />}
              <MenuItem
                onClick={messageAction}
                messageCount={messageCount}
                icon={<MessagesSVG />}
              />
              <MenuItem
                reference={refNotification}
                onClick={() => setNotificationsVisible((value) => !value)}
                icon={<NotificationBellSVG />}
                messageCount={notificationCount}>
                {isNotificationsVisible && <Notifications />}
              </MenuItem>
            </Component>
            <ProfileMenuItem />
          </Component>
        </>
      )}
      {isMobile && <MobileBurger setIsOpen={setSitebarOpen} />}
    </>
  );

  const final = props.hasTabs === false ? <Component name="nav">{Children}</Component> : Children;

  return final;
};

const AcademyPopup = (props: {
  notifications: NotificationsDto[];
  setPopupIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { user } = useUser();
  const { useHistoryNavigate } = historyNavigate();
  const { apiRequest } = useRequest();

  const removeAcademyNotifications = async () => {
    for (let index = 0; index < props.notifications.length; index++) {
      const element = props.notifications[index];
      await apiRequest<Paginated<NotificationsDto>>(`notifications/${element._id}`, "DELETE");
    }
  };

  const goToMasterclass = async () => {
    await removeAcademyNotifications();
    useHistoryNavigate(`/academy?academyId=${props.notifications[0].notificationObjectId}`);
  };

  return (
    <Popup
      setPopupIsOpen={(val) => {
        removeAcademyNotifications();
        props.setPopupIsOpen(val);
      }}
      size={"SMALL"}>
      <Component name="academy-popup">
        <Component name="academy-popup-title">
          <Title name="academy-popup-title" size="m" type="h2">
            Hey {user?.firstName} 👋🏻
          </Title>
        </Component>
        <Component name="academy-popup-body">
          <Component name="academy-popup-text">
            {props.notifications.map((notification) => {
              return (
                <Paragraph name="academy-popup-text" size="m" key={notification._id}>
                  {notification.text}
                </Paragraph>
              );
            })}
            <Paragraph name="academy-popup-text" size="m">
              Jetzt kann’s losgehen! Aber vergiss nicht - um dein offizielles SDRs of Germany
              Zertifikat zu erhalten musst du alle Aufgaben erarbeiten und zu allen Disskusionen
              beitragen.
            </Paragraph>
            <Paragraph name="academy-popup-text" size="m">
              Viel Erfolg wünscht dir das Team der SDRs of Germany!🚀
            </Paragraph>
          </Component>
          <Button type="primary" name="academy-popup-button" onClick={goToMasterclass}>
            Zur Masterclass
          </Button>
        </Component>
      </Component>
    </Popup>
  );
};

const Notifications = () => {
  const [notifications, setNotifications] = useState<NotificationsDto[]>([]);
  const { apiRequest } = useRequest();
  const { getAccessTokenSilently } = useAuth0();

  const getNotifications = async () => {
    const { data } = await apiRequest<Paginated<NotificationsDto>>(
      "notifications?notificationGroup=noMessages",
      "GET",
      {
        accessTokenHard: await getAccessTokenSilently(),
      },
    );
    setNotifications(removeAcademyNotifications(data.docs));
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const deleteNotification = async (id: string) => {
    await apiRequest<Paginated<NotificationsDto>>(`notifications/${id}`, "DELETE");
    getNotifications();
  };

  return (
    <ComponentCard type="standard" name="notifications" onClick={(e) => e.stopPropagation()}>
      <Title type="paragraph" name="notifications" size="s">
        Benachrichtigungen
      </Title>
      <Seperator></Seperator>
      {!notifications ||
        (notifications.length === 0 && (
          <Title name="no-notifications" type="h2" size="xxs">
            Es gibt aktuell keine Benachrichtigungen für dich. Du bist auf dem neuesten Stand
          </Title>
        ))}
      {notifications?.map((notification) => {
        return (
          <Component
            name="notification"
            key={notification._id}
            className={`notification-type-${notification.type}`}>
            {notification.notificationObject === "discussion" ? (
              <Link
                name="notification"
                target=""
                href={`/${notification.notificationObject}/${notification.notificationObjectId}`}>
                {notification.text}
              </Link>
            ) : (
              <Paragraph name="notification" type="paragraph" size="m">
                {notification.notificationObject === "track"
                  ? `Deine Aufgabe aus der Lektion ${notification.notificationDetails} wurde ${
                      notification.type === "success" ? "angenommen" : "abgelehnt"
                    }`
                  : notification.text}
              </Paragraph>
            )}

            <Icon
              name="notification"
              svg={<TrashSVG />}
              onClick={() => {
                deleteNotification(notification._id);
              }}></Icon>
          </Component>
        );
      })}
    </ComponentCard>
  );
};

const MenuItem = (props: Props) => {
  const [hideBubble, setHideBubble] = useState<boolean>(false);
  const [wiggle, setWiggle] = useState<boolean>(
    props.messageCount !== undefined && props.messageCount !== 0,
  );

  useEffect(() => {
    if (wiggle) {
      setTimeout(() => {
        setWiggle(false);
      }, 1000);

      setTimeout(() => {
        setHideBubble(true);
      }, 1750);
    }
  }, [wiggle]);

  useEffect(() => {
    if (props.messageCount) {
      setWiggle(props.messageCount !== 0);
    }
  }, [props.messageCount]);

  return (
    <>
      <Component name="menu-item" onClick={props.onClick} ref={props.reference ?? null}>
        <Component name="hd-link" className={(props.classAddOn ?? "") + (wiggle ? "wiggle" : "")}>
          {props.messageCount !== undefined && props.messageCount !== 0 && !wiggle && (
            <Component name="message-count" className={hideBubble ? "" : "hide"}>
              {!hideBubble && (
                <Title name="message-count" size="xxs" type="h4">
                  {props.messageCount}
                </Title>
              )}
            </Component>
          )}
          {props.icon && <Icon name="header-icon" svg={props.icon} />}
          {props.label && (
            <Paragraph name="hd-link" size="nav">
              {props.label}
            </Paragraph>
          )}
        </Component>
        {props.children}
      </Component>
    </>
  );
};

const LevelMenuItem = (props: { level?: Level; onClick: () => void }) => {
  return (
    <>
      <Component name="menu-item" onClick={props.onClick}>
        <Component name="hd-link">
          <LevelBadge level={props.level?.level} size="s" />
          <LevelBar
            points={props.level?.points}
            pointsToNextLevel={props.level?.pointsToNextLevel}
            size="s"
          />
        </Component>
      </Component>
    </>
  );
};

const MobileHeader = () => {
  const icons = [
    { id: 1, icon: <ProfileSVG />, location: "/profile" },
    { id: 2, icon: <NotificationBellSVG />, location: "/notifications" },
    { id: 3, icon: <ChatSVG />, location: "/messages" },
  ];

  const { useHistoryNavigate } = historyNavigate();

  return (
    <>
      <Component name="header-icons">
        {icons.map((icon) => {
          return (
            <Component key={icon.id} name="header-icon">
              <Icon
                name="header-icon"
                svg={icon.icon}
                onClick={() => {
                  useHistoryNavigate(icon.location);
                }}></Icon>
            </Component>
          );
        })}
      </Component>
    </>
  );
};

const MobileBurger = (props: ChildrenProps) => {
  return (
    <>
      <Component name="nav pseudo">
        <Component name="nav-toggle pseudo">
          <Icon name="nav-toggle" svg={<BurgerSVG />} />
        </Component>
        <Component name="nav-bg"></Component>
      </Component>
      <Component name="nav">
        <Component name="nav-toggle">
          <Icon
            name="nav-toggle"
            svg={<BurgerSVG />}
            onClick={() => (props.setIsOpen ? props.setIsOpen((value: any) => !value) : "")}
          />
        </Component>
        {props?.children}
      </Component>
    </>
  );
};

const removeAcademyNotifications = (notifications: NotificationsDto[]) => {
  return notifications.filter((notification) => notification.notificationObject !== "academy");
};

export { MobileHeader, HeaderParent as Header, MobileBurger, Notifications };
