const useFormOptions = () => {
  const getFormOptions = async () => {
    const yearsExperienceOptions = [
      {
        id: "NONE",
        name: "Keine Erfahrung",
      },
      {
        id: "6MONTHS",
        name: "0.5 Jahre",
      },
      {
        id: "1YEAR",
        name: "1 Jahr",
      },
      {
        id: "2YEAR",
        name: "2 Jahre",
      },
      {
        id: "3YEAR",
        name: "3 Jahre",
      },
      {
        id: "3TO5YEAR",
        name: "3 bis 5 Jahre",
      },
      {
        id: "OVER5YEAR",
        name: "Über 5 Jahre",
      },
    ];

    const targetLocationsOptions = [
      {
        id: "RM",
        name: "Remote",
      },
      {
        id: "EVERYWHERE",
        name: "Überall",
      },
      {
        id: "DD",
        name: "Düsseldorf",
      },
      {
        id: "BER",
        name: "Berlin",
      },
      {
        id: "FFM",
        name: "Frankfurt am Main",
      },
      {
        id: "HAM",
        name: "Hamburg",
      },
      {
        id: "HDLBG",
        name: "Heidelberg",
      },
      {
        id: "COL",
        name: "Köln",
      },
      {
        id: "LP",
        name: "Leipzig",
      },
      {
        id: "MUC",
        name: "München",
      },
      {
        id: "STU",
        name: "Stuttgart",
      },
      {
        id: "BO",
        name: "Bonn",
      },
      {
        id: "ES",
        name: "Essen",
      },
    ];

    const leadWarmthOptions = [
      {
        id: "Inbound",
        name: "Inbound",
      },
      {
        id: "Outbound",
        name: "Outbound",
      },
      {
        id: "Both",
        name: "Beide",
      },
    ];

    const learningAndDevelopmentOptions = [
      {
        id: "masterclass_academy",
        name: "SDRsoG Masterclass Academy",
      },
      {
        id: "lnd_budget",
        name: "LnD Budget",
      },
      {
        id: "sales_training",
        name: "Sales Training/Coaching extern",
      },
      {
        id: "sales_enablement",
        name: "Sales Enablement Manager",
      },
      {
        id: "other",
        name: "Andere",
      },
    ];

    const companySizeOptions = [
      {
        id: "startup",
        name: "Startup",
      },
      {
        id: "scaleup",
        name: "Scaleup",
      },
      {
        id: "enterprise",
        name: "Enterprise",
      },
      {
        id: "other",
        name: "Andere",
      },
    ];

    const germanLevelOptions = [
      {
        id: "A",
        name: "Anfänger",
      },
      {
        id: "B",
        name: "Arbeite dran",
      },
      {
        id: "C1",
        name: "Fließend",
      },
      {
        id: "C2",
        name: "Muttersprache",
      },
    ];

    const positionOptions = [
      {
        id: "select",
        name: "Auswählen",
      },
      {
        id: "sdr_bdr",
        name: "SDR/BDR",
      },
      {
        id: "ae",
        name: "AE",
      },
      {
        id: "sales_manager",
        name: "Sales Manager",
      },
      {
        id: "sales_director",
        name: "Sales Director",
      },
      {
        id: "teamlead",
        name: "Teamlead SDR",
      },
      {
        id: "c_level",
        name: "C-Level",
      },
      {
        id: "founder",
        name: "Founder",
      },
      {
        id: "sales_leader",
        name: "Sales Leader",
      },
      {
        id: "recruiter_headhunter",
        name: "Recruiter/Headhunter",
      },
    ];

    const positionJobBoardOptions = [
      {
        id: "sdr_bdr",
        name: "SDR/BDR",
      },
      {
        id: "teamlead",
        name: "Teamlead SDR",
      },
      {
        id: "ae",
        name: "AE",
      },
      {
        id: "sales_manager",
        name: "Sales Manager",
      },
      {
        id: "sales_director",
        name: "Sales Director",
      },
      {
        id: "customer_success_manager",
        name: "Customer Success Manager",
      },
      {
        id: "c_level",
        name: "C-Level",
      },
      {
        id: "founder",
        name: "Founder",
      },
      {
        id: "sales_leader",
        name: "Sales Leader",
      },
      {
        id: "recruiter_headhunter",
        name: "Recruiter/Headhunter",
      },
      {
        id: "other",
        name: "Andere",
      },
    ];

    const interestsOptions = [
      {
        id: "networking",
        name: "Networking",
      },
      {
        id: "training",
        name: "Training",
      },
      {
        id: "mentorship",
        name: "Mentorship",
      },
      {
        id: "find_sales_talents",
        name: "Recruiting",
      },
      {
        id: "others",
        name: "Anderes",
      },
    ];

    const leadsOptions = [
      {
        id: "linked_in",
        name: "LinkedIn",
      },
      {
        id: "recommendation",
        name: "Empfehlung",
      },
      {
        id: "podcast",
        name: "Podcast",
      },
      {
        id: "youtube",
        name: "YouTube",
      },
      {
        id: "instagram",
        name: "Instagram",
      },
      {
        id: "google",
        name: "Google",
      },
      {
        id: "others",
        name: "Andere",
      },
    ];

    const industryOptions = [
      {
        id: "saas",
        name: "SaaS",
      },
      {
        id: "fintech",
        name: "Fintech",
      },
      {
        id: "manufacture",
        name: "Manufaktur",
      },
      {
        id: "medical",
        name: "Medical",
      },
      {
        id: "healthcare",
        name: "Healthcare",
      },
      {
        id: "proptech",
        name: "PropTech",
      },
      {
        id: "e_commerce",
        name: "Commerce",
      },
      {
        id: "media",
        name: "Media",
      },
      {
        id: "other",
        name: "Andere",
      },
    ];

    const notificationSettingOptions = [
      {
        id: "mailNotification",
        name: "Mail Notification",
      },
      {
        id: "inAppNotification",
        name: "In App Notification",
      },
      // {
      //   id: "thirdPartyNotification",
      //   name: "Manufaktur",
      // },
    ];

    const locationOptions = [
      {
        id: "remote",
        name: "Remote",
      },
      {
        id: "hybrid",
        name: "Hybrid",
      },
      {
        id: "in_person",
        name: "Vor Ort",
      },
    ];

    const techstackOptions = [
      {
        id: "cognism",
        name: "Cognism",
      },
      {
        id: "hubspot",
        name: "Hubspot",
      },
      {
        id: "salesforce",
        name: "Salesforce",
      },
      {
        id: "salesloft",
        name: "Salesloft",
      },
      {
        id: "pipedrive",
        name: "Pipedrive",
      },
      {
        id: "dealfront",
        name: "Dealfront",
      },
      {
        id: "outreach",
        name: "Outreach",
      },
      {
        id: "appollo",
        name: "Appollo",
      },
      {
        id: "excel",
        name: "Excel",
      },
      {
        id: "6Sense",
        name: "6Sense",
      },
      {
        id: "gong",
        name: "Gong",
      },
      {
        id: "clari",
        name: "Clari",
      },
      {
        id: "zoomInfo",
        name: "ZoomInfo",
      },
      {
        id: "amplemarket",
        name: "Amplemarket",
      },
      {
        id: "bao",
        name: "Bao",
      },
      {
        id: "surfe",
        name: "Surfe",
      },
      {
        id: "leadIq",
        name: "LeadiQ",
      },
      {
        id: "lusha",
        name: "Lusha",
      },
      {
        id: "others",
        name: "Andere",
      },
    ];

    const icpOptions = [
      {
        id: "sales",
        name: "Sales",
      },
      {
        id: "marketing",
        name: "Marketing",
      },
      {
        id: "hr",
        name: "HR",
      },
      {
        id: "legal",
        name: "Legal",
      },
      {
        id: "it",
        name: "IT",
      },
      {
        id: "product",
        name: "Product",
      },
      {
        id: "operation",
        name: "Operation",
      },
      {
        id: "finance",
        name: "Finance",
      },
      {
        id: "others",
        name: "Andere",
      },
    ];

    const allOptionsCombined = [
      yearsExperienceOptions,
      positionOptions,
      interestsOptions,
      leadsOptions,
      industryOptions,
      targetLocationsOptions,
      leadWarmthOptions,
      learningAndDevelopmentOptions,
      germanLevelOptions,
      notificationSettingOptions,
      icpOptions,
      techstackOptions,
      locationOptions,
      positionJobBoardOptions,
      companySizeOptions,
    ];

    const getLabelById = (id: string) => {
      for (let index = 0; index < allOptionsCombined.length; index++) {
        const optionsList = allOptionsCombined[index];
        const found = optionsList.find((option: any) => option.id === id);
        if (found !== undefined) {
          return found;
        }
      }
      return undefined;
    };

    return {
      getLabelById,
      yearsExperienceOptions,
      targetLocationsOptions,
      leadWarmthOptions,
      germanLevelOptions,
      positionOptions,
      interestsOptions,
      learningAndDevelopmentOptions,
      leadsOptions,
      industryOptions,
      notificationSettingOptions,
      icpOptions,
      techstackOptions,
      locationOptions,
      positionJobBoardOptions,
      companySizeOptions,
    };
  };
  return { getFormOptions };
};

export default useFormOptions;
