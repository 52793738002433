import { useEffect, Dispatch, SetStateAction, useState, useRef } from "react";
import {
  TrackBaseRequestDto,
  ContentTrackResponse,
  ContentTrackRequest,
  ExerciseTrackResponse,
  ExerciseTrackRequest,
  DiscussionTrackResponse,
  DiscussionTrackRequestDto,
  TrackResponseDto,
} from "../../requests/interfaces";
import {
  RichTextEditor,
  Component,
  Title,
  Icon,
  FileUploader,
  ImageUploader,
} from "../Elements/Elements";
import { TextInputElement, TagSelect } from "../Elements/FormElements";
import ArrowSVG from "../../Icons/ArrowSVG";

type TrackType =
  | {
      type: "content";
      item: ContentTrackResponse | undefined;
      setItem: Dispatch<SetStateAction<ContentTrackRequest | undefined>>;
    }
  | {
      type: "exercise";
      item: ExerciseTrackResponse | undefined;
      setItem: Dispatch<SetStateAction<ExerciseTrackRequest | undefined>>;
    }
  | {
      type: "discussion";
      item: DiscussionTrackResponse | undefined;
      setItem: Dispatch<SetStateAction<DiscussionTrackRequestDto | undefined>>;
    };

export const TrackPart = (props: { track: TrackType }) => {
  useEffect(() => {
    setTitle(props.track.item?.title ?? "");
    setDescription(props.track.item?.description ?? "");
  }, [props.track.item]);

  const [title, setTitle] = useState<string>("");
  const [closed, setClosed] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");
  const [trackBase, setTrackBase] = useState<TrackBaseRequestDto>({
    title: title,
    description: description,
  });
  const editorRef: any = useRef();

  useEffect(() => {
    setTrackBase({ title: title, description: description });
  }, [title, description]);

  return (
    <>
      <Component name="track-part-title" onClick={() => setClosed((value) => !value)}>
        <Title type="h3" size="s">
          {props.track.type.charAt(0).toUpperCase() + props.track.type.slice(1)}
        </Title>
        <Icon name="admin-academy-close" svg={<ArrowSVG />} className={closed ? "closed" : ""} />
      </Component>
      {!closed && (
        <Component name="form-inputs">
          <Component name="form-row">
            <Component name="form-input">
              <TextInputElement
                id={`${props.track.type}-track-title`}
                label={`${
                  props.track.type.charAt(0).toUpperCase() + props.track.type.slice(1)
                } Track Title`}
                type="text"
                labelClasses="p--l"
                onInput={(e) => {
                  setTitle((e.target as HTMLInputElement).value);
                }}
                value={title}
              />
            </Component>
          </Component>
          <Component name="form-row">
            <Component name="form-input">
              <label className="p--l">{"Video Description"}</label>
              <RichTextEditor setText={setDescription} text={description} ref={editorRef} />
            </Component>
          </Component>
          {props.track.type === "content" && (
            <ContentTrackPart
              item={props.track.item}
              setItem={props.track.setItem}
              baseItem={trackBase}
            />
          )}
          {props.track.type === "exercise" && (
            <ExerciseTrackPart
              item={props.track.item}
              setItem={props.track.setItem}
              baseItem={trackBase}
            />
          )}
          {props.track.type === "discussion" && (
            <DiscussionTrackPart
              item={props.track.item}
              setItem={props.track.setItem}
              baseItem={trackBase}
            />
          )}
        </Component>
      )}
    </>
  );
};

const ContentTrackPart = (props: {
  baseItem: TrackBaseRequestDto;
  item: ContentTrackResponse | undefined;
  setItem: Dispatch<SetStateAction<ContentTrackRequest | undefined>>;
}) => {
  useEffect(() => {
    props.setItem({
      description: props.baseItem.description,
      title: props.baseItem.title,
    });
  }, [props.baseItem]);

  return <></>;
};

const ExerciseTrackPart = (props: {
  baseItem: TrackBaseRequestDto;
  item: ExerciseTrackResponse | undefined;
  setItem: Dispatch<SetStateAction<ExerciseTrackRequest | undefined>>;
}) => {
  const [subject, setSubject] = useState<string>("");
  const [exerciseType, setExerciseType] = useState<"FILE_UPLOAD" | "LINK" | "FREE_TEXT" | "DONE">(
    "FREE_TEXT",
  );
  const exerciseTypeOptions = [
    {
      name: "File upload",
      id: "FILE_UPLOAD",
    },
    {
      name: "Link",
      id: "LINK",
    },
    {
      name: "Free text",
      id: "FREE_TEXT",
    },
    {
      name: "Done",
      id: "DONE",
    },
  ];

  useEffect(() => {
    setSubject(props.item?.subject ?? "");
    setExerciseType(props.item?.exerciseType ?? "FREE_TEXT");
  }, [props.item]);

  useEffect(() => {
    props.setItem({
      description: props.baseItem.description,
      title: props.baseItem.title,
      exerciseType: exerciseType,
      subject: subject,
    });
  }, [props.baseItem, exerciseType, subject]);

  return (
    <>
      <Component name="form-row">
        <Component name="form-input">
          <TextInputElement
            id="track-exercise-subject"
            label="Exercise Subject"
            type="text"
            labelClasses="p--l"
            onChange={(e) => setSubject((e.target as HTMLInputElement).value)}
            value={subject}
          />
        </Component>
      </Component>
      <Component name="form-row">
        <Component name="form-input">
          <TagSelect
            id="track-exercise-type"
            label="Exercise Type"
            labelClasses="p--l"
            optionLabelClasses="p--m"
            name="cs-lang"
            isHidden={true}
            options={exerciseTypeOptions}
            type="radio"
            value={exerciseType}
            onChange={(e) =>
              setExerciseType(() => {
                const x = (e.target as HTMLInputElement).id;
                if (x === "FILE_UPLOAD" || x === "LINK" || x === "FREE_TEXT" || x === "DONE") {
                  return x;
                }
                return "FILE_UPLOAD";
              })
            }
          />
        </Component>
      </Component>
    </>
  );
};

const DiscussionTrackPart = (props: {
  baseItem: TrackBaseRequestDto;
  item: DiscussionTrackResponse | undefined;
  setItem: Dispatch<SetStateAction<DiscussionTrackRequestDto | undefined>>;
}) => {
  const [question, setQuestion] = useState<string>("");

  useEffect(() => {
    setQuestion(props.item?.question ?? "");
  }, [props.item]);

  useEffect(() => {
    props.setItem({
      title: props.baseItem.title,
      description: props.baseItem.description,
      question: question,
    });
  }, [props.baseItem, question]);

  return (
    <>
      <Component name="form-row">
        <Component name="form-input">
          <label className="p--l">{"Discussion Question"}</label>
          <TextInputElement
            id="discussion-track-question"
            type="text"
            labelClasses="p--l"
            onInput={(e) => {
              setQuestion((e.target as HTMLInputElement).value);
            }}
            value={question}
          />
        </Component>
      </Component>
    </>
  );
};

export const MediaTrackPart = (props: { track: TrackResponseDto }) => {
  const [closed, setClosed] = useState<boolean>(false);
  const [thumbnail, setThumbnail] = useState<string>();
  const changeImage = (e: any) => {
    setThumbnail(e.target.files[0]);
  };

  console.log(props.track.contentTrack.fileStatus);

  return (
    <>
      <Component name="track-part-title" onClick={() => setClosed((value) => !value)}>
        <Title type="h3" size="s">
          Medien hinzufügen
        </Title>
        <Icon name="admin-academy-close" svg={<ArrowSVG />} className={closed ? "closed" : ""} />
      </Component>
      {!closed && (
        <Component name="form-inputs">
          <Component name="form-row">
            <Component name="form-input">
              <label className="p--l">{"Track Thumbnail"}</label>
              <ImageUploader
                img={thumbnail}
                type="small"
                onChange={changeImage}
                route={`academy/files/track/${props.track._id}/thumbnail`}
                method={"POST"}
              />
            </Component>
          </Component>
          {props.track.contentTrack && (
            <>
              <Component name="form-row">
                <Component name="form-input">
                  <label className="p--l">{`File for the viewers to download`}</label>
                  <FileUploader
                    type="small"
                    onFileUploaded={(url) => console.log(url)}
                    onChange={(url) => () => console.log(url)}
                    route={`academy/files/track/${props.track._id}/file`}
                    method={"POST"}
                    trackType="content"
                  />
                </Component>
              </Component>
              <hr />
            </>
          )}
          {props.track.exerciseTrack && (
            <>
              <Component name="form-row">
                <Component name="form-input">
                  <label className="p--l">{`File for the viewers to download`}</label>
                  <FileUploader
                    type="small"
                    onFileUploaded={(url) => console.log(url)}
                    onChange={(url) => () => console.log(url)}
                    route={`academy/files/track/${props.track._id}/file`}
                    method={"POST"}
                    trackType="exercise"
                  />
                </Component>
              </Component>
              <hr />
            </>
          )}
          {props.track.discussionTrack && (
            <>
              <Component name="form-row">
                <Component name="form-input">
                  <label className="p--l">{`File for the viewers to download`}</label>
                  <FileUploader
                    type="small"
                    onFileUploaded={(url) => console.log(url)}
                    onChange={(url) => () => console.log(url)}
                    route={`academy/files/track/${props.track._id}/file`}
                    method={"POST"}
                    trackType="discussion"
                  />
                </Component>
              </Component>
            </>
          )}
        </Component>
      )}
    </>
  );
};
