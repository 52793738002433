const useUtil = () => {
  const getRandomId = () => {
    const length = 8;
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };



  const removeImageFromList =
    (id: string, setUploadedPictures: (value: React.SetStateAction<{
      id: string;
      image: string;
    }[] | undefined>) => void) => {
      setUploadedPictures((oldValues) => {
        const final = [...(oldValues ?? [])];
        return final.filter(function (obj) {
          return obj.id !== id;
        });
      });
    };

  return { getRandomId, removeImageFromList }
}

export default useUtil