import { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  Component,
  Section,
  SectionElement,
  Title,
  Image,
  Paragraph,
  ProfileBubble,
  Popup,
  Button,
} from "../Elements/Elements";
import WelcomeMessage from "./WelcomeMessage";
import { useUser } from "../../hooks/useUser";
import {
  Paginated,
  WelcomeMessageResponseDto,
  EventResponseDto,
  AcademyResponseDto,
  User,
} from "../../requests/interfaces";
import historyNavigate from "../../hooks/useHistoryNavigate";
import useRequest from "../../hooks/useRequest";
import { Loading } from "../Loading/Loading";
import { EventItem } from "../Events/EventsTemplate";
import { HelloMessage, WelcomeWidget } from "./Components";
import { CountdownTimer, MasterclassEarlyBirdPopup } from "../Elements/Components";
import { AcademyWelcomeTab, AcademyWelcomeTabMocks } from "../../requests/AcademyMock";
import Context from "../../context/Context";
import { SelectInput } from "../Elements/FormElements";
import useFormOptions from "../../hooks/useFormOptions";

const WelcomeTab = () => {
  const { user } = useUser();
  const { useHistoryNavigate } = historyNavigate();
  const [welcomeMessages, setWelcomeMessages] = useState<WelcomeMessageResponseDto[]>([]);
  const [academyPreviews, setAcademyPreviews] = useState<AcademyResponseDto[]>();
  const [academyPreviewsMock, setAcademyPreviewsMock] = useState<AcademyWelcomeTab[]>();
  const [events, setEvents] = useState<EventResponseDto[]>([]);
  const { apiRequest } = useRequest();
  const [popupIsOpen, setPopupOpen] = useState<boolean>(false);
  const { setPopupContent } = useContext(Context);
  const [isOpen, setOpen] = useState<boolean>();

  const getWelcomeMessages = async () => {
    const { data } = await apiRequest<Paginated<WelcomeMessageResponseDto>>(
      `users/welcomeMessages?limit=10`,
      "GET",
    );

    setWelcomeMessages((oldValues) => {
      const combined = [...oldValues, ...data.docs];
      const final: WelcomeMessageResponseDto[] = [];
      combined.map((value) => {
        const index = final.findIndex((item) => item._id === value._id);
        if (index === -1) {
          final.push(value);
        }
      });
      return final;
    });
  };

  const getAcademies = async () => {
    const url = `academy?limit=50`;
    const { data } = await apiRequest<Paginated<AcademyResponseDto>>(url, "GET");
    setAcademyPreviews(data.docs);
    setAcademyPreviewsMock(AcademyWelcomeTabMocks);
  };

  useEffect(() => {
    const getEvents = async () => {
      const { data } = await apiRequest<Paginated<EventResponseDto>>(
        "events?limit=3&showAllEvents=true",
        "GET",
      );
      setEvents(data.docs);
    };
    getWelcomeMessages();
    getEvents();
    getAcademies();
  }, []);

  useEffect(() => {
    if (setPopupContent) {
      if (popupIsOpen) {
        setPopupContent(<PositionPopup setPopupOpen={setPopupOpen} />);
      } else {
        setPopupContent(undefined);
      }
    }
  }, [popupIsOpen]);

  useLayoutEffect(() => {
    if (!user?.career?.position && welcomeMessages.length !== 0 && events.length !== 0) {
      setPopupOpen(true);
    }
  }, [user, welcomeMessages, events]);

  useEffect(() => {
    if (setPopupContent) {
      if (isOpen) {
        setPopupContent(<MasterclassEarlyBirdPopup setOpen={setOpen} />);
      } else {
        setPopupContent(undefined);
      }
    }
  }, [isOpen]);

  return (
    <>
      <Section name="welcome">
        <SectionElement name="welcome" type="body">
          <Component name="welcome">
            {user && <HelloMessage name={user.firstName} />}
            {welcomeMessages.length === 0 && <Loading />}
            <Component name="welcome-messages">
              {welcomeMessages &&
                welcomeMessages.map((message, i) => {
                  return <WelcomeMessage {...message} key={"message-" + i} />;
                })}
            </Component>
          </Component>
          <Component name="welcome-dashboard">
            {true && (
              <WelcomeWidget
                title="Sales Education - neu gedacht 🤯"
                desc="Die SDRs of Germany Masterclass Academy"
                ctaTitle="Schalte jetzt die gesamte SDRs of Germany Masterclass Academy frei"
                onCtaClick={() =>
                  window
                    .open(
                      `https://payments.sdrsofgermany.com/b/14kaFf0BAaD1h2wdRo` +
                        `?prefilled_email=${user?.email}`,
                      "_blank",
                    )
                    ?.focus()
                }>
                {academyPreviews?.map((academyPreview) => {
                  return (
                    <Component
                      name="welcome-cta"
                      key={"academyPreview-" + academyPreview._id}
                      onClick={() => {
                        if (new Date(academyPreview.releaseDate).getTime() > new Date().getTime()) {
                          setOpen(true);
                        } else {
                          useHistoryNavigate(`?academyId=${academyPreview._id}`);
                        }
                      }}>
                      <Component name="test">
                        <Image
                          name="welcome-tab-academy-preview"
                          img={academyPreview.thumbnailUrl}
                        />
                        <CountdownTimer
                          targetDate={new Date(academyPreview.releaseDate)}
                          display={
                            new Date(academyPreview.releaseDate).getTime() > new Date().getTime()
                          }
                        />
                      </Component>
                      <Component name="academy-preview-info">
                        <Title name="welcome-cta" type="h2" size="xxs">
                          {academyPreview.title}
                        </Title>
                        {/* <Paragraph
                          name="academyPreview-instructor"
                          className="txt--c-surface-on-l"
                          size="l">
                          {`Mit ${academyPreview.instructors[0].firstName} ${academyPreview.instructors[0].lastName}`}
                        </Paragraph> */}
                        <ProfileBubble user={academyPreview.instructors[0]} />
                      </Component>
                    </Component>
                  );
                })}
              </WelcomeWidget>
            )}
            {false && (
              <WelcomeWidget
                title="Masterclass Academy"
                desc=""
                ctaTitle="Early Access anfordern"
                onCtaClick={() =>
                  window.open("https://www.wonderlink.de/@sdrsofgermany", "_blank")?.focus()
                }>
                {academyPreviewsMock?.map((academyPreview) => {
                  const description1 = academyPreview.description.split("\n")[0];
                  const description2 = academyPreview.description.split("\n")[1];

                  return (
                    <Component
                      name="welcome-cta"
                      key={"academyPreview-" + academyPreview._id}
                      onClick={() => {
                        // onAcademyUpsellClick(academyPreview._id)
                      }}>
                      <Image name="academy" img={academyPreview.thumbnailUrl} />
                      <Component name="academyPreview-info">
                        <Title name="welcome-cta" type="h2" size="xxs">
                          {description1}
                        </Title>
                        <Paragraph
                          name="academyPreview"
                          type="paragraph"
                          className="p--m txt--c-on-bg-l">
                          {description2}
                        </Paragraph>
                      </Component>
                    </Component>
                  );
                })}
              </WelcomeWidget>
            )}
            <WelcomeWidget
              title="Aktuelle Events 🔥"
              desc="On- und Offline Webinare und Networking Sessions"
              ctaTitle="Zu den aktuellen Events"
              onCtaClick={() => useHistoryNavigate("/events")}>
              {events?.map((event) => {
                return <EventItem event={event} key={event._id} isHome={true} />;
              })}
            </WelcomeWidget>
          </Component>
        </SectionElement>
      </Section>
    </>
  );
};

const PositionPopup = (props: { setPopupOpen: any }) => {
  enum ErrorFields {
    "POSITION",
  }

  const [position, setPosition] = useState<string>("");
  const { user } = useUser();
  const [errorFields, setErrorFields] = useState<ErrorFields[]>();
  const { apiRequest } = useRequest();
  const [formOptions, setFormOptions] = useState<any>();
  const { setUser } = useContext(Context);

  useEffect(() => {
    validate();
  }, [position]);

  useEffect(() => {
    if (user?.career?.position) {
      setPosition(user.career.position);
    }
  }, [user]);

  const validate = () => {
    const errors = [];
    if (position === "" || position === "select") {
      errors.push(ErrorFields.POSITION);
    }
    setErrorFields(errors);
  };

  useEffect(() => {
    const setupOptions = async () => {
      setFormOptions(await useFormOptions().getFormOptions());
    };
    setupOptions();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const save = async (e: any, operator?: number, request?: any) => {
    const finalRequest = {
      career: {
        ...user?.career,
        position: position,
      },
    };
    const { data, status } = await apiRequest<User>("user", "PUT", {
      body: finalRequest,
      showToast: true,
    });
    if (status > 200 && status < 299) {
      props.setPopupOpen(false);
      if (setUser) setUser(data);
      window.location.reload();
    }
  };

  return (
    <Popup setPopupIsOpen={props.setPopupOpen} size="SMALL" isClosable={false}>
      <Title name="position-popup-title" type="h2" size="xxs">
        Wir benötigen noch ein paar Informationen, um dein Login abzuschliessen
      </Title>
      {formOptions && (
        <SelectInput
          id="position"
          label="Position"
          labelClasses="p--l"
          optionLabelClasses="p--m"
          name="cs-position"
          value={position}
          options={formOptions.positionOptions}
          onInput={(e) => {
            setPosition((e.target as HTMLInputElement).value);
          }}
          errorMessage="Bitte ergänzen Sie ihre Firma"
          displayErrorMessage={errorFields?.includes(ErrorFields.POSITION)}
        />
      )}
      <Button
        name="save-position"
        type="primary"
        onClick={save}
        disabled={errorFields?.includes(ErrorFields.POSITION)}>
        Speichern
      </Button>
    </Popup>
  );
};

export default WelcomeTab;
