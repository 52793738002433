import { useContext } from "react";
import historyNavigate from "./useHistoryNavigate";
import Context from "../context/Context";

export const useProfile = () => {
  const { useHistoryNavigate } = historyNavigate();
  const { setPopupContent } = useContext(Context);

  const onProfileClick = (id: string) => {
    if (!window.location.pathname.split("/user")[1]) {
      useHistoryNavigate("/user/" + id);
      setPopupContent ? setPopupContent(undefined) : "";
    }
  };

  return { onProfileClick };
};
