import { Main, Page } from "../components/Elements/Elements";
import Sidebar from "../components/Navigation/Sidebar";
import { Notifications as NotificationsComponent } from "../components/Navigation/Notifications";

const Notifications = () => {
  return (
    <Page name="notifications">
      <Sidebar />
      <Main>
        <NotificationsComponent />
      </Main>
    </Page>
  );
};

export default Notifications;
