import { Auth0Provider } from "@auth0/auth0-react";

export const Auth0ProviderWithNavigate = ({ children }: any) => {
  const domain = `${process.env.REACT_APP_NODE_ENV}-winno-x-sdrs.eu.auth0.com`;
  const clientId = process.env.REACT_APP_AUTH0_ID;
  const redirectUri = window.location.origin;

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
        scope: "openid profile email offline_access",
      }}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      cacheLocation="localstorage">
      {children}
    </Auth0Provider>
  );
};
