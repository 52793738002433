import { useEffect, useState } from "react";
import ArrowSVG from "../../Icons/ArrowSVG";
import CheckCircleSVG from "../../Icons/CheckCircleSVG";
import PlayCircleSVG from "../../Icons/PlayCircleSVG";
import PlaySVG from "../../Icons/PlaySVG";
import historyNavigate from "../../hooks/useHistoryNavigate";
import useRequest from "../../hooks/useRequest";
import {
  AcademyMediaLibraryResponse,
  AcademyPreviewResponse,
  AcademyResponseDto,
  Paginated,
} from "../../requests/interfaces";
import {
  Component,
  ComponentCard,
  Icon,
  Image,
  LevelBar,
  Paragraph,
  ProfileBubble,
  Section,
  SectionElement,
  Title,
} from "../Elements/Elements";
import { AcademyHero, AcademyItem } from "./AllMasterclasses";
import { Loading } from "../Loading/Loading";

const MediaLibrary = () => {
  return <AllMedias />;
};

const AllMedias = () => {
  const [mediaResponse, setMediaResponse] = useState<AcademyMediaLibraryResponse>();
  const [myAcademies, setMyAcademies] = useState<AcademyResponseDto[]>();
  const { useHistoryNavigate } = historyNavigate();
  const { apiRequest } = useRequest();

  const onCurrentVideoClicked = () => {
    if (mediaResponse) {
      useHistoryNavigate(
        `/academy/${mediaResponse.lastVideo.academyId}/${
          mediaResponse.lastVideo.trackId
        }?route=${mediaResponse.lastVideo.videoType.toLowerCase()}`,
      );
    }
  };

  useEffect(() => {
    const getContent = async () => {
      const { data } = await apiRequest<AcademyMediaLibraryResponse>("academy/mediaLibrary", "GET");
      setMediaResponse(data);

      const myAcademiesRes = await apiRequest<Paginated<AcademyResponseDto>>(
        `academy?boughtFilter=bought&limit=50`,
        "GET",
      );
      setMyAcademies(myAcademiesRes.data.docs);
    };
    getContent();
  }, []);

  return (
    <Section name="media-library">
      <SectionElement type="header" name="media-library"></SectionElement>
      <SectionElement type="body" name="media-library">
        {mediaResponse && mediaResponse.lastVideo && myAcademies?.length !== 0 && (
          <Component name="media-library-content">
            <Component name="media-library">
              <Component name="media-library-overview">
                <Title name="media-library-overview" type="h2" size="s">
                  Mein Fortschritt:
                </Title>
                <Component name="media-library-overview-stats">
                  <ComponentCard name="media-library-overview-stat">
                    <Title
                      name="media-library-overview-stat"
                      type="h3"
                      size="xs"
                      className="txt--c-pri">
                      {mediaResponse.completedAcademies}
                    </Title>
                    <Paragraph name="media-library-overview-stat" size="s">
                      Zertifikate
                    </Paragraph>
                  </ComponentCard>
                  <ComponentCard name="media-library-overview-stat">
                    <Title
                      name="media-library-overview-stat"
                      type="h3"
                      size="xs"
                      className="txt--c-pri">
                      {mediaResponse.completedLessons}
                    </Title>
                    <Paragraph name="media-library-overview-stat" size="s">
                      Lektionen beendet
                    </Paragraph>
                  </ComponentCard>
                  <ComponentCard name="media-library-overview-stat">
                    <Title
                      name="media-library-overview-stat"
                      type="h3"
                      size="xs"
                      className="txt--c-pri">
                      {mediaResponse.approvedTasks}
                    </Title>
                    <Paragraph name="media-library-overview-stat" size="s">
                      Aufgaben bearbeitet
                    </Paragraph>
                  </ComponentCard>
                </Component>
              </Component>
              <Component name="media-library-resume">
                <Title name="media-library-resume" type="h2" size="s">
                  Lektion fortsetzen:
                </Title>
                <Component name="media-library-resume-preview" onClick={onCurrentVideoClicked}>
                  <Image
                    img={mediaResponse.lastVideo.trackThumbnail}
                    name="media-library-resume-preview"
                  />
                  <Component name="video-control-overlay">
                    <Icon svg={<PlaySVG />} name="video-control-play" />
                    <Component name="video-controls">
                      <Paragraph name="video-control-academy" size="m" className="txt--c-on-pri">
                        {mediaResponse.lastVideo.academyTitle}
                      </Paragraph>
                      <Paragraph name="media-library-current-video-track-title" size="l">
                        {mediaResponse.lastVideo.trackTitle}
                      </Paragraph>
                      <LevelBar points={50} pointsToNextLevel={100} withHover={false} size="m" />
                    </Component>
                  </Component>
                </Component>
              </Component>
            </Component>
            <Component name="media-library-menu">
              <MenuTab
                videoPreviews={mediaResponse.started}
                icon={<PlayCircleSVG />}
                title="angefangene Masterclasses"
              />
              <MenuTab
                videoPreviews={mediaResponse.finished}
                icon={<CheckCircleSVG />}
                title="abgeschlossene Masterclasses"
              />
            </Component>
          </Component>
        )}
        {myAcademies?.length !== 0 && (
          <Component name="available-masterclasses">
            <Title name="my-masterclasses" size="m" type="h3">
              Meine Masterclasses
            </Title>
            {myAcademies?.length !== 0 && (
              <Component name="my-masterclasses">
                {myAcademies?.map((academy, i) => {
                  return <AcademyItem key={i} academy={academy} filters={[""]} toDelete={i} />;
                })}
              </Component>
            )}
          </Component>
        )}
        {myAcademies === undefined && <Loading />}
        {myAcademies?.length === 0 && <AcademyHero academies={[]} type="mediaLibrary" />}
      </SectionElement>
      <SectionElement type="footer" name="media-library"></SectionElement>
    </Section>
  );
};

const MenuTab = (props: { videoPreviews: AcademyPreviewResponse[]; title: string; icon: any }) => {
  const [isExtended, setIsExtended] = useState<boolean>(false);

  const { useHistoryNavigate } = historyNavigate();

  return (
    <>
      <Component
        name="media-library-menu-tab-header"
        onClick={() => setIsExtended((value) => !value)}>
        <Component name="media-library-menu-tab-header-content">
          <Component name="media-library-menu-tab-header-title">
            <Icon name="media-library-menu-tab-header-title" svg={props.icon} />
            <Title name="media-library-menu-tab-header-title" type="h2" size="xs">
              {props.title}
            </Title>
          </Component>
          <Icon
            name={`media-library-menu-tab-header-icon`}
            className={`${isExtended ? "extended" : ""}`}
            svg={<ArrowSVG />}
          />
        </Component>
        <hr />
      </Component>
      {isExtended && (
        <Component name="media-library-menu-tab-content">
          {props.videoPreviews.map((videoPreview, i) => {
            return (
              <>
                <Component
                  key={videoPreview.academy._id}
                  name="media-library-menu-academy-item"
                  onClick={() => {
                    useHistoryNavigate(`/academy?academyId=${videoPreview.academy._id}`);
                  }}>
                  <Component name="media-library-menu-tab-thumbnail">
                    <Image
                      img={videoPreview.academy.thumbnail}
                      name="media-library-menu-tab-academy-thumbnail"
                    />
                  </Component>
                  <Component name="media-library-menu-tab-academy-info">
                    <Title name="media-library-menu-tab-academy-title" type="h3" size="xs">
                      {videoPreview.academy.title}
                    </Title>
                    <ProfileBubble user={videoPreview.academy.instructors[0]} />
                    <Paragraph name="media-library-overview-course-certificates" size="m">
                      {`${videoPreview.doneTracksAmount} von ${videoPreview.trackAmount} Videos`}
                    </Paragraph>
                    <LevelBar
                      points={videoPreview.doneTracksAmount}
                      pointsToNextLevel={videoPreview.trackAmount}
                      withHover={false}
                      size="m"
                    />
                  </Component>
                </Component>
                {i + 1 !== props.videoPreviews.length && (
                  <hr className="el--media-library-seperator el" />
                )}
              </>
            );
          })}
          {props.videoPreviews.length === 0 && (
            <Title name="media-library-menu-tab-finish-academy" size="xs" type="h3">
              Du musst zuerst eine Masterclass{" "}
              {props.title === "Angefangen" ? "anfange" : "vollständig abschliessen"}
            </Title>
          )}
        </Component>
      )}
    </>
  );
};

export default MediaLibrary;
