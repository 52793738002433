import { Markup } from "interweave";
import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DiscussionSVG from "../../Icons/DiscussionSVG";
import DownloadSVG from "../../Icons/DownloadSVG";
import ListSVG from "../../Icons/ListSVG";
import StarSVG from "../../Icons/StarSVG";
import VideoSVG from "../../Icons/VideoSVG";
import historyNavigate from "../../hooks/useHistoryNavigate";
import useRequest from "../../hooks/useRequest";
import {
  AcademyDiscussionResponseDto,
  ContentTrackResponse,
  DiscussionTrackResponse as DiscussionTrackDto,
  ExerciseTrackResponse,
  Paginated,
  TrackResponseDto,
} from "../../requests/interfaces";
import { DiscussionElement, StartDiscussion } from "../Discussion/Components";
import {
  MasterclassBuyButton,
  BackButton,
  Button,
  Component,
  FileUploader,
  Icon,
  Paragraph,
  RichTextEditor,
  Section,
  SectionElement,
  Title,
  ComponentCard,
} from "../Elements/Elements";
import { ErrorMessage, Switch, TextInputElement } from "../Elements/FormElements";
import { Loading } from "../Loading/Loading";
import Navigation from "../Navigation/Navigation";
import { TrackShakaPlayer } from "./ShakaPlayer";
import { saveAcademyProgress } from "../Routes/Routes";
import Context from "../../context/Context";

interface Progress {
  contentVideoProgress: number;
  exerciseVideoProgress: number;
  discussionVideoProgress: number;
  exerciseDone: boolean;
  submittedText: boolean;
}

const TrackContext = React.createContext<{
  trackId?: string;
  academyId?: string;
  setContinueButton?: Dispatch<SetStateAction<boolean>>;
  progress?: Progress;
  setProgress?: Dispatch<SetStateAction<Progress>>;
}>({
  trackId: "",
});

const TrackDetails = (props: { academyId?: string; trackId?: string }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const route = searchParams.get("route");
  const [track, setTrack] = useState<TrackResponseDto>();
  const { apiRequest } = useRequest();
  const [responseStatus, setResponseStatus] = useState<number>(0);
  const { useHistoryNavigate } = historyNavigate();
  const [trackLink, setTrackLink] = useState<string>("https://www.google.com");
  const [, setContinueButton] = useState<boolean>(false);
  const [progress, setProgress] = useState<Progress>({
    contentVideoProgress: 0,
    discussionVideoProgress: 0,
    exerciseDone: false,
    exerciseVideoProgress: 0,
    submittedText: false,
  });
  const { isMobile } = useContext(Context);

  useEffect(() => {
    if (route !== "content" && route !== "exercise" && route !== "discussion") {
      if (!(track?.exerciseTrack && track.discussionTrack) && route !== null) {
        searchParams.set("route", "text");
      } else {
        searchParams.set("route", "content");
      }
      searchParams.delete("tab");
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const loadTrack = async () => {
    try {
      const { data, status } = await apiRequest<TrackResponseDto>(
        `academy/track/${props.trackId}`,
        "GET",
      );
      setResponseStatus(status);
      setTrack(data);
    } catch (e: any) {
      setTrackLink("https://www.google.com");
      setResponseStatus(e.cause);
    }
  };

  useEffect(() => {
    loadTrack();
  }, [props.trackId]);

  const markAsFinishedTrack = async () => {
    try {
      const { data } = await apiRequest<{ nextTrackId: string }>(
        `academy/progress/track/${props.trackId}/finishWithoutError`,
        "POST",
      );
      data.nextTrackId
        ? useHistoryNavigate(`/academy/${props.academyId}/${data.nextTrackId}`)
        : useHistoryNavigate(`/academy/${props.academyId}?tab=2`);
    } catch (e) {
      useHistoryNavigate(`/academy/${props.academyId}?tab=2`);
    }
  };

  useEffect(() => {
    if (track) {
      setProgress({
        contentVideoProgress: track.contentTrack.videoProgress,
        exerciseVideoProgress: track?.exerciseTrack?.videoProgress ?? 0,
        exerciseDone: track?.exerciseTrack?.exerciseSubmission !== null,
        discussionVideoProgress: track?.discussionTrack?.videoProgress ?? 0,
        submittedText: false,
      });
    }
  }, [track]);

  const onNextLessonButtonClick = () => {
    let nextRoute = "";
    if (route && track) {
      if (route === "content" || route === "exercise") {
        nextRoute =
          route === "content" && track.exerciseTrack
            ? "exercise"
            : route === "exercise"
            ? "discussion"
            : "text";
        searchParams.set("route", nextRoute);
        setSearchParams(searchParams);
      } else {
        markAsFinishedTrack();
      }
    }
  };

  return (
    <>
      <Navigation />
      {track && (
        <Section name="track">
          <SectionElement type="body" name="track">
            <TrackContext.Provider
              value={{
                trackId: props.trackId,
                academyId: props.academyId,
                setContinueButton: setContinueButton,
                progress: progress,
                setProgress: setProgress,
              }}>
              <Component name="full-track">
                <Component name="track-info">
                  <Component name="back-button">
                    <BackButton fixedLocation={`/academy?academyId=${props.academyId}&tab=2`} />
                    {isMobile && (
                      <NextLessonButton
                        progress={progress}
                        route={route ?? ""}
                        index={track.index}
                        hasExerciseAndDiscussionTrack={
                          track.discussionTrack !== undefined && track.exerciseTrack !== undefined
                        }
                        onClick={onNextLessonButtonClick}
                      />
                    )}
                  </Component>
                  <Title name="track-title" size="m" type="h1">
                    {track.title}
                  </Title>
                  <Component name="track-menu">
                    <TrackMenuItem
                      selected={route === "content"}
                      title={track.contentTrack.title}
                      icon={<VideoSVG />}
                      route="content"
                    />
                    {track.exerciseTrack && (
                      <TrackMenuItem
                        selected={route === "exercise"}
                        title={track.exerciseTrack.title}
                        icon={<ListSVG />}
                        route="exercise"
                      />
                    )}
                    {track.discussionTrack && (
                      <TrackMenuItem
                        selected={route === "discussion"}
                        title={track.discussionTrack.title}
                        icon={<DiscussionSVG />}
                        route="discussion"
                      />
                    )}
                    {!(track.discussionTrack && track.exerciseTrack) && (
                      <TrackMenuItem
                        selected={route === "text"}
                        title={track.index === 1 ? "Deine Ziele" : "Feedback"}
                        icon={<DiscussionSVG />}
                        route="text"
                      />
                    )}
                  </Component>
                </Component>
                <Component name="track">
                  <Component name="next-button">
                    <>
                      {!isMobile && (
                        <NextLessonButton
                          progress={progress}
                          route={route ?? ""}
                          index={track.index}
                          hasExerciseAndDiscussionTrack={
                            track.discussionTrack !== undefined && track.exerciseTrack !== undefined
                          }
                          onClick={onNextLessonButtonClick}
                        />
                      )}
                    </>
                  </Component>
                  {!(track.discussionTrack && track.exerciseTrack) && route === "text" && (
                    <TextFieldTrack
                      mode={track.index === 1 ? "MEET_YOUR_INSTRUCTOR" : "WRAP_UP"}
                      academyId={props.academyId ?? ""}
                      trackId={props.trackId ?? ""}
                    />
                  )}
                  {route === "content" && props.trackId === track._id && (
                    <ContentTrack contentTrack={track.contentTrack} />
                  )}{" "}
                  {track.discussionTrack && track.exerciseTrack && (
                    <>
                      {route === "exercise" && (
                        <ExerciseTrack exerciseTrack={track.exerciseTrack} />
                      )}
                      {route === "discussion" && (
                        <DiscussionTrack discussionTrack={track.discussionTrack} />
                      )}
                    </>
                  )}
                </Component>
              </Component>
            </TrackContext.Provider>
          </SectionElement>
        </Section>
      )}
      {!track && responseStatus === 0 && <Loading />}
      {!track && responseStatus === 409 && (
        <ComponentCard name="no-access">
          <Title name="no-access" size="s" type="h1">
            Zugriff auf diese Lektion bekommst du, wenn du die vorherige beendet hast 😊
          </Title>
          <Button
            type="primary"
            onClick={() => useHistoryNavigate(`/academy?academyId=${props.academyId}&tab=2`)}>
            Zurück zur Masterclass
          </Button>
        </ComponentCard>
      )}
      {!track && responseStatus === 403 && (
        <Component name="no-access">
          <MasterclassBuyButton
            type="mediaLibrary"
            productLink={trackLink} // academy Link
          />
        </Component>
      )}
    </>
  );
};

const TrackMenuItem = (props: { selected: boolean; title: string; icon: any; route: string }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setProgress } = useContext(TrackContext);
  const { apiRequest } = useRequest();

  return (
    <Component
      name="track-menu-item"
      className={`${props.selected ? "selected" : ""}`}
      onClick={async () => {
        searchParams.set("route", props.route);
        setSearchParams(searchParams);
        const data = await saveAcademyProgress(apiRequest);
        if (setProgress) {
          setProgress((old) => {
            if (data?.trackType === "content") {
              old.contentVideoProgress = data.videoProgress;
            }
            if (data?.trackType === "exercise") {
              old.exerciseVideoProgress = data.videoProgress;
            }
            if (data?.trackType === "discussion") {
              old.discussionVideoProgress = data.videoProgress;
            }
            return old;
          });
        }
      }}>
      <Icon name="track-menu-item" svg={props.icon} />
      <Paragraph name="track-menu-item" size="m">
        {props.title}
      </Paragraph>
    </Component>
  );
};

const testStream =
  "https://storage.googleapis.com/pictures-sdrs-test/academies/28049988-086d-4d73-993b-abc41f4eb7dd/marketing-video/hls.m3u8?asdf=asdf";
const testStream2 =
  "https://storage.googleapis.com/pictures-sdrs-test/academies/0d6bd525-6526-4fbc-a8b7-c0ab5845eb48/marketing-video/hls.m3u8?asdf=asdf";
const testStream3 =
  "https://storage.googleapis.com/pictures-sdrs-test/academies/0d6e3b06-d498-45bf-b718-e652de22e58f/marketing-video/hls.m3u8?asdf=asdf";
const testStream4 =
  "https://storage.googleapis.com/pictures-sdrs-test/academies/2378a3d2-6078-41dc-a200-c214f03cbca1/marketing-video/hls.m3u8?asdf=asdf";

const testStreams = [testStream, testStream2, testStream3, testStream4];
const maxStarAmount = 5;

const ContentTrack = (props: { contentTrack: ContentTrackResponse }) => {
  const { trackId, setContinueButton, progress } = useContext(TrackContext);
  const [video, setVideo] = useState<string>();

  useEffect(() => {
    setVideo(
      process.env.REACT_APP_NODE_ENV === "development" && !props.contentTrack.videoUrl
        ? testStreams[Math.floor(Math.random() * testStreams.length)]
        : props.contentTrack.videoUrl,
    );
  }, [props.contentTrack]);

  return (
    <Component name="content-track">
      {setContinueButton && (
        <>
          <Component name="track-description">
            {video && (
              <TrackShakaPlayer
                video={video}
                trackId={trackId ?? ""}
                trackType="content"
                seekTo={props.contentTrack.videoProgress ?? progress?.contentVideoProgress ?? 0}
                setContinueButton={setContinueButton}
              />
            )}
            <Markup content={props.contentTrack.description} />
          </Component>
          {props.contentTrack.fileUrl && (
            <FileDownload
              fileUrl={props.contentTrack.fileUrl}
              fileName={props.contentTrack.filename}
            />
          )}
        </>
      )}
    </Component>
  );
};

const ExerciseTrack = (props: { exerciseTrack: ExerciseTrackResponse }) => {
  const editorRef: any = useRef();
  const [textContent, setTextContent] = useState<string>("");
  const [file, setFile] = useState<string>("");
  const [video, setVideo] = useState<string>("");
  const { trackId, setContinueButton, setProgress, progress } = useContext(TrackContext);
  const { apiRequest } = useRequest();

  useEffect(() => {
    setVideo(
      process.env.REACT_APP_NODE_ENV === "development" && !props.exerciseTrack.videoUrl
        ? testStreams[Math.floor(Math.random() * testStreams.length)]
        : props.exerciseTrack.videoUrl,
    );
  }, [props.exerciseTrack]);

  const uploadExercise = async () => {
    let request = {};
    if (props.exerciseTrack.exerciseType === "FREE_TEXT") {
      request = {
        freeText: textContent,
      };
    } else if (props.exerciseTrack.exerciseType === "LINK") {
      request = {
        link: textContent,
      };
    } else if (props.exerciseTrack.exerciseType === "DONE") {
      request = {
        freeText: "FINISHED",
      };
    }

    await apiRequest(`academy/progress/track/${trackId}/exercise`, "POST", {
      body: request,
      showToast: true,
    });
    markExerciseAsDone();
  };

  const markExerciseAsDone = () => {
    if (setProgress) {
      setProgress((old) => {
        old.exerciseDone = true;
        return old;
      });
    }
  };

  useEffect(() => {
    if (props.exerciseTrack.exerciseSubmission) {
      setTextContent(
        props.exerciseTrack.exerciseSubmission.freeText ??
          props.exerciseTrack.exerciseSubmission.link ??
          "",
      );
      console.log(props.exerciseTrack.exerciseSubmission.filename ?? "");
      setFile(props.exerciseTrack.exerciseSubmission.filename ?? "");
    }
  }, [props.exerciseTrack]);

  useEffect(() => {
    // reload
  }, [textContent]);

  const getExerciseText = () => {
    const approved =
      "✅ Herzlichen Glückwunsch - du hast das Gelernte in die Praxis umgesetzt und dein Erarbeitetes wurde von uns überprüft!";
    const rejected =
      "👀 Du hast diese Aufgabe noch nicht vollständig  oder mangelhaft fertiggestellt - überprüfe deine Arbeit und reiche sie nochmals ein.";
    const pending =
      "🕑 Du hast das Gelernte in die Praxis umgesetzt - wir überprüfen dein Erarbeitetes und geben dir Rückmeldung.";
    const feedback = (
      <>
        <strong>📝 Du hast Feedback zu deiner Aufgabe erhalten: </strong>
        {props.exerciseTrack.exerciseSubmission?.adminFeedback}
      </>
    );

    if (props.exerciseTrack.exerciseSubmission?.adminFeedback) return feedback;

    switch (props.exerciseTrack.exerciseSubmission?.status) {
      case "APPROVED":
        return approved;
      case "PENDING":
        return pending;
      default:
        return rejected;
    }
  };

  return (
    <Component name="exercise-track">
      {setContinueButton && (
        <>
          <Component name="track-description">
            <Component name="exercise-track-header">
              {props.exerciseTrack.exerciseSubmission && (
                <Component
                  name="exercise-track-exercise-status"
                  className={`exercise-status-${props.exerciseTrack.exerciseSubmission.status.toLowerCase()}`}>
                  {getExerciseText()}
                </Component>
              )}
              {video && (
                <TrackShakaPlayer
                  video={video}
                  trackId={trackId ?? ""}
                  trackType="exercise"
                  seekTo={props.exerciseTrack.videoProgress ?? progress?.exerciseVideoProgress ?? 0}
                  setContinueButton={setContinueButton}
                />
              )}
            </Component>
            <Markup content={props.exerciseTrack.description} />
            {props.exerciseTrack.fileUrl && (
              <FileDownload
                fileUrl={props.exerciseTrack.fileUrl}
                fileName={props.exerciseTrack.filename}
              />
            )}
          </Component>
          <Component name="exercise-input">
            {props.exerciseTrack.exerciseType === "FILE_UPLOAD" && (
              <FileUploader
                type="small"
                file={file}
                onChange={(url) => () => console.log(url)}
                route={`academy/progress/track/${trackId}/exercise`}
                method="POST"
                onFileUploaded={() => {
                  markExerciseAsDone();
                }}
              />
            )}
            {props.exerciseTrack.exerciseType === "LINK" && (
              <TextInputElement
                id="link"
                label="Reiche hier deine Aufgabe ein"
                type="text"
                labelClasses="p--l"
                onInput={(e) => setTextContent((e.target as HTMLInputElement).value)}
                value={textContent}
              />
            )}
            {props.exerciseTrack.exerciseType === "DONE" && (
              <Switch
                label={"Aufgabe gelöst"}
                type={""}
                customChange={(checked) => {
                  if (checked) {
                    uploadExercise();
                  }
                }}
              />
            )}
            {props.exerciseTrack.exerciseType === "FREE_TEXT" && (
              <RichTextEditor setText={setTextContent} ref={editorRef} text={textContent} />
            )}
            {textContent && (
              <Button type="primary" name="upload-exercise" onClick={uploadExercise}>
                Bestätigen
              </Button>
            )}
          </Component>
        </>
      )}
    </Component>
  );
};

const NextLessonButton = (props: {
  progress: any;
  route: string;
  index: number;
  hasExerciseAndDiscussionTrack: boolean;
  onClick: any;
}) => {
  const route = props.route;
  return (
    <Component name="next-lesson-button">
      <Component name="next-lesson">
        <Button
          type="primary"
          disabled={!props.progress.exerciseDone && route === "discussion"}
          name="next-button"
          onClick={() => {
            props.onClick();
          }}>
          {!props.hasExerciseAndDiscussionTrack
            ? props.index !== 1 && route === "text"
              ? "Masterclass abschliessen"
              : "nächste Lektion"
            : "nächste Lektion"}
        </Button>
      </Component>
      <Component name="not-finished-masterclass">
        <ErrorMessage
          displayErrorMessage={!props.progress.exerciseDone && route === "discussion"}
          errorMessage="⚠️ Um diese Lektion abzuschließen, musst du das Gelernte in die Praxis umsetzen. Sobald du dein Erarbeitetes eingereicht hast, wirst du von uns Feedback bekommen."
        />
      </Component>
    </Component>
  );
};

const DiscussionTrack = (props: { discussionTrack: DiscussionTrackDto }) => {
  const { trackId, setContinueButton, progress } = useContext(TrackContext);
  const { apiRequest } = useRequest();
  const [discussions, setDiscussions] = useState<AcademyDiscussionResponseDto[]>([]);
  const [disabled, setDisabled] = useState<boolean>();
  const [video, setVideo] = useState<string>("");

  useEffect(() => {
    setVideo(
      process.env.REACT_APP_NODE_ENV === "development" && !props.discussionTrack.videoUrl
        ? testStreams[Math.floor(Math.random() * testStreams.length)]
        : props.discussionTrack.videoUrl,
    );
  }, [props.discussionTrack]);

  useEffect(() => {
    setDisabled(
      !(
        progress &&
        progress.contentVideoProgress > 10 &&
        progress.exerciseVideoProgress > 10 &&
        progress.exerciseDone
      ),
    );
  }, [progress]);

  const startAcademyDiscussion = async (
    discussionText: string,
    tags: string[],
    anonym: boolean,
    uploadedImages: string[],
  ) => {
    await saveAcademyProgress(apiRequest);

    const request = {
      content: discussionText.trim(),
      pictures: uploadedImages,
    };
    await apiRequest<AcademyDiscussionResponseDto>(`academy/discussions/${trackId}`, "POST", {
      body: request,
      showToast: true,
    });
    // await apiRequest(`academy/progress/track/${trackId}/finish`, "POST");
    getDiscussions();
  };

  const getDiscussions = async () => {
    const { data } = await apiRequest<Paginated<AcademyDiscussionResponseDto>>(
      `academy/discussions/track/${trackId}`,
      "GET",
    );
    setDiscussions(data.docs);
  };

  useEffect(() => {
    getDiscussions();
  }, []);

  return (
    <Component name="discussion-track">
      {setContinueButton && (
        <>
          <Component name="track-description">
            {video && (
              <TrackShakaPlayer
                video={video}
                trackId={trackId ?? ""}
                trackType="discussion"
                seekTo={
                  props.discussionTrack.videoProgress ?? progress?.discussionVideoProgress ?? 0
                }
                setContinueButton={setContinueButton}
              />
            )}
            {/*<Markup content={props.discussionTrack.description} />
            {props.discussionTrack.fileUrl && (
              <FileDownload
                fileUrl={props.discussionTrack.fileUrl}
                fileName={props.discussionTrack.filename}
              />
            )}*/}
            <StartDiscussion
              onSubmit={startAcademyDiscussion}
              imageRoute={`academy/discussions/discussionPictures`}
              showTags={false}
              discussionQuestion={props.discussionTrack.question}
              canPostAnonymous={false}
              // isDisabled={disabled}
            />
            {disabled && progress !== undefined && (
              // <Paragraph name="track-error-message" className="txt--c-error" size="m">
              //   {progress.contentVideoProgress === 0
              //     ? "Bevor du eine Diskussion starten kannst, musst du das erste Video angeschaut haben"
              //     : progress.exerciseVideoProgress === 0
              //     ? "Bevor du eine Diskussion starten kannst, musst du das zweite Video angeschaut haben"
              //     : !progress.exerciseDone
              //     ? "Bevor du eine Diskussion starten kannst, musst du die Aufgabe aus dem zweiten Video eingereicht haben"
              //     : ""}
              // </Paragraph>
              <></>
            )}
            {discussions?.map((discussion) => {
              return (
                <DiscussionElement
                  key={discussion._id}
                  discussion={{
                    status: "",
                    hashtags: [],
                    ...discussion,
                    _id: discussion._id ?? "",
                  }}
                  showComments={false}
                  baseRoute="academy/"
                />
              );
            })}
          </Component>
          <Component name="discussion-track-discussions"></Component>
        </>
      )}
    </Component>
  );
};

const TextFieldTrack = (props: {
  mode: "MEET_YOUR_INSTRUCTOR" | "WRAP_UP";
  academyId: string;
  trackId: string;
}) => {
  const [starAmount, setStarAmount] = useState<number>(-1);
  const [fixed, setFixed] = useState<number>(-1);
  const [text, setText] = useState<string>("");
  const [displayError, setDisplayError] = useState<boolean>(false);
  const { progress } = useContext(TrackContext);

  const { apiRequest } = useRequest();

  const submit = async () => {
    let request = {};
    let route = "";

    if (props.mode === "MEET_YOUR_INSTRUCTOR") {
      request = {
        expectation: text,
      };
      route = `academy/progress/${props.academyId}/expectation`;
    } else if (props.mode === "WRAP_UP") {
      route = `academy/progress/${props.academyId}/feedback`;
      request = {
        feedback: text,
        rating: fixed,
      };
    }
    // if (props.mode === "WRAP_UP") {
    //   await apiRequest(`academy/progress/track/${props.trackId}/finish`, "POST");
    // }
    try {
      await apiRequest(route, "POST", {
        body: request,
        showToast: true,
      });
    } catch (e: any) {
      setDisplayError(true);
    }
    // if (props.mode === "MEET_YOUR_INSTRUCTOR") {
    //   await apiRequest(`academy/progress/track/${props.trackId}/finish`, "POST");
    // }
  };

  return (
    <>
      <Component name="text-field-track">
        <Title type="h2" size="s" name="text-field-track-title">
          {props.mode === "MEET_YOUR_INSTRUCTOR"
            ? "Was sind deine Ziele für diese Masterclass? Was möchtest du hier lernen?"
            : "Wie hat die Masterclass deine Performance verbessert?"}
        </Title>
        <TextInputElement
          id="text-field-track-text"
          type="textarea"
          labelClasses="p--l"
          onInput={(e) => {
            setText((e.target as HTMLInputElement).value);
          }}
          value={text}
        />
        {props.mode === "WRAP_UP" && (
          <Component
            name="review-stars"
            onMouseLeave={() => {
              setStarAmount(-1);
            }}>
            {[...Array(maxStarAmount)].map((e, index) => {
              return (
                <Component
                  name="star"
                  key={index}
                  onMouseOver={() => {
                    setStarAmount(index);
                  }}
                  onClick={() => setFixed(index)}>
                  <Icon
                    svg={<StarSVG />}
                    className={`star ${index <= starAmount || index <= fixed ? "active" : ""}`}
                    name="star"
                  />
                </Component>
              );
            })}
          </Component>
        )}
        {progress?.contentVideoProgress === 0 && (
          <Paragraph name="text-track-error" size="m" className="txt--c-error">
            {/* Bitte schaue zuerst das Content Video fertig */}
          </Paragraph>
        )}
        <Button
          type="primary"
          disabled={(props.mode === "MEET_YOUR_INSTRUCTOR" ? false : fixed === -1) || text === ""}
          name="submit-text-track"
          onClick={submit}>
          Absenden
        </Button>
        <ErrorMessage
          displayErrorMessage={displayError}
          errorMessage="Bitte bearbeite in jeder Lektion die Aufgabe, bevor du ein Feedback gibst. Danke"
        />
      </Component>
    </>
  );
};

const FileDownload = (props: { fileUrl: string; fileName: string }) => {
  return (
    <a href={props.fileUrl} download rel="noreferrer" target="_blank">
      <Button name="track-download" type={"primary"} svg={<DownloadSVG />}>
        Download
      </Button>
      {/*<Component name="track-download">
        <Icon name="download" svg={<DownloadSVG />} />
  </Component>*/}
      <Paragraph name="track-download-file-name" size="m">
        {props.fileName}
      </Paragraph>
    </a>
  );
};

export default TrackDetails;
