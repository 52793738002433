import { useEffect, useState } from "react";
import { TabProps } from "../Elements/Components";
import {
  AppView,
  ComingSoon,
  Section,
  SectionElement,
  Image,
  Component,
} from "../Elements/Elements";
import Navigation from "../Navigation/Navigation";
import AllMasterclasses from "./AllMasterclasses";
import MediaLibrary from "./MediaLibrary";
import TrackDetails from "./TrackDetails";
import "./academy.scss";
import { useParams, useSearchParams } from "react-router-dom";
import { AcademyDiscussionResponseDto } from "../../requests/interfaces";
import useRequest from "../../hooks/useRequest";
import { DiscussionElement } from "../Discussion/Components";
import AcademyDetails from "./AcademyDetails";
import historyNavigate from "../../hooks/useHistoryNavigate";

const Academy = (props: { trackId?: string; academyId?: string }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabId = (searchParams.get("tab") as unknown as number) * 1;
  const academyId = searchParams.get("academyId");
  const { useHistoryNavigate } = historyNavigate();
  const base = [
    {
      children: <AllMasterclasses />,
      id: 0,
      isActive: true,
      label: "Alle Masterclasses",
    },
    {
      children: <MediaLibrary />,
      id: 1,
      isActive: false,
      label: "Meine Masterclasses",
    },
  ];
  const [tabs, setTabs] = useState<TabProps[]>(base);

  useEffect(() => {
    const possAcademyId = window.location.pathname.split("/")[2];
    if (possAcademyId) {
      useHistoryNavigate(`?academyId=${possAcademyId}`);
    }
  }, []);

  useEffect(() => {
    if ((academyId || props.academyId) && tabs.length !== 3) {
      const obj = {
        id: 2,
        label: "Aktuelle Masterclass",
        children: <AcademyDetails academyId={academyId ?? props.academyId ?? ""} />,
        isActive: tabId === 2,
      };
      setTabs((old) => {
        const oldModified = old.map((tab) => {
          return { ...tab, isActive: false, id: tab.id === obj.id ? tab.id + 1 : tab.id };
        });
        const copy = [...oldModified, obj];
        return copy;
      });
    }

    if (props.academyId && !props.trackId) {
      searchParams.set("tab", "2");
      setSearchParams(searchParams);
    }
  }, [academyId, props.academyId]);

  useEffect(() => {
    if (tabs.length === 3 && (academyId || props.academyId)) {
      useHistoryNavigate(`/academy/${academyId ?? props.academyId}?tab=2`);
    } else if (!window.location.href.includes("discussion")) {
      useHistoryNavigate(window.location.pathname);
    }
  }, [tabs]);

  useEffect(() => {
    const tabId = (searchParams.get("tab") as unknown as number) * 1;
    if (
      tabId !== 2 &&
      tabs.length === 3 &&
      !isNaN(tabId) &&
      !props.trackId &&
      !window.location.href.includes("discussion")
    ) {
      setTabs(base);
      useHistoryNavigate(`/academy?tab=${tabId}`);
    }
  }, [searchParams]);

  return (
    <>
      <AppView name="academy">
        {true && (
          <>
            {window.location.pathname.includes("discussion") && <AcademyDiscussion />}
            {!window.location.pathname.includes("discussion") && !props.trackId && (
              <Navigation tabProps={tabs} />
            )}
            {!window.location.pathname.includes("discussion") &&
              props.academyId &&
              props.trackId && <TrackDetails academyId={props.academyId} trackId={props.trackId} />}
          </>
        )}
        {false && (
          <Section name="academy">
            <Navigation />
            <SectionElement name="academy" type="body">
              <Component name="coming-soon-overlay">
                <ComingSoon isMasterClassVersion={true} />
              </Component>
              <Component name="preview-image">
                <Image
                  name="preview-image"
                  img={
                    "https://storage.googleapis.com/pictures-sdrs-development/resources/2fae9f2a-d6a2-4c69-bde4-f287317481c6.webp"
                  }
                />
              </Component>
            </SectionElement>
            <SectionElement name="academy" type="footer"></SectionElement>
          </Section>
        )}
      </AppView>
    </>
  );
};

const AcademyDiscussion = () => {
  const { id } = useParams();
  const { apiRequest } = useRequest();
  // const [discussions, setDiscussions] = useState<AcademyDiscussionResponseDto[]>();
  const [discussion, setDiscussion] = useState<AcademyDiscussionResponseDto>();

  // const getDiscussions = async () => {
  //   const { data } = await apiRequest<Paginated<AcademyDiscussionResponseDto>>(
  //     `academy/discussions/${id}`,
  //     "GET",
  //   );
  //   setDiscussions(data.docs);
  // };

  const getDiscussion = async () => {
    const { data } = await apiRequest<AcademyDiscussionResponseDto>(
      `academy/discussions/${id}`,
      "GET",
    );
    setDiscussion(data);
  };

  useEffect(() => {
    getDiscussion();
    // getDiscussions();
  }, [id]);

  return (
    <>
      <AppView name="academy-discussion">
        <Navigation />
        <Section name="academy-discussion">
          <SectionElement type="body" name="academy-discussion">
            <Component name="academy-discussion">
              {/* {discussions?.map((discussion) => {
                return (
                  <DiscussionElement
                    key={discussion._id}
                    discussion={{ status: "", hashtags: [], ...discussion }}
                    showComments={true}
                    baseRoute="academy/"
                  />
                );
              })} */}
              {discussion && (
                <DiscussionElement
                  key={discussion._id}
                  discussion={{ status: "", hashtags: [], ...discussion }}
                  showComments={true}
                  baseRoute="academy/"
                />
              )}
            </Component>
          </SectionElement>
        </Section>
      </AppView>
    </>
  );
};

export { Academy };
