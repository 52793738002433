import format from "date-fns/format";
import { Markup } from "interweave";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import LockSVG from "../../Icons/LockSVG";
import UnlockSVG from "../../Icons/UnlockSVG";
import historyNavigate from "../../hooks/useHistoryNavigate";
import useRequest from "../../hooks/useRequest";
import { useUser } from "../../hooks/useUser";
import { Paginated, Resource, ResourceReviewResponse } from "../../requests/interfaces";
import {
  AppView,
  BackButton,
  Button,
  Component,
  ComponentCard,
  Icon,
  Image,
  Paragraph,
  ProfilePicture,
  Section,
  SectionElement,
  TagsFiltered,
  Title,
} from "../Elements/Elements";
import { ContentTypeTag, HashtagsFilter } from "../Elements/FormElements";
import { Loading } from "../Loading/Loading";
import Navigation from "../Navigation/Navigation";
import { ProfileName } from "../util/Components";
import "./learning.scss";

const LearningParent = (props: { id?: string }) => {
  const { user } = useUser();

  return (
    <>
      <AppView name={"learnings"}>
        <Navigation />
        <Section name="learnings">
          <>
            {user?.isAcademyMember || user?.teamMemberSince ? (
              <Learning id={props.id} />
            ) : (
              <>
                <NotAllowed />
              </>
            )}
          </>
        </Section>
      </AppView>
    </>
  );
};

const Learning = (props: { id?: string }) => {
  const [filters, setFilters] = useState<string[]>([]);
  const [resources, setResources] = useState<Resource[]>();
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const { apiRequest } = useRequest();

  const getResources = async (resetPage?: boolean) => {
    let filterString = "";
    if (filters.length !== 0) {
      filterString = filters.join("&");
    }
    const resourceResponse = await apiRequest<Paginated<Resource>>(
      `resources?page=${resetPage ? 0 : page}${filterString ? `&${filterString}` : ""}&limit=5`,
      "GET",
    );

    setResources((oldValues) => {
      const combined = [...(oldValues ?? []), ...resourceResponse.data.docs];
      const final: Resource[] = [];
      if (resetPage) {
        return resourceResponse.data.docs;
      } else {
        combined.map((value) => {
          const index = final.findIndex((item) => item._id === value._id);
          if (index === -1) {
            final.push(value);
          }
        });
        return final;
      }
    });

    setHasNextPage(resourceResponse.data.hasNextPage);
    if (resourceResponse.data.hasNextPage) {
      setPage(resourceResponse.data.page + 1);
    }
  };

  useEffect(() => {
    getResources(true);
  }, [filters]);

  return (
    <>
      {props.id && (
        <>
          <SectionElement name="learnings" type="header"></SectionElement>
          <SectionElement name="learnings" type="body">
            <ResourceElement id={props.id} />
          </SectionElement>
        </>
      )}
      {!props.id && resources && (
        <>
          <SectionElement name="learnings" type="header">
            <HashtagsFilter onFilterChange={setFilters} showLabel={false} />
          </SectionElement>
          {resources.length === 0 && (
            <Title name="not-found" type="h1" size="l">
              Keine Learnings gefunden
            </Title>
          )}
          <InfiniteScroll
            dataLength={resources.length}
            next={getResources}
            hasMore={hasNextPage}
            loader={<h4>Loading...</h4>}
            scrollableTarget="parent-component">
            <SectionElement name="learnings" type="body">
              <Component name="learnings">
                {resources.map((resource) => {
                  return (
                    <ResourceListElement resource={resource} filters={filters} key={resource._id} />
                  );
                })}
              </Component>
            </SectionElement>
          </InfiniteScroll>
        </>
      )}
      {!resources && !props.id && <Loading />}
    </>
  );
};

const NotAllowed = () => {
  const [iconHovered, setIconHovered] = useState<boolean>(false);
  const { user } = useUser();
  const [resources, setResources] = useState<ResourceReviewResponse[]>([]);

  const { apiRequest } = useRequest();

  const getResources = async () => {
    const resourceResponse = await apiRequest<Paginated<ResourceReviewResponse>>(
      `resources?limit=20`,
      "GET",
    );
    setResources(resourceResponse.data.docs);
  };

  useEffect(() => {
    getResources();
  }, []);

  return (
    <Component name="not-authorized">
      <ComponentCard name="masterclass-unlock-learning">
        <Icon
          name="masterclass-unlock-learning"
          svg={iconHovered ? <UnlockSVG /> : <LockSVG />}
          onMouseEnter={() => {
            if (!iconHovered) {
              setIconHovered(true);
            }
          }}
          onMouseLeave={() => {
            if (iconHovered) {
              setIconHovered(false);
            }
          }}
          onClick={() =>
            window.open(
              `https://payments.sdrsofgermany.com/b/14kaFf0BAaD1h2wdRo?prefilled_email=${user?.email}`,
              "_blank",
            )
          }
        />
        <Title name="masterclass-unlock" type="h2" size="xs" className="txt--a-c">
          Hol dir jetzt die Academy und bekomme Zugriff auf das gesamte Sales Wissen <br />
          der Community und alle Masterclasses!
        </Title>
        <Component name="masterclass-unlock-buttons">
          <Button
            type={"primary"}
            onClick={() => {
              window.open(
                `https://payments.sdrsofgermany.com/b/14kaFf0BAaD1h2wdRo?prefilled_email=${user?.email}`,
                "_blank",
              );
            }}>
            Jetzt loslegen
          </Button>
          <Button
            type="primaryGhost"
            onClick={() => {
              window.open("https://calendly.com/lea-sdrsog/hold-dir-die-academy", "_blank");
            }}>
            Sprich mit uns
          </Button>
        </Component>
      </ComponentCard>
      <Component name="not-allowed-learnings">
        {resources.map((resource) => {
          return (
            <ResourceListElement
              resource={resource}
              filters={[]}
              key={resource._id}
              isAllowed={false}
            />
          );
        })}
      </Component>
    </Component>
  );
};

const ResourceListElement = (props: {
  resource: ResourceReviewResponse;
  filters: string[];
  isAllowed?: boolean;
}) => {
  const { useHistoryNavigate } = historyNavigate();
  const { user } = useUser();

  return (
    <ComponentCard
      type={props.isAllowed === false ? "standard" : "shadow"}
      name={props.isAllowed === false ? "not-allowed-learning" : "learning"}
      onClick={() => useHistoryNavigate(`/resource/${props.resource._id}`)}>
      <Component name="learning-thumbnail">
        <Image img={props.resource.picture} name="learning-thumbnail" />
      </Component>
      <Component name="learning-info">
        <Component name="learning-tags">
          <TagsFiltered hashtags={props.resource.hashtags} filters={props.filters} />
        </Component>
        <Title type="h2" size="m" name="learning-title">
          {props.resource.title}
        </Title>
        <Component name="learning-author">
          <ProfilePicture size="xs" img={props.resource.author.picture} />
          <Paragraph size="l">
            {props.resource.author._id === user?._id
              ? "Du"
              : `${props.resource.author.firstName} ${props.resource.author.lastName}`}
          </Paragraph>
        </Component>
      </Component>
      <Component name="learning-type">
        <ContentTypeTag
          contentType={props.resource.contentType}
          isActive={
            props.filters.filter((s) =>
              s.includes(props.resource.contentType.replaceAll(" ", "-").toLowerCase()),
            ).length !== 0
          }
        />
      </Component>
    </ComponentCard>
  );
};

const ResourceElement = (props: { id: string }) => {
  const [resource, setResource] = useState<Resource>();
  const { apiRequest } = useRequest();

  const getResourceById = async () => {
    const { data } = await apiRequest<Resource>(`resources/${props.id}`, "GET");
    setResource(data);
  };

  useEffect(() => {
    getResourceById();
  }, []);

  return (
    <Component name="learning-details">
      {resource && (
        <>
          <BackButton />
          <Component name="resource-title">
            <Title name="learning-details-title" size="l" type="h2">
              {resource.title}
            </Title>
            <Component name="learning-details-info">
              <Paragraph type="paragraph" name="learning-details-date">{`Am ${format(
                new Date(resource.createdAt),
                "LLLL d, y",
              )} veröffentlicht`}</Paragraph>
            </Component>
          </Component>
          <Component name="resource">
            <Component name="resource-infos">
              <Component name="learning-details-thumbnail">
                <Image img={resource.picture} name="learning-details-thumbnail" />
              </Component>
              <Component name="resource-author" className="fd-row gap-0-5">
                <ProfilePicture img={resource.author.picture} />
                <ProfileName
                  userId={resource.author._id}
                  firstName={resource.author.firstName}
                  lastName={resource.author.lastName}
                />
              </Component>
              <TagsFiltered hashtags={resource.hashtags} />
            </Component>
            <hr className="seperator" />
            <Component name="resource-content">
              <Markup content={resource.description} />
              {resource.contentType === "pdf" && <PubluuEmbed link={resource.contentLink} />}
              {resource.contentType === "youtube" && <YouTubeEmbed link={resource.contentLink} />}
              {resource.contentType === "spotify" && <SpotifyEmbed link={resource.contentLink} />}
            </Component>
          </Component>
        </>
      )}
    </Component>
  );
};

const PubluuEmbed = (props: { link: string }) => {
  return (
    <>
      <iframe
        allowFullScreen
        allow="autoplay"
        style={{
          width: "100%",
          height: "100%",
          border: "0px",
          borderRadius: "1em",
          background: "transparent",
        }}
        src={props.link}
      />
    </>
  );
};

const SpotifyEmbed = (props: { link: string }) => {
  const getEmbedLink = () => {
    if (props.link.includes("track")) {
      return `https://open.spotify.com/embed/track${
        props.link.split("/track")[1].split("?")[0]
      }?utm_source=generator`;
    } else if (props.link.includes("episode")) {
      return `https://open.spotify.com/embed/episode${
        props.link.split("/episode")[1].split("?")[0]
      }?utm_source=generator`;
    } else if (props.link.includes("playlist")) {
      return `https://open.spotify.com/embed/playlist${
        props.link.split("/playlist")[1].split("?")[0]
      }?utm_source=generator`;
    }
  };

  return (
    <iframe
      style={{ borderRadius: "1em" }}
      src={getEmbedLink()}
      width="100%"
      height={352}
      frameBorder={0}
      allowFullScreen
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      loading="lazy"
    />
  );
};

export const YouTubeEmbed = (props: { link: string }) => {
  const getEmbedLink = () => {
    if (props.link.includes("list")) {
      let link = props.link.split("?list=")[1]?.split("&")[0];
      if (!link) {
        link = props.link.split("&list=")[1]?.split("&")[0];
      }
      return `https://www.youtube.com/embed/videoseries?list=${link}`;
    } else if (props.link.includes("watch")) {
      return `https://www.youtube.com/embed/${props.link.split("?v=")[1].split("&")[0]}`;
    }
  };

  return (
    <iframe
      style={{ borderRadius: "1em" }}
      width={560}
      height={315}
      src={getEmbedLink()}
      title="YouTube video player"
      frameBorder={0}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    />
  );
};

export { LearningParent };
