import { useSearchParams } from "react-router-dom";
import {
  Paginated,
  SkillResponseDto,
  SkillRequest,
  UsersResponseDto,
} from "../../requests/interfaces";
import {
  Button,
  Component,
  Icon,
  Paragraph,
  Section,
  SectionElement,
  Table,
  TableColumn,
  TablePagination,
  Title,
} from "../Elements/Elements";
import { useEffect, useState } from "react";
import historyNavigate from "../../hooks/useHistoryNavigate";
import useRequest from "../../hooks/useRequest";
import { TextInputElement } from "../Elements/FormElements";
import { UserSearchField } from "../Elements/Components";
import TrashSVG from "../../Icons/TrashSVG";
import PlusSVG from "../../Icons/PlusSVG";

const AdminSkills = () => {
  const [mode, setMode] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const modeId = searchParams.get("mode");
  const [modes, setModes] = useState<any[]>([]);
  // TODO form change content link after changing the type

  const setupModes = () => {
    const allModes = [
      {
        modeId: "DEFAULT",
        component: (
          <AdminSkillsAssigner searchParams={searchParams} setSearchParams={setSearchParams} />
        ),
        active: modeId === null || modeId !== "create",
      },
      {
        modeId: "CREATE",
        component: <AdminSkillsItem />,
        active: modeId === "create",
      },
    ];
    setModes(allModes);
  };

  useEffect(() => {
    setMode(modes.find((tab: any) => tab.active === true));
  }, [modes]);

  useEffect(() => {
    setupModes();
  }, [modeId]);

  useEffect(() => {
    setupModes();
  }, []);

  return <>{mode && mode.component}</>;
};

const AdminSkillsAssigner = (props: { searchParams: any; setSearchParams: any }) => {
  const [skills, setSkills] = useState<SkillResponseDto[]>();
  const [userSkills, setUserSkills] = useState<SkillResponseDto[]>();
  const { apiRequest } = useRequest();
  const [searchedUser, setSearchedUser] = useState<UsersResponseDto>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [hasNext, setHasNext] = useState<boolean>();

  // TODO: user dropdown, skills dropdown

  const getSkills = async () => {
    const skillsResponse = await apiRequest<Paginated<SkillResponseDto>>(
      `skills?limit=20&page=${currentPage}`,
      "GET",
    );
    setSkills(skillsResponse.data.docs);
    setHasNext(skillsResponse.data.hasNextPage);
  };

  const getUsersSkills = async () => {
    if (searchedUser) {
      const skillsResponse = await apiRequest<SkillResponseDto[]>(
        `users/${searchedUser?._id}/skills`,
        "GET",
      );
      setUserSkills(skillsResponse.data);
    }
  };

  useEffect(() => {
    getSkills();
    getUsersSkills();
  }, [searchedUser]);

  useEffect(() => {
    getSkills();
  }, [currentPage]);

  useEffect(() => {
    getSkills();
    getUsersSkills();
  }, [userSkills]);

  const create = () => {
    props.searchParams.set("mode", "create");
    props.setSearchParams(props.searchParams);
  };

  const deleteSkill = async (id: string) => {
    await apiRequest(`skills/${id}`, "DELETE");
    getSkills();
  };

  // user search
  // show two tables: one with all skills and one with user skills

  const addSkillToUser = async (id: string) => {
    await apiRequest(`users/skills/${searchedUser?._id}/apply`, "PATCH", {
      body: { skills: [id] },
      showToast: true,
    });
  };

  const removeSkillFromUser = async (id: string) => {
    await apiRequest(`users/skills/${searchedUser?._id}/remove`, "PATCH", {
      body: { skills: [id] },
      showToast: true,
    });
  };

  return (
    <Section name="admin-skills">
      <SectionElement type="header" name="admin-skill">
        <Title name="admin-skills-title" type="h1" size="m">
          Skills
        </Title>
        <Button type="primary" name="create-new-skill" onClick={() => create()}>
          + Neu
        </Button>
      </SectionElement>
      <SectionElement type="body" name="admin-skill">
        <Component name="admin-skill">
          <Component name="skill-assigner">
            <Component name="user-search">
              <UserSearchField
                setUser={(user) => {
                  setSearchedUser(user[0]);
                }}
                selected={searchedUser ? [searchedUser] : []}
                title="User"
              />
            </Component>
            {searchedUser && (
              <>
                <SkillList skills={skills} icon={<PlusSVG />} onIconClick={addSkillToUser} />
                <SkillList
                  skills={userSkills}
                  icon={<TrashSVG />}
                  onIconClick={removeSkillFromUser}
                />
              </>
            )}
          </Component>
          <Component name="table-background">
            <Table name="admin-resources">
              <thead>
                <tr className="h--xxs">
                  <th>
                    <Paragraph name="admin-resource-thumbnail">Skill</Paragraph>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {skills?.map((skill) => {
                  return (
                    <tr key={skill._id}>
                      <TableColumn name="admin-col">
                        <Paragraph type="paragraph" name="admin-resource-title">
                          {skill.skill}
                        </Paragraph>
                      </TableColumn>
                      <TableColumn>
                        <Icon
                          name="resource-delete"
                          svg={<TrashSVG />}
                          className="danger"
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteSkill(skill._id);
                          }}
                        />
                      </TableColumn>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {hasNext !== undefined && (
              <TablePagination
                currentPage={currentPage}
                hasNext={hasNext}
                setCurrentPage={setCurrentPage}
              />
            )}
          </Component>
        </Component>
      </SectionElement>
    </Section>
  );
};

const SkillList = (props: {
  skills: SkillResponseDto[] | undefined;
  icon: any;
  onIconClick: (id: string) => void;
}) => {
  return (
    <Component name="skill-list">
      {props.skills?.map((skill) => {
        return (
          <Component name="skill-item" key={skill._id}>
            <Paragraph name="skill-item">{skill.skill}</Paragraph>
            <Icon svg={props.icon} name="add-skill" onClick={() => props.onIconClick(skill._id)} />
          </Component>
        );
      })}
    </Component>
  );
};

const AdminSkillsItem = () => {
  const [skill, setSkill] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(false);
  const { apiRequest } = useRequest();
  const { useHistoryNavigate } = historyNavigate();

  const createSkill = async () => {
    const request: SkillRequest = {
      skill: skill,
    };
    const { status } = await apiRequest("skills", "POST", { body: request, showToast: true });
    if (status === 201) {
      useHistoryNavigate("/admin-dashboard?tab=8");
    }
  };

  useEffect(() => {
    if (skill === "") {
      return;
    }
    setIsValid(true);
  }, [skill]);

  return (
    <>
      <Section name="admin-skill-item-builder">
        <SectionElement name="admin-skill-item-builder" type="body">
          <Component>
            <Component name="form-inputs">
              <Component name="form-row">
                <Component name="form-input">
                  <TextInputElement
                    id="skill"
                    label="Skill"
                    type="text"
                    labelClasses="p--l"
                    onInput={(e) => setSkill((e.target as HTMLInputElement).value)}
                    value={skill}
                  />
                </Component>
              </Component>
              <Component name="form-row">
                <Component name="form-input">
                  <Button type="primary" onClick={createSkill} disabled={!isValid}>
                    Erstellen
                  </Button>
                </Component>
              </Component>
            </Component>
          </Component>
        </SectionElement>
      </Section>
    </>
  );
};

export default AdminSkills;
