import { AppView, Component, Main, Page, Section, Title } from "../Elements/Elements";
import Navigation from "../Navigation/Navigation";
import Sidebar from "../Navigation/Sidebar";

const ErrorComponent = () => {
  return (
    <Page name="error">
      <Sidebar />
      <Main>
        <AppView name="error">
          <Navigation />
          <Section name="error">
            <Component name="error">
              <Title type="h1" size="xl" name="page-error">
                Oops something went wrong. <br />
                Please try again later or contact support.
              </Title>
            </Component>
          </Section>
        </AppView>
      </Main>
    </Page>
  );
};

export default ErrorComponent;
