export interface AcademyWelcomeTab {
  _id: string;
  thumbnailUrl: string,
  description: string;
  videoLink: string;
}

const mock1: AcademyWelcomeTab = {
  _id: "asdfasdf1",
  description: "Deep Discovery für deinen Sales Cycle \nVerfügbar ab September",
  thumbnailUrl: "https://storage.googleapis.com/pictures-sdrs-development/resources/b0540efc-f4f3-4d53-a308-6d1af7f9cdb8.webp",
  videoLink: "https://www.youtube.com/watch?v=y6120QOlsfU"
}

const mock2: AcademyWelcomeTab = {
  _id: "asdfasdf2",
  description: "Mit Acting Skills Deals closen \nVerfügbar ab September",
  thumbnailUrl: "https://storage.googleapis.com/pictures-sdrs-development/resources/31b860ec-d891-4381-bfbf-12efb50ecc03.webp",
  videoLink: "https://www.youtube.com/watch?v=y6120QOlsfU"
}

const mock3: AcademyWelcomeTab = {
  _id: "asdfasdf3",
  description: "Deine Karriere richtig entwickeln \nVerfügbar ab September",
  thumbnailUrl: "https://storage.googleapis.com/pictures-sdrs-development/resources/296a40c3-761c-4420-a95e-aba4b6296437.webp",
  videoLink: "https://www.youtube.com/watch?v=y6120QOlsfU"
}

const mock4: AcademyWelcomeTab = {
  _id: "asdfasdf4",
  description: "Deine Mental Health verstehen \nVerfügbar ab September",
  thumbnailUrl: "https://storage.googleapis.com/pictures-sdrs-development/resources/3f3cba40-13ff-4489-b6e8-88e2a1374403.webp",
  videoLink: "https://www.youtube.com/watch?v=y6120QOlsfU"
}

const mock5: AcademyWelcomeTab = {
  _id: "asdfasdf5",
  description: "Dein 90/10 Cold Call Framework \nVerfügbar ab September",
  thumbnailUrl: "https://storage.googleapis.com/pictures-sdrs-development/resources/906da75d-a125-4e4e-b460-63c77474164d.webp",
  videoLink: "https://www.youtube.com/watch?v=y6120QOlsfU"
}

const mock6: AcademyWelcomeTab = {
  _id: "asdfasdf6",
  description: "Nachhaltige Führungskraft für dein Team \nVerfügbar ab September",
  thumbnailUrl: "https://storage.googleapis.com/pictures-sdrs-production/resources/906fad2d-6e37-4a53-a989-585ca9cd1d27.webp",
  videoLink: "https://www.youtube.com/watch?v=y6120QOlsfU"
}

const mock7: AcademyWelcomeTab = {
  _id: "asdfasdf7",
  description: "Mit Empfehlungen deine Pipeline füllen \nVerfügbar ab September",
  thumbnailUrl: "https://storage.googleapis.com/pictures-sdrs-development/events/99d29bbb-c6a9-49ec-9133-8e90c9fa5d28.webp",
  videoLink: "https://www.youtube.com/watch?v=y6120QOlsfU"
}

const mock8: AcademyWelcomeTab = {
  _id: "asdfasdf8",
  description: "Für C-Level Relevanz im Gespräch finden \nVerfügbar ab September",
  thumbnailUrl: "https://storage.googleapis.com/pictures-sdrs-production/resources/6cd77962-0a6c-4343-b536-99e6fce25667.webp",
  videoLink: "https://www.youtube.com/watch?v=y6120QOlsfU"
}

const mock9: AcademyWelcomeTab = {
  _id: "asdfasdf9",
  description: "Das Interview smashen \nVerfügbar ab September",
  thumbnailUrl: "https://storage.googleapis.com/pictures-sdrs-development/events/a563d8f0-3a41-40cc-9368-ded289658bd8.webp",
  videoLink: "https://www.youtube.com/watch?v=y6120QOlsfU"
}

const mock10: AcademyWelcomeTab = {
  _id: "asdfasd10",
  description: "Winning Mindset eines Sellers \nVerfügbar ab September",
  thumbnailUrl: "https://storage.googleapis.com/pictures-sdrs-development/events/5c4892e5-a6d4-436f-81f9-06c8d69ed7f8.webp",
  videoLink: "https://www.youtube.com/watch?v=y6120QOlsfU"
}



export const AcademyWelcomeTabMocks = [mock1, mock2, mock3, mock4, mock5, mock6, mock7, mock8, mock9, mock10]