import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import {
  AppView,
  Component,
  Icon,
  IconImageUploader,
  Paragraph,
  Popup,
  ProfilePicture,
  Section,
  SectionElement,
  Image,
  Title,
  BackButton,
  UserSearchPopup,
} from "../Elements/Elements";
import Navigation from "../Navigation/Navigation";
import {
  UsersResponseDto,
  ChatResponseDto,
  MessagesResponseDto,
  Paginated,
} from "../../requests/interfaces";
import PlusSVG from "../../Icons/PlusSVG";
import DoubleCheckSVG from "../../Icons/DoubleCheckSVG";
import "./messages.scss";
import { format } from "date-fns";
import { TextInputElement } from "../Elements/FormElements";
import SendSVG from "../../Icons/SendSVG";
import { useSearchParams, useBeforeUnload } from "react-router-dom";
import Context from "../../context/Context";
import CloseSVG from "../../Icons/CloseSVG";
import { useProfile } from "../../hooks/useProfile";
import useRequest from "../../hooks/useRequest";
import InfiniteScroll from "react-infinite-scroll-component";
import { useWebsocket } from "../../requests/socket";
import { Loading } from "../Loading/Loading";
import useUtil from "../../hooks/useUtil";
import WatchSVG from "../../Icons/WatchSVG";

interface PaginationOptions {
  fetchMoreData: () => void;
  hasMore: boolean;
}

interface Message {
  message: MessagesResponseDto;
  status: "SENDING" | "SENT";
}

interface ChatProps extends PaginationOptions {
  messages?: Message[];
  setReload: Dispatch<SetStateAction<boolean>>;
  participant?: UsersResponseDto;
  setParticipant: Dispatch<SetStateAction<UsersResponseDto | undefined>>;
  onMessageRead?: (id: string) => void;
}

interface ChatsProps extends PaginationOptions {
  chats: ChatResponseDto[];
  participant?: UsersResponseDto;
  setParticipant: Dispatch<SetStateAction<UsersResponseDto | undefined>>;
}

const Messages = () => {
  const [chats, setChats] = useState<ChatResponseDto[]>([]);
  const [messages, setMessages] = useState<Message[]>();
  const [participant, setParticipant] = useState<UsersResponseDto>();
  const [reload, setReload] = useState<boolean>(false);
  const { apiRequest } = useRequest();
  const [hasMoreMessages, setHasMoreMessages] = useState<boolean>(false);
  const [hasMoreChats, setHasMoreChats] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [chatPage, setChatPage] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const { socket } = useWebsocket();
  const { isMobile } = useContext(Context);

  useEffect(() => {
    if (!socket.connected) {
      socket.connect();
    }
  }, [id]);

  socket.on("connect", () => {
    socket.on("newMessage", (msg: any) => {
      if (id === msg.senderId) {
        setMessages((value) => {
          return [{ message: msg as MessagesResponseDto, status: "SENT" }, ...(value ?? [])];
        });
      }
      // get unread messages
      getChats();
    });

    socket.on("readMessages", (msg: any) => {
      if (id === msg.senderId) {
        setMessagesOnRead();
      }
      getChats();
    });
  });

  useBeforeUnload(() => {
    socket.disconnect();
  });

  const messageRead = (id: string) => {
    socket.emit("readMessages", { id: id });
  };

  const setMessagesOnRead = () => {
    if (messages) {
      const copiedMessages = [...messages];
      for (let index = 0; index < copiedMessages.length; index++) {
        const message = copiedMessages[index];
        message.message.status = "read";
      }
      setMessages(copiedMessages);
    }
  };

  const getMessages = async (reset?: boolean) => {
    const { data } = await apiRequest<Paginated<MessagesResponseDto>>(
      `messages/${id}?page=${reset ? 0 : page}`,
      "GET",
    );

    const docs = data.docs.map((message) => {
      return { message: message, status: "SENT" } as Message;
    });
    setMessages((value) => {
      return reset ? docs : value?.concat(docs);
    });
    setHasMoreMessages(data.hasNextPage);
    setPage(data.page + 1);
  };

  const getChats = async () => {
    const { data } = await apiRequest<Paginated<ChatResponseDto>>(
      `messages/chats?page=${chatPage}`,
      "GET",
    );
    setChats(data.docs);
    setHasMoreChats(data.hasNextPage);
    setChatPage(data.page);
  };

  const getParticipant = async () => {
    const { data } = await apiRequest<UsersResponseDto>(`users/${id}`, "GET");
    setParticipant(data);
  };

  useEffect(() => {
    if (id) {
      getMessages(true);
      getChats();
      if (!participant) {
        getParticipant();
      }
    }
  }, [id, reload]);

  useEffect(() => {
    getChats();
  }, []);

  return (
    <AppView name="messages">
      <Navigation />
      <Section name="messages">
        <SectionElement type="body" name="messages">
          {isMobile && !participant && (
            <AllChats
              chats={chats}
              setParticipant={setParticipant}
              participant={participant}
              fetchMoreData={getChats}
              hasMore={hasMoreChats}
            />
          )}
          {isMobile && participant && (
            <ChatItem
              messages={participant ? messages : []}
              participant={participant}
              setReload={setReload}
              fetchMoreData={getMessages}
              hasMore={hasMoreMessages}
              onMessageRead={messageRead}
              setParticipant={setParticipant}
            />
          )}
          {!isMobile && (
            <>
              <AllChats
                chats={chats}
                setParticipant={setParticipant}
                participant={participant}
                fetchMoreData={getChats}
                hasMore={hasMoreChats}
              />
              <ChatItem
                messages={participant ? messages : []}
                participant={participant}
                setReload={setReload}
                fetchMoreData={getMessages}
                hasMore={hasMoreMessages}
                onMessageRead={messageRead}
                setParticipant={setParticipant}
              />
            </>
          )}
        </SectionElement>
      </Section>
    </AppView>
  );
};

const AllChats = (props: ChatsProps) => {
  const [popupIsOpen, setPopupIsOpen] = useState<boolean>();
  const { setPopupContent } = useContext(Context);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (setPopupContent) {
      if (popupIsOpen) {
        setPopupContent(<NewChat setPopupOpen={() => setPopupIsOpen(false)} />);
      } else {
        setPopupContent(undefined);
      }
    }
  }, [popupIsOpen]);

  return (
    <Component name="all-chats">
      <Component name="chats-header">
        <Title name="messages" size="m" type="h2">
          Privat Nachrichten
        </Title>
        <Component name="add-chat" onClick={() => setPopupIsOpen(true)}>
          <Icon svg={<PlusSVG />} name="add-chat" />
        </Component>
      </Component>
      <Component name="chats-body" id="chats-body">
        <InfiniteScroll
          dataLength={props.chats?.length}
          next={props.fetchMoreData}
          inverse={true}
          hasMore={props.hasMore}
          loader={<h4>Loading...</h4>}
          scrollableTarget="chats-body">
          <Component name="chats">
            {props.chats.map((chat, i) => {
              return (
                <>
                  <ChatMenuItem
                    key={chat._id + "" + i}
                    setChat={() => {
                      searchParams.set("id", chat.user._id);
                      setSearchParams(searchParams);
                      props.setParticipant(chat.user);
                    }}
                    chat={chat}
                    isActive={props.participant ? chat.user._id === props.participant._id : false}
                  />
                </>
              );
            })}
          </Component>
        </InfiniteScroll>
      </Component>
    </Component>
  );
};

const ChatMenuItem = (props: {
  setChat: (chat?: any) => void;
  chat?: ChatResponseDto;
  chatter?: UsersResponseDto;
  isActive?: boolean;
}) => {
  const [unreadMessages, setUnreadMessages] = useState<number>(
    props.chat?.countUnreadMessages ?? 0,
  );

  useEffect(() => {
    setUnreadMessages(props.chat?.countUnreadMessages ?? 0);
  }, [props.chat?.countUnreadMessages]);

  return (
    <Component
      onClick={() => {
        setUnreadMessages(0);
        props.setChat();
      }}
      name="chat-item"
      className={`${props.isActive ? "active" : ""}`}>
      <ProfilePicture img={props.chat?.user.picture ?? props.chatter?.picture} />
      <Component name="chat-user-info">
        <Title name="chat-name" size="xs" type="h3">{`${
          props.chat?.user.firstName ?? props.chatter?.firstName
        } ${props.chat?.user.lastName ?? props.chatter?.lastName}`}</Title>
        {props.chat?.message && (
          <Component name="last-message-body">
            <Component name="last-message">
              {props.chat.isSender && (
                <Icon
                  svg={<DoubleCheckSVG />}
                  name="last-message"
                  className={`${props.chat.status === "read" ? "read" : ""}`}
                />
              )}
              <Paragraph name="last-message">{props.chat.message}</Paragraph>
            </Component>
            {unreadMessages !== 0 && (
              <Component name="unread-messages">
                <Paragraph name="unread-messages">{unreadMessages}</Paragraph>
              </Component>
            )}
          </Component>
        )}
      </Component>
    </Component>
  );
};

const ChatItem = (props: ChatProps) => {
  const [chatText, setChatText] = useState<string>("");
  const { onProfileClick } = useProfile();
  const { apiRequest } = useRequest();
  const [pictures, setPictures] = useState<{ id: string; image: File }[]>();
  const [uploadedPictures, setUploadedPictures] = useState<{ id: string; image: string }[]>();
  const { getRandomId, removeImageFromList } = useUtil();
  const [reload, setReload] = useState<boolean>();
  const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false);
  const [openedImage, setOpenedImage] = useState<string>();
  const { setPopupContent, isMobile } = useContext(Context);
  const [searchParams, setSearchParams] = useSearchParams();

  const sendMessage = async () => {
    props.messages?.unshift({
      message: {
        _id: "sent",
        message: chatText,
        status: "sending",
        createdAt: new Date(),
        isSender: true,
        pictures: uploadedPictures?.map((image) => image.image) ?? [],
      },
      status: "SENDING",
    });
    setReload(true);

    const { status } = await apiRequest(`messages/${props.participant?._id}`, "POST", {
      body: {
        message: chatText,
        pictures: uploadedPictures?.map((image) => image.image) ?? [],
      },
    });

    if (status === 201) {
      props.setReload((value) => !value);
      setChatText("");
      setPictures([]);
      setUploadedPictures([]);
    }
  };

  useEffect(() => {
    if (props.participant && props.onMessageRead) {
      props.onMessageRead(props.participant._id);
    }
  }, [reload, props.participant]);

  const onImageChange = (e: any) => {
    setPictures((old) => [...(old ?? []), { id: getRandomId(), image: e.target.files[0] }]);
  };

  const onImageError = () => {
    console.log("hell");
  };

  const onImageUploaded = (urls: { url: string }[], filename?: string) => {
    setUploadedPictures((old) => {
      const final = [...(old ?? [])];
      urls.map((url) => {
        final.push({ id: getRandomId(), image: url.url });
      });
      return final;
    });
    setPictures((oldValues) => {
      const final = [...(oldValues ?? [])];
      return final.filter(function (obj) {
        return obj.image.name !== filename;
      });
    });
  };

  const onImageClick = (link: string) => {
    setOpenedImage(link);
  };

  useEffect(() => {
    if (setPopupContent) {
      if (popupIsOpen && openedImage) {
        setPopupContent(<ImagePopup image={openedImage} />);
      } else {
        setPopupContent(undefined);
        setPopupIsOpen(false);
      }
    }
  }, [popupIsOpen]);

  useEffect(() => {
    setPopupIsOpen(true);
  }, [openedImage]);

  const ImagePopup = (props: { image: string }) => {
    return (
      <Popup
        setPopupIsOpen={(value) => {
          if (value === false) {
            setOpenedImage(undefined);
          }
          setPopupIsOpen(value);
        }}
        size="SMALL">
        <Component name="popup-image">
          <Image img={props.image} name="popup-image" />
        </Component>
      </Popup>
    );
  };

  return (
    <Component name="selected-chat">
      <Component name="chat-header">
        {isMobile && props.participant && (
          <BackButton
            onClick={() => {
              searchParams.delete("id");
              setSearchParams(searchParams);
              props.setParticipant(undefined);
            }}
          />
        )}
        {props?.participant && (
          <Component
            name="chat-participant"
            onClick={() => onProfileClick(props.participant?._id ?? "")}>
            <ProfilePicture img={props.participant?.picture} />
            <Title
              name="chat-name"
              size="xs"
              type="h3">{`${props.participant?.firstName} ${props.participant?.lastName}`}</Title>
          </Component>
        )}
      </Component>
      <Component name="chat-body">
        <Component name="messages" id="messages">
          {props.messages && (
            <InfiniteScroll
              dataLength={props.messages.length}
              next={props.fetchMoreData}
              style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
              inverse={true}
              hasMore={props.hasMore}
              loader={<h4>Loading...</h4>}
              scrollableTarget="messages">
              {props?.participant &&
                props.messages.map((messageObj, index) => {
                  return (
                    <Component
                      key={messageObj.message.message + index}
                      name="message-old"
                      className={`${messageObj.message.isSender ? "own" : ""}`}>
                      <Component
                        name="message-content"
                        className={`${messageObj.message.isSender ? "own" : ""}`}>
                        {messageObj.message.pictures && (
                          <Component name="message-images">
                            {messageObj.message.pictures?.map((picture) => {
                              return (
                                <Component
                                  key={picture}
                                  name="message-image"
                                  onClick={() => onImageClick(picture)}>
                                  <Image img={picture} name="message-image" />
                                </Component>
                              );
                            })}
                          </Component>
                        )}
                        {messageObj.message.message && (
                          <Paragraph name="message" type="paragraph">
                            {messageObj.message.message}
                          </Paragraph>
                        )}
                      </Component>
                      <Component name="message-information">
                        {messageObj.message.isSender && (
                          <>
                            {messageObj.status === "SENT" && (
                              <Icon
                                svg={<DoubleCheckSVG />}
                                name="last-message"
                                className={`${messageObj.message.status === "read" ? "read" : ""}`}
                              />
                            )}
                            {messageObj.status === "SENDING" && (
                              <Icon svg={<WatchSVG />} name="last-message" />
                            )}
                          </>
                        )}
                        <Paragraph type="paragraph" name="date" size="s">{`${format(
                          new Date(messageObj.message.createdAt),
                          "HH:mm",
                        )} Uhr`}</Paragraph>
                      </Component>
                    </Component>
                  );
                })}
            </InfiniteScroll>
          )}
          {!props.messages && <Loading />}
          {!props?.participant && (
            <>
              <Title type="h3" size="l" name="no-chat-open">
                Keinen Chat geöffnet
              </Title>
            </>
          )}
        </Component>
        <Component name="pictures">
          {pictures && (
            <>
              {pictures.map((picture) => {
                return (
                  <Component name="message-image-upload" key={picture.id}>
                    <Image img={picture.image} name="message-image-file" />
                  </Component>
                );
              })}
            </>
          )}
          {uploadedPictures && (
            <>
              {uploadedPictures.map((picture) => {
                return (
                  <Component name="message-image-upload" key={picture.id}>
                    <Icon
                      name="remove-image"
                      svg={<CloseSVG />}
                      onClick={() => removeImageFromList(picture.id, setUploadedPictures)}
                    />
                    <Image img={picture.image} name="message-image" />
                  </Component>
                );
              })}
            </>
          )}
        </Component>
      </Component>
      {props?.participant && (
        <Component name="chat-footer">
          <Component name="chat-text-input">
            <TextInputElement
              id="chat-input"
              type="text"
              placeHolder="Schreibe eine Nachricht"
              onKeyUp={(e) => {
                if (
                  e.key === "Enter" &&
                  (chatText.trim() !== "" || !uploadedPictures || uploadedPictures?.length !== 0)
                ) {
                  sendMessage();
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                setChatText((e.target as HTMLInputElement).value);
              }}
              value={chatText}
              svg={
                <Icon
                  name="send-icon"
                  className={
                    chatText.trim() !== "" || !uploadedPictures || uploadedPictures?.length !== 0
                      ? "active"
                      : ""
                  }
                  svg={<SendSVG />}
                  onClick={sendMessage}
                />
              }
              iconAfter={true}
            />
          </Component>
          <IconImageUploader
            img={null}
            onManyImagesUploaded={onImageUploaded}
            onImageError={onImageError}
            onChange={onImageChange}
            route="messages/messagePictures"
            method="POST"
          />
        </Component>
      )}
    </Component>
  );
};

const NewChat = (props: { setPopupOpen: (value: boolean) => void }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <UserSearchPopup
      setPopupOpen={props.setPopupOpen}
      setUser={(user) => {
        searchParams.set("id", user._id);
        setSearchParams(searchParams);
        props.setPopupOpen(false);
      }}
      popupTitle="Neue Nachricht"
    />
  );
};

export { Messages };
