import { useState } from "react";
import MailWaitingSVG from "../../../Icons/MailWaitingSVG";
import useRequest from "../../../hooks/useRequest";
import { Button, Component, Title, Icon, Paragraph } from "../../Elements/Elements";
import { SupportForm } from "../../Elements/Components";
import { useAuth0 } from "@auth0/auth0-react";

const VerifyStep = () => {
  const { apiRequest } = useRequest();
  const [supportFormIsOpen, setSupportFormIsOpen] = useState<boolean>(false);
  const { logout } = useAuth0();

  const contactSupport = () => {
    setSupportFormIsOpen(true);
  };

  const resendMail = async () => {
    await apiRequest("user/resendEmailVerification", "POST", { showToast: true });
  };

  return (
    <>
      <Component name="verify-mail">
        <Icon name="verify-mail" svg={<MailWaitingSVG />}></Icon>
        <Component name="form-info">
          <Title name="form-info" className="txt--c-on-surface" type="h1" size="xs">
            Überprüfe deinen Posteingang
          </Title>
          <Paragraph name="form-info" className="txt--c-on-surface-l txt--l">
            Um deine Registrierung abzuschliessen musst du eine Mail-Adresse verifizieren.
          </Paragraph>
        </Component>
        <Component name="btn-group">
          <Button onClick={resendMail} name="resend-mail" type="primaryGhost">
            E-Mail erneut schicken
          </Button>
          <Button type="danger" name="logout" onClick={() => logout()}>
            Zurück
          </Button>
          <Button onClick={contactSupport} name="support" type="lightText">
            Support kontaktieren
          </Button>
        </Component>
        <SupportForm popupIsOpen={supportFormIsOpen} setPopupIsOpen={setSupportFormIsOpen} />
      </Component>
    </>
  );
};

export default VerifyStep;
