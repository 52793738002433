import { TabProps } from "../Elements/Components";
import { AppView } from "../Elements/Elements";
import Navigation from "../Navigation/Navigation";
import JobPosts from "./JobPosts";

const JobDashboard = () => {
  const tabs: TabProps[] = [
    {
      children: <JobPosts />,
      id: 0,
      isActive: true,
      label: "Alle Jobs",
    },
  ];

  return (
    <>
      <AppView name="job-dashboard">
        <Navigation tabProps={tabs} />
      </AppView>
    </>
  );
};

export { JobDashboard };
