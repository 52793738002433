import { useEffect, useState } from "react";
import useRequest from "../../hooks/useRequest";
import { JobPostSimple, Paginated } from "../../requests/interfaces";
import {
  Button,
  Component,
  Image,
  Paragraph,
  Section,
  SectionElement,
  Table,
  TableColumn,
  TablePagination,
  TableSearch,
  Title,
} from "../Elements/Elements";
import { Slider } from "../Elements/FormElements";

const AdminJobsFeatured = () => {
  const [jobs, setJobs] = useState<JobPostSimple[]>([]);
  const [hasNext, setHasNext] = useState<boolean>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { apiRequest } = useRequest();

  const getJobs = async () => {
    const { data } = await apiRequest<Paginated<JobPostSimple>>(
      `jobs?limit=10&page=${currentPage}`,
      "GET",
    );
    setJobs(data.docs);
    setHasNext(data.hasNextPage);
  };

  useEffect(() => {
    getJobs();
  }, []);

  useEffect(() => {
    getJobs();
  }, [currentPage]);

  return (
    <>
      <Section name="admin-jobs">
        <SectionElement type="header" name="admin-jobs">
          <Title name="admin-jobs-title" type="h1" size="m">
            Jobs
          </Title>
        </SectionElement>
        <SectionElement type="body" name="admin-jobs">
          <Component name="table-background">
            <TableSearch
              route="jobs"
              setItem={setJobs}
              resetSearched={() => {
                if (currentPage !== 0) {
                  setCurrentPage(0);
                } else {
                  getJobs();
                }
              }}
            />
            <Table name="admin-jobs">
              <thead>
                <tr className="h--xxs">
                  <th></th>
                  <th>
                    <Paragraph name="admin-jobs-company" type="paragraph">
                      Company
                    </Paragraph>
                  </th>
                  <th>
                    <Paragraph name="admin-jobs-position" type="paragraph">
                      Position
                    </Paragraph>
                  </th>
                  <th>
                    <Paragraph name="admin-jobs-location" type="paragraph">
                      Location
                    </Paragraph>
                  </th>
                  <th>
                    <Paragraph name="admin-jobs-is-featured" type="paragraph">
                      Is Featured
                    </Paragraph>
                  </th>
                </tr>
              </thead>
              <tbody>
                {jobs.map((job, i) => {
                  return <JobListItem job={job} key={i} />;
                })}
              </tbody>
            </Table>
            {hasNext !== undefined && (
              <TablePagination
                currentPage={currentPage}
                hasNext={hasNext}
                setCurrentPage={setCurrentPage}
              />
            )}
          </Component>
        </SectionElement>
      </Section>
    </>
  );
};

const JobListItem = (props: { job: JobPostSimple }) => {
  const { apiRequest } = useRequest();
  const [months, setMonths] = useState<number>(1);

  const onClick = async () => {
    const res = await apiRequest(`jobs/feature/${props.job._id}`, "POST", {
      body: {
        months: months,
      },
      showToast: true,
      toastText: "featured job",
    });
    if (res.status > 199 && res.status < 299) {
      props.job.isFeatured = true;
    }
  };

  return (
    <tr>
      <TableColumn name="admin-col">
        <Component name="admin-jobs-company-image">
          <Image name="company-image" img={props.job.companyImage} />
        </Component>
      </TableColumn>
      <TableColumn name="admin-col">
        <Component name="admin-jobs-company">
          <Paragraph type="paragraph" name="admin-jobs-company">
            {props.job.company}
          </Paragraph>
        </Component>
      </TableColumn>
      <TableColumn name="admin-col">
        <Component name="admin-jobs-position">
          <Paragraph type="paragraph" name="admin-jobs-position">
            {props.job.position}
          </Paragraph>
        </Component>
      </TableColumn>
      <TableColumn>
        <Component name="admin-jobs-location">
          <Paragraph type="paragraph" name="admin-jobs-location">
            {props.job.location}
          </Paragraph>
        </Component>
      </TableColumn>
      <TableColumn>
        <Component name="admin-jobs-featured">
          <Slider
            label={"Months: "}
            maxValue={12}
            minValue={1}
            showMinAndMaxValue={false}
            factor={1}
            labelClasses="p--l"
            value={months}
            onInput={(e) => setMonths((e.target as HTMLInputElement).value as unknown as number)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
          <Button type={props.job.isFeatured ? "danger" : "primary"} onClick={onClick}>
            {props.job.isFeatured ? "Unfeature" : "Feature"}
          </Button>
        </Component>
      </TableColumn>
    </tr>
  );
};

export default AdminJobsFeatured;
