import { useEffect, useRef, useState } from "react";
import { AppView, Button, Component, Section, SectionElement } from "../Elements/Elements";
import { FormComponents } from "../Elements/FormComponents";
import Navigation from "../Navigation/Navigation";
import "./test.scss";
import { TextInputElement } from "../Elements/FormElements";
import ShakaPlayer from "../Academy/ShakaPlayer";

const Test = () => {
  const [videoLink, setVideoLink] = useState<string>();
  const [approvedVideoLink, setApprovedVideoLink] = useState<string>();
  const ref = useRef(null);

  return (
    <>
      <AppView name="test">
        <Navigation />
        <Section name="test">
          {/* <SectionElement type="header" name="test"></SectionElement>
          <SectionElement type="body" name="test">
            <Component name="test">
              <Component name="test-controls">
                <TextInputElement
                  type={"text"}
                  onInput={(e) => setVideoLink((e.target as HTMLInputElement).value)}
                />
                <Button type="primary" onClick={() => setApprovedVideoLink(videoLink)}>
                  Approve
                </Button>
              </Component>
              {approvedVideoLink && (
                <ShakaPlayer controls={true} video={approvedVideoLink} ref={ref} />
              )}
            </Component>
          </SectionElement>
          <SectionElement type="footer" name="test"></SectionElement> */}
          <div
            id="zuddl-embeddable-form"
            data-zuddl-event-id="fdfe925e-bd69-46c6-9fc5-717c9841bbfe"
            data-env="prod"
            style={{ width: "100%", height: "100%" }}></div>
        </Section>
      </AppView>
    </>
  );
};

export { Test };
