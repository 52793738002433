import { Paragraph, Component } from "../Elements/Elements";

export const MessageText = (props: { isOwn: boolean; messageText: string }) => {
  return (
    <Component name="text" className={`${props.isOwn ? "own" : ""}`}>
      <Paragraph name="text" type="paragraph">
        {props.messageText}
      </Paragraph>
    </Component>
  );
};
