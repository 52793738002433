import { useContext, useEffect, useState } from "react";
import { TabProps } from "../Elements/Components";
import { AppView, Title } from "../Elements/Elements";
import Navigation from "../Navigation/Navigation";
import "./adminDashboard.scss";
import Context from "../../context/Context";
import AdminResource from "./AdminResource";
import AdminUsers from "./AdminUsers";
import AdminEvents from "./AdminEvents";
import AdminDiscussions from "./AdminDiscussions";
import AdminSupport from "./AdminSupport";
import AdminNotify from "./AdminNotify";
import AdminQandA from "./AdminQandA";
import AdminSkills from "./AdminSkills";
import { useUser } from "../../hooks/useUser";
import AdminMasterclass from "./AdminMasterclass";
import AdminExercises from "./AdminExercises";
import { useSearchParams } from "react-router-dom";
import AdminMasterclassAssign from "./AdminMasterclassAssign";
import AdminCredly from "./AdminCredly";
import AdminJobsFeatured from "./AdminJobsFeatured";
import AdminTeamdashboard from "./AdminTeamdashboard";

const AdminDashboard = () => {
  const { isMobile } = useContext(Context);
  const { user } = useUser();
  const [searchParams] = useSearchParams();
  const tabId = (searchParams.get("tab") as unknown as number) * 1;

  const [tabs, setTabs] = useState<TabProps[]>([
    {
      children: <AdminResource />,
      id: 0,
      isActive: tabId === 0 || tabId > 10,
      label: "Resourcen",
    },
    {
      children: <AdminEvents />,
      id: 1,
      isActive: tabId === 1,
      label: "Events",
    },
  ]);

  useEffect(() => {
    if (user?.role === "ADMIN") {
      setTabs((value) => {
        const prev = [...value];
        return [
          ...prev,
          {
            children: <AdminUsers />,
            id: 2,
            isActive: tabId === 2,
            label: "Users",
          },

          {
            children: <AdminDiscussions />,
            id: 3,
            isActive: tabId === 3,
            label: "Diskussionen",
          },
          {
            children: <AdminSupport />,
            id: 4,
            isActive: tabId === 4,
            label: "Support",
          },
          {
            children: <AdminNotify />,
            id: 5,
            isActive: tabId === 5,
            label: "Notify",
          },
          {
            children: <AdminQandA />,
            id: 6,
            isActive: tabId === 6,
            label: "Q and A",
          },
          {
            children: <AdminSkills />,
            id: 7,
            isActive: tabId === 7,
            label: "Skills",
          },
          {
            children: <AdminMasterclass />,
            id: 8,
            isActive: tabId === 8,
            label: "Masterclass",
          },
          {
            children: <AdminMasterclassAssign />,
            id: 9,
            isActive: tabId === 9,
            label: "Masterclasses zuweisen",
          },
          {
            children: <AdminExercises />,
            id: 10,
            isActive: tabId === 10,
            label: "Exercises",
          },
          {
            children: <AdminJobsFeatured />,
            id: 11,
            isActive: tabId === 11,
            label: "Jobs featuren",
          },
          {
            children: <AdminCredly />,
            id: 12,
            isActive: tabId === 12,
            label: "Credly Badges",
          },
          {
            children: <AdminTeamdashboard />,
            id: 13,
            isActive: tabId === 13,
            label: "Team Dashboard",
          },
        ];
      });
    }
  }, [user]);

  return (
    <>
      {!isMobile && (
        <AppView name="admin-dashboard">
          <Navigation tabProps={tabs} />
        </AppView>
      )}
      {isMobile && (
        <>
          <Title type="h1" size="m" className="txt--c-on-pri">
            Admin Änderungen können nur am PC durchgeführt werden
          </Title>
          <Navigation />
        </>
      )}
    </>
  );
};

export { AdminDashboard };
