import useRequest from "../../hooks/useRequest";
import { Role } from "../../requests/interfaces";
import { Button, Component, Section, SectionElement, Title } from "../Elements/Elements";
import { SelectInput, TextInputElement, UserRolesSelect } from "../Elements/FormElements";
import { useState } from "react";

const AdminNotify = () => {
  const [information, setInformation] = useState<string>("");
  const [role, setRole] = useState<Role>();
  const [type, setType] = useState<string>("");
  const { apiRequest } = useRequest();

  const notify = async () => {
    const request = {
      text: information,
      type: type,
    };
    setInformation("");
    setType("select");
    await apiRequest("notifications", "POST", { body: request, showToast: true });
  };

  return (
    <>
      <Section name="admin-notify">
        <SectionElement type="header" name="admin-notify">
          <Title name="admin-notify-title" type="h1" size="m">
            Notify
          </Title>
        </SectionElement>
        <SectionElement type="body" name="admin-notify">
          <Component name="form-inputs">
            <Component name="form-row">
              <Component name="form-input">
                <TextInputElement
                  id="information"
                  label="Notify Nachricht"
                  type="text"
                  labelClasses="p--l"
                  onInput={(e) => setInformation((e.target as HTMLInputElement).value)}
                  value={information}
                  placeHolder="Im neuen Update..."
                />
              </Component>
            </Component>
            <Component name="form-row">
              <Component name="form-input">
                <UserRolesSelect
                  onInput={(newValue) => {
                    const value = Object.entries(Role).find(
                      ([, val]) => val === newValue?.value,
                    )?.[0] as unknown as Role;
                    setRole(value);
                  }}
                  defaultValue={role}
                  showLabel={true}
                />
              </Component>
            </Component>
            <Component name="form-row">
              <Component name="form-input">
                <SelectInput
                  optionLabelClasses="p--l"
                  options={[
                    { id: "select", name: "Auswählen" },
                    { id: "danger", name: "Gefahr" },
                    { id: "information", name: "Information" },
                    { id: "success", name: "Success" },
                  ]}
                  label="Benachrichtigungstyp"
                  labelClasses="p--l"
                  id="type"
                  name="cs-type"
                  onInput={(e) => {
                    setType((e.target as HTMLInputElement).value);
                  }}
                />
              </Component>
            </Component>
            <Button
              type="primary"
              onClick={() => notify()}
              disabled={information === "" || type === "" || type === "select"}>
              Benachrichtigen
            </Button>
          </Component>
        </SectionElement>
      </Section>
    </>
  );
};

export default AdminNotify;
