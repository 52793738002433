const ExclamationMarkSVG: React.FC<React.HTMLProps<HTMLOrSVGElement>> = () => {
  return (
    <svg viewBox="0 0 18 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0C4.02944 0 0 4.02944 0 9C0 12.0264 1.80977 14.5918 3.89505 16.399L7.33648 19.3816C8.29117 20.209 9.70883 20.209 10.6635 19.3816L14.1049 16.399C16.1902 14.5918 18 12.0264 18 9C18 4.02944 13.9706 0 9 0ZM9 5C9.55229 5 10 5.44772 10 6V8C10 8.55228 9.55229 9 9 9C8.44771 9 8 8.55228 8 8V6C8 5.44772 8.44771 5 9 5ZM9 11C8.44771 11 8 11.4477 8 12C8 12.5523 8.44771 13 9 13C9.55229 13 10 12.5523 10 12C10 11.4477 9.55229 11 9 11Z"
      />
    </svg>
  );
};

export default ExclamationMarkSVG;
