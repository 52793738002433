const UploadSVG: React.FC<React.HTMLProps<HTMLOrSVGElement>> = () => {
  return (
    <svg viewBox="0 0 44 40" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.44444 17.3304C3.79447 17.3304 4.88889 18.3839 4.88889 19.6833V34.9774H39.1111V19.6833C39.1111 18.3839 40.2055 17.3304 41.5556 17.3304C42.9056 17.3304 44 18.3839 44 19.6833V37.3303C44 38.6298 42.9056 39.6832 41.5556 39.6832H2.44444C1.09442 39.6832 0 38.6298 0 37.3303V19.6833C0 18.3839 1.09442 17.3304 2.44444 17.3304Z"
      />
      <path d="M19.4875 1.00654C20.4421 0.0876626 21.9898 0.0876626 22.9444 1.00654L31.5 9.24184C32.4546 10.1607 32.4546 11.6505 31.5 12.5694C30.5454 13.4883 28.9977 13.4883 28.043 12.5694L23.6604 8.35083V28.5527C23.6604 29.8522 22.566 30.9056 21.216 30.9056C19.8659 30.9056 18.7715 29.8522 18.7715 28.5527V8.35083L14.3889 12.5694C13.4343 13.4883 11.8865 13.4883 10.9319 12.5694C9.97731 11.6505 9.97731 10.1607 10.9319 9.24184L19.4875 1.00654Z" />
    </svg>
  );
};

export default UploadSVG;
