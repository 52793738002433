// filter
// add a job -> jobs/create
// Job Posts

import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import ArrowSVG from "../../Icons/ArrowSVG";
import useFormOptions from "../../hooks/useFormOptions";
import historyNavigate from "../../hooks/useHistoryNavigate";
import useRequest from "../../hooks/useRequest";
import { JobPostSimple, Paginated } from "../../requests/interfaces";
import { Filter, FilterOptionProps } from "../Elements/Components";
import {
  Button,
  Component,
  ComponentCard,
  Icon,
  Image,
  Paragraph,
  Section,
  SectionElement,
  Tag,
  Title,
} from "../Elements/Elements";
import "./jobdashboard.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loading } from "../Loading/Loading";
import { JobDashboardContext } from "../../pages/JobDashboard";
import Context from "../../context/Context";

const arraysHaveSameContentById = (arr1: any[], arr2: any[]) => {
  // Check if both arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Extract _id values from both arrays
  const ids1 = arr1.map((item) => item._id);
  const ids2 = arr2.map((item) => item._id);

  // Sort _id arrays for a consistent order
  ids1.sort();
  ids2.sort();

  // Check if every _id in the first array matches the corresponding _id in the second array
  for (let i = 0; i < ids1.length; i++) {
    if (ids1[i] !== ids2[i]) {
      return false;
    }
  }

  // If all checks passed, arrays have the same content by _id
  return true;
};

const JobPosts = () => {
  const [jobs, setJobs] = useState<JobPostSimple[]>([]);
  const { useHistoryNavigate } = historyNavigate();
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [filters, setFilters] = useState<string[]>([]);
  const { apiRequest } = useRequest();
  const { isPublic } = useContext(JobDashboardContext);

  const getJobs = async (resetPage?: boolean) => {
    let filterString = "";
    if (filters.length !== 0) {
      filterString = filters.join("&");
    }
    const discussionsResponse = await apiRequest<Paginated<JobPostSimple>>(
      `jobs?page=${resetPage ? 0 : page}${filterString ? `&${filterString}` : ""}&limit=8`,
      "GET",
    );
    if (!arraysHaveSameContentById(jobs, discussionsResponse.data.docs)) {
      console.log("get new things");
      setJobs((oldValues) => {
        if (resetPage) {
          return discussionsResponse.data.docs;
        } else {
          const combined = [...oldValues, ...discussionsResponse.data.docs];
          const final: JobPostSimple[] = [];
          combined.map((value) => {
            const index = final.findIndex((item) => item._id === value._id);
            if (index === -1) {
              final.push(value);
            }
          });
          return final;
        }
      });
      setHasNextPage(discussionsResponse.data.hasNextPage);
      if (discussionsResponse.data.hasNextPage) {
        setPage(discussionsResponse.data.page + 1);
      }
    }
  };

  useEffect(() => {
    getJobs(true);
  }, [filters]);

  const createEvent = async () => {
    const body = {
      teamImage:
        "https://storage.googleapis.com/pictures-sdrs-development/jobs/af616565-e8dd-446d-921a-efc4807596e4.webp",
      companyImage:
        "https://storage.googleapis.com/pictures-sdrs-development/jobs/fc720890-7c9e-49f1-9c67-7c4e20fd8cb0.webp",
      company: "asdf",
      position: "ae",
      experience: "3YEAR",
      salaryStart: "41",
      salaryEnd: "54",
      isFeatured: false,
      location: "asdf",
      locationType: "in_person",
      industry: "medical",
      icp: ["it"],
      techStack: ["bao"],
      companySize: "enterprise",
      learningAndDevelopment: ["lnd_budget"],
      teamAthmosphere: "asdfasdf",
      progressionPlan: "asdf",
      roleSpec: "asdf",
      aboutYou: "asdf",
      companyInfos: "asdf",
      caldendlyLink: "https://linkedin.com/",
      targetComissionSplit: "asdf",
      salesReps: "asdf",
    };

    await apiRequest("jobs", "POST", {
      body: body,
      showToast: true,
      toastText: "Erfolgreich Job hinzugefügt",
    });
  };

  return (
    <Section name="jobs">
      <SectionElement name="jobs" type="body">
        <Component name="jobs">
          {process.env.REACT_APP_NODE_ENV === "development" && (
            <Button type="primary" onClick={createEvent}>
              CREATE TEST JOB
            </Button>
          )}
          {!isPublic ? (
            <Button
              name="add-job"
              type="primary"
              onClick={() => {
                useHistoryNavigate("/jobs/create");
              }}>
              + Job erstellen
            </Button>
          ) : (
            <Button
              name="add-job"
              type="primary"
              onClick={() => {
                useHistoryNavigate("/");
              }}>
              Jetzt anmelden und Job posten
            </Button>
          )}
          <Component name="job-posts-container">
            <Component name="job-filters">
              <JobFilter setFilters={setFilters} />
            </Component>
            <Component name="job-posts-parent">
              {jobs ? (
                <InfiniteScroll
                  dataLength={jobs?.length}
                  next={getJobs}
                  hasMore={hasNextPage}
                  loader={<h4>Loading...</h4>}
                  scrollableTarget="parent-component">
                  <>
                    <Component name="job-posts">
                      {jobs?.map((job, i) => {
                        return <JobPostComponent job={job} key={i} />;
                      })}
                    </Component>
                  </>
                </InfiniteScroll>
              ) : (
                <Loading />
              )}
            </Component>
          </Component>
        </Component>
      </SectionElement>
    </Section>
  );
};

const JobFilter = (props: { setFilters: Dispatch<SetStateAction<string[]>> }) => {
  const { isMobile } = useContext(Context);

  const [filterOptionList, setFilterOptionList] = useState<
    { name: string; filterOptions: FilterOptionProps[] }[]
  >([]);
  const [filters, setFilters] = useState<string[]>([]);

  const [formOptions, setFormOptions] = useState<any>();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  useEffect(() => {
    props.setFilters(filters);
  }, [filters]);

  useEffect(() => {
    const setupOptions = async () => {
      setFormOptions(await useFormOptions().getFormOptions());
    };
    setupOptions();
  }, []);

  useEffect(() => {
    const getFilters = async () => {
      if (!formOptions) {
        return;
      }
      const filterGroup: {
        name: string;
        options: string[];
        value: string;
        type: "checkbox" | "select" | "radio";
        isArray: boolean;
      }[] = [
        {
          name: "Unternehmensart",
          options: formOptions.companySizeOptions,
          type: "radio",
          value: "companySize",
          isArray: false,
        },
        {
          name: "Position",
          options: formOptions.positionJobBoardOptions,
          type: "select",
          value: "position",
          isArray: false,
        },
        {
          name: "Erfahrung",
          options: formOptions.yearsExperienceOptions,
          type: "select",
          value: "experience",
          isArray: false,
        },
        {
          name: "Standort",
          options: formOptions.locationOptions,
          type: "select",
          value: "locationType",
          isArray: false,
        },
        {
          name: "Industrie",
          options: formOptions.industryOptions,
          type: "select",
          value: "industry",
          isArray: false,
        },
        {
          name: "ICP",
          options: formOptions.icpOptions,
          type: "select",
          value: "icp",
          isArray: true,
        },
        {
          name: "Techstack",
          options: formOptions.techstackOptions,
          type: "select",
          value: "techstack",
          isArray: true,
        },
        {
          name: "Weiterbildungsmöglichkeiten",
          options: formOptions.learningAndDevelopmentOptions,
          type: "checkbox",
          value: "learningAndDevelopment",
          isArray: true,
        },
        // TODO
        // {
        //   name: "Gehalt",
        //   options: formOptions.positionOptions,
        // },
      ];

      for (let index = 0; index < filterGroup.length; index++) {
        const element = filterGroup[index];
        const filterOptions: FilterOptionProps[] = [];
        element.options.map((option: any) => {
          filterOptions.push({
            id: option.id,
            label: option.name,
            type: element.type,
            name: element.value + "[]",
          });
        });
        setFilterOptionList((value) => {
          const prev = [...value];
          const indexOfItem = prev.findIndex((item) => item.name === element.name);
          if (indexOfItem === -1) {
            prev.push({ name: element.name, filterOptions: filterOptions });
          }
          return prev;
        });
      }
    };
    getFilters();
  }, [formOptions]);

  const toggleFilter = (key: string, selectedFilter: string[]) => {
    if (filters.length === 0) {
      setFilters(selectedFilter);
    } else {
      const final: string[] = [];
      const allFilters: string[] = [...selectedFilter, ...filters];
      allFilters.map((item) => {
        if (item.startsWith(key)) {
          if (!final.includes(item) && selectedFilter.includes(item)) {
            final.push(item);
          }
        } else {
          final.push(item);
        }
      });
      setFilters(final);
    }
  };

  return (
    <ComponentCard
      name="job-filter-container"
      onClick={() => {
        if (isMobile) {
          setIsCollapsed((value) => !value);
        }
      }}
      showClickable={false}>
      {isMobile && (
        <Component name="job-filter-header">
          <Title size="xxs" type="h3" name="job-filter-header">
            Filter
          </Title>
          <Icon svg={<ArrowSVG />} name="collapse" className={isCollapsed ? "is-collapsed" : ""} />
        </Component>
      )}
      {(isMobile ? !isCollapsed : true) && (
        <>
          {filterOptionList?.map((filter) => {
            return (
              <Filter
                key={filter.name}
                filterOptions={filter.filterOptions}
                filterSize="large"
                onFilterChange={(filtered) => toggleFilter(filter.filterOptions[0].name, filtered)}
                filterName={filter.name}
                showResetButton={false}
                filterIsCollapsible={false}
              />
            );
          })}
        </>
      )}
    </ComponentCard>
  );
};

const JobPostComponent = (props: { job: JobPostSimple }) => {
  const { useHistoryNavigate } = historyNavigate();
  const { isPublic } = useContext(JobDashboardContext);

  const [formOptions, setFormOptions] = useState<any>();
  useEffect(() => {
    const setupOptions = async () => {
      setFormOptions(await useFormOptions().getFormOptions());
    };
    setupOptions();
  }, []);

  return (
    <ComponentCard
      name="job-post"
      type="shadow"
      onClick={() => useHistoryNavigate(`${isPublic ? "/public/jobs/" : ""}${props.job._id}`)}>
      <Component name="job-info-image">
        <Image name="job-info-image" img={props.job.companyImage} />
      </Component>
      <Component name="job-info">
        <Title name="job-info-title" type="h2" size="s">
          {formOptions && (formOptions.getLabelById(props.job.position ?? "")?.name ?? "")}
        </Title>
        <Title name="job-info-company" className="txt--c-pri" type="h3" size="xs">
          {props.job.company}
        </Title>
        <Component name="job-info-footer">
          <Paragraph
            name="job-info-salary"
            size="s">{`${props.job.salaryStart} - ${props.job.salaryEnd}k OTE`}</Paragraph>
          <div className="el--bullet el"></div>
          <Paragraph name="job-info-industry" size="s">
            {formOptions && (formOptions.getLabelById(props.job.industry ?? "")?.name ?? "")}
          </Paragraph>
          <div className="el--bullet el"></div>
          <Paragraph name="job-info-location" size="s">
            {props.job.location}
          </Paragraph>
        </Component>
      </Component>
      <Component name="job-info-continue">
        {props.job.isFeatured && <Tag isActive={true}>Featured</Tag>}
        {props.job.isOwn && (
          <Component name="job-edit">
            <Button
              type="primaryGhost"
              onClick={(e) => {
                e.stopPropagation();
                useHistoryNavigate(`${props.job._id}/edit`);
              }}>
              Edit
            </Button>
          </Component>
        )}
        <Component name="job-info-continue-icon">
          <Icon name="job" svg={<ArrowSVG />}></Icon>
        </Component>
      </Component>
    </ComponentCard>
  );
};

export default JobPosts;
