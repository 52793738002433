import { AppView, Component, Section, Title, SectionElement } from "../Elements/Elements";
import { FilterOptionProps } from "../Elements/Components";
import Navigation from "../Navigation/Navigation";
import "./discussion.scss";
import { useContext, useEffect, useState } from "react";
import { Paginated, DiscussionResponseDto } from "../../requests/interfaces";
import { HashtagsFilter } from "../Elements/FormElements";
import useRequest from "../../hooks/useRequest";
import InfiniteScroll from "react-infinite-scroll-component";
import { DiscussionElement, StartDiscussion } from "./Components";
import Context from "../../context/Context";

const otherFilterOptions: FilterOptionProps[] = [
  {
    id: "newest",
    label: "Neuste",
    name: "sortBy",
    type: "radio",
  },
  {
    id: "best",
    label: "Top Diskussionen",
    name: "sortBy",
    type: "radio",
  },
];

const Discussions = (props: { discussionId?: string }) => {
  const [filters, setFilters] = useState<string[]>([]);
  const [discussions, setDiscussions] = useState<DiscussionResponseDto[]>([]);
  const [newItem, setNewItem] = useState<DiscussionResponseDto>();
  const [page, setPage] = useState<number>(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const { apiRequest } = useRequest();
  const { isMobile } = useContext(Context);

  useEffect(() => {
    const filterString = filters?.join("&");
    const getFilteredDiscussions = async () => {
      await getDiscussions(true);
      if (!filterString.includes("hashtags")) {
        await getMyDiscussions();
      }
    };
    if (!props.discussionId) {
      getFilteredDiscussions();
    }
  }, [filters]);

  useEffect(() => {
    if (props.discussionId) {
      const getDiscussionById = async () => {
        setDiscussions([]);
        const { data } = await apiRequest<DiscussionResponseDto>(
          `discussions/${props.discussionId}`,
          "GET",
        );
        data._id = props.discussionId ?? "";
        setDiscussions([data]);
      };
      getDiscussionById();
    }
  }, [props.discussionId]);

  useEffect(() => {
    if (!props.discussionId) {
      const getAllDiscussions = async () => {
        await getDiscussions();
        await getMyDiscussions();
      };
      getAllDiscussions();
    }
  }, [newItem, props.discussionId]);

  const getMyDiscussions = async () => {
    const submittedDiscussions = await apiRequest<Paginated<DiscussionResponseDto>>(
      "discussions/submitted/me",
      "GET",
    );
    setDiscussions((value) => {
      const indexOfDiscussionPreview = value.findIndex(
        (discussion) => discussion.status === "PENDING",
      );
      if (indexOfDiscussionPreview === -1) {
        const prev = submittedDiscussions.data.docs.concat(value);
        return prev;
      }
      return value;
    });
  };

  const getDiscussions = async (resetPage?: boolean) => {
    let filterString = "";
    if (filters.length !== 0) {
      filterString = filters.join("&");
    }
    const discussionsResponse = await apiRequest<Paginated<DiscussionResponseDto>>(
      `discussions?page=${resetPage ? 0 : page}${filterString ? `&${filterString}` : ""}&limit=8`,
      "GET",
    );
    setDiscussions((oldValues) => {
      if (resetPage) {
        return discussionsResponse.data.docs;
      } else {
        const combined = [...oldValues, ...discussionsResponse.data.docs];
        const final: DiscussionResponseDto[] = [];
        combined.map((value) => {
          const index = final.findIndex((item) => item._id === value._id);
          if (index === -1) {
            final.push(value);
          }
        });
        return final;
      }
    });
    setHasNextPage(discussionsResponse.data.hasNextPage);
    if (discussionsResponse.data.hasNextPage) {
      setPage(discussionsResponse.data.page + 1);
    }
  };

  const createDiscussion = async (
    discussionText: string,
    tags: string[],
    anonym: boolean,
    uploadedPictures: string[],
    taggedUsers: string[],
  ) => {
    const request = {
      content: discussionText.trim(),
      hashtags: tags,
      isAnonymous: anonym,
      pictures: uploadedPictures,
      taggedUsers: taggedUsers,
    };
    const { data } = await apiRequest<DiscussionResponseDto>("discussions", "POST", {
      body: request,
      showToast: true,
    });
    setNewItem(data);
  };

  return (
    <AppView name="discussion">
      <Navigation />
      <Section name="discussion">
        <SectionElement type="header" name="discussion">
          <Component name="discussion-parent">
            {!props.discussionId && !isMobile && (
              <HashtagsFilter
                onFilterChange={(filters) => setFilters(filters)}
                type="large"
                otherFilterOptions={otherFilterOptions}
              />
            )}
            {!props.discussionId && (
              <InfiniteScroll
                dataLength={discussions.length}
                next={getDiscussions}
                hasMore={hasNextPage}
                loader={<h4>Loading...</h4>}
                scrollableTarget="parent-component">
                <Component name="discussions" id="discussion-infinite-scroll">
                  <StartDiscussion onSubmit={createDiscussion} />
                  {!props.discussionId && isMobile && (
                    <HashtagsFilter
                      onFilterChange={(filters) => setFilters(filters)}
                      type="large"
                      otherFilterOptions={otherFilterOptions}
                    />
                  )}
                  {discussions.length === 0 && (
                    <Title name="not-found" type="h1" size="l">
                      Keine Diskussionen gefunden
                    </Title>
                  )}
                  {discussions.length !== 0 &&
                    discussions.map((discussion) => {
                      return (
                        <DiscussionElement
                          discussion={discussion}
                          key={discussion._id}
                          showComments={props.discussionId ? true : false}
                        />
                      );
                    })}
                </Component>
              </InfiniteScroll>
            )}
            {props.discussionId && (
              <Component name="discussions" id="discussion-infinite-scroll">
                {discussions.map((discussion) => {
                  return (
                    <DiscussionElement
                      discussion={discussion}
                      key={discussion._id}
                      showComments={props.discussionId ? true : false}
                    />
                  );
                })}
              </Component>
            )}
          </Component>
        </SectionElement>
      </Section>
    </AppView>
  );
};

export { Discussions };
