import { AppView, ComingSoon, Section, SectionElement } from "../Elements/Elements";
import Navigation from "../Navigation/Navigation";
import "./journal.scss";

const Journal = () => {
  return (
    <>
      <AppView name="journal">
        <Navigation />
        <Section name="journal">
          <SectionElement name="journal" type="header"></SectionElement>
          <SectionElement name="journal" type="body">
            <ComingSoon />
          </SectionElement>
          <SectionElement name="journal" type="footer"></SectionElement>
        </Section>
      </AppView>
    </>
  );
};

export { Journal };
