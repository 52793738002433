import { Main, Page } from "../components/Elements/Elements";
import Sidebar from "../components/Navigation/Sidebar";
import { AdminDashboard as AdminDashboardComponent } from "../components/AdminDashboard/AdminDashboard";

const Career = () => {
  return (
    <Page name="admindashboard">
      <Sidebar />
      <Main>
        <AdminDashboardComponent />
      </Main>
    </Page>
  );
};

export default Career;
