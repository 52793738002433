import { useContext, useEffect, useState } from "react";
import {
  Component,
  ProfilePictureEdit,
  Button,
  Section,
  SectionElement,
  Link,
  Popup,
  Icon,
} from "../Elements/Elements";
import { useAuth0 } from "@auth0/auth0-react";
import { useUser } from "../../hooks/useUser";
import useRequest from "../../hooks/useRequest";
import { User } from "../../requests/interfaces";
import Context from "../../context/Context";
import { ConfirmationDisplay, SupportForm } from "../Elements/Components";
import CareerStep from "../Authentication/RegisterSteps/CareerStep";
import "./profileInformation.scss";
import historyNavigate from "../../hooks/useHistoryNavigate";
import { TextInputElement } from "../Elements/FormElements";
import EditSVG from "../../Icons/EditSVG";

const ProfileInformation = () => {
  const { logout } = useAuth0();
  const { user } = useUser();
  const { apiFileRequest: apiImageRequest } = useRequest();
  const [selectedImage, setSelectedImage] = useState<File | null | string>(user?.picture ?? null);
  const { setUser } = useContext(Context);
  const [supportFormIsOpen, setSupportFormIsOpen] = useState<boolean>(false);
  const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false);
  const { setPopupContent } = useContext(Context);
  const { apiRequest } = useRequest();
  const [newWelcomeMessage, setNewWelcomeMessage] = useState<string>();
  const [linkedInUrl, setLinkedInUrl] = useState<string>();
  const [editWelcomeMessage, setEditWelcomeMessage] = useState<boolean>(false);
  const [editLinkedInUrl, setEditLinkedInUrl] = useState<boolean>(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState<boolean>(false);
  const [passwordResetDialogIsOpen, setPasswordResetDialogIsOpen] = useState<boolean>(false);

  const signOutAction = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const deleteProfileAction = async () => {
    await apiRequest("user", "DELETE");
    signOutAction();
  };

  const changeImage = async () => {
    const { data } = await apiImageRequest<User>("user/profilePicture", "PUT", selectedImage);
    if (setUser) {
      setUser(data);
    }
  };

  const { useHistoryNavigate } = historyNavigate();

  const updateCareer = async (e: any, operator?: number, request?: any) => {
    const finalRequest: any = {};
    if (Object.keys(request.career).length !== 0) {
      finalRequest.career = request.career;
    }
    if (request.hyre) {
      finalRequest.hyre = request.hyre;
    }

    const { data, status } = await apiRequest<User>("user", "PUT", {
      body: finalRequest,
      showToast: true,
    });
    if (status > 200 && status < 299) {
      useHistoryNavigate("/");
      if (setUser) setUser(data);
    }
  };

  useEffect(() => {
    if (setPopupContent) {
      if (popupIsOpen) {
        setPopupContent(
          <Popup setPopupIsOpen={setPopupIsOpen} size="SMALL">
            <CareerStep key="career" action={updateCareer} id={0} />
          </Popup>,
        );
      } else {
        setPopupContent(undefined);
      }
    }
  }, [popupIsOpen]);

  const contactSupport = () => {
    setSupportFormIsOpen(true);
  };

  const resetPassword = async () => {
    const { data } = await apiRequest<any>("user/requestPasswordChange", "PATCH");
    window.open(data.ticketUrl)?.focus();
    signOutAction();
  };

  const saveNewWelcomeMessage = async () => {
    const { data } = await apiRequest<User>("user", "PUT", {
      body: { welcomeMessage: newWelcomeMessage },
    });
    if (setUser) setUser(data);
    setNewWelcomeMessage(undefined);
    setEditWelcomeMessage(false);
  };

  const saveLinkedInLink = async () => {
    const { data } = await apiRequest<User>("user", "PUT", {
      body: { linkedInUrl: linkedInUrl },
    });
    if (setUser) setUser(data);
    setLinkedInUrl(undefined);
    setEditLinkedInUrl(false);
  };

  return (
    <>
      <Section name="profile">
        <SectionElement type="body" name="profile">
          <Component name="profile-information-control">
            <ProfilePictureEdit
              img={selectedImage}
              onChange={(e: any) => setSelectedImage(e.target.files[0])}
              method="PUT"
            />
            {selectedImage !== user?.picture && selectedImage !== null && (
              <Button
                type="primary"
                name="profile"
                onClick={() => changeImage()}
                disabled={selectedImage === user?.picture || selectedImage === null}>
                Speichern
              </Button>
            )}
            <Component name="linkedin-link-edit">
              <Component name="linkedin-link-text-editor">
                <Component name="linkedin-link-editor-controls">
                  <TextInputElement
                    id="linkedin-link-editor"
                    label="Dein LinkedIn Profil"
                    labelClasses="p--l"
                    type="text"
                    onChange={(e) => setLinkedInUrl((e.target as HTMLInputElement).value)}
                    value={linkedInUrl ?? user?.linkedInUrl}
                    className="linkedin-link-editor"
                    disabled={!editLinkedInUrl}
                  />
                </Component>
                {editLinkedInUrl ? (
                  <Button
                    type="primary"
                    name="save-linkedin-link"
                    disabled={linkedInUrl && linkedInUrl !== "" ? false : true}
                    onClick={() => saveLinkedInLink()}>
                    Speichern
                  </Button>
                ) : (
                  <Icon
                    svg={<EditSVG />}
                    name="edit-linkedin-link"
                    onClick={() => {
                      setEditLinkedInUrl(true);
                    }}
                  />
                )}
              </Component>
            </Component>
            <Component name="welcome-message-edit">
              <Component name="welcome-message-text-editor">
                <Component name="welcome-message-editor-controls">
                  <TextInputElement
                    id="welcome-message-editor"
                    label="Deine Willkommensnachricht"
                    labelClasses="p--l"
                    type="textarea"
                    onChange={(e) => setNewWelcomeMessage((e.target as HTMLInputElement).value)}
                    value={newWelcomeMessage ?? user?.welcomeMessage}
                    className="welcome-message-editor"
                    disabled={!editWelcomeMessage}
                  />
                </Component>
                {editWelcomeMessage ? (
                  <Button
                    type="primary"
                    name="save-welcome-message"
                    disabled={newWelcomeMessage && newWelcomeMessage !== "" ? false : true}
                    onClick={() => saveNewWelcomeMessage()}>
                    Speichern
                  </Button>
                ) : (
                  <Icon
                    svg={<EditSVG />}
                    name="edit-welcome-message"
                    onClick={() => {
                      setEditWelcomeMessage(true);
                    }}
                  />
                )}
              </Component>
            </Component>
            <Button type="darkGhost" name="profile-edit" onClick={() => setPopupIsOpen(true)}>
              Profil Bearbeiten
            </Button>
            <Button type="danger" name="profile" onClick={signOutAction}>
              Abmelden
            </Button>
            <Button
              type="darkGhost"
              name="profile-reset-password"
              onClick={() => setPasswordResetDialogIsOpen(true)}>
              Passwort zurücksetzen
            </Button>
            <Button type="dangerGhost" name="profile" onClick={() => setDeleteDialogIsOpen(true)}>
              Profil löschen
            </Button>
            <Button onClick={contactSupport} name="support" type="lightText">
              Support kontaktieren
            </Button>
            <Component name="profile-control-right">
              <Component name="impressum-link">
                <Link
                  href="https://www.sdrsofgermany.com/legal/impressum"
                  onClick={() =>
                    window.open("https://www.sdrsofgermany.com/legal/impressum", "_blank")?.focus()
                  }
                  name="impressum-link">
                  Impressum
                </Link>
              </Component>
            </Component>
          </Component>
          <SupportForm popupIsOpen={supportFormIsOpen} setPopupIsOpen={setSupportFormIsOpen} />
        </SectionElement>
      </Section>
      <ConfirmationDisplay
        action={deleteProfileAction}
        popupIsOpen={deleteDialogIsOpen}
        setPopupIsOpen={setDeleteDialogIsOpen}
        title="Bist du dir sicher, dein Profil unwideruflich zu löschen?"
      />
      <ConfirmationDisplay
        action={resetPassword}
        popupIsOpen={passwordResetDialogIsOpen}
        setPopupIsOpen={setPasswordResetDialogIsOpen}
        title="Bist du dir sicher, dass du dein Passwort ändern willst?"
      />
    </>
  );
};

export default ProfileInformation;
