import { Main, Page } from "../components/Elements/Elements";
import Sidebar from "../components/Navigation/Sidebar";
import { Events as EventsComponent } from "../components/Events/Events";

const Test = () => {
  return (
    <Page name="career">
      <Sidebar />
      <Main>
        <EventsComponent />
      </Main>
    </Page>
  );
};

export default Test;
