import { useEffect, useState } from "react";
import TickSVG from "../../Icons/TickSVG";
import TrashSVG from "../../Icons/TrashSVG";
import useRequest from "../../hooks/useRequest";
import { CredlyBadgeSubmissionDto, Paginated } from "../../requests/interfaces";
import {
  Component,
  Icon,
  Paragraph,
  ProfileBubble,
  Section,
  SectionElement,
  Table,
  TableColumn,
  TablePagination,
  Title,
} from "../Elements/Elements";

const AdminCredly = () => {
  const [badgeSubmissions, setBadgeSubmissions] = useState<CredlyBadgeSubmissionDto[]>([]);
  const [hasNext, setHasNext] = useState<boolean>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { apiRequest } = useRequest();

  const getBadgeSubmissions = async () => {
    const credlyBadgeSubmissions = await apiRequest<Paginated<CredlyBadgeSubmissionDto>>(
      `academy/admin/orderBadges?page=${currentPage}&limit=10&credlyBadgeStatus[]=PENDING`,
      "GET",
    );
    setBadgeSubmissions(credlyBadgeSubmissions.data.docs);
    setHasNext(credlyBadgeSubmissions.data.hasNextPage);
  };

  useEffect(() => {
    getBadgeSubmissions();
  }, []);

  useEffect(() => {
    getBadgeSubmissions();
  }, [currentPage]);

  const approve = async (id: string) => {
    await apiRequest(`academy/admin/orderBadges/${id}/approve`, "PUT");
    getBadgeSubmissions();
  };

  const deleteBadgeSubmission = async (id: string) => {
    await apiRequest(`academy/admin/orderBadges/${id}`, "DELETE");
    getBadgeSubmissions();
  };

  return (
    <>
      <Section name="admin-badge-submissions">
        <SectionElement type="header" name="admin-badge-submissions">
          <Title name="admin-badge-submissions-title" type="h1" size="m">
            Credly Badges
          </Title>
        </SectionElement>
        <SectionElement type="body" name="admin-badge-submissions">
          <Component name="table-background">
            <Table name="admin-badge-submissions">
              <thead>
                <tr className="h--xxs">
                  <th>
                    <Paragraph name="admin-badge-submissions-badge-submission" type="paragraph">
                      Benutzer
                    </Paragraph>
                  </th>
                  <th>
                    <Paragraph name="admin-badge-submissions-login-state" type="paragraph">
                      Academy
                    </Paragraph>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {badgeSubmissions?.map((badgeSubmission) => {
                  return (
                    <tr key={badgeSubmission.academyProgressId}>
                      <TableColumn name="admin-col">
                        {badgeSubmission.user && <ProfileBubble user={badgeSubmission.user} />}
                      </TableColumn>
                      <TableColumn name="admin-col" className="main-content">
                        <Component name="admin-badge-submissions-login-state">
                          <Paragraph type="paragraph" name="admin-badge-submissions-login-state">
                            {badgeSubmission.academyTitle}
                          </Paragraph>
                        </Component>
                      </TableColumn>
                      <TableColumn>
                        <Component name="admin-badge-submissions-profile-state">
                          <Paragraph type="paragraph" name="admin-badge-submissions-profile-state">
                            {badgeSubmission.credlyBadgeStatus}
                          </Paragraph>
                        </Component>
                      </TableColumn>
                      <TableColumn>
                        {badgeSubmission.credlyBadgeStatus !== "PENDING" && (
                          <Component name="admin-badge-submissions-profile-state">
                            <Icon
                              name="badge-submissions-delete"
                              svg={<TrashSVG />}
                              className="danger"
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteBadgeSubmission(badgeSubmission.academyProgressId);
                              }}
                            />
                          </Component>
                        )}
                        {badgeSubmission.credlyBadgeStatus === "PENDING" && (
                          <>
                            <Component name="admin-badge-submissions-profile-state">
                              <Icon
                                name="resource-edit"
                                svg={<TickSVG />}
                                className="primary"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  approve(badgeSubmission.academyProgressId);
                                }}
                              />
                            </Component>
                          </>
                        )}
                      </TableColumn>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {hasNext !== undefined && (
              <TablePagination
                currentPage={currentPage}
                hasNext={hasNext}
                setCurrentPage={setCurrentPage}
              />
            )}
          </Component>
        </SectionElement>
      </Section>
    </>
  );
};

export default AdminCredly;
