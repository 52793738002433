import {
  HashtagsResponseDto,
  TopicsResponseDto,
  ResourceTypesDto,
  UserRolesDto,
  SkillResponseDto,
  Paginated,
  StorageFileDto,
  CredlyBadgeResponse,
  StripeResponseDto,
} from "../requests/interfaces";
import useRequest from "./useRequest";

export const useConsts = () => {
  const { apiRequest } = useRequest();

  const getHashtags = async () => {
    return {
      hashtags: (await apiRequest<HashtagsResponseDto>("general/hashtags", "GET")).data.hashtags,
    };
  };

  const getCredlyBadges = async () => {
    return {
      badges: (await apiRequest<CredlyBadgeResponse[]>("credly", "GET")).data,
    };
  };

  const getCredlyBadgeById = async (id: string) => {
    return {
      badge: (await apiRequest<CredlyBadgeResponse>(`credly/${id}`, "GET")).data,
    };
  };

  const getFiles = async () => {
    return {
      files: (await apiRequest<StorageFileDto[]>("academy/files", "GET")).data,
    };
  };

  const getStripeProducts = async () => {
    return {
      products: (await apiRequest<StripeResponseDto[]>("stripe", "GET")).data,
    };
  };

  const getQandATopics = async () => {
    return {
      topics: (await apiRequest<TopicsResponseDto>("general/topics", "GET")).data.topics,
    };
  };

  const getResourcesTypes = async () => {
    return {
      resourceTypes: (await apiRequest<ResourceTypesDto>("general/resourceTypes", "GET")).data
        .resourceTypes,
    };
  };

  const getUserRoles = async () => {
    return {
      userRoles: (await apiRequest<UserRolesDto>("general/userRoles", "GET")).data.userRoles,
    };
  };

  const getSkills = async () => {
    return {
      skills: (await apiRequest<Paginated<SkillResponseDto>>("skills?limit=50", "GET")).data.docs,
    };
  };

  return {
    getHashtags,
    getSkills,
    getQandATopics,
    getResourcesTypes,
    getUserRoles,
    getFiles,
    getCredlyBadges,
    getCredlyBadgeById,
    getStripeProducts,
  };
};
