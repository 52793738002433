import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ArrowSVG from "../../Icons/ArrowSVG";
import useFormOptions from "../../hooks/useFormOptions";
import historyNavigate from "../../hooks/useHistoryNavigate";
import useRequest from "../../hooks/useRequest";
import { JobDashboardContext } from "../../pages/JobDashboard";
import { JobPost, JobPostCreate } from "../../requests/interfaces";
import { ConfirmationDisplay } from "../Elements/Components";
import {
  AppView,
  BackButton,
  Button,
  Component,
  ComponentCard,
  Icon,
  Image,
  ImageUploader,
  Paragraph,
  Popup,
  Section,
  SectionElement,
  Title,
} from "../Elements/Elements";
import { Slider, TagSelect, TextInputElement } from "../Elements/FormElements";
import Navigation from "../Navigation/Navigation";
import Context from "../../context/Context";

enum ErrorFields {
  "COMPANYIMAGE",
  "TEAMIMAGE",
  "COMPANY",
  "POSITION",
  "EXPERIENCE",
  "SALARYSTART",
  "SALARYEND",
  "LOCATIONTYPE",
  "LOCATION",
  "INDUSTRY",
  "ICP",
  "TECHSTACK",
  "COMPANYSIZE",
  "LEARNINGANDDEVELOPMENT",
  "PROGRESSIONPLAN",
  "ONBOARDINGIONPLAN",
  "ROLESPEC",
  "ABOUTYOU",
  "COMPANYINFOS",
  "CALDENDLYLINK",
  "TARGETCOMISSIONSPLIT",
  "SALESREPS",
}

export enum JobDetailsMode {
  EDIT,
  READ,
  CREATE,
}

const JobDetailsContext = createContext<{
  mode: JobDetailsMode;
  job?: JobPost;
}>({
  mode: JobDetailsMode.READ,
});

const JobDetailsItemContext = createContext<{
  title: string;
}>({
  title: "",
});

const JobDetailsParent = (props: { mode: JobDetailsMode; id?: string }) => {
  const { isPublic } = useContext(JobDashboardContext);
  const [job, setJob] = useState<JobPost>();
  const { apiRequest } = useRequest();
  const { useHistoryNavigate } = historyNavigate();
  const locationUsed = useLocation();

  const getJob = async () => {
    if (props.id) {
      const response = await apiRequest<JobPost>(`jobs/${props.id}`, "GET");
      setJob(response.data);
      if (!response.data.isOwn && props.mode === JobDetailsMode.EDIT) {
        useHistoryNavigate(locationUsed.state?.prevLocation ?? "/");
      }
    }
  };

  useEffect(() => {
    getJob();
  }, []);

  return (
    <AppView name="job-details">
      {!isPublic && <Navigation />}
      <JobDetailsContext.Provider value={{ mode: props.mode, job }}>
        <JobDetails />
      </JobDetailsContext.Provider>
    </AppView>
  );
};

const JobDetails = () => {
  const [formOptions, setFormOptions] = useState<any>();
  const { mode, job } = useContext(JobDetailsContext);
  const { useHistoryNavigate } = historyNavigate();
  const { apiRequest } = useRequest();
  const locationUsed = useLocation();
  const [errorFields, setErrorFields] = useState<ErrorFields[]>([]);

  useEffect(() => {
    const setupOptions = async () => {
      setFormOptions(await useFormOptions().getFormOptions());
    };
    setupOptions();
  }, []);

  const [companySize, setCompanySize] = useState<string[]>([]);
  const [learningAndDevelopment, setLearningAndDevelopment] = useState<string[]>([]);
  const [onboardingPlan, setOnboardingPlan] = useState<string>("");
  const [targetComissionSplit, setTargetComissionSplit] = useState<string>("");
  const [salesReps, setSalesReps] = useState<string>("");
  const [progressionPlan, setProgressionPlan] = useState<string>("");
  const [roleSpec, setRoleSpec] = useState<string>("");
  const [aboutYou, setAboutYou] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [companyInfos, setCompanyInfos] = useState<string>("");
  const [calendlyLink, setCalendlyLink] = useState<string>("");
  const [teamImage, setTeamImage] = useState<File | null | string>(null);
  const [companyImage, setCompanyImage] = useState<File | null | string>(null);
  const [createdJobId, setCreatedJobId] = useState<string>();

  const [salaryStart, setSalaryStart] = useState<number>(0);
  const [salaryEnd, setSalaryEnd] = useState<number>(0);

  const [position, setPosition] = useState<string[]>([]);
  const [experience, setExperience] = useState<string[]>([]);
  const [locationType, setLocationType] = useState<string[]>([]);
  const [location, setLocation] = useState<string>("");
  const [industry, setIndustry] = useState<string[]>([]);
  const [icp, setIcp] = useState<string[]>([]);
  const [techStack, setTechStack] = useState<string[]>([]);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState<boolean>(false);
  const [featureDialogIsOpen, setFeatureDialogIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (mode !== JobDetailsMode.CREATE && job) {
      setCompanySize([job?.companySize]);
      setLearningAndDevelopment(job?.learningAndDevelopment);
      setOnboardingPlan(job?.teamAthmosphere);
      setProgressionPlan(job?.progressionPlan);
      setRoleSpec(job?.roleSpec);
      setAboutYou(job?.aboutYou);
      setCompany(job?.company);
      setCompanyInfos(job?.companyInfos);
      setCalendlyLink(job?.caldendlyLink);
      setSalaryStart(job?.salaryStart);
      setSalaryEnd(job?.salaryEnd);
      setPosition([job?.position]);
      setExperience([job?.experience]);
      setLocation(job?.location);
      setLocationType([job?.locationType]);
      setIndustry([job?.industry]);
      setIcp(job?.icp);
      setTechStack(job?.techStack);
      setTargetComissionSplit(job?.targetComissionSplit);
      setSalesReps(job?.salesReps);
      setTeamImage(job?.teamImage);
      setCompanyImage(job?.companyImage);
    }
  }, [job]);

  const onClickAction = async (feature?: boolean) => {
    if (mode === JobDetailsMode.READ) {
      window.open(calendlyLink !== "" ? calendlyLink : job?.caldendlyLink, "_blank");
    } else if (feature) {
      setFeatureDialogIsOpen(true);
    } else {
      const isValid = validate(true);
      if (isValid) {
        const request: JobPostCreate = {
          teamImage: typeof teamImage === "string" ? teamImage : "",
          companyImage: typeof companyImage === "string" ? companyImage : "",
          company: company,
          position: position[0],
          experience: experience[0],
          salaryStart: salaryStart,
          salaryEnd: salaryEnd,
          isFeatured: false,
          location: location,
          locationType: locationType[0],
          industry: industry[0],
          icp: icp,
          techStack: techStack,
          companySize: companySize[0],
          learningAndDevelopment: learningAndDevelopment,
          teamAthmosphere: onboardingPlan,
          progressionPlan: progressionPlan,
          roleSpec: roleSpec,
          aboutYou: aboutYou,
          companyInfos: companyInfos,
          caldendlyLink: calendlyLink,
          targetComissionSplit: targetComissionSplit,
          salesReps: salesReps,
        };

        const res = await apiRequest<JobPost>(
          job ? `jobs/${job._id}` : "jobs",
          job ? "PATCH" : "POST",
          {
            body: request,
            showToast: true,
            toastText: "Erfolgreich Job " + (job ? "geändert" : "hinzugefügt"),
          },
        );

        if (!job) {
          setCreatedJobId(res.data._id);
          setFeatureDialogIsOpen(true);
        } else {
          useHistoryNavigate(locationUsed.state?.prevLocation ?? "/");
        }
      }
    }
  };

  const validate = (withError: boolean) => {
    const errors = [];
    if (companySize.length === 0) {
      errors.push(ErrorFields.COMPANYSIZE);
    }
    if (learningAndDevelopment.length === 0) {
      errors.push(ErrorFields.LEARNINGANDDEVELOPMENT);
    }
    if (onboardingPlan === "") {
      errors.push(ErrorFields.ONBOARDINGIONPLAN);
    }
    if (targetComissionSplit === "") {
      errors.push(ErrorFields.TARGETCOMISSIONSPLIT);
    }
    if (salesReps === "") {
      errors.push(ErrorFields.SALESREPS);
    }
    if (progressionPlan === "") {
      errors.push(ErrorFields.PROGRESSIONPLAN);
    }
    if (roleSpec === "") {
      errors.push(ErrorFields.ROLESPEC);
    }
    if (aboutYou === "") {
      errors.push(ErrorFields.ABOUTYOU);
    }
    if (company === "") {
      errors.push(ErrorFields.COMPANY);
    }
    if (companyInfos === "") {
      errors.push(ErrorFields.COMPANYINFOS);
    }
    if (calendlyLink === "" || !/(https?:\/\/[^\s]+)/g.test(calendlyLink)) {
      errors.push(ErrorFields.CALDENDLYLINK);
    }
    if (teamImage === "" || teamImage === null) {
      errors.push(ErrorFields.TEAMIMAGE);
    }
    if (companyImage === "" || companyImage === null) {
      errors.push(ErrorFields.COMPANYIMAGE);
    }
    if (salaryStart === 0) {
      errors.push(ErrorFields.SALARYSTART);
    }
    if (salaryEnd === 0) {
      errors.push(ErrorFields.SALARYEND);
    }
    if (position.length === 0) {
      errors.push(ErrorFields.POSITION);
    }
    if (experience.length === 0) {
      errors.push(ErrorFields.EXPERIENCE);
    }
    if (locationType.length === 0) {
      errors.push(ErrorFields.LOCATIONTYPE);
    }
    if (location === "") {
      errors.push(ErrorFields.LOCATION);
    }
    if (industry.length === 0) {
      errors.push(ErrorFields.INDUSTRY);
    }
    if (icp.length === 0) {
      errors.push(ErrorFields.ICP);
    }
    if (techStack.length === 0) {
      errors.push(ErrorFields.TECHSTACK);
    }
    if (withError && errors.length !== 0) {
      setErrorFields(errors);
      return false;
    }
    return true;
  };

  useEffect(() => {
    validate(false);
  }, [
    companySize,
    learningAndDevelopment,
    onboardingPlan,
    targetComissionSplit,
    salesReps,
    progressionPlan,
    roleSpec,
    aboutYou,
    company,
    companyInfos,
    calendlyLink,
    teamImage,
    companyImage,
    salaryStart,
    salaryEnd,
    position,
    experience,
    locationType,
    location,
    industry,
    icp,
    techStack,
  ]);

  const deleteAction = async (id: string) => {
    setDeleteDialogIsOpen(false);
    await apiRequest(`jobs/${id}`, "DELETE", {
      toastText: "Erfolgreich Job gelöscht",
      showToast: true,
    });
    useHistoryNavigate(locationUsed.state?.prevLocation ?? "/");
  };

  return (
    <Section name="job-details">
      <SectionElement name="job-details" type="body">
        {formOptions && (
          <Component name="job-details">
            <Component name="job-details-header">
              <BackButton />
              <Button
                name="job-details-save"
                type="primary"
                onClick={() => {
                  onClickAction(mode === JobDetailsMode.EDIT);
                  // setSaveDialogIsOpen(true);
                }}>
                {mode === JobDetailsMode.READ && "jetzt bewerben"}
                {mode === JobDetailsMode.CREATE && "Speichern"}
                {mode === JobDetailsMode.EDIT && "Jetzt Job Post featuren"}
              </Button>
            </Component>
            <Component name="job-details-topic">
              <JobDetailsItemGroup title="Allgemeine Infos">
                <Component name="job-overall-infos">
                  <Component name="job-overall-infos-content">
                    {mode === JobDetailsMode.READ ? (
                      <ComponentCard name="job-overall-infos-content-card">
                        {job && (
                          <CombinedItems
                            items={[
                              {
                                category: "Position",
                                text: position
                                  .map((value) => formOptions.getLabelById(value).name)
                                  .join(", "),
                              },
                              {
                                category: "Gehalt",
                                text: `${salaryStart}k - ${salaryEnd}k OTE`,
                              },
                              {
                                category: "Erfahrung",
                                text: experience
                                  .map((value) => formOptions.getLabelById(value).name)
                                  .join(", "),
                              },
                              {
                                category: "Arbeitstyp",
                                text: locationType
                                  .map((value) => formOptions.getLabelById(value).name)
                                  .join(", "),
                              },
                              {
                                category: "Industrie",
                                text: industry
                                  .map((value) => formOptions.getLabelById(value).name)
                                  .join(", "),
                              },
                              {
                                category: "ICP",
                                text: icp
                                  .map((value) => formOptions.getLabelById(value).name)
                                  .join(", "),
                              },
                            ]}
                          />
                        )}
                        {/* <Component name="job-details-combined-infos">
                          <CombinedItem
                            text="Position"
                            title={position
                              .map((value) => formOptions.getLabelById(value).name)
                              .join(", ")}
                          />
                          <CombinedItem
                            text="Gehalt"
                            title={`${salaryStart}k - ${salaryEnd}k OTE`}
                          />
                          <CombinedItem
                            text="Erfahrung"
                            title={experience
                              .map((value) => formOptions.getLabelById(value).name)
                              .join(", ")}
                          />
                          <CombinedItem text="Standort" title={location} />
                          <CombinedItem
                            text="Arbeitstyp"
                            title={locationType
                              .map((value) => formOptions.getLabelById(value).name)
                              .join(", ")}
                          />
                          <CombinedItem
                            text="Industrie"
                            title={industry
                              .map((value) => formOptions.getLabelById(value).name)
                              .join(", ")}
                          />
                          <CombinedItem
                            text="ICP"
                            title={icp
                              .map((value) => formOptions.getLabelById(value).name)
                              .join(", ")}
                          />
                        </Component> */}
                        <Component name="job-details-image-info logo">
                          {/* {job?.learningAndDevelopment.includes("masterclass_academy") && (
                            <Icon name="job-details-image-info-academy-icon" svg={<AcademySVG />} />
                          )} */}
                          <Image name="job-details-image-info-logo" img={job?.companyImage} />
                        </Component>
                      </ComponentCard>
                    ) : (
                      <>
                        <JobDetailsItem
                          title={"Position"}
                          editInfo="Welche Rolle soll besetzt werden?"
                          hasError={errorFields?.includes(ErrorFields.POSITION) ?? false}>
                          <JobDetailsTagItem
                            options={formOptions.positionJobBoardOptions}
                            setValues={setPosition}
                            type="radio"
                            values={position}
                          />
                        </JobDetailsItem>
                        <JobDetailsItem
                          hasError={errorFields?.includes(ErrorFields.CALDENDLYLINK) ?? false}
                          title={"Bewerber Link"}
                          editInfo="Wohin soll der Bewerber weitergeleitet werden? Direkt auf eure Bewerberseite oder direkt zum Kalender des Recruiters/Hiring Managers? Fügt hier den passenden Link für euch hinzu.">
                          <JobDetailsTextItem
                            value={calendlyLink}
                            setValue={setCalendlyLink}
                            type={"text"}
                          />
                        </JobDetailsItem>
                        <JobDetailsItem
                          title={"Gehalt"}
                          editInfo=""
                          hasError={
                            (errorFields?.includes(ErrorFields.SALARYSTART) ||
                              errorFields?.includes(ErrorFields.SALARYEND)) ??
                            false
                          }>
                          <JobDetailsRangeItem
                            startValue={salaryStart}
                            setStartValue={setSalaryStart}
                            endValue={salaryEnd}
                            setEndValue={setSalaryEnd}
                          />
                        </JobDetailsItem>
                        <JobDetailsItem
                          title={"Erfahrung"}
                          editInfo=""
                          hasError={errorFields?.includes(ErrorFields.EXPERIENCE) ?? false}>
                          <JobDetailsTagItem
                            options={formOptions.yearsExperienceOptions}
                            setValues={setExperience}
                            type="radio"
                            values={experience}
                          />
                        </JobDetailsItem>
                        <JobDetailsItem
                          title={"Arbeitstyp"}
                          editInfo=""
                          hasError={errorFields?.includes(ErrorFields.LOCATIONTYPE) ?? false}>
                          <JobDetailsTagItem
                            options={formOptions.locationOptions}
                            setValues={setLocationType}
                            type="radio"
                            values={locationType}
                          />
                        </JobDetailsItem>
                        <JobDetailsItem
                          title={"Standort"}
                          editInfo=""
                          hasError={errorFields?.includes(ErrorFields.LOCATION) ?? false}>
                          <JobDetailsTextItem
                            value={location}
                            setValue={setLocation}
                            type={"text"}
                          />
                        </JobDetailsItem>
                        <JobDetailsItem
                          title={"Industrie"}
                          editInfo="In welcher Industrie operiert dein Unternehmen?"
                          hasError={errorFields?.includes(ErrorFields.INDUSTRY) ?? false}>
                          <JobDetailsTagItem
                            options={formOptions.industryOptions}
                            setValues={setIndustry}
                            type="radio"
                            values={industry}
                          />
                        </JobDetailsItem>
                        <JobDetailsItem
                          title={"ICP"}
                          editInfo="An wen verkauft ihr?"
                          hasError={errorFields?.includes(ErrorFields.ICP) ?? false}>
                          <JobDetailsTagItem
                            options={formOptions.icpOptions}
                            setValues={setIcp}
                            type="checkbox"
                            values={icp}
                          />
                        </JobDetailsItem>
                        <JobDetailsItem
                          hasError={errorFields?.includes(ErrorFields.COMPANYIMAGE) ?? false}
                          title={"Logo"}
                          editInfo="Hier kommt das Logo deiner Firma hin">
                          <JobDetailsImageItem
                            value={companyImage}
                            setValue={setCompanyImage}
                            imageRoute="companyImage"
                            id={job?._id}
                          />
                        </JobDetailsItem>
                        <JobDetailsItem
                          hasError={errorFields?.includes(ErrorFields.TEAMIMAGE) ?? false}
                          title={"Team Foto"}
                          editInfo="Wenn ihr gerade ein tolles Teamfoto habt, könnt ihr dieses hier hochladen">
                          <JobDetailsImageItem
                            value={teamImage}
                            setValue={setTeamImage}
                            imageRoute="teamImage"
                            id={job?._id}
                          />
                        </JobDetailsItem>
                      </>
                    )}
                  </Component>
                  {mode === JobDetailsMode.READ && (
                    <Component name="job-overall-infos-photos">
                      <Component name="job-details-image-info">
                        <Image name="job-details-image-info" img={job?.teamImage} />
                      </Component>
                    </Component>
                  )}
                </Component>
              </JobDetailsItemGroup>
            </Component>
            <Component name="job-details-sdrs-questions">
              <JobDetailsItemGroup title="Die SDRs of Germany Fragen für dich">
                <JobDetailsItem
                  hasError={errorFields?.includes(ErrorFields.TECHSTACK) ?? false}
                  title={"Techstack"}
                  editInfo="Welche Sales Software nutzt ihr intern, um eure Seller zu unterstützen?">
                  <JobDetailsTagItem
                    options={formOptions.techstackOptions}
                    setValues={setTechStack}
                    type="checkbox"
                    values={techStack}
                  />
                </JobDetailsItem>
                <JobDetailsItem
                  title={"Target"}
                  editInfo="Welches Target habt ihr definiert? "
                  hasError={errorFields?.includes(ErrorFields.TARGETCOMISSIONSPLIT) ?? false}>
                  <JobDetailsTextItem
                    value={targetComissionSplit}
                    setValue={setTargetComissionSplit}
                    type={"text"}
                  />
                </JobDetailsItem>
                <JobDetailsItem
                  hasError={errorFields?.includes(ErrorFields.SALESREPS) ?? false}
                  title={"Wie viele Sales Reps erreichen ihre Quota?"}
                  editInfo="Ein Target im Sales zu haben, ist nur dann erfolgreich, wenn es auch realistisch ist! Teilt hier die Infos zum Team und wie viele ihre Quota erreichen. ">
                  <JobDetailsTextItem value={salesReps} setValue={setSalesReps} type={"text"} />
                </JobDetailsItem>
                <JobDetailsItem
                  hasError={errorFields?.includes(ErrorFields.LEARNINGANDDEVELOPMENT) ?? false}
                  title={"Learning & Development"}
                  editInfo="Welche Weiterbildungsmöglichkeiten bietet ihr intern, um eure Seller zu unterstützen?">
                  <JobDetailsTagItem
                    options={formOptions.learningAndDevelopmentOptions}
                    setValues={setLearningAndDevelopment}
                    type="checkbox"
                    values={learningAndDevelopment}
                  />
                </JobDetailsItem>
                <JobDetailsItem
                  hasError={errorFields?.includes(ErrorFields.ONBOARDINGIONPLAN) ?? false}
                  title={"Onboarding Plan"}
                  editInfo="Wie sieht euer Onboarding Plan aus? Gibt es eine Ramp Up Phase? Bekommt euer Hire einen Buddy? Teilt alles zum Thema Onboarding im Unternehmen.">
                  <JobDetailsTextItem
                    value={onboardingPlan}
                    setValue={setOnboardingPlan}
                    type={"textarea"}
                  />
                </JobDetailsItem>
                <JobDetailsItem
                  hasError={errorFields?.includes(ErrorFields.PROGRESSIONPLAN) ?? false}
                  title={"Progression Plan"}
                  editInfo="Wir wollen, dass eure Hire verstehen, wie sie sich bei euch im Unternehmen weiterentwickeln können. Teilt hier alle Infos zum Thema Weiterentwicklung + mögliche Beförderungen.">
                  <JobDetailsTextItem
                    value={progressionPlan}
                    setValue={setProgressionPlan}
                    type={"textarea"}
                  />
                </JobDetailsItem>
              </JobDetailsItemGroup>
            </Component>
            <Component name="job-details-about-job">
              <JobDetailsItemGroup title="Über den Job">
                <Title
                  name="job-details-category-title"
                  className="txt--c-surface-on-l"
                  type="h2"
                  size="m"></Title>
                <JobDetailsItem
                  hasError={errorFields?.includes(ErrorFields.ROLESPEC) ?? false}
                  title={"Deine Rolle"}
                  editInfo="Welche Rolle im Unternehmen würden die neuen Hire übernehmen?">
                  <JobDetailsTextItem value={roleSpec} setValue={setRoleSpec} type={"textarea"} />
                </JobDetailsItem>
                <JobDetailsItem
                  hasError={errorFields?.includes(ErrorFields.ABOUTYOU) ?? false}
                  title={"Über dich"}
                  editInfo="Hier könnt ihr alle Infos zu eurem idealen Hire angeben und so die richtigen Leute ansprechen! Wir empfehlen, realistisch zu bleiben.">
                  <JobDetailsTextItem value={aboutYou} setValue={setAboutYou} type={"textarea"} />
                </JobDetailsItem>
              </JobDetailsItemGroup>
            </Component>
            <Component name="job-details-about-company">
              <JobDetailsItemGroup title="Über das Unternehmen">
                <JobDetailsItem
                  title={"Unternehmensnamen"}
                  editInfo=""
                  hasError={errorFields?.includes(ErrorFields.COMPANY) ?? false}>
                  <JobDetailsTextItem value={company} setValue={setCompany} type={"text"} />
                </JobDetailsItem>
                <JobDetailsItem
                  title={"Unternehmensart"}
                  editInfo="Wo steht ihr als Unternehmen?"
                  hasError={errorFields?.includes(ErrorFields.COMPANYSIZE) ?? false}>
                  <JobDetailsTagItem
                    options={formOptions.companySizeOptions}
                    setValues={setCompanySize}
                    type="radio"
                    values={companySize}
                  />
                </JobDetailsItem>
                <JobDetailsItem
                  hasError={errorFields?.includes(ErrorFields.COMPANYINFOS) ?? false}
                  title={"Unternehmensbeschreibung"}
                  editInfo="Jetzt geht’s um euch! Gebt alle Informationen an, die für eure idealen Hire relevant sein könnten! Ob Start Up mit Share Option oder Corporate mit Rentenversicherung, wie stellt ihr sicher, dass eure Hire eine erfolgreiche Zeit bei euch haben?">
                  <JobDetailsTextItem
                    value={companyInfos}
                    setValue={setCompanyInfos}
                    type={"textarea"}
                  />
                </JobDetailsItem>
              </JobDetailsItemGroup>
            </Component>
            <Component name="job-details-action">
              <Button
                name="job-details-save"
                type="primary"
                onClick={() => {
                  onClickAction();
                }}>
                {mode === JobDetailsMode.READ ? "jetzt bewerben" : "Speichern"}
              </Button>
              {mode === JobDetailsMode.EDIT && (
                <Button
                  name="job-details-delete"
                  type="danger"
                  onClick={() => {
                    setDeleteDialogIsOpen(true);
                  }}>
                  löschen
                </Button>
              )}
            </Component>
            <ConfirmationDisplay
              action={() => deleteAction(job?._id ?? "")}
              popupIsOpen={deleteDialogIsOpen}
              setPopupIsOpen={setDeleteDialogIsOpen}
              title="Bist du dir sicher, diesen Job zu löschen?"
            />
            <FeaturePopup
              popupIsOpen={featureDialogIsOpen}
              setPopupIsOpen={setFeatureDialogIsOpen}
              id={createdJobId ?? job?._id ?? ""}
              mode={mode}
            />
          </Component>
        )}
      </SectionElement>
    </Section>
  );
};

const FeaturePopup = (props: {
  popupIsOpen: boolean;
  setPopupIsOpen: (value: boolean) => void;
  id: string;
  mode: JobDetailsMode;
}) => {
  const { setPopupContent } = useContext(Context);
  const { apiRequest } = useRequest();
  const { useHistoryNavigate } = historyNavigate();
  const locationUsed = useLocation();

  const getPaymentLink = async (type: "small" | "medium" | "large") => {
    const res = await apiRequest<{ url: string }>(`jobs/paymentLink/${props.id}/${type}`, "GET");
    if (res.data) {
      props.setPopupIsOpen(false);
      window.open(res.data.url, "_blank");
    } else {
      props.setPopupIsOpen(false);
    }
    props.setPopupIsOpen(false);
    useHistoryNavigate(locationUsed.state?.prevLocation ?? "/");
  };

  useEffect(() => {
    if (setPopupContent) {
      if (props.popupIsOpen) {
        setPopupContent(<Content />);
      } else {
        setPopupContent(undefined);
      }
    }
  }, [props.popupIsOpen]);

  const Content = () => {
    return (
      <Popup setPopupIsOpen={props.setPopupIsOpen} size="SMALL">
        <Component name="confirmation-dialog">
          <Title type="h2" size="s" name="report-popup-title">
            {props.mode === JobDetailsMode.CREATE
              ? "Dein Job wurde gepostet! 👏"
              : "Willst du deinen Job feautern?"}
          </Title>
          <Paragraph type="paragraph" size="m" name="feature-popup-small-text">
            Wenn du schneller von unserer Community gesehen werden möchtest und damit schneller
            recruiten willst, feature deinen Job ganz einfach hier:
          </Paragraph>
          <Component name="confirmation-buttons">
            <Component name="confirmation-buttons-row">
              <Button
                type={"primaryGhost"}
                onClick={async () => {
                  getPaymentLink("small");
                }}
                name="confirmation-submit">
                1 Monat
              </Button>
              <Button
                type={"primaryGhost"}
                onClick={async () => {
                  getPaymentLink("medium");
                }}
                name="confirmation-submit">
                3 Monate
              </Button>
            </Component>
            <Component name="confirmation-buttons-row">
              <Button
                type={"primaryGhost"}
                onClick={async () => {
                  getPaymentLink("large");
                }}
                name="confirmation-submit">
                6 Monate
              </Button>
              <Button
                type={"dangerGhost"}
                onClick={() => {
                  props.setPopupIsOpen(false);
                }}
                name="confirmation-break">
                Nein danke
              </Button>
            </Component>
          </Component>
        </Component>
      </Popup>
    );
  };

  return <></>;
};

const JobDetailsItemGroup = (props: { title: string; children: any }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    <>
      <Component name="job-details-category" onClick={() => setIsOpen((value) => !value)}>
        <Title name="job-details-category-title" className="txt--c-pri" type="h2" size="m">
          {props.title}
        </Title>
        <Component name="job-details-item-arrow" className={isOpen ? "open" : ""}>
          <Icon name="job" svg={<ArrowSVG />} className="txt--c-surface-on-l"></Icon>
        </Component>
      </Component>
      <>{isOpen && <>{props.children}</>}</>
    </>
  );
};

const JobDetailsItem = (props: {
  title: string;
  children: any;
  editInfo: string;
  hasError: boolean;
}) => {
  const { mode } = useContext(JobDetailsContext);

  // const [isOpen, setIsOpen] = useState<boolean>(false);

  if (mode === undefined) {
    return <></>;
  }

  return (
    // <ComponentCard name="job-details-item" onClick={() => setIsOpen((value) => !value)}>
    <ComponentCard name="job-details-item" className={props.hasError ? "error" : ""}>
      <Component name="job-details-item-title">
        <Title name="job-details-item-title" type="h2" size="xs">
          {props.title}
        </Title>
        {mode !== JobDetailsMode.READ && (
          <Paragraph
            name="job-details-item-info"
            className="txt--c-surface-on-l"
            type="paragraph"
            size="m">
            {props.editInfo}
          </Paragraph>
        )}
        {/* <Component name="job-details-item-arrow" className={isOpen ? "open" : ""}>
          <Icon name="job" svg={<ArrowSVG />}></Icon>
        </Component> */}
      </Component>
      <JobDetailsItemContext.Provider value={{ title: props.title }}>
        {/* {isOpen && <>{props.children}</>} */}
        {props.children}
      </JobDetailsItemContext.Provider>
    </ComponentCard>
  );
};

const JobDetailsImageItem = (props: {
  value: File | null | string;
  setValue: Dispatch<SetStateAction<File | null | string>>;
  imageRoute: string;
  id?: string;
}) => {
  const { mode } = useContext(JobDetailsContext);
  const [reload, setReload] = useState<boolean>(false);

  const changeImage = (e: any) => {
    props.setValue(e.target.files[0]);
  };

  const onImageUploaded = (url: string) => {
    if (url.length !== 0) {
      props.setValue(url);
    } else {
      setReload((value) => !value);
    }
  };

  useEffect(() => {
    // reload
  }, [reload]);

  return (
    <>
      {mode === JobDetailsMode.READ ? (
        <Image name="job-details-image-info" img={props.value} />
      ) : (
        <ImageUploader
          img={props.value}
          type="large"
          onImageUploaded={onImageUploaded}
          onChange={changeImage}
          route={`jobs/${props.imageRoute}${props.id ? `/${props.id}` : ""}`}
          method={props.id ? "PUT" : "POST"}
        />
      )}
    </>
  );
};

const JobDetailsTextItem = (props: {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  type: "text" | "textarea";
}) => {
  const { mode } = useContext(JobDetailsContext);
  const { title } = useContext(JobDetailsItemContext);

  return (
    <>
      {mode === JobDetailsMode.READ ? (
        <Paragraph name="job-details-text-info" type="paragraph" size="m" withLineBreaks={true}>
          {props.value}
        </Paragraph>
      ) : (
        <TextInputElement
          id={title}
          type={props.type}
          labelClasses="p--l"
          placeHolder={title}
          value={props.value}
          onInput={(e) => {
            props.setValue((e.target as HTMLInputElement).value);
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      )}
    </>
  );
};

const toggleItem = (value: any, setArrayState: (value: any) => void, prevArray: any[]) => {
  const index = prevArray.indexOf(value);
  const newArr = [...prevArray];
  if (index > -1) {
    newArr.splice(index, 1);
  } else {
    newArr.push(value);
  }
  setArrayState(newArr);
};

const JobDetailsTagItem = (props: {
  values: string[];
  setValues: Dispatch<SetStateAction<string[]>>;
  options: {
    id: string;
    name: string;
  }[];
  type: "checkbox" | "radio";
}) => {
  const { mode } = useContext(JobDetailsContext);
  const { title } = useContext(JobDetailsItemContext);

  const [formOptions, setFormOptions] = useState<any>();

  useEffect(() => {
    const setupOptions = async () => {
      setFormOptions(await useFormOptions().getFormOptions());
    };
    setupOptions();
  }, []);

  // getLabelById

  return (
    <>
      {mode === JobDetailsMode.READ && formOptions ? (
        <Paragraph name="job-details-text-info" type="paragraph" size="m">
          {props.values.map((value) => formOptions.getLabelById(value).name).join(", ")}
        </Paragraph>
      ) : (
        <TagSelect
          id={title}
          labelClasses="p--l"
          optionLabelClasses="p--m"
          name="cs-industry"
          isHidden={true}
          options={props.options}
          type={props.type}
          onChange={(e) => {
            if (props.type === "checkbox") {
              toggleItem((e.target as HTMLInputElement).id, props.setValues, props.values);
            } else {
              props.setValues([(e.target as HTMLInputElement).id]);
            }
          }}
          value={props.values.join(",")}
        />
      )}
    </>
  );
};

const JobDetailsRangeItem = (props: {
  startValue: number;
  setStartValue: Dispatch<SetStateAction<number>>;
  endValue: number;
  setEndValue: Dispatch<SetStateAction<number>>;
}) => {
  const { mode } = useContext(JobDetailsContext);
  const { title } = useContext(JobDetailsItemContext);

  return (
    <>
      {mode === JobDetailsMode.READ ? (
        <Paragraph name="job-details-text-info" type="paragraph" size="m">
          {props.startValue} - {props.endValue} OTC
        </Paragraph>
      ) : (
        <>
          <Slider
            id={title + "start"}
            label={title + " Base"}
            maxValue={150}
            minValue={25}
            showMinAndMaxValue={true}
            factor={1000}
            labelClasses="p--l"
            value={props.startValue}
            onInput={(e) =>
              props.setStartValue((e.target as HTMLInputElement).value as unknown as number)
            }
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
          <Slider
            withPlus={true}
            id={title + "end"}
            label={title + " OTE"}
            maxValue={150}
            minValue={25}
            showMinAndMaxValue={true}
            factor={1000}
            labelClasses="p--l"
            value={props.endValue}
            onInput={(e) =>
              props.setEndValue((e.target as HTMLInputElement).value as unknown as number)
            }
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </>
      )}
    </>
  );
};

const CombinedItems = (props: { items: { category: string; text: string }[] }) => {
  const categories = props.items.map((item) => item.category);
  const texts = props.items.map((item) => item.text);

  return (
    <Component name="job-combined-items">
      <Component name="job-combined-items-categories">
        {categories.map((category, i) => {
          return (
            <Component key={i} name="job-combined-items-category">
              <Paragraph name="job-details-text-info" type="paragraph" size="m">
                {category}
              </Paragraph>
            </Component>
          );
        })}
      </Component>
      <Component name="job-combined-items-category-values">
        {texts.map((text, i) => {
          return (
            <Component key={i} name="job-combined-items-category-value">
              <Title name="job-details-item-title" type="h2" size="xs">
                {text}
              </Title>
            </Component>
          );
        })}
      </Component>
    </Component>
  );
};

export { JobDetailsParent };
