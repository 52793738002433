import { Banned as BannedComponent } from "../components/Banned/Banned";
import { Main, Page } from "../components/Elements/Elements";

const Banned = () => {
  return (
    <Page name="banned">
      <Main>
        <BannedComponent />
      </Main>
    </Page>
  );
};

export default Banned;
