import { Main, Page } from "../components/Elements/Elements";
import Sidebar from "../components/Navigation/Sidebar";
import { Home as HomeComponent } from "../components/Home/Home";

const Home = () => {
  return (
    <Page name="home">
      <Sidebar />
      <Main>
        <HomeComponent />
      </Main>
    </Page>
  );
};

export default Home;
